const roomStatus: {name: string; event: string}[] = [
	{
		name: 'LIVE',
		event: 'on_air',
	},
	{
		name: 'SOON',
		event: 'soon',
	},
	{
		name: 'DISABLED',
		event: 'unavailable',
	},
	{
		name: 'ENDED',
		event: 'ended',
	},
];

export {roomStatus};
