import {useLocalObservable} from 'mobx-react-lite';
import {useEffect, useRef} from 'react';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message} from 'models/room';
import TranslateService from 'services/api/TranslateService';
import appService from 'store/appService';
import roomService from 'store/roomService';
import userService from 'store/userService';
import threadService from 'store/threadService';
import useUser from './useUser';

const useTranslation = () => {
	const {accessToken, translateMode, setTranslateMode, userData} = useLocalObservable(
		() => userService
	);
	const {translateLangs, setTranslateLangs} = useLocalObservable(() => appService);
	const threadStore = useLocalObservable(() => threadService);
	const roomStore = useLocalObservable(() => roomService);

	const {userExtraDataPatchTranslateMode} = useUser();

	const isThread = useRef<boolean>(false);

	useEffect(() => {
		isThread.current = !!threadStore.currentThreadId;
	}, [threadStore.currentThreadId]);

	const updateMessagesTranslation = (messages: Message[], lang: string) => {
		isThread.current
			? threadStore.updateMessagesTranslation(messages, lang)
			: roomStore.updateMessagesTranslation(messages, lang);
	};

	const getTranslateLangs = async (disaplayLang: string) => {
		if (accessToken) {
			const response = await TranslateService.getTranslateLangs(accessToken, disaplayLang);
			if (response.status === ResponseStatus.SUCCESS) {
				setTranslateLangs(
					response.data.map((el: {languageCode: string; displayName: string}) => {
						return {languageCode: el.languageCode, displayName: el.displayName};
					})
				);
			}
		}
	};

	const toggleIsMessageTranslateInProgress = (value: boolean) => {
		isThread.current
			? threadStore.toggleIsMessageTranslateInProgress(value)
			: roomStore.toggleIsMessageTranslateInProgress(value);
	};

	const translateMessages = async (lang: string, msgs?: Message[]) => {
		const messages = isThread.current ? threadStore.messages : roomStore.messages;
		const messagesToTranslate = msgs || messages;
		if (accessToken) {
			const filteredMessages = messagesToTranslate
				.filter(
					msg =>
						msg.text &&
						(!msg.translate?.text || (msg.translate?.text && msg.translate?.lang !== lang))
				)
				.map(el => {
					return {id: el.id, text: el.text};
				});

			const mentionMessages = messagesToTranslate
				.filter(
					el =>
						el.mentionMessage &&
						el.mentionMessage.text &&
						(!el.mentionMessage.translate?.text ||
							(el.mentionMessage.translate?.text && el.mentionMessage.translate?.lang !== lang))
				)
				?.map(el => {
					return {id: el.mentionMessage?.id || 0, text: el.mentionMessage?.text || ''};
				});

			if (mentionMessages.length) {
				mentionMessages.forEach(msg => {
					if (!filteredMessages.map(el => el.id).find(id => id === msg.id))
						filteredMessages.push(msg);
				});
			}

			if (roomStore.pinnedMessages?.length && roomStore.activePinnedMessage)
				filteredMessages.push({
					id: roomStore.activePinnedMessage.id,
					text: roomStore.activePinnedMessage.text,
				});

			if (filteredMessages.length) {
				toggleIsMessageTranslateInProgress(true);
				const response = await TranslateService.translateMessages(
					accessToken,
					lang,
					filteredMessages
				);
				toggleIsMessageTranslateInProgress(false);
				if (response.status === ResponseStatus.SUCCESS) {
					if (response.data.length) updateMessagesTranslation(response.data, lang);
				}
			}
		}
	};

	const translateMessage = async (lang: string, message: Message) => {
		if (accessToken) {
			const body = [
				{
					id: message.id,
					text: message.text,
				},
			];
			if (message.mentionMessage) {
				body.push({id: message.mentionMessage.id, text: message.mentionMessage.text});
			}

			const response = await TranslateService.translateMessages(accessToken, lang, body);
			if (response.status === ResponseStatus.SUCCESS) {
				if (response.data.length) updateMessagesTranslation(response.data, lang);
			}
		}
	};

	const turnOnTranslation = () => {
		setTranslateMode({...translateMode, enable: true});
		const lang = translateMode.lang.languageCode || translateLangs[0].languageCode;
		const data = {
			enable: true,
			lang: {
				displayName: translateMode.lang.displayName || translateLangs[0].displayName,
				languageCode: lang,
			},
		};
		userData && userExtraDataPatchTranslateMode(userData, data);
		translateMessages(lang);
	};

	return {getTranslateLangs, translateMessages, translateMessage, turnOnTranslation};
};

export default useTranslation;
