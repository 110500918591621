import {useCallback, useEffect, useRef, useState} from 'react';

const useSwipe = (callback: (e: any) => void, swipeLeft?: () => void) => {
	const firstClientX = useRef<number>(0);
	const firstClientY = useRef<number>(0);

	const start = useCallback((e: TouchEvent) => {
		if (e?.touches?.length) {
			firstClientX.current = e.touches[0].clientX;
			firstClientY.current = e.touches[0].clientY;
		}
	}, []);

	const end = (e: any) => {
		const threshold = 50;
		const swipeSpeed = 1; // sec;
		const touchEndX = e.changedTouches[0].clientX;
		const touchStartX = firstClientX.current;
		const touchEndY = e.changedTouches[0].clientY;
		const touchStartY = firstClientY.current;
		// const elapsedTime = (Date.now() - touchCoordsRef.current.touchStart.time) / 1000;
		// if (elapsedTime > swipeSpeed) {
		// 	return;
		// }
		const xDistance = touchStartX - touchEndX;
		const yDistance = touchStartY - touchEndY;

		if (Math.abs(xDistance) < threshold) {
			return;
		}

		if (Math.abs(xDistance) >= Math.abs(yDistance)) {
			if (xDistance > 0 && swipeLeft) swipeLeft();
		}
	};

	return {
		onMouseDown: (e: any) => start(e),
		onTouchStart: (e: any) => start(e),
		onTouchEnd: (e: any) => end(e),
	};
};

export default useSwipe;
