import {Bet} from 'models/bet';
import {BetTypesV2} from 'models/enums/Bet.enum';

export const bets: Bet[] = [
	{
		betType: BetTypesV2.SINGLE,
		status: 1,
		totalFactor: 3.6,
		orderDate: '2024-02-07T09:29:17.163Z',
		barcode: '136737395115',
		positions: [
			{
				positionId: 1,
				status: 1,
				factorValue: 3.6,

				eventId: '17484857',
				eventTitle: 'Man Utd - Southampton',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496371',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: '',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
				betBuilderStakes: [
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						argument: 1.5,
						status: 1,
						stakeId: 3294114552,
					},
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						argument: 1.5,
						status: 2,
						stakeId: 3294114552,
					},
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						argument: 1.5,
						status: 3,
						stakeId: 3294114552,
					},
				],
			},
			{
				positionId: 2,
				status: 1,
				factorValue: 3.6,

				eventId: '17484859',
				eventTitle: 'Southampton - Man Utd',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496371',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: '',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
				betBuilderStakes: [
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						argument: 1.5,
						status: 1,
						stakeId: 3294114553,
					},
				],
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 5,
		totalFactor: 2.1,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '231848075228',
		positions: [
			{
				positionId: 1,
				status: 3,
				factorValue: 2.1,

				eventId: '17484857',
				eventTitle: 'Liverpool - Chelsea',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496372',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
				betBuilderStakes: [
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						argument: 1.5,
						status: 1,
						stakeId: 3294114552,
					},
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						status: 2,
						stakeId: 3294114552,
					},
					{
						periodName: 'Full Time',
						outcomeGroupTitle: 'Result:',
						outcomeTitle: 'Win 1',
						argument: 1.5,
						status: 3,
						stakeId: 3294114552,
					},
				],
			},
			{
				positionId: 2,
				status: 2,
				factorValue: 4.2,

				eventId: '17484857',
				eventTitle: 'Arsenal - Man City',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 9,
		totalFactor: 4.2,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '61712462761',
		positions: [
			{
				positionId: 1,
				status: 2,
				factorValue: 4.2,

				eventId: '17484857',
				eventTitle: 'Arsenal - Man City',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 4,
		totalFactor: 2.8,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '61712462765544',
		positions: [
			{
				positionId: 1,
				status: 2,
				factorValue: 2.8,

				eventId: '17484857',
				eventTitle: 'Liverpool - Chelsea',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 4,
		totalFactor: 4.2,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '6171246273341',
		positions: [
			{
				positionId: 1,
				status: 1,
				factorValue: 4.2,

				eventId: '17484857',
				eventTitle: 'Arsenal - Man City',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 7,
		totalFactor: 3.2,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '6171246276221',
		positions: [
			{
				positionId: 1,
				status: 2,
				factorValue: 3.2,

				eventId: '17484857',
				eventTitle: 'Liverpool - Chelsea',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
	{
		betType: BetTypesV2.SINGLE,
		status: 10,
		totalFactor: 10,
		orderDate: '2023-05-18T09:29:17.163Z',
		barcode: '617124627622441',
		positions: [
			{
				positionId: 1,
				status: 3,
				factorValue: 3.6,

				eventId: '17484857',
				eventTitle: 'Arsenal - Man City',
				eventDate: '2023-05-18T07:11:01.05Z',

				outcomeId: '1947496373',
				outcomeGroupTitle: 'WINNER',
				outcomeTitle: '2',
				blockTitle: 'Main time',
				additionalValue: null,
				sportTypeTitle: 'FootBall',
				sportTypeId: 128,
			},
		],
	},
];
