import {FunctionComponent} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';

const ShareBetPreloader: FunctionComponent = function ShareBetPreloader() {
	const {appLoaderIcon} = useLocalObservable(() => appService);
	const {getAppIcon} = useAppData();

	return <div className='share-bet__preloader'>{getAppIcon(appLoaderIcon)}</div>;
};

export default ShareBetPreloader;
