import roomServices from 'store/roomService';
import FeedbackService from 'services/api/FeedbackService';
import userServices from 'store/userService';
import feedbackServices from 'store/feedbackService';
import alertService from 'store/alertService';
import modalServices from 'store/modalService';
import SocketIoServices from 'services/SocketIoServices';
import useSendEventWithTimeouts from 'hooks/useSendEventWithTimeouts';
import {useLocalObservable} from 'mobx-react-lite';
import useAgora from './useAgora';

const useFeedbackAudio = () => {
	const {accessToken, clearUserData} = useLocalObservable(() => userServices);
	const {roomId} = useLocalObservable(() => roomServices);
	const {audioFeedbackData} = useLocalObservable(() => feedbackServices);
	const {hideAllModals} = useLocalObservable(() => modalServices);
	const {hideAlert} = useLocalObservable(() => alertService);

	const {clearSessionTime} = useSendEventWithTimeouts();
	const {clearAgoraStatsInterval} = useAgora();

	const sendAudioFeedbackHandler = async () => {
		if (roomId && audioFeedbackData) {
			const {rating, issue, info, comment} = audioFeedbackData;
			await FeedbackService.sendAudioFeedback(accessToken, roomId, rating, issue, info, comment);
		}
	};

	const sendAudioFeedbackAndExitApp = () => {
		sendAudioFeedbackHandler();
		clearSessionTime();
		clearAgoraStatsInterval();
		SocketIoServices.soketDisconnect();
		clearUserData();
		hideAllModals();
		hideAlert();

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify({type: 'watchersWindowClose'}));
			return;
		}
		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
				JSON.stringify({type: 'watchersWindowClose'})
			);
			return;
		}

		window.parent.postMessage({type: 'watchersWindowClose'}, '*');
	};

	return {sendAudioFeedbackHandler, sendAudioFeedbackAndExitApp};
};

export default useFeedbackAudio;
