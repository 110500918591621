import MessageType from 'models/enums/MessageType.enum';
import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import toastService from 'store/toastService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useAnalytics from 'hooks/useAnalytics';
import useCopy from 'hooks/useCopy';
import {FunctionComponent, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import Button from 'components/hoc/Button';
import threadService from 'store/threadService';

interface IButtonCopyProps {
	message: Message;
}

const ButtonCopy: FunctionComponent<IButtonCopyProps> = function ButtonCopy({message}) {
	const {text, type: messageType, advertisement} = message;

	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {setSubmenuMessage} = useLocalObservable(() =>
		currentThreadId ? threadService : roomService
	);
	const {addToast} = useLocalObservable(() => toastService);

	const {chatScreen, toasts} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();

	const {IcoCopy} = appIcons;

	const textMessageForCopy = () => {
		if (messageType === MessageType.ADVERTISEMENT && advertisement) {
			const {title: advertisementTitle, text: advertisementText} = advertisement;
			return `${advertisementTitle || ''}${advertisementTitle && advertisementText ? '. ' : ''}${
				advertisementText || ''
			}`;
		}
		return text;
	};

	const {copied, copy} = useCopy(textMessageForCopy());

	const onCopyHandler = async () => {
		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(
				JSON.stringify({
					type: 'watchersDataExchange',
					body: {action: 'copy', data: {text: textMessageForCopy()}},
				})
			);
			addToast({
				iconName: ToastIconsName.copy,
				liveTime: 3000,
				message: toasts.messageCopied,
				cancellable: true,
			});
			sendAnalytics('message_text_copied');
			setSubmenuMessage(null);
			return;
		}
		await copy();
		setSubmenuMessage(null);
	};

	useEffect(() => {
		if (copied) {
			addToast({
				iconName: ToastIconsName.copy,
				liveTime: 3000,
				message: toasts.messageCopied,
				cancellable: true,
			});
			sendAnalytics('message_text_copied');
		}
	}, [copied]);

	return (
		<Button className='chat__submenu-btn' onClick={onCopyHandler}>
			{chatScreen.chatSubmenu.copy}
			{getAppIcon(IcoCopy.pic, {className: 'chat__submenu-icon'})}
		</Button>
	);
};

export default observer(ButtonCopy);
