type ApiPath = {
	partnerAuth: (apiKey: string) => string;
	register: string;
	login: string;
	check: string;
	decrypt: string;
	getMyUser: string;
	patchMyUser: string;
	deleteMyUser: string;
	restoreMyUser: string;
	blockUser: string;
	reportUser: string;
	wordList: string;
	stat: string;
	statAgora: string;
	statEvent: string;
	setPoll: string;
	uploadPollOptionPic: string;
	selfmoder: string;
	sendPollVote: (externalRoomId: string) => string;
	getUserById: (id: number) => string;
	getRoomData: (externalRoomId: string) => string;
	getMessages: (externalRoomId: string) => string;
	getMessagesAround: (externalRoomId: string) => string;
	getAllDataOnload: (externalRoomId: string) => string;
	getPinnedMessages: (externalRoomId: string) => string;
	getTalkersCount: (externalRoomId: string) => string;
	getActiveTalkers: (externalRoomID: string, limit: number, offset: number) => string;
	getActiveSpeakers: (externalRoomID: string) => string;
	getWaitingSpeakers: (externalRoomID: string) => string;
	getBannedTalkers: (externalRoomID: string) => string;
	getPoll: (externalRoomID: string) => string;
	pollVote: (externalRoomID: string) => string;
	endPoll: (externalRoomID: string) => string;
	deletePoll: (externalRoomID: string, pollId: number) => string;
	viewAdvertisement: (advertisementId: number) => string;
	sticker: string;
	settings: (apiKey: string) => string;
	settingsThemes: (apiKey: string) => string;
	settingsTheme: (apiKey: string, themeId: number) => string;
	getRules: (lang: string) => string;
	feedback: string;
	getUserLastMessage: (externalRoomID: string, userId: number) => string;
	getGreetingMessage: (lang: string) => string;
	getChatBot: string;
	getBet: (userId: number, page: number, limit?: number) => string;
	decryptid: string;
	getWelcome: (lang: string) => string;
	getDoubleEncryptedUserId: (id: number) => string;
	getProfileUrl: (id: number) => string;
	getTranslateLangs: (displayLang: string) => string;
	translate: string;
	getGamble: (userId: number, offset: number, limit?: number) => string;
	picMessage: string;
	getStreamKey: string;
};

const apiPath: ApiPath = {
	partnerAuth: apiKey => `${process.env.REACT_APP_SETTINGS_SERVICE}/settings/global/${apiKey}`,
	register: `/auth/register`,
	login: `/auth/login`,
	check: `/auth/check`,
	decrypt: `/auth/decrypt`,
	getMyUser: `/user/me`,
	patchMyUser: `/user/me`,
	deleteMyUser: `/user`,
	restoreMyUser: `/user/restore`,
	blockUser: `/block`,
	reportUser: `/report`,
	wordList: `/wordlist`,
	stat: `/stat`,
	statAgora: `${process.env.REACT_APP_ANALYTICS_URL}/stat/agora`,
	statEvent: `${process.env.REACT_APP_ANALYTICS_URL}/stat/event`,
	setPoll: `/poll`,
	selfmoder: `/admin/talker/moder`,
	sendPollVote: externalRoomId => `/poll/${externalRoomId}/vote`,
	uploadPollOptionPic: `/room/pic`,
	getUserById: id => `/user/${id}`,
	getRoomData: externalRoomId => `/room/${externalRoomId}`,
	getMessages: externalRoomId => `/message/room/${externalRoomId}`,
	getMessagesAround: (externalRoomId: string) => `/message/room/${externalRoomId}/around`,
	getAllDataOnload: externalRoomId => `/data/${externalRoomId}`,
	getPinnedMessages: externalRoomId => `/message/room/${externalRoomId}/pinned`,
	getTalkersCount: externalRoomID => `talker/room/${externalRoomID}/count`,
	getActiveTalkers: (externalRoomID, limit, offset) =>
		`/talker/?isActive=1&room__externalRoomId=${externalRoomID}&limit=${limit}&offset=${offset}`,
	getActiveSpeakers: externalRoomID =>
		`/talker/?isActive=1&role=SPEAKER&room__externalRoomId=${externalRoomID}`,
	getWaitingSpeakers: externalRoomID =>
		`/talker/?isActive=1&hand=1&room__externalRoomId=${externalRoomID}`,
	getBannedTalkers: externalRoomID => `/talker/room/${externalRoomID}/ban`,
	getPoll: externalRoomID => `/poll/${externalRoomID}`,
	pollVote: externalRoomID => `/poll/${externalRoomID}/vote`,
	endPoll: externalRoomID => `/poll/${externalRoomID}/end`,
	deletePoll: (externalRoomID, pollId) => `/poll/${externalRoomID}/${pollId}`,
	viewAdvertisement: advertisementId => `/message/advertisement/view/${advertisementId}`,
	sticker: `${process.env.REACT_APP_SETTINGS_SERVICE}/sticker`,
	settings: apiKey => `${process.env.REACT_APP_SETTINGS_SERVICE}/settings/?apiKey=${apiKey}`,
	settingsThemes: apiKey =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/settings/theme/?apiKey=${apiKey}`,
	settingsTheme: (apiKey, themeId) =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/settings/theme/${themeId}?apiKey=${apiKey}`,
	getRules: (lang: string) => `${process.env.REACT_APP_SETTINGS_SERVICE}/rules/${lang}`,
	feedback: `/feedback`,
	getUserLastMessage: (externalRoomID: string, userId: number) =>
		`/message/last/room/${externalRoomID}/user/${userId}`,
	getGreetingMessage: (lang: string) =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/greeting/${lang}`,
	getChatBot: `/chatbot`,
	getBet: (userId: number, page: number, limit?: number) =>
		`/bet/${userId}?limit=${limit}&page=${page}`,
	decryptid: `/auth/decryptid`,
	getWelcome: lang => `${process.env.REACT_APP_SETTINGS_SERVICE}/welcome/${lang}`,
	getDoubleEncryptedUserId: (id: number) => `user/encrypted/${id}`,
	getProfileUrl: (id: number) => `user/profile/${id}`,
	getTranslateLangs: (displayLang: string) => `/message/translate/lang/${displayLang}`,
	translate: `/message/translate`,
	getGamble: (userId: number, offset: number, limit?: number) =>
		`/gamble/${userId}?limit=${limit}&offset=${offset}`,
	picMessage: `/message`,
	getStreamKey: `/stream/key`,
};

export {apiPath};
