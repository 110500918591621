import ResponseStatus from 'models/enums/ResponseStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import {Poll, PollOption} from 'models/poll';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import pollServices from 'store/pollService';
import RoomService from 'services/api/RoomService';
import SocketIoServices from 'services/SocketIoServices';
import usePollAnalytics from 'hooks/usePollAnalytics';
import useL10n from 'l10n/useL10n';
import {useCallback} from 'react';
import {useLocalObservable} from 'mobx-react-lite';

const usePollActions = () => {
	const {language} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {roomId} = useLocalObservable(() => roomServices);
	const {
		poll,
		pollOptionsChosen,
		updatePoll,
		setPollOptionsChosen,
		toggllePollAlreadyVoted,
		toggllePollOptionsBlocked,
	} = useLocalObservable(() => pollServices);
	const {poll: pollTranslate} = useL10n();
	const {sendPollAnalytics} = usePollAnalytics();

	// const declOfNum = (number: number) => {
	// 	let n = Math.abs(number);
	// 	n %= 100;
	// 	if (n >= 5 && n <= 20) {
	// 		return pollTranslate.results.words[2];
	// 	}
	// 	n %= 10;
	// 	if (n === 1) {
	// 		return pollTranslate.results.words[0];
	// 	}
	// 	if (n >= 2 && n <= 4) {
	// 		return pollTranslate.results.words[1];
	// 	}
	// 	return pollTranslate.results.words[2];
	// };

	const onSendPollVote = async (optionId?: number) => {
		toggllePollOptionsBlocked(true);

		if (roomId) {
			const optionIds: number[] = [];
			if (optionId) {
				optionIds.push(optionId);
			}
			if (pollOptionsChosen.length) {
				pollOptionsChosen.forEach(pollOptionChose => optionIds.push(pollOptionChose.id));
			}
			if (optionIds.length) {
				const response = await RoomService.sendPollVote(roomId, optionIds, accessToken);
				if (response.status === ResponseStatus.SUCCESS) {
					const {pollResults, chosenOptions} = response.data;
					const pollMessageDataForEmit = JSON.stringify({
						poll,
						pollOptionsChosen: chosenOptions,
					});
					updatePoll({options: pollResults});
					setPollOptionsChosen(chosenOptions);
					toggllePollOptionsBlocked(false);
					toggllePollAlreadyVoted(true);
					if (pollMessageDataForEmit) {
						SocketIoServices.emitMessage({
							pollText: pollMessageDataForEmit,
							externalRoomId: roomId,
							type: MessageType.VOTE,
						});
					}
					sendPollAnalytics('pq_answer_sent', chosenOptions);
					return;
				}
			}
		}
		toggllePollOptionsBlocked(false);
	};

	const calcPollOptionVote = (options: PollOption[], optionId: number) => {
		if (options.length > 0) {
			let sumOfVotes = 0;
			const findOption = options.find(optionItem => optionItem.id === optionId);
			const findOptionsHaveVoices = options.filter(optionItem => optionItem.votesLength);
			options.forEach(opt => {
				if (opt.votesLength) {
					sumOfVotes += opt.votesLength;
				}
			});
			if (sumOfVotes && findOption && findOption.votesLength) {
				if (
					findOptionsHaveVoices.length &&
					findOptionsHaveVoices[findOptionsHaveVoices.length - 1].id === optionId &&
					sumOfVotes > findOption.votesLength
				) {
					return 100 - Math.floor(((sumOfVotes - findOption.votesLength) * 100) / sumOfVotes);
				}
				const multiplyOptionVotesLength = findOption.votesLength * 100;
				return Math.floor(multiplyOptionVotesLength / sumOfVotes);
			}
		}
		return 0;
	};

	const calcPollVotesLength = useCallback(
		(incomingPoll: Poll) => {
			let votesLength = 0;
			if (incomingPoll && incomingPoll.options.length) {
				incomingPoll.options.forEach(option => {
					if (option.votesLength) {
						votesLength += option.votesLength;
					}
				});
				if (votesLength) {
					// return `${votesLength} ${declOfNum(votesLength)}`;
					return `${pollTranslate.results.votes}: ${votesLength}`;
				}
			}
			return null;
		},
		[poll, language]
	);

	return {
		onSendPollVote,
		calcPollOptionVote,
		calcPollVotesLength,
	};
};

export default usePollActions;
