import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {StickersEmojiControlsBtn} from 'models/room';

export const stickersEmojiBtnsData: StickersEmojiControlsBtn[] = [
	{
		id: 0,
		icon: 'IcoStickers',
		tabName: StickersEmojiType.STICKERS,
	},
	{
		id: 1,
		icon: 'IcoEmoji',
		tabName: StickersEmojiType.EMOJI,
	},
];
