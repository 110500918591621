import {action, makeObservable, observable} from 'mobx';
import {Sticker} from 'models/room';

class SuggestService {
	@observable
	public visibleSuggest = false;

	@observable
	public visibleSuggestSticker = false;

	@observable
	public suggestStickers: Sticker[] = [];

	@action
	public toggleVisibleSuggest = (value: boolean) => {
		this.visibleSuggest = value;
	};

	@action
	public toggleVisibleSuggestSticker = (value: boolean) => {
		this.visibleSuggestSticker = value;
	};

	@action
	public setSuggestStickers = (value: Sticker[]) => {
		this.suggestStickers = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new SuggestService();
