/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: '协议',
		subTitle: '许可协议和隐私政策',
		textFirst: '我接受这些条件',
		textSecond: '并同意根据',
		license: '许可协议',
		policy: '隐私政策',
	},
	controlPanel: {
		streamEnded: '广播结束',
		banAlert: '您不能在聊天中发言',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? '贴纸' : 'Emoji',
	},
	accountDeletedScreen: {
		title: '个人资料已删除',
		text: (date: string) => `您可以将您的个人资料恢复为 ${date}`,
	},
	nameScreen: {
		title: '昵称',
		inputPlaceholder: '输入昵称',
		nameError: '无效的昵称',
	},
	avatarScreen: {
		title: '头像',
		label: '选择其他:',
	},
	settingsScreen: {
		title: '设置',
		personal: '个人设置',
		room: '房间设置',
		profileBtn: '已屏蔽用户',
		languageBtn: '语言',
		blockedBtn: '已屏蔽用户',
		rules: '聊天规则',
		deleteProfile: '删除个人资料',
		slowModeBtn: '慢速模式',
		agora: {
			microphone: '麦克风',
			playbackDevice: '音源',
		},
	},
	languageScreen: {
		title: '语言和界面',
		messagesTranslate: '消息翻译',
		translateMessagesMode: '在聊天中翻译消息',
		translateMessagesTo: '将消息翻译为:',
	},
	rulesScreen: {
		title: '聊天规则',
	},
	profileScreen: {
		title: '个人资料',
		selectAnotherPic: '选择其他头像',
		chatNameLabel: '聊天名称',
		changeName: '修改',
	},
	blockedScreen: {
		title: '已屏蔽用户',
		emptyListText: '无已屏蔽用户',
	},
	chatScreen: {
		inputPlaceholder: '消息...',
		cantWrite: '您无法在聊天中发言',
		chatSubmenu: {
			hideMessage: '隐藏消息',
			showMessage: '显示消息',
			removeFromSpeakers: '从发言人中移除',
			setSpeaker: '设为发言人',
			hideOnlyMessage: '隐藏消息',
			showOnlyMessage: '显示消息',
			reportMessage: '举报消息',
			reportUser: '举报用户',
			muteSpeaker: '关闭麦克风',
			blockUser: '屏蔽',
			unblockUser: '取消屏蔽',
			reply: '回复',
			copy: '复制',
			edit: '编辑',
			delete: '删除',
			pinMessage: '置顶',
			unpinMessage: '取消置顶',
			replyInThread: '在线程中回复',
		},
		actionMessage: {
			editing: '编辑',
			reply: '回复',
		},
		status: {
			top: '前',
		},
		translating: '翻译...',
	},
	chatUsersScreen: {
		title: '参与者',
		edited: '已编辑',
		hidden: '隐藏',
		unreadMessages: '未读消息',
		usersSubmenu: {
			muteSpeaker: {
				title: '静音参与者',
				subtitle: '关闭麦克风',
			},
			setSpeaker: {
				title: '移除发言人身份',
				subtitle: '仅文字聊天',
			},
			removeFromSpeaker: {
				title: '设为发言人',
				subtitle: '允许语音',
			},
			showMessages: {
				title: '显示消息',
				subtitle: '仅对管理员可见',
			},
			hideMessages: {
				title: '隐藏消息',
				subtitle: '仅对管理员可见',
			},
			blockUser: {
				title: '屏蔽用户',
				subtitle: '加入黑名单',
			},
			unblockUser: {
				title: '解除屏蔽',
				subtitle: '解除用户屏蔽',
			},
			blockUserByUser: {
				title: '屏蔽用户',
				subtitle: '隐藏用户所有消息',
			},
			unblockUserByUser: {
				title: '"解除屏蔽',
				subtitle: '显示用户所有消息',
			},
			reportUser: {
				title: '举报用户',
				subtitle: '举报将匿名发送',
			},
		},
		days: {
			today: '今天',
			yesterday: '昨天',
		},
		chatCreated: '聊天创建于,',
	},
	alerts: {
		btns: {
			unblock: '解除屏蔽',
			block: '屏蔽',
			send: '发送',
			sendAndBlock: '发送并屏蔽',
			show: '显示',
			showAll: '全部显示',
			cancel: '取消',
			yes: '是',
			hide: '隐藏选定',
			hideAll: '全部隐藏',
			make: '设为',
			remove: '移除',
			delete: '删除',
			deleteAccount: '删除个人资料',
			close: '关闭',
			pin: '置顶',
			unpin: '取消置顶',
			edit: '编辑',
			end: '完成',
			endAndShare: '完成并分享',
			reset: '重置',
			rules: '聊天规则',
			contactus: '联系我们',
		},
		pinMessage: {
			title: '置顶消息？',
		},
		unPinMessage: {
			title: '取消置顶消息？',
		},
		accountRestored: {
			title: '个人资料已恢复',
		},
		closeApp: {
			title: '您确定要退出吗？',
		},
		removeMessage: {
			title: '删除消息？',
		},
		report: {
			title: '提交投诉？',
			subtitle: '您还可以屏蔽用户，以便彼此不再看到对方的消息。',
		},
		banUser: {
			title: (name: string) => `屏蔽 ${name}？`,
			subtitle: (text: string) => `用户将被屏蔽 ${text}`,
		},
		unBanUser: {
			title: (name: string) => `解除屏蔽 ${name}？`,
		},
		showUserMessages: {
			title: (name: string) => `显示来自 ${name} 的消息？`,
			subTitle: '其他用户将能够看到这个用户的消息',
		},
		hideUserMessages: {
			title: (name: string) => `隐藏来自 ${name} 的消息？`,
			subTitle: '其他用户将无法再看到这个用户的消息',
		},
		showUserMessage: {
			title: (name: string) => `显示来自 ${name} 的消息？`,
			subTitle: '其他用户将能够看到这条消息',
		},
		hideUserMessage: {
			title: (name: string) => `隐藏来自 ${name} 的消息？`,
			subTitle: '其他用户将无法再看到这条消息',
		},
		blockUserInRoom: {
			title: (name: string) => `屏蔽 ${name}？`,
			subTitle: '用户将无法在此房间中发言',
		},
		unBlockUserInRoom: {
			title: (name: string) => `解除屏蔽 ${name}？`,
			subTitle: '用户将能够在此房间中发言',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `屏蔽 ${name}？`,
			subTitle: '您将不再看到对方的消息',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `设为 ${name} 发言人？`,
			unTitle: (name: string) => `移除 ${name} 发言人身份？`,
		},
		banInApp: {
			title: '管理员已屏蔽您',
			for: '',
			subtitle: (bannedUntil: string) => `您将被屏蔽直至 ${bannedUntil}`,
			permanent: '您已被永久屏蔽',
		},
		banInAppBadNickname: {
			title: '管理员因不当昵称而屏蔽您',
			subtitle: '请前往个人资料更改昵称并重新加入聊天',
		},
		deleteAccount: {
			title: '删除个人资料',
			subTitle: '所有数据将被删除，您将有30天的时间来恢复个人资料。',
		},
		pollEdit: {
			title: '编辑调查？',
			subTitle: '用户的回答将被重置',
		},
		pollReset: {
			title: '重置？',
			subTitle: '调查将不会被保存',
		},
		pollEnd: {
			title: '您确定要结束投票吗？',
		},
		pollDelete: {
			title: '删除投票？',
		},
		agoraCheck: {
			updateIOSText: '更新 iOS 版本以使用应用程序的所有工具。',
			allowAccess: {
				title: '你想说点什么吗？',
				text: '请打开应用程序设置并允许访问麦克风和扬声器',
			},
			microphoneAndSpeakerText: '自14.3版本以来，麦克风和扬声器的使用已经可用',
			microphoneText: '自14.3版本以来，麦克风和扬声器的使用已经可用',
			btn: '好的!',
		},
	},
	eventScreen: {
		title: '计划事件',
		titleSoon: '直播即将开始',
		subtitle: '聊天即将开放，请稍等。',
		days: '天',
		hours: '小时',
		minutes: '分钟',
		seconds: '秒钟',
		startTitle: '开始',
	},
	toasts: {
		reciveComplaint: '我们已收到您的投诉',
		setSpeakerRoleForUser: '用户已被指定为发言人',
		unSpeakerRoleForUser: '用户已从发言人中移除',
		messagesShown: '消息已显示',
		messagesHidden: '消息已隐藏',
		messageShown: '消息已显示',
		messageHidden: '消息已隐藏',
		userBlocked: '用户已被屏蔽',
		userUnBlocked: '用户已被解除屏蔽',
		youAreSpeaker: '您是发言人！说点什么吧',
		youAreNotSpeaker: '管理员已将您从发言人中移除',
		userIsSpeaker: (name: string) => `${name}现在是发言人`,
		userNotSpeaker: (name: string) => `${name}现在不是发言人`,
		yourUserNameInChat: (name: string) => `您在聊天中的用户名为${name}`,
		messageRemoved: '消息已删除',
		messageCopied: '文本已复制',
		obscenities: '请尝试重新表达',
		requestSent: '请求已发送',
		requestDecline: '您的麦克风请求已被拒绝',
		youareblocked: (text: string) => `您因${text}而被屏蔽。您无法在聊天中发送消息`,
		youareunblocked: '您已被解除屏蔽。您可以在聊天中发送消息',
		youAreModerator: '您是管理员！',
		youAreNoModerator: '您不再是管理员',
		micMuted: '您的麦克风已被静音',
		noInet: '无互联网连接',
		messagePinned: '消息已置顶',
		messageUnpinned: '消息已取消置顶',
		pollPublished: '投票已发布',
		pollPublishedError: '错误！请稍后再试',
		pollAddPic: '添加图片',
		pollAddPicError: '请选择其他图片',
		pollDeleted: '投票已删除',
		pollEnded: '投票已结束',
		sendStickerWhenSlowModeIsOn: (time: string) => `请等待${time}以发送贴纸`,
		linkProhibited: '您不能在聊天中发送链接',
		phoneProhibited: '您不能在聊天中发送电话号码。',
		profileHidden: '用户已隐藏其个人资料',
		imageSizeWarning: '您可以上传不超过 10 MB 的图片',
		imageBlocked: '版主已阻止图片上传',
		streamKeyCopied: '廣播密鑰已複製',
	},
	btns: {
		acceptandgo: '接受并继续',
		saveandgo: '保存并继续',
		save: '保存',
		unblock: '解除屏蔽',
		block: '屏蔽',
		restoreAccount: '恢复个人资料',
		close: '关闭',
	},
	coopyrights: '在 Watchers.io 上运行',
	modals: {
		report: {
			title: (target: 'message' | 'user') => (target === 'message' ? '投诉消息' : '举报违规行为'),
			subtitle: '投诉将匿名发送',
			btns: {
				violence: '辱骂、威胁、骚扰',
				provocations: '挑衅',
				personalDetails: '个人信息',
				flood: '刷屏',
				spam: '垃圾信息',
				fraudBegging: '欺诈、乞讨',
				other: '其他',
			},
		},
		ban: {
			title: '封禁用户',
			subtitle: '请说明封禁原因',
			btns: {
				insults: '辱骂、威胁、骚扰',
				personal: '传播个人信息',
				flood: '刷屏',
				links: '发布外部链接',
				violence: '暴力威胁',
				fraud: '欺诈和乞讨行为',
				nickname: '不当昵称',
				politicial: '政治、性暗示等挑衅行为',
				repeated: '重复违规',
				auto: '聊天用户投诉',
				spam: '垃圾信息',
			},
		},
		waitingSpeakers: {
			text: '您没有等待中的发言人',
			handsUp: '举手',
			waiting: '等待中的发言人',
		},
		poll: {
			supTitles: {
				typeQuiz: '选择正确答案',
				typeQuizMultiple: '选择正确的选项',
				typePoll: '分享意见',
				typePollMultiple: '可以选择多个选项',
				default: '公开投票',
			},
			btns: {
				reply: '回复',
				vote: '投票',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: '测验',
				typePoll: '多项选择',
				default: '公开投票',
			},
			btns: {
				edit: '编辑投票',
				end: '完成',
			},
		},
		pollCreated: {
			titles: {
				created: '创建投票',
				typeQuiz: '测验',
				typePoll: '多项选择',
				default: '公开投票',
			},
		},
		changeName: {
			title: '更改聊天名称',
			subtitle: '您因使用不当的名称而被封锁',
		},
		temporaryAgreement: {
			title: '聊天规则',
			rules: {
				bePolite: {
					title: '保持礼貌',
					text: '禁止在聊天中使用敌意和不雅的言辞。',
				},
				beAttentive: {
					title: '保持警惕',
					text: '请勿分享个人信息，也不要向他人索要个人信息。',
				},
				notAdvertise: {
					title: '不要进行广告',
					text: '最好分享您的意见或情感。',
				},
			},
			btns: {
				allRules: '所有规则',
				acceptAndContinue: '接受并继续',
			},
		},
		slowMode: {
			title: '慢速模式',
			text: '参与者将无法在聊天中频繁发送消息。',
			enable: '启用慢速模式',
			disable: '停用慢速模式',
			sendTimeout: '发送超时',
		},
		slowModeTimeout: {
			title: '发送超时',
		},
		chatBot: {
			title: '使用聊天机器人',
			rules: [
				{
					title: '人工智能',
					text: '机器人模仿与人类的交流，并基于其知识提供信息。但请记住，它的回答可能是有限的，不能代替人类。',
				},
				{
					title: '机器人可能会犯错',
					text: '机器人不能保证信息的准确性和可靠性。',
				},
			],
			btns: {
				close: '关闭',
			},
		},
		shareBet: {
			title: '分享',
			noBetsTitle: '找不到您的赌注',
			noBetsText: '您可以分享在过去14天内下注的赌注',
			btns: {
				share: '分享赌注',
				copy: '复制赌注',
			},
			status: {
				null: '进行中',
				win: '胜利',
				lose: '失败',
			},
			betTypeV1: {
				express: '多',
				single: '单一',
			},
			betTypeV2: {
				single: '单一',
				multi: '多',
				system: 'System',
			},
			totalFactor: '系数',
			bet: '赌注',
			showMore: '显示更多',
		},
		shareGamble: {
			noGamblesText: `您还没有可以分享的奖金。继续玩，您的下一个奖金将显示在这里，供您与其他玩家分享！`,
		},
		record: {
			text: '正在处理录音...',
		},
		translate: {
			title: '消息翻译',
			chooseLanguage: '选择语言',
			descr: '聊天消息将自动翻译为您选择的语言',
			btn: '翻译',
		},
		streamSettings: {
			title: '流设置',
			description: '下载并安装视频编码器。在其设置中输入流密钥',
			shareScreen: {
				title: '屏幕共享',
				description: '无需特殊设置',
			},
			rtmp: {
				title: '外部流来源',
				description: '使用额外的程序进行流媒体传输',
			},
			streamKey: '流密钥',
			showStreamKey: '显示',
			hideStreamKey: '隐藏',
			btns: {
				continue: '继续',
				close: '关闭',
				ok: '完成',
			},
		},
	},
	pinnedMessage: {
		title: '置顶消息',
	},
	errorPage: {
		error: '错误',
		types: [
			// errorNumber 0
			{
				title: '我们无法识别您',
				text: '请尝试重新进入聊天',
			},
			// errorNumber 1
			{
				title: '找不到聊天',
				text: '请尝试重新进入',
			},
			// errorNumber 2
			{
				title: '无法加载聊天',
				text: '请尝试重新加载',
			},
			// errorNumber 3
			{
				title: '我们无法识别您',
				text: '聊天处于隐身模式下不可用',
			},
			// errorNumber 4
			{
				title: '不合规的昵称',
				text: '进入您的个人资料，更改昵称并重新进入聊天。',
			},
		],
	},
	tooltips: {
		emotions: '按住以更改',
		badInet: '网络连接不佳',
		blockedUsers: '您可以在此查看被封锁的用户列表。',
		waitingSpeakers: '有参与者想要发言。',
		wantSpeak: '点击以请求发言权。',
		slowMode: {
			header: (time: string) =>
				`慢速模式已启用。 用户每隔 <br> ${time} 可以发送一条消息。 这个规则 <br> 不适用于管理员。`,
			btnSendMessage: (time: string) => `您可以每隔 ${time} 发送一条消息。`,
		},
		shareBet: '在聊天中分享投注',
		translate: '将消息翻译成其他语言',
	},
	poll: {
		toggleSubmenu: {
			create: '创建投票',
			edit: '编辑投票',
			show: '显示投票',
			end: '结束投票',
		},
		tooltip: {
			btn: '投票',
		},
		settings: {
			title: '设置',
			titleAdditionally: '此外',
			switches: {
				quizMode: '测验模式',
				multipleChoice: '多项选择',
				withPhoto: '附带照片的投票',
			},
		},
		question: {
			title: '问题',
			inputPlaceholder: '添加问题',
		},
		message: {
			title: '您已投票',
		},
		answers: {
			title: '答案选项',
			warning: '添加至多5个答案选项',
		},
		answer: {
			tooltip: '选择正确答案',
			inputPlaceholder: '答案',
		},
		results: {
			text: '投票结果',
			votes: '票',
			// words: ['票', '票', '票'],
		},
		btns: {
			addQuestion: '添加问题',
			addAnswers: '添加答案',
			addAnswer: '添加答案',
			publish: '发布',
			vote: '投票',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: '评分',
				continue: '继续',
				checkConnection: '检查连接',
				close: '关闭',
			},
			quality: {
				title: '评估声音质量',
			},
			reason: {
				title: '出了什么问题？',
			},
			connection: {
				title: '测试连接质量',
				text: '检查将不会超过一分钟，有助于找出问题的原因。',
			},
			statistics: {
				title: '收集统计信息...',
			},
			done: {
				title: '谢谢！',
				text: '我们将查明发生了什么，并确保尽快解决问题。',
			},
		},
	},
	chatBot: {
		message: '问机器人有关任何事情...',
		warnings: {
			disabled: '我被禁用了',
			overloaded: '我们过载了',
		},
	},
	audioReason: {
		nothear: '什么也没听见',
		connection: '声音中断了',
		microphone: '无法使用麦克风',
	},
	screenShare: {
		title: '屏幕分享',
		stopSharing: '停止屏幕分享',
		modal: {
			title: '屏幕分享仅支持从电脑进行。 ',
			text: '在您的电脑上打开聊天，并单击右上角的“屏幕分享”按钮。',
			btn: '复制邀请链接。',
		},
	},
	threads: {
		title: '线程',
		back: '返回',
		replies: ['回复', '回复', '回复'],
	},
	attach: {
		submenu: {
			sendImage: '发送图片',
			shareBet: '分享投注',
			shareGamble: '分享奖金',
		},
		images: ['图片', '图片', '图片'],
		addCaption: '添加标题',
	},
} as unknown as Dictionary;
