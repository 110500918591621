import {action, makeObservable, observable} from 'mobx';
import {Welcome} from 'models/welcome';

class WelcomeService {
	@observable
	public welcomeMessage: Welcome | null = null;

	@action
	public setWelcomeMessage = (value: Welcome | null) => {
		this.welcomeMessage = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new WelcomeService();
