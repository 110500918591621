import {AppEmotion} from 'models/app';
import {Reaction} from 'models/room';
import emotionServices from 'store/emotionService';
import useReactions from 'hooks/useReactions';
import {FunctionComponent, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useDraggable} from 'react-use-draggable-scroll';
import {v4 as uuidv4} from 'uuid';
import threadService from 'store/threadService';
import roomService from 'store/roomService';

interface IChatMessageSubmenuReactionsProps {
	messageId: number;
	reactions?: Reaction[];
	userId?: number;
}

const ChatMessageSubmenuReactions: FunctionComponent<IChatMessageSubmenuReactionsProps> =
	function ChatMessageSubmenuReactions({messageId, reactions, userId}) {
		const reactionsAxisXRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

		const {currentThreadId} = useLocalObservable(() => threadService);
		const {setSubmenuMessage} = useLocalObservable(() =>
			currentThreadId ? threadService : roomService
		);
		const {emotions} = useLocalObservable(() => emotionServices);
		const {reactionItemHandler} = useReactions();
		const {events} = useDraggable(reactionsAxisXRef, {
			applyRubberBandEffect: true,
		});

		const onClickSubmenuReactionItemHandler = (emotion: AppEmotion) => {
			if (userId)
				reactionItemHandler({
					id: uuidv4(),
					name: emotion.name,
					messageId,
					items: reactions || [],
					messageUserId: userId,
				});
			setSubmenuMessage(null);
		};

		const renderReactionsBtn = (emotion: AppEmotion) => (
			<button
				type='button'
				key={emotion.id}
				data-id={emotion.id}
				className='chat__submenu-reactions-btn'
				style={{backgroundImage: `url(${emotion.pic})`}}
				onClick={() => onClickSubmenuReactionItemHandler(emotion)}
				aria-hidden='true'
			/>
		);

		return (
			<div className='chat__submenu-reactions'>
				<div className='chat__submenu-reactions-axis-x' {...events} ref={reactionsAxisXRef}>
					<div className='chat__submenu-reactions-items'>{emotions.map(renderReactionsBtn)}</div>
				</div>
			</div>
		);
	};

export default observer(ChatMessageSubmenuReactions);
