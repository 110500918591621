import {emotions} from 'data/emotions';
import {AppEmotion} from 'models/app';
import {action, makeObservable, observable} from 'mobx';

class EmotionService {
	@observable
	public emotions: AppEmotion[] = emotions;

	@observable
	public emotionsAnim: AppEmotion[] = [];

	@observable
	public emotionsVisible = false;

	@observable
	public idEmotionActive: number = emotions[0].id;

	@action
	public setEmotions = (data: AppEmotion[]) => {
		this.emotions = data;
		this.idEmotionActive = this.emotions[0].id;
	};

	@action
	public addEmotion = (emotion: AppEmotion) => {
		this.emotionsAnim = [...this.emotionsAnim, emotion];
	};

	@action
	public removeEmotion = (emotion: AppEmotion) => {
		this.emotionsAnim = this.emotionsAnim.filter(item => item.uuid !== emotion.uuid);
	};

	@action
	public toggleEmotionsVisible = (value: boolean) => {
		this.emotionsVisible = value;
	};

	@action
	public toggleIdEmotionActive = (emotionId: number) => {
		this.idEmotionActive = emotionId;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new EmotionService();
