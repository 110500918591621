/* eslint-disable react/no-danger */
import classNames from 'classnames/bind';
import Button from 'components/hoc/Button';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {useCallback} from 'react';
import Modal from 'react-modal';
import AlertService from 'store/alertService';

import './Alert.scss';

const Alert: React.FC = function Alert() {
	const {isVisible, alert, hideAlert} = useLocalObservable(() => AlertService);

	const btnAlertClasses = (type: AlertBtnType) =>
		classNames('alert__btn', {
			'alert__btn--destructive': type === AlertBtnType.DESTRUCTIVE,
			'alert__btn--success': type === AlertBtnType.SUCCESS,
		});

	const renderAlertButons = useCallback(
		(item, index) => (
			<Button
				className={btnAlertClasses(item.type)}
				key={index}
				workWithoutInet
				onClick={item.onPress || hideAlert}>
				{item.text}
			</Button>
		),
		[]
	);

	if (!isVisible) {
		return null;
	}

	return (
		<Modal
			className='alert'
			isOpen={isVisible}
			ariaHideApp={false}
			shouldCloseOnOverlayClick={alert?.closeOnBackdropPress}
			onRequestClose={hideAlert}
			style={{
				overlay: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0, 0, 0, 0.6)',
					zIndex: alert?.zIndex ? alert.zIndex : 10000,
				},
			}}>
			<div className='alert__header'>
				<p className='alert__title'>{alert?.title}</p>
				{alert?.subtitle && (
					<p className='alert__subtitle' dangerouslySetInnerHTML={{__html: alert?.subtitle}} />
				)}
			</div>
			<div className='alert__btns'>{alert?.buttons.map(renderAlertButons)}</div>
		</Modal>
	);
};

export default observer(Alert);
