import {OnboardStage} from 'models/enums/OnboardStage.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import UserService from 'services/api/UserService';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {getRandomInt} from 'utils/getRandom';
import {FunctionComponent, useCallback, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoAvatarCheckmark} from 'assets/svg/ico-avatar-checkmark.svg';
import './avatarForm.scss';
import {Avatar} from 'models/user';
import useUser from 'hooks/useUser';

interface IAvatarFormProps {
	setCurrentScreen?: () => void;
	currentScreen: string;
}

const AvatarForm: FunctionComponent<IAvatarFormProps> = function AvatarForm(props) {
	const {setCurrentScreen, currentScreen} = props;

	const {appAvatars, isStand} = useLocalObservable(() => appService);
	const {accessToken, userData, updateUserData} = useLocalObservable(() => userServices);
	const {updateUserMessagesByUserId} = useLocalObservable(() => roomServices);
	const {toggleOnboardModal} = useLocalObservable(() => modalServices);
	const {userExtraDataCheckOffer} = useUser();

	const [selectedAvatar, setSelectedAvatar] = useState<null | Avatar>(
		currentScreen === 'profile' ? null : appAvatars[getRandomInt(0, appAvatars.length - 1)]
	);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const findIdActiveAvatar = (incomingAvatar: Avatar) => {
		const getNameAvatar = (src: string) => src.split('/').pop()?.split('.')[0];
		return (
			userData?.pic &&
			getNameAvatar(userData.pic) &&
			getNameAvatar(incomingAvatar.pic) &&
			getNameAvatar(userData.pic) === getNameAvatar(incomingAvatar.pic)
		);
	};

	const onClickOnSaveHandler = async () => {
		if (selectedAvatar !== null) {
			const response = await UserService.patchUserData(
				{avatarName: selectedAvatar.name, isOnboard: true, onboardStage: OnboardStage.CHAT},
				accessToken
			);
			if (response.status === ResponseStatus.SUCCESS) {
				updateUserData({pic: response.data.pic, isOnboard: true, onboardStage: OnboardStage.CHAT});

				if (setCurrentScreen) {
					if (userData) {
						if (userData.id) {
							updateUserMessagesByUserId(userData.id, 'pic', response.data.pic);
						}
					}

					sendAnalytics('avatar_changed');
					setCurrentScreen();
					return;
				}

				sendAnalytics('avatar_set');

				if (userData) userExtraDataCheckOffer({...userData, isOnboard: true}, false);
				if (isStand) {
					window.parent.postMessage({registerFlowFinished: true}, '*');
				}
				toggleOnboardModal(false);
			}
		}
	};

	const renderUserElement = useCallback(
		(item: Avatar) => {
			return (
				<div className='avatar-form__list-item' key={item.id}>
					<div className='avatar-form__list-spacer' />
					<label htmlFor={item.id.toString()} className='avatar-form__item'>
						<input
							type='radio'
							id={item.id.toString()}
							name='avatars-list'
							className='avatar-form__item-input'
							checked={
								item.id === selectedAvatar?.id || (!selectedAvatar && !!findIdActiveAvatar(item))
							}
							onChange={() => setSelectedAvatar(item)}
						/>
						<span className='avatar-form__item-checkmark'>
							<IcoAvatarCheckmark />
						</span>
						<span className='avatar-form__item-img' style={{backgroundImage: `url(${item.pic})`}} />
					</label>
				</div>
			);
		},
		[appAvatars.length, selectedAvatar?.id]
	);

	return (
		<section className='avatar-form'>
			<div className='avatar-form__axis-y'>
				<div className='avatar-form__picked'>
					<div
						className='avatar-form__picked-img'
						style={{
							backgroundImage: `url(${selectedAvatar?.pic || userData?.pic})`,
						}}
					/>
				</div>
				<p className='avatar-form__label'>{translations.avatarScreen.label}</p>
				<div className='avatar-form__list'>{appAvatars.map(renderUserElement)}</div>
			</div>
			<Button
				disabled={selectedAvatar === null}
				onClick={onClickOnSaveHandler}
				className='btn avatar-form__btn'>
				{translations.btns.save}
			</Button>
		</section>
	);
};

export default observer(AvatarForm);
