import {action, makeObservable, observable} from 'mobx';
import {Bet} from 'models/bet';

class CopybettingService {
	@observable
	public bets: [Bet[]] = [[]];

	@observable
	public betsError = '';

	@observable
	public currentBetForShare: Bet | null = null;

	@action
	public setBets = (value: Bet[], page: number) => {
		this.bets[page] = [...value];
	};

	@action
	public clearBets = () => {
		this.bets = [[]];
	};

	@action
	public setBetsError = (error: string) => {
		this.betsError = error;
	};

	@action
	public setCurrentBetForShare = (value: Bet | null) => {
		this.currentBetForShare = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new CopybettingService();
