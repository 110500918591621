import useAnalytics from 'hooks/useAnalytics';
import {useRef} from 'react';

const TIMEOUT_DEFAULT = 60000;
const TIMEOUT_DEFAULT_FOR_EVENT = 60;

const TIMEOUT_ITEMS = [
	{textForEvent: 10, realTimeout: 10000},
	{textForEvent: 20, realTimeout: 10000},
	{textForEvent: 30, realTimeout: 10000},
	{textForEvent: 60, realTimeout: 30000},
	{textForEvent: 90, realTimeout: 30000},
	{textForEvent: 120, realTimeout: 30000},
	{textForEvent: 150, realTimeout: 30000},
	{textForEvent: 180, realTimeout: 30000},
];

let sendEvenStop = false;

const useSendEventWithTimeouts = () => {
	const sendEvenRef: {current: NodeJS.Timeout | null} = useRef(null);
	const localTimeoutMsRef: {current: {textForEvent: number; realTimeout: number} | null} =
		useRef(null);

	const {sendAnalytics} = useAnalytics();

	const getTimeoutMs = (index: number) => {
		if (!TIMEOUT_ITEMS[index]) {
			localTimeoutMsRef.current
				? (localTimeoutMsRef.current = {
						textForEvent: localTimeoutMsRef.current.textForEvent + TIMEOUT_DEFAULT_FOR_EVENT,
						realTimeout: TIMEOUT_DEFAULT,
				  })
				: (localTimeoutMsRef.current = {
						textForEvent:
							TIMEOUT_ITEMS[TIMEOUT_ITEMS.length - 1].textForEvent + TIMEOUT_DEFAULT_FOR_EVENT,
						realTimeout: TIMEOUT_DEFAULT,
				  });
			return localTimeoutMsRef.current;
		}
		return TIMEOUT_ITEMS[index];
	};

	const clearSessionTime = () => {
		sendEvenStop = true;
		if (sendEvenRef.current) {
			clearTimeout(sendEvenRef.current);
			sendEvenRef.current = null;
		}
	};

	const sessionTime = (event: string, index = 0) => {
		if (sendEvenRef.current) {
			clearTimeout(sendEvenRef.current);
			sendEvenRef.current = null;
		}
		sendEvenRef.current = setTimeout(() => {
			sendAnalytics(event, {
				duration: TIMEOUT_ITEMS[index]?.textForEvent || localTimeoutMsRef.current?.textForEvent,
			});
			if (!sendEvenStop) {
				sessionTime(event, index + 1);
			}
		}, getTimeoutMs(index).realTimeout);
	};

	return {sessionTime, clearSessionTime};
};

export default useSendEventWithTimeouts;
