import appService from 'store/appService';
import {useLocalObservable} from 'mobx-react-lite';
import {getUA, deviceDetect} from 'react-device-detect';

const useDetectDeviceAndBrowser = () => {
	const {setDeviceInfo, setAppVersion} = useLocalObservable(() => appService);

	const stringToNumbers = (value: string, appVersion?: boolean) => {
		const valueSplit = value.split('.');
		const numbersValue = valueSplit.slice(0, 2).map((item, index) => {
			if (index === 1 && item.length > 1) {
				return appVersion ? +item : +item[0];
			}
			return +item;
		});

		if (numbersValue.length < 2) {
			numbersValue.push(0);
		}
		return numbersValue;
	};

	const initDetectDeviceAndBrowser = (appOsName?: string | null, appOsVersion?: string | null) => {
		if (appOsName && appOsName.toLowerCase() === 'ios' && appOsVersion) {
			setDeviceInfo({
				name: appOsName.toLowerCase(),
				version: stringToNumbers(appOsVersion),
				type: 'app',
			});
			return;
		}

		const {os, osVersion} = deviceDetect(getUA);
		if (os && os.toLowerCase() === 'ios' && osVersion) {
			setDeviceInfo({
				name: os.toLowerCase(),
				version: stringToNumbers(osVersion),
				type: 'web',
			});
		}
	};

	const initAppVersion = (appVersion: string | null) => {
		appVersion && setAppVersion(stringToNumbers(appVersion, true));
	};

	return {
		initDetectDeviceAndBrowser,
		initAppVersion,
	};
};

export default useDetectDeviceAndBrowser;
