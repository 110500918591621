import {useState, useEffect} from 'react';

const useDeviceOrientation = () => {
	const [isPortrait, setIsPortrait] = useState(
		window.matchMedia('(orientation: portrait)').matches
	);

	useEffect(() => {
		const updateOrientation = () => {
			setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
		};

		const mediaQuery = window.matchMedia('(orientation: portrait)');
		mediaQuery.addEventListener('change', updateOrientation);

		return () => {
			mediaQuery.removeEventListener('change', updateOrientation);
		};
	}, []);

	return {isPortrait};
};

export default useDeviceOrientation;
