/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Соглашение',
		subTitle: 'Лицензионное соглашение и политика конфиденциальности',
		textFirst: 'Я принимаю условия',
		textSecond: 'и даю своё согласие на обработку персональных данных на условиях, определенных',
		license: 'Лицензионного соглашения',
		policy: 'Политикой конфиденциальности',
	},
	controlPanel: {
		streamEnded: 'Трансляция завершена',
		banAlert: 'Вы не можете писать в чат',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Стикеры' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Профиль удалён',
		text: (date: string) => `Вы можете восстановить свой профиль до ${date}`,
	},
	nameScreen: {
		title: 'Имя в чате',
		inputPlaceholder: 'Введите имя',
		nameError: 'Недопустимое имя',
	},
	avatarScreen: {
		title: 'Аватар',
		label: 'Выбрать другой:',
	},
	settingsScreen: {
		title: 'Настройки',
		personal: 'Персональные настройки',
		room: 'Настройки комнаты',
		profileBtn: 'Профиль',
		languageBtn: 'Язык и интерфейс',
		blockedBtn: 'Заблокированные',
		rules: 'Правила чата',
		deleteProfile: 'Удалить профиль',
		slowModeBtn: 'Медленный режим',
		agora: {
			microphone: 'Микрофон',
			playbackDevice: 'Источник звука',
		},
	},
	languageScreen: {
		title: 'Язык и интерфейс',
		messagesTranslate: 'Перевод сообщений',
		translateMessagesMode: 'Переводить сообщения в чате',
		translateMessagesTo: 'Переводить сообщения на:',
	},
	rulesScreen: {
		title: 'Правила чата',
	},
	profileScreen: {
		title: 'Профиль',
		selectAnotherPic: 'Выбрать другой аватар',
		chatNameLabel: 'Имя в чате',
		changeName: 'изменить',
	},
	blockedScreen: {
		title: 'Заблокированные',
		emptyListText: 'Нет заблокированных пользователей',
	},
	chatScreen: {
		inputPlaceholder: 'Сообщение...',
		cantWrite: 'Вы не можете писать в чат',
		chatSubmenu: {
			hideMessage: 'Скрыть сообщения',
			showMessage: 'Показать сообщения',
			removeFromSpeakers: 'Убрать из спикеров',
			setSpeaker: 'Сделать спикером',
			hideOnlyMessage: 'Скрыть сообщение',
			showOnlyMessage: 'Показать сообщение',
			reportMessage: 'Пожаловаться на сообщение',
			reportUser: 'Пожаловаться на пользователя',
			muteSpeaker: 'Выключить микрофон',
			blockUser: 'Заблокировать',
			unblockUser: 'Разблокировать',
			reply: 'Ответить',
			copy: 'Копировать',
			edit: 'Изменить',
			delete: 'Удалить',
			pinMessage: 'Закрепить',
			unpinMessage: 'Открепить',
			goToProfile: 'Перейти в профиль',
			replyInThread: 'Ответить в треде',
		},
		actionMessage: {
			editing: 'Редактирование',
			reply: 'Ответить',
		},
		status: {
			top: 'Топ',
		},
		translating: 'Переводим...',
	},
	chatUsersScreen: {
		title: 'Участники',
		edited: 'Изменено',
		hidden: 'Скрыто',
		unreadMessages: 'Непрочитанные сообщения',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Заглушить участника',
				subtitle: 'Выключить микрофон',
			},
			setSpeaker: {
				title: 'Убрать из спикеров',
				subtitle: 'Только текстовый чат',
			},
			removeFromSpeaker: {
				title: 'Сделать спикером',
				subtitle: 'Пользователь сможет разговаривать голосом',
			},
			showMessages: {
				title: 'Показать сообщения',
				subtitle: 'Видно только админу',
			},
			hideMessages: {
				title: 'Скрыть сообщения',
				subtitle: 'Видно только админу',
			},
			blockUser: {
				title: 'Заблокировать',
				subtitle: 'Добавить в черный список',
			},
			unblockUser: {
				title: 'Разблокировать',
				subtitle: 'Разблокируйте пользователя',
			},
			blockUserByUser: {
				title: 'Заблокировать',
				subtitle: 'Скрыть все сообщения пользователя',
			},
			unblockUserByUser: {
				title: 'Разблокировать',
				subtitle: 'Показать все сообщения пользователя',
			},
			reportUser: {
				title: 'Пожаловаться на пользователя',
				subtitle: 'Жалоба отправится анонимно',
			},
		},
		days: {
			today: 'Сегодня',
			yesterday: 'Вчера',
		},
		chatCreated: 'Чат создан,',
	},
	alerts: {
		btns: {
			unblock: 'Разблокировать',
			block: 'Заблокировать',
			send: 'Отправить',
			sendAndBlock: 'Отправить и заблокировать',
			show: 'Показать',
			showAll: 'Показать все',
			cancel: 'Отмена',
			yes: 'Да',
			hide: 'Скрыть выбранное',
			hideAll: 'Скрыть все',
			make: 'Сделать',
			remove: 'Убрать',
			delete: 'Удалить',
			deleteAccount: 'Удалить профиль',
			close: 'Закрыть',
			pin: 'Закрепить',
			unpin: 'Открепить',
			edit: 'Редактировать',
			end: 'Завершить',
			endAndShare: 'Завершить и поделиться',
			reset: 'Сбросить',
			rules: 'Правила чата',
			contactus: 'Написать нам',
		},
		pinMessage: {
			title: 'Закрепить сообщение?',
		},
		unPinMessage: {
			title: 'Открепить сообщение?',
		},
		accountRestored: {
			title: 'Профиль восстановлен',
		},
		closeApp: {
			title: 'Вы действительно хотите выйти?',
		},
		removeMessage: {
			title: 'Удалить сообщение?',
		},
		report: {
			title: 'Отправить жалобу?',
			subtitle: 'Вы также можете заблокировать пользователя, чтобы не видеть сообщения друг друга.',
		},
		banUser: {
			title: (name: string) => `Заблокировать ${name}?`,
			subtitle: (text: string) => `Пользователь будет заблокирован за ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Разблокировать ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Показать сообщения от ${name}?`,
			subTitle: 'Другие пользователи смогут видеть сообщения от этого пользователя',
		},
		hideUserMessages: {
			title: (name: string) => `Скрыть сообщения от ${name}?`,
			subTitle: 'Другие пользователи больше не смогут видеть сообщения от этого пользователя',
		},
		showUserMessage: {
			title: (name: string) => `Показать сообщениe от ${name}?`,
			subTitle: 'Другие пользователи смогут видеть это сообщение',
		},
		hideUserMessage: {
			title: (name: string) => `Скрыть сообщениe от ${name}?`,
			subTitle: 'Другие пользователи больше не смогут видеть это сообщение',
		},
		blockUserInRoom: {
			title: (name: string) => `Заблокировать ${name}?`,
			subTitle: 'Пользователь не сможет участвовать в этой комнате',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Разблокировать ${name}?`,
			subTitle: 'Пользователь сможет участвовать в этой комнате',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Заблокировать ${name}?`,
			subTitle: 'Вы не будете видеть сообщения друг друга',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Сделать ${name} спикером?`,
			unTitle: (name: string) => `Убрать ${name} из спикеров?`,
		},
		banInApp: {
			title: 'Администратор заблокировал вас',
			for: 'за',
			subtitle: (bannedUntil: string) => `Вы заблокированы до ${bannedUntil}`,
			permanent: 'Вы заблокированы навсегда',
		},
		banInAppBadNickname: {
			title: 'Администратор заблокировал вас за недопустимое имя',
			subtitle: 'Зайдите в свой профиль, измените имя и войдите в чат заново',
		},
		deleteAccount: {
			title: 'Удаление профиля',
			subTitle: 'Все данные будут удалены. Вы сможете восстановить профиль в течение 30 дней.',
		},
		pollEdit: {
			title: 'Редактировать опрос?',
			subTitle: 'Ответы пользователей будут сброшены',
		},
		pollReset: {
			title: 'Сбросить?',
			subTitle: 'Опрос не будет сохранен',
		},
		pollEnd: {
			title: 'Вы действительно хотите завершить опрос?',
		},
		pollDelete: {
			title: 'Удалить опрос?',
		},
		agoraCheck: {
			updateIOSText: 'Обновите версию iOS, чтобы использовать все возможности приложения.',
			allowAccess: {
				title: 'Громко и четко',
				text: 'Чтобы использовать микрофон и динамик, зайдите в настройки приложения и разрешите доступ.',
			},
			microphoneAndSpeakerText: 'Микрофон и динамик доступны только с\u00A0версии 14.3',
			microphoneText: 'Микрофон доступен с\u00A0версии 14.3',
			btn: 'Понятно',
		},
	},
	eventScreen: {
		title: 'Запланированное событие',
		titleSoon: 'Трансляция скоро начнется',
		subtitle: 'Чат скоро откроется, подождите.',
		days: 'дни',
		hours: 'часы',
		minutes: 'минуты',
		seconds: 'секунды',
		startTitle: 'Начало',
	},
	toasts: {
		reciveComplaint: 'Мы получили вашу жалобу',
		setSpeakerRoleForUser: 'Пользователь назначен спикером',
		unSpeakerRoleForUser: 'Пользователь удален из спикеров',
		messagesShown: 'Сообщения показаны',
		messagesHidden: 'Сообщения скрыты',
		messageShown: 'Сообщение показано',
		messageHidden: 'Сообщение скрыто',
		userBlocked: 'Пользователь заблокирован',
		userUnBlocked: 'Пользователь разблокирован',
		youAreSpeaker: 'Вы спикер! Скажите что-нибудь',
		youAreNotSpeaker: 'Администратор удалил вас из спикеров',
		userIsSpeaker: (name: string) => `${name} теперь спикер`,
		userNotSpeaker: (name: string) => `${name} теперь не спикер`,
		yourUserNameInChat: (name: string) => `Ваше имя в чате — ${name}`,
		messageRemoved: 'Сообщение удалено',
		messageCopied: 'Текст скопирован',
		obscenities: 'Попробуйте перефразировать',
		requestSent: 'Запрос отправлен',
		requestDecline: 'Ваш запрос на микрофон отклонен',
		youareblocked: (text: string) => `Вас заблокировали за ${text}. Вы не можете писать в чат`,
		youareunblocked: 'Вас разблокировали. Вы можете писать сообщения в чат',
		youAreModerator: 'Вы модератор!',
		youAreNoModerator: 'Вы больше не модератор',
		micMuted: 'Ваш микрофон заглушен',
		noInet: 'Отсутствует интернет-соединение',
		messagePinned: 'Сообщение закреплено',
		messageUnpinned: 'Сообщение откреплено',
		pollPublished: 'Опрос опубликован',
		pollPublishedError: 'Ошибка! Попробуйте позже',
		pollAddPic: 'Добавьте фото',
		pollAddPicError: 'Выберите другое изображение',
		pollDeleted: 'Опрос удален',
		pollEnded: 'Опрос завершен',
		sendStickerWhenSlowModeIsOn: (time: string) => `Подождите ${time} для отправки стикера`,
		streamConnecting: 'Подключаемся к трансляции...',
		linkProhibited: 'Вы не можете отправлять ссылки в чат',
		phoneProhibited: 'Вы не можете отправлять номера телефонов в чат',
		profileHidden: 'Пользователь скрыл профиль',
		imageSizeWarning: 'Можно загрузить картинку не больше 10 MB',
		imageBlocked: 'Модератор заблокировал изображение',
		streamKeyCopied: 'Ключ трансляции скопирован',
	},
	btns: {
		acceptandgo: 'Принять и продолжить',
		saveandgo: 'Сохранить и продолжить',
		save: 'Сохранить',
		unblock: 'Разблокировать',
		block: 'Заблокировать',
		restoreAccount: 'Восстановить профиль',
		close: 'Закрыть',
	},
	coopyrights: 'Работает на базе Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Пожаловаться на сообщение' : 'Сообщить о нарушении',
			subtitle: 'Жалобы отправляются анонимно',
			btns: {
				violence: 'Оскорбления, угрозы, ругань',
				provocations: 'Провокации',
				personalDetails: 'Персональные данные',
				flood: 'Флуд',
				spam: 'Спам',
				fraudBegging: 'Мошенничество, попрошайничество',
				other: 'Другое',
			},
		},
		ban: {
			title: 'Заблокировать пользователя',
			subtitle: 'Укажите причину блокировки',
			btns: {
				insults: 'Оскорбления, угрозы, ругань',
				personal: 'Распространение персональных данных',
				flood: 'Флуд',
				links: 'Ссылки на сторонние сервисы',
				violence: 'Угрозы насилия',
				fraud: 'Попытки мошенничества и попрошайничество',
				nickname: 'Недопустимый никнейм',
				politicial: 'Политические, сексуализированные и другие провокации',
				repeated: 'Повторное нарушение',
				auto: 'Жалобы пользователей чата',
				spam: 'Спам',
			},
		},
		waitingSpeakers: {
			text: 'У вас нет ожидающих спикеров',
			handsUp: 'Подняли руку',
			waiting: 'Ожидающие спикеры',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Дай верный ответ',
				typeQuizMultiple: 'Отметь верные варианты',
				typePoll: 'Поделись мнением',
				typePollMultiple: 'Можно отметить несколько вариантов',
				default: 'Публичный опрос',
			},
			btns: {
				reply: 'Ответить',
				vote: 'Голосовать',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Викторина',
				typePoll: 'Несколько ответов',
				default: 'Публичный опрос',
			},
			btns: {
				edit: 'Редактировать опрос',
				end: 'Завершить',
			},
		},
		pollCreated: {
			titles: {
				created: 'Создать опрос',
				typeQuiz: 'Викторина',
				typePoll: 'Несколько ответов',
				default: 'Публичный опрос',
			},
		},
		changeName: {
			title: 'Измените имя в чате',
			subtitle: 'Вы заблокированы за недопустимое имя',
		},
		temporaryAgreement: {
			title: 'Правила чата',
			rules: {
				bePolite: {
					title: 'Будьте вежливы',
					text: 'В чате запрещены враждебные и нецензурные высказывания.',
				},
				beAttentive: {
					title: 'Будьте внимательны',
					text: 'Не делитесь личными данными и не просите их у других.',
				},
				notAdvertise: {
					title: 'Не занимайтесь рекламой',
					text: 'Лучше поделитесь мнением или эмоциями.',
				},
			},
			btns: {
				allRules: 'Все правила',
				acceptAndContinue: 'Принять и продолжить',
			},
		},
		slowMode: {
			title: 'Медленный режим',
			text: 'Участники не смогут отправлять сообщения в чат чаще, чем в выбранный промежуток времени',
			enable: 'Включить медленный режим',
			disable: 'Выключить медленный режим',
			sendTimeout: 'Таймаут отправки',
		},
		slowModeTimeout: {
			title: 'Таймаут отправки',
		},
		chatBot: {
			title: 'Использование чат-бота',
			rules: [
				{
					title: 'Искусственный интеллект',
					text: 'Бот имитирует общение с людьми и предоставляет информацию в силу своих знаний. Однако помните, что его ответы могут быть ограниченными и не заменят человека.',
				},
				{
					title: 'Бот может ошибаться',
					text: 'Бот не может гарантировать точность и достоверность информации',
				},
			],
			btns: {
				close: 'Закрыть',
			},
		},
		shareBet: {
			title: 'Поделиться',
			noBetsTitle: 'Не смогли найти ваши пари',
			noBetsText: 'Можно поделиться пари, которые заключены в течение 14 дней',
			btns: {
				share: 'Поделиться ставкой',
				copy: 'Повторить пари',
			},
			status: {
				null: 'В игре',
				win: 'Выигрыш',
				lose: 'Проигрыш',
			},
			betTypeV1: {
				express: 'Экспресс',
				single: 'Одинар',
			},
			betTypeV2: {
				single: 'Одинар.',
				multi: 'Множ.',
				system: 'System',
			},
			totalFactor: 'Кэф',
			bet: 'пари',
			showMore: 'Показать еще',
			betRepeated: 'Повторили ставку',
			times: ['раз', 'раза', 'раз'],
		},
		shareGamble: {
			noGamblesText:
				'У вас пока нет выигрышей для публикации. Продолжайте играть, следующий выигрыш появится здесь, чтобы вы могли поделиться с другими игроками!',
		},
		record: {
			text: 'Запись эфира обрабатывается...',
		},
		agoraDevices: {
			title: 'Настройки аудио',
			text: 'Проверьте, что выбран правильный микрофон',
		},
		translate: {
			title: 'Перевод сообщений',
			chooseLanguage: 'Выбрать язык',
			descr: 'Все сообщения в чате будут автоматически переведены на выбранный язык.',
			btn: 'Включить перевод',
		},
		streamSettings: {
			title: 'Настроить трансляцию',
			description: 'Скачайте и установите видеокодер и в его настройках укажите ключ трансляции',
			shareScreen: {
				title: 'Демонстрация экрана',
				description: 'Простой способ не требующий настройки',
			},
			rtmp: {
				title: 'Внешний источник трансляции',
				description: 'Использовать дополнительную программу для трансляции потока',
			},
			streamKey: 'Ключ трансляции',
			showStreamKey: 'Показать ключ трансляции',
			hideStreamKey: 'Скрыть ключ трансляции',
			btns: {
				continue: 'Продолжить',
				close: 'Закрыть',
				ok: 'Готово',
			},
		},
	},
	pinnedMessage: {
		title: 'Закрепленное сообщение',
	},
	errorPage: {
		error: 'Ошибка',
		types: [
			// errorNumber 0
			{
				title: 'Мы вас не узнаём',
				text: 'Попробуйте перезайти в чат',
			},
			// errorNumber 1
			{
				title: 'Не можем найти чат',
				text: 'Попробуйте перезайти',
			},
			// errorNumber 2
			{
				title: 'Невозможно загрузить чат',
				text: 'Попробуйте перезагрузить',
			},
			// errorNumber 3
			{
				title: 'Мы вас не узнаём',
				text: 'Чат в режиме инкогнито недоступен',
			},
			// errorNumber 4
			{
				title: 'Недопустимый никнейм',
				text: 'Зайдите в свой профиль, измените никнейм и войдите в чат заново.',
			},
		],
	},
	tooltips: {
		emotions: 'Удержите, чтобы сменить',
		badInet: 'Плохое соединение с сетью',
		blockedUsers: 'Просмотреть список заблокированных <br>пользователей можно здесь.',
		waitingSpeakers: 'Кто-то из участников хочет высказаться.',
		wantSpeak: 'Нажмите, чтобы запросить право голоса.',
		slowMode: {
			header: (time: string) =>
				`Медленный режим включен. Пользователи <br>могут отправлять одно сообщение <br>кажд${time}. Это правило <br>не касается модераторов.`,
			btnSendMessage: (time: string) => `Вы можете отправлять сообщения <br>каждые ${time}.`,
		},
		shareBet: 'Поделиться ставкой в чате',
		translate: 'Перевести сообщения на другой язык',
	},
	poll: {
		toggleSubmenu: {
			create: 'Создать опрос',
			edit: 'Редактировать опрос',
			show: 'Показать опрос',
			end: 'Завершить опрос',
		},
		tooltip: {
			btn: 'Голосовать',
		},
		settings: {
			title: 'Настройки',
			titleAdditionally: 'Дополнительно',
			switches: {
				quizMode: 'Режим викторины',
				multipleChoice: 'Выбор нескольких ответов',
				withPhoto: 'Опрос с фотографией',
			},
		},
		question: {
			title: 'Вопрос',
			inputPlaceholder: 'Введите вопрос',
		},
		message: {
			title: 'Вы проголосовали',
		},
		answers: {
			title: 'Варианты ответа',
			warning: 'Вы можете добавить до 5 ответов',
		},
		answer: {
			tooltip: 'Выберите правильный ответ',
			inputPlaceholder: 'Ответ',
		},
		results: {
			text: 'Результаты голосования',
			votes: 'Голосов',
			// words: ['голос', 'голоса', 'голосов'],
		},
		btns: {
			addQuestion: 'Добавить вопрос',
			addAnswers: 'Добавить ответы',
			addAnswer: 'Добавить ответ',
			publish: 'Опубликовать',
			vote: 'Голосуйте',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Оценить',
				continue: 'Продолжить',
				checkConnection: 'Проверить соединение',
				close: 'Закрыть',
			},
			quality: {
				title: 'Оцените качество звука',
			},
			reason: {
				title: 'Что пошло не так?',
			},
			connection: {
				title: 'Протестируйте качество соединения',
				text: 'Проверка займет не больше минуты и поможет найти причину проблемы',
			},
			statistics: {
				title: 'Собираем статистику...',
			},
			done: {
				title: 'Спасибо!',
				text: 'Мы изучим, что пошло не так, и обязательно исправим.',
			},
		},
	},
	chatBot: {
		message: 'Спросите бота о чем-нибудь...',
		warnings: {
			disabled: 'Меня отключили',
			overloaded: 'Мы перегружены',
		},
	},
	audioReason: {
		nothear: 'Ничего не было слышно',
		connection: 'Звук прерывался',
		microphone: 'Не получилось воспользоваться микрофоном',
	},

	copybetting: {
		orderStatus: {
			won: 'Выигрыш',
			lost: 'Проигрыш',
			new: 'Новая',
			pending: 'В ожидании',
			cashedOut: 'Вывод средств',
			boreDraw: 'Безгол. ничья',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Отмена оператором',
			megaBetCanceledByPlayer: 'MegaBet Отмена игроком',
			megaBetCanceledBySystem: 'MegaBet Отмена системой',
			betGamesPending: 'BetGames В ожидании',
			betGamesWon: 'BetGames Выигрыш',
			betGamesLost: 'BetGames Проигрыш',
			paid: 'Оплачено',
			initialWin: 'Исх. выигрыш',
			autoBetCanceledBySystem: 'AutoBet Отмена системой',
			autoBetCanceledByPlayer: 'AutoBet Отмена игроком',
			autobetPending: 'AutoBet В ожидании',
			goalAhead: 'Будет гол',
			canceled: 'Отмена',
			return: 'Возврат',
		},
		stakeStatus: {
			pending: 'В ожидании',
			won: 'Выигрыш',
			lost: 'Проигрыш',
			return: 'Возврат',
			lostReturn: 'Проигрыш | Возврат',
			wonReturn: 'Выигрыш | Возврат',
			canceled: 'Отмена',
			initialReturn: 'Исх. возврат',
			pendingInitialReturn: 'В ожидании | Исх. возврат',
			wonInitialReturn: 'Выигрыш | Исх. возврат',
		},
	},
	screenShare: {
		title: 'Демонстрация экрана',
		stopSharing: 'Остановить трансляцию',

		modal: {
			title: 'Делиться экраном можно только с компьютера',
			text: 'Откройте чат на компьютере и нажмите кнопку «Демонстрация экрана» в правом верхнем углу.',
			btn: 'Копировать ссылку для входа',
		},
	},
	threads: {
		title: 'Тред',
		back: 'Назад',
		replies: ['ответ', 'ответа', 'ответов'],
	},

	attach: {
		submenu: {
			sendImage: 'Отправить изображение',
			shareBet: 'Поделиться ставкой',
			shareGamble: 'Поделиться выигрышем',
		},
		images: ['изображение', 'изображения', 'изображений'],
		addCaption: 'Добавить подпись',
	},
	following: {
		following: 'В подписках',
	},
} as unknown as Dictionary;
