import userServices from 'store/userService';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import UserService from 'services/api/UserService';
import useAppData from 'hooks/useAppData';
import useBanUsers from 'hooks/useBanUsers';
import useSearchParams from 'hooks/useSearchParams';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './settings.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertService from 'store/alertService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Button from 'components/hoc/Button';
import {Select} from 'components/ui';
import UserRole from 'models/enums/UserRole.enum';
import useAgora from 'hooks/useAgora';

const Settings: React.FC = function Settings() {
	const history = useHistory();
	const {userData, accessToken, updateUserData} = useLocalObservable(() => userServices);
	const {myTalker} = useLocalObservable(() => roomServices);
	const {
		appIcons,
		appVoice,
		blockedUsers,
		changeDeleteState,
		changeAgoraStatus,
		agoraMicrophoneActive,
		agoraMicrophones,
		agoraPlaybackDeviceActive,
		agoraPlaybackDevices,
		setAgoraMicrophoneActive,
		setAgoraPlaybackDeviceActive,
		appEnableAvatarChange,
		appEnableNameChange,
		appEnableProfileDeletion,
		appEnableAgora,
		appEnableMessageTranslation,
		appSettingsButtonVisible,
	} = useLocalObservable(() => appService);
	const {
		showProfileModal,
		showBlockedModal,
		toggleRulesModal,
		toggleSlowModeModal,
		hideAllModals,
		toggleLanguageModal,
	} = useLocalObservable(() => modalServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);

	const {search} = useSearchParams();
	const {getBanUsersWithServices} = useBanUsers();
	const {alerts, settingsScreen} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {destroyAgora, setPlaybackDevice, setMicrophone} = useAgora();

	const {IcoChevron} = appIcons;

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const isMyTalkerSpeacker = myTalker?.role === UserRole.SPEAKER;

	const agoraDataForSelect = (data: MediaDeviceInfo[]) => {
		const dataFilterFromLabel = data.filter(item => item.label !== '');
		if (dataFilterFromLabel.length) {
			return dataFilterFromLabel.map(item => {
				let cleanTitle = item.label;
				const cleanTitleSplit = cleanTitle.split(' ');
				if (
					cleanTitleSplit.length > 1 &&
					cleanTitleSplit[cleanTitleSplit.length - 1].includes('(')
				) {
					cleanTitle = cleanTitleSplit.slice(0, -1).join(' ');
				}
				return {
					id: item.deviceId,
					title: cleanTitle,
				};
			});
		}
		return [];
	};

	const onRemoveUserHandler = () => {
		sendAnalytics('delete_profile_pressed');
		showAlert({
			title: alerts.deleteAccount.title,
			subtitle: alerts.deleteAccount.subTitle,
			buttons: [
				{
					text: alerts.btns.deleteAccount,
					type: AlertBtnType.DESTRUCTIVE,

					onPress: async () => {
						const response = await UserService.selfDeleteUser(accessToken);
						if (response.status === ResponseStatus.SUCCESS) {
							updateUserData({deletedAt: `${new Date()}`});
							changeDeleteState(true);
							hideAllModals();
							hideAlert();
							sendAnalytics('delete_profile_confirmed');
							appEnableAgora && destroyAgora(changeAgoraStatus);
							setTimeout(
								() =>
									history.push({
										pathname: '/restore',
										search,
									}),
								100
							);
						}
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						sendAnalytics('delete_profile_cancelled');
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const onClickProfileBtnHandler = () => {
		sendAnalytics('profile_opened');
		showProfileModal();
	};

	const onClickBannedBtnHandler = () => {
		sendAnalytics('banned_opened');
		showBlockedModal();
	};

	const onClickSlowModeBtnHandler = () => {
		toggleSlowModeModal(true);
	};

	const onChangeAgoraMicrophoneDevice = (option: SelectOption) => {
		setMicrophone(option.id);
		const findMicrophone = agoraMicrophones.find(microphone => microphone.deviceId === option.id);
		if (findMicrophone) {
			setAgoraMicrophoneActive(findMicrophone);
		}
	};

	const onChangeAgoraPlaybackDevice = (option: SelectOption) => {
		setPlaybackDevice(option.id);
		const findPlaybackDevice = agoraPlaybackDevices.find(
			playbackDevice => playbackDevice.deviceId === option.id
		);
		if (findPlaybackDevice) {
			setAgoraPlaybackDeviceActive(findPlaybackDevice);
		}
	};

	useEffect(() => {
		getBanUsersWithServices();
	}, []);

	return (
		<div className='settings'>
			<div className='settings__groups'>
				{(isMyTalkerModer || isMyTalkerSpeacker) && appVoice && agoraMicrophones.length > 1 && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.agora.microphone}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Select
									options={agoraDataForSelect(agoraMicrophones)}
									optionIdActive={agoraMicrophoneActive?.deviceId}
									onOptionHandler={onChangeAgoraMicrophoneDevice}
								/>
							</div>
						</div>
					</div>
				)}

				{appVoice && agoraPlaybackDevices.length > 1 && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.agora.playbackDevice}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Select
									options={agoraDataForSelect(agoraPlaybackDevices)}
									optionIdActive={agoraPlaybackDeviceActive?.deviceId}
									onOptionHandler={onChangeAgoraPlaybackDevice}
								/>
							</div>
						</div>
					</div>
				)}

				{(appSettingsButtonVisible || appEnableMessageTranslation) && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.personal}</div>
						<div className='settings__items'>
							{(appEnableAvatarChange || appEnableNameChange) && (
								<div className='settings__item'>
									<Button className='settings__button' onClick={onClickProfileBtnHandler}>
										{settingsScreen.profileBtn}
										<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
									</Button>
								</div>
							)}
							{appEnableMessageTranslation && (
								<div className='settings__item'>
									<Button className='settings__button' onClick={() => toggleLanguageModal(true)}>
										{settingsScreen.languageBtn}
										<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
									</Button>
								</div>
							)}

							<div className='settings__item'>
								<Button
									className='settings__button'
									onClick={onClickBannedBtnHandler}
									disabled={!blockedUsers?.length}>
									{settingsScreen.blockedBtn}
									{blockedUsers?.length > 0 && (
										<>
											<span className='settings__button-number'>{blockedUsers.length}</span>
											<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
										</>
									)}
								</Button>
							</div>
						</div>
					</div>
				)}

				{isMyTalkerModer && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.room}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Button className='settings__button' onClick={onClickSlowModeBtnHandler}>
									{settingsScreen.slowModeBtn}
									<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className='settings__bottom'>
				<Button onClick={() => toggleRulesModal(true)} className='settings__delete-account'>
					{settingsScreen.rules}
				</Button>

				{appEnableProfileDeletion && (
					<Button onClick={onRemoveUserHandler} className='settings__delete-account'>
						{settingsScreen.deleteProfile}
					</Button>
				)}
				{/* <span className='settings__powered-by'>{coopyrights}</span> */}
			</div>
		</div>
	);
};

export default observer(Settings);
