import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import AntimatService from 'services/api/AntimatService';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';

export default () => {
	const appApiUrlsBackendRef = useRef('');

	const {appApiUrls, project} = useLocalObservable(() => appService);

	useEffect(() => {
		if (appApiUrls.antimat) {
			appApiUrlsBackendRef.current = appApiUrls.antimat;
		}
	}, [appApiUrls.antimat]);

	const containsMat = async (message: string, lang: string, callBackForError?: () => void) => {
		if (appApiUrlsBackendRef.current) {
			const response = await AntimatService.containsMat(
				message,
				lang,
				project,
				appApiUrlsBackendRef.current,
				callBackForError
			);
			if (response.status === ResponseStatus.SUCCESS) {
				return {status: ResponseStatus.SUCCESS, ...response.data};
			}
		}
		return {status: ResponseStatus.ERROR, message, isVailable: false};
	};

	const checkName = async (name: string, lang: string, callBackForError?: () => void) => {
		if (appApiUrlsBackendRef.current) {
			const response = await AntimatService.checkName(
				name,
				lang,
				project,
				appApiUrlsBackendRef.current,
				callBackForError
			);
			if (response.status === ResponseStatus.SUCCESS) {
				return {status: ResponseStatus.SUCCESS, ...response.data};
			}
		}
		return {status: ResponseStatus.ERROR, name, isVailable: false};
	};

	return {containsMat, checkName};
};
