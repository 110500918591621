import {MODAL_STYLE} from 'constants/constants';
import {PollType, PollScreens} from 'models/enums/Poll.enum';
import modalServices from 'store/modalService';
import roomServices from 'store/roomService';
import pollService from 'store/pollService';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {PollSettings} from 'components/poll/pollSettings';
import {PollQuestion} from 'components/poll/pollQuestion';
import {PollAnswers} from 'components/poll/pollAnswers';
import alertService from 'store/alertService';
import {AlertBtnType} from 'models/enums/Alert.enum';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import Button from 'components/hoc/Button';
import appService from 'store/appService';

const PollCreatedModal: React.FC = function PollCreatedModal() {
	const {modals, alerts} = useL10n();
	const {appIcons} = useLocalObservable(() => appService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {pollCreateModalVisible, togglePollCreateModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {pollCreated, screen, resetPollCreated, setScreen, setPollWithPics} = useLocalObservable(
		() => pollService
	);
	const {roomId} = useLocalObservable(() => roomServices);

	const {getAppIcon} = useAppData();

	const {IcoClose, IcoChevron} = appIcons;

	const onModalClosehandler = () => {
		showAlert({
			title: alerts.pollReset.title,
			subtitle: alerts.pollReset.subTitle,
			buttons: [
				{
					text: alerts.btns.reset,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: () => {
						togglePollCreateModalVisible(false);
						setScreen(PollScreens.SETTINGS);
						roomId && resetPollCreated(roomId);
						setPollWithPics(false, false);
						hideAlert();
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const renderScreen = () => {
		switch (screen) {
			case PollScreens.SETTINGS:
				return <PollSettings />;
			case PollScreens.QUESTION:
				return <PollQuestion />;
			case PollScreens.ANSWERS:
				return <PollAnswers />;
			default:
				return <PollSettings />;
		}
	};

	const getTitle = () => {
		if (screen === PollScreens.SETTINGS) {
			return modals.pollCreated.titles.created;
		}
		if (pollCreated?.type === PollType.QUIZ) {
			return modals.pollCreated.titles.typeQuiz;
		}
		if (pollCreated?.type === PollType.POLL && pollCreated.isMultiple) {
			return modals.pollCreated.titles.typePoll;
		}
		return modals.pollCreated.titles.default;
	};

	const openPrevScreen = () => {
		switch (screen) {
			case PollScreens.QUESTION:
				setScreen(PollScreens.SETTINGS);
				break;
			case PollScreens.ANSWERS:
				setScreen(PollScreens.QUESTION);
				break;
			default:
				setScreen(PollScreens.SETTINGS);
				break;
		}
	};

	if (!pollCreateModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--autoheight'
			isOpen={pollCreateModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				{screen !== PollScreens.SETTINGS && (
					<Button className='modal__prev' onClick={openPrevScreen}>
						{getAppIcon(IcoChevron.pic)}
					</Button>
				)}
				<div className='modal__title'>{getTitle()}</div>
				<Button className='modal__close' aria-label='modal-close' onClick={onModalClosehandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>{renderScreen()}</div>
			</div>
		</Modal>
	);
};

export default observer(PollCreatedModal);
