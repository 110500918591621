import {observer, useLocalObservable} from 'mobx-react-lite';

import './disabled.scss';
import {ReactComponent as IcoDisabled} from 'assets/svg/ico-disabled.svg';
import {ReactComponent as IcoLoader} from 'assets/svg/loader.svg';

import {useEffect, useState} from 'react';
import useUser from 'hooks/useUser';
import appService from 'store/appService';
import {CSSTransition} from 'react-transition-group';
import useSearchParams from 'hooks/useSearchParams';
import PartnerService from 'services/api/PartnerService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useAppData from 'hooks/useAppData';

const Disabled: React.FC = function Disabled() {
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [currentText, setCurrentText] = useState<any>(null);
	const {
		isAppSettings,
		disabledChatText,
		setDisabledChatText,
		language,
		isInternernetActive,
		appIcons,
	} = useLocalObservable(() => appService);
	const {getAppSettings} = useUser();
	const {apikeyFromUrl, langFromUrl} = useSearchParams();
	const {getAppIcon} = useAppData();
	const {IcoDisabledChat} = appIcons;

	const lang = langFromUrl || language;
	const authPartner = async (apikey: string) => {
		const response = await PartnerService.auth(apikey);
		if (response.status === ResponseStatus.SUCCESS) {
			setDisabledChatText(response.data.disabledChatText);
		}
	};

	useEffect(() => {
		if (disabledChatText) {
			setCurrentText(
				JSON.parse(disabledChatText)?.find((el: {lang: string; text: string}) => el.lang === lang)
			);
		}
	}, [disabledChatText, language]);

	useEffect(() => {
		if (isAppSettings) setVisiblePreloader(false);
	}, [isAppSettings]);

	useEffect(() => {
		if (apikeyFromUrl && isInternernetActive) {
			authPartner(apikeyFromUrl);
		}
	}, [isInternernetActive]);

	useEffect(() => {
		getAppSettings();
	}, []);

	return (
		<>
			<CSSTransition in={visiblePreloader} timeout={300} classNames='fade' unmountOnExit>
				<div className='preloader'>
					<IcoLoader className='preloader__ico-loader' />
				</div>
			</CSSTransition>
			<CSSTransition in={!visiblePreloader} timeout={300} classNames='fade' unmountOnExit>
				<div className='disabled'>
					<span className='disabled__ico'>{getAppIcon(IcoDisabledChat.pic)}</span>

					<div className='disabled__title'>{currentText?.text}</div>
				</div>
			</CSSTransition>
		</>
	);
};

export default observer(Disabled);
