import {ActionType} from 'models/enums/ActionType.enum';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import actionMessageServices from 'store/actionMessageService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useMessage from 'hooks/useMessage';
import {useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import Button from 'components/hoc/Button';
import './actionMessage.scss';
import MessageType from 'models/enums/MessageType.enum';
import {textEnding} from 'utils/helpers';
import classNames from 'classnames';

const ActionMessage: React.FC = function ActionMessage() {
	const {appIcons} = useLocalObservable(() => appService);
	const {myTalker, toggleClearChatTextarea} = useLocalObservable(() => roomServices);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage, actionData} = useLocalObservable(
		() => actionMessageServices
	);
	const {chatScreen, attach} = useL10n();
	const {getAppIcon} = useAppData();
	const {getMessageText, getBetTitle} = useMessage();

	const {IcoReplayAccent, IcoEditAccent, IcoToastClose} = appIcons;

	const clearActionMessageAndClearChatTextarea = () => {
		toggleClearChatTextarea(true);
		toggleIsVisibleActionMessage(false, true);
	};

	const onCloseHandler = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		clearActionMessageAndClearChatTextarea();
		chatTextarea?.focus();
	};

	useEffect(() => {
		if (myTalker?.bans?.length && actionData) {
			clearActionMessageAndClearChatTextarea();
		}
	}, [myTalker?.bans?.length]);

	if (!actionData) {
		return null;
	}

	const {type, message} = actionData;
	const {talker, user} = message;
	const isEditType = type === ActionType.EDIT;
	const pics = message.picData ? JSON.parse(message.picData).filter((el: any) => el.isVisible) : [];
	const iconClasses = classNames('action-message__icon', {
		'action-message__icon--pic': message.type === MessageType.PIC,
	});

	return (
		<CSSTransition in={isVisibleActionMessage} timeout={100} classNames='fade' unmountOnExit>
			<div className='action-message'>
				<div className={iconClasses}>
					{isEditType ? getAppIcon(IcoEditAccent.pic) : getAppIcon(IcoReplayAccent.pic)}
				</div>
				{message.type === MessageType.PIC && message.picData && pics && (
					<div>
						{pics[0].url && (
							<div className='action-message__pic'>
								<img src={pics[0].url} alt='' />
							</div>
						)}
					</div>
				)}
				<div className='action-message__content'>
					<div className='action-message__title'>
						{isEditType
							? chatScreen.actionMessage.editing
							: `${chatScreen.actionMessage.reply} ${user.name ? user.name : 'User deleted'}`}
					</div>
					{getMessageText(message) && (
						<div className='action-message__text'>{getMessageText(message)}</div>
					)}
					{message.type === MessageType.PIC && message.picData && !message.text && (
						<div className='action-message__img-text'>
							{pics.length} {textEnding(pics.length, attach.images)}
						</div>
					)}

					{message.sticker && <div className='action-message__text'>стикер</div>}
					{message.bet?.data && (!message.text || !message.isVisible) && (
						<div className='action-message__text'>{getBetTitle(message)}</div>
					)}
					{message.gamble && (!message.text || !message.isVisible) && (
						<div className='action-message__text'>{message.gamble.title}</div>
					)}
				</div>
				<Button className='action-message__close' onClick={onCloseHandler}>
					{getAppIcon(IcoToastClose.pic)}
				</Button>
			</div>
		</CSSTransition>
	);
};

export default observer(ActionMessage);
