/* eslint-disable max-lines */
import {Message, Talker} from 'models/room';
import {NUMBER_OF_MESSAGES_TO_DOWNLOAD, TALKER_STUB} from 'constants/constants';
import {qs} from 'utils/helpers';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import useAdvertisement from 'hooks/useAdvertisement';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import pollServices from 'store/pollService';
import threadService from 'store/threadService';
import RoomService from 'services/api/RoomService';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';

const BUTTON_VISIBILITY_BORDER = 35;

const getVerifiedMessages = (messages: Message[]) => {
	return messages.map(m => {
		if (m.talker) {
			return {...m, isNotReaded: false};
		}
		return {...m, talker: TALKER_STUB, isNotReaded: false};
	});
};

export default () => {
	const myTalkerRef = useRef<Talker | null>();
	const blockedUsersForFilteredMessagesRef = useRef<number[]>([]);
	const isLoading = useRef<boolean>(false);
	const isThread = useRef<boolean>(false);
	const isFilterBySubscriptionRef = useRef<boolean>(false);

	const {accessToken, translateMode} = useLocalObservable(() => userServices);
	const {appEnableSubscriptionsFilter} = useLocalObservable(() => appService);

	const threadStore = useLocalObservable(() => threadService);
	const roomStore = useLocalObservable(() => roomServices);
	const {setPoll, setPollOptionsChosen, toggllePollAlreadyVoted} = useLocalObservable(
		() => pollServices
	);
	const {checkViewedAdvertisement} = useAdvertisement();

	const toggleRestApiDataLoaded = (value: {
		messagesLoaded?: boolean;
		pinnedMessageLoaded?: boolean;
		pollLoaded?: boolean;
	}) => {
		isThread.current
			? threadStore.toggleRestApiDataLoaded(value)
			: roomStore.toggleRestApiDataLoaded(value);
	};

	const setMessages = (value: Message[]) => {
		isThread.current ? threadStore.addMessages(value) : roomStore.addMessages(value);
	};

	const updateMessage = (value: Message) => {
		isThread.current ? threadStore.updateMessage(value) : roomStore.updateMessage(value);
	};

	const setPinnedMessages = (value: Message[]) => {
		isThread.current ? threadStore.setPinnedMessages(value) : roomStore.setPinnedMessages(value);
	};

	const toggleChatScrollPositionBottom = (value: boolean) => {
		isThread.current
			? threadStore.toggleChatScrollPositionBottom(value)
			: roomStore.toggleChatScrollPositionBottom(value);
	};

	const pushMessages = (value: Message[]) => {
		isThread.current ? threadStore.pushMessages(value) : roomStore.pushMessages(value);
	};

	const unshiftMessages = (value: Message[]) => {
		isThread.current ? threadStore.unshiftMessages(value) : roomStore.unshiftMessages(value);
	};

	const toggleVisibleDateForFirstMessage = (value: boolean) => {
		isThread.current
			? threadStore.toggleVisibleDateForFirstMessage(value)
			: roomStore.toggleVisibleDateForFirstMessage(value);
	};

	const togglePreviousMessagesMode = (value: boolean) => {
		isThread.current
			? threadStore.togglePreviousMessagesMode(value)
			: roomStore.togglePreviousMessagesMode(value);
	};

	const reduceMentionMessageArray = () => {
		isThread.current
			? threadStore.reduceMentionMessageArray()
			: roomStore.reduceMentionMessageArray();
	};

	useEffect(() => {
		if (roomStore.myTalker) {
			myTalkerRef.current = roomStore.myTalker;
		}
	}, [roomStore.myTalker]);

	useEffect(() => {
		blockedUsersForFilteredMessagesRef.current = roomStore.blockedUsersForFilteredMessages;
	}, [roomStore.blockedUsersForFilteredMessages]);

	useEffect(() => {
		isFilterBySubscriptionRef.current = roomStore.isFilterBySubscription;
	}, [roomStore.isFilterBySubscription]);

	useEffect(() => {
		isThread.current = !!threadStore.currentThreadId;
	}, [threadStore.currentThreadId]);

	const checkMessages = (data: Message[]) => {
		return data.filter(
			message =>
				!blockedUsersForFilteredMessagesRef.current.includes(message.user?.id as number) &&
				(myTalkerRef.current?.isModer ||
					!myTalkerRef.current?.isModer ||
					(!myTalkerRef.current?.isModer && message.user?.id === roomStore.myTalker?.user.id)) &&
				(message.type === MessageType.USER ||
					(message.type === MessageType.ADVERTISEMENT &&
						myTalkerRef.current?.user.showAdvertisments) ||
					message.type === MessageType.POLLRESULTS ||
					message.type === MessageType.STICKER ||
					message.type === MessageType.BET ||
					message.type === MessageType.GAMBLE ||
					message.type === MessageType.PIC ||
					(message.type === MessageType.VOTE && message.user?.id === myTalkerRef.current?.user.id))
		);
	};

	const getMessagesOnScroll = async (
		messageCount: number,
		messageFirstId?: number,
		messageLastId?: number,
		onlySubscriptions?: number
	) => {
		const roomId = isThread.current ? threadStore.currentThreadId : roomStore.roomId;
		if (accessToken && roomId) {
			const response = await RoomService.getMessages(
				accessToken,
				roomId,
				messageCount,
				messageFirstId,
				messageLastId,
				onlySubscriptions
			);

			if (response.status === ResponseStatus.SUCCESS) {
				return response.data;
			}
		}
		return [];
	};

	const getMessagesWithServices = async (
		limit: number,
		onlySubscriptions?: number,
		chatScrollPositionBottom?: () => void
	) => {
		const roomId = isThread.current ? threadStore.currentThreadId : roomStore.roomId;
		if (accessToken && roomId) {
			let renderMessages: any = [];
			toggleVisibleDateForFirstMessage(false);

			const finishGettingMessages = () => {
				renderMessages = [];

				toggleRestApiDataLoaded({
					messagesLoaded: true,
					pinnedMessageLoaded: true,
				});
				if (chatScrollPositionBottom) chatScrollPositionBottom();
				toggleChatScrollPositionBottom(true);
			};

			// get messages until necessary count (15)
			const getVerifiedMessagesCount = async (id: number) => {
				const requestMessages = await getMessagesOnScroll(
					NUMBER_OF_MESSAGES_TO_DOWNLOAD,
					undefined,
					id,
					onlySubscriptions
				);
				if (requestMessages.length) {
					const verifiedRequestMessages = getVerifiedMessages(requestMessages);
					const checkedMessages = checkMessages(verifiedRequestMessages);

					if (checkedMessages.length) {
						renderMessages = [...checkedMessages, ...renderMessages];
						if (checkedMessages.length < 15) {
							getVerifiedMessagesCount(verifiedRequestMessages[0].id);
							return;
						}

						setMessages(renderMessages);
						finishGettingMessages();
						return;
					}
					getVerifiedMessagesCount(verifiedRequestMessages[0].id);
					return;
				}
				setMessages(renderMessages);

				finishGettingMessages();

				toggleVisibleDateForFirstMessage(true);
			};

			// start getting messages

			const response = await RoomService.getDataOnLoad(
				accessToken,
				roomId,
				limit,
				onlySubscriptions
			);
			if (response.status === ResponseStatus.SUCCESS) {
				let pinnedMessageData;
				if (response.data.pinnedMessage.length) {
					pinnedMessageData = response.data.pinnedMessage;

					setPinnedMessages(pinnedMessageData);
				}

				if (response.data.messages.length) {
					const verifiedMessages = getVerifiedMessages(response.data.messages);
					const checkedMessages = checkMessages(verifiedMessages);

					if (checkedMessages.length) {
						renderMessages = [...checkedMessages];
						if (checkedMessages.length < 15) {
							await getVerifiedMessagesCount(verifiedMessages[0]?.id);
							return;
						}
						setMessages(renderMessages);
						finishGettingMessages();
						return;
					}

					await getVerifiedMessagesCount(verifiedMessages[0]?.id);
					return;
				}
				toggleRestApiDataLoaded({messagesLoaded: true, pinnedMessageLoaded: true});
			}
			toggleRestApiDataLoaded({messagesLoaded: false, pinnedMessageLoaded: false});
		}
	};

	const getPollWithServices = async () => {
		if (accessToken && roomStore.roomId) {
			const response = await RoomService.getPoll(accessToken, roomStore.roomId);

			if (response?.status === ResponseStatus.SUCCESS) {
				const {poll, alreadyVoted, chosenOptions} = response.data;
				if (poll) {
					setPoll(poll, roomStore.roomId);
				}
				if (chosenOptions?.length) {
					setPollOptionsChosen(chosenOptions);
				}
				toggllePollAlreadyVoted(alreadyVoted);
				toggleRestApiDataLoaded({pollLoaded: true});
				return;
			}
			toggleRestApiDataLoaded({pollLoaded: false});
		}
	};

	const scrollToMessage = (
		messageID: number,
		chatMessagesRef: HTMLDivElement | null,
		chatScrollRef: HTMLDivElement | null,
		callback?: boolean,
		offset?: boolean
	) => {
		let messageLastChildPaddingBottom = 0;
		let scrollTimeout: any;
		let calcHeightForCenterScroll = 0;

		const messageToScroll = document.querySelector(`.chat__message[data-id="${messageID}"]`);
		const messageLastChild = document.querySelector('.chat__message:last-child');
		const pinnedMessageHeight = document.querySelector('.pinned-message')?.clientHeight;
		const headerHeight = document.querySelector('.header')?.clientHeight;
		const bottomHeight = document.querySelector('.main-bottom')?.clientHeight;

		if (pinnedMessageHeight) {
			calcHeightForCenterScroll += pinnedMessageHeight;
		}
		if (headerHeight) {
			calcHeightForCenterScroll += headerHeight;
		}
		if (bottomHeight) {
			calcHeightForCenterScroll -= bottomHeight;
		}

		if (messageLastChild && getComputedStyle(messageLastChild)) {
			messageLastChildPaddingBottom = parseInt(
				getComputedStyle(messageLastChild).paddingBottom,
				10
			);
		}

		if (chatMessagesRef && messageToScroll && messageToScroll instanceof HTMLElement) {
			let calcScrollOffsetY =
				messageLastChildPaddingBottom +
				messageToScroll.offsetTop +
				messageToScroll.clientHeight -
				chatMessagesRef.clientHeight;

			if (offset && chatScrollRef) {
				calcScrollOffsetY =
					messageToScroll.offsetTop -
					chatScrollRef.clientHeight / 2 +
					messageToScroll.offsetHeight / 2 -
					calcHeightForCenterScroll / 2;
			}

			chatScrollRef?.scroll({
				top: calcScrollOffsetY,
				behavior: 'smooth',
			});

			if (callback) {
				document
					.querySelector(`.chat__message[data-id="${messageID}"] .chat__message-container`)
					?.classList.add('chat__message-container--lighted');
				chatScrollRef?.addEventListener('scroll', () => {
					clearTimeout(scrollTimeout);
					scrollTimeout = setTimeout(() => {
						document
							.querySelector(`.chat__message[data-id="${messageID}"] .chat__message-container`)
							?.classList.remove('chat__message-container--lighted');
					}, 1000);
				});

				/* trigger scroll start */
				const event = new Event('scroll');
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				chatScrollRef?.addEventListener('scroll', () => {}, false);
				chatScrollRef?.dispatchEvent(event);
				/* trigger scroll end */
			}
		}
	};

	const getMessagesByIdAndCount = async (
		chatMessagesRef: HTMLDivElement | null,
		setChatScrollTop: (val: number) => void,
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		messageId: number,
		messageCount: number,
		forward = false
	) => {
		setVisiblePreloader(true);
		setDisableScroll(true);
		const roomId = isThread.current ? threadStore.currentThreadId : roomStore.roomId;
		if (roomId) {
			// очистка для режима получения след сообщений
			const forwardClearLoadingState = () => {
				toggleChatScrollPositionBottom(true);
				// setVisiblePreloader(false);
				isLoading.current = false;
				setDisableScroll(false);
			};

			// очистка для режима получения предыдыущих сообщений
			const prevClearLoadingState = () => {
				// setVisiblePreloader(false);
				isLoading.current = false;
				setDisableScroll(false);
			};

			// добавление валидных сообщений в стор
			const addMessagesToStorage = (incomingMessages: Message[]) => {
				forward ? pushMessages(incomingMessages) : unshiftMessages(incomingMessages);
			};

			const clearState = () => {
				forward ? forwardClearLoadingState() : prevClearLoadingState();
			};

			// получение, пока не будет необходимое кол-во для вывода c учетом сообщений от заблокированных юзеров и скрытых сообщений (не меньше 15)
			let renderMessages: any = [];
			let initialHeight = 0;
			const loaderHeight = qs('.chat__messages-preloader')?.offsetHeight;
			if (chatMessagesRef) {
				initialHeight = chatMessagesRef.scrollHeight - chatMessagesRef.scrollTop + loaderHeight;
			}

			const setScrollTop = () => {
				if (initialHeight && chatMessagesRef) {
					const messageStyles = window.getComputedStyle(qs('.chat__message'));
					const margin = parseFloat(messageStyles.marginBottom);
					setChatScrollTop(chatMessagesRef.scrollHeight - initialHeight + loaderHeight + margin);
				}
			};

			const getVerifiedMessagesCount = async (id: number) => {
				setVisiblePreloader(true);
				const requestMessages = forward
					? await getMessagesOnScroll(
							messageCount,
							id,
							appEnableSubscriptionsFilter ? +isFilterBySubscriptionRef.current : undefined
					  )
					: await getMessagesOnScroll(
							messageCount,
							undefined,
							id,
							appEnableSubscriptionsFilter ? +isFilterBySubscriptionRef.current : undefined
					  );
				if (requestMessages.length) {
					const verifiedRequestMessages = getVerifiedMessages(requestMessages);
					const checkedMessages = checkMessages(verifiedRequestMessages);

					if (checkedMessages.length) {
						renderMessages = forward
							? [...renderMessages, ...checkedMessages]
							: [...checkedMessages, ...renderMessages];
						if (checkedMessages.length < 15) {
							getVerifiedMessagesCount(
								verifiedRequestMessages[forward ? verifiedRequestMessages.length - 1 : 0].id
							);
							return;
						}
						addMessagesToStorage(renderMessages);
						renderMessages = [];
						clearState();
						!forward && setScrollTop();
						return;
					}
					getVerifiedMessagesCount(
						verifiedRequestMessages[forward ? verifiedRequestMessages.length - 1 : 0].id
					);
					return;
				}
				addMessagesToStorage(renderMessages);
				clearState();
				!forward && toggleVisibleDateForFirstMessage(true);
				forward && togglePreviousMessagesMode(false);
			};

			// запрос
			await getVerifiedMessagesCount(messageId);
		}

		// setVisiblePreloader(false);
		setDisableScroll(false);
	};

	const getMessagesAround = async (
		chatMessagesRef: HTMLDivElement | null,
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		messageId: number,
		limit: number,
		onlySubscriptions?: number
	) => {
		setVisiblePreloader(true);
		setDisableScroll(true);
		toggleVisibleDateForFirstMessage(false);
		const roomId = isThread.current ? threadStore.currentThreadId : roomStore.roomId;
		if (roomId) {
			const response = await RoomService.getMessagesAround(
				accessToken,
				roomId,
				messageId,
				limit,
				onlySubscriptions
			);
			if (response.status === ResponseStatus.SUCCESS && response.data.length) {
				const verifiedMessages = getVerifiedMessages(response.data);
				setVisiblePreloader(false);
				setDisableScroll(false);
				chatMessagesRef?.scroll({top: 10});
				setMessages(verifiedMessages);
				return {status: ResponseStatus.SUCCESS};
			}
		}
		setVisiblePreloader(false);
		setDisableScroll(false);
		return {status: ResponseStatus.ERROR};
	};

	const checkScrollTop = async (
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		setNewMessagesCount: (val: number) => void,
		setScrollToBottom: (val: boolean) => void,
		resetStateAfterScrollToBottom: () => void,
		newMessagesCount: number,
		visiblePreloader: boolean,
		scrollToBottom: boolean,
		chatMessagesRef: HTMLDivElement | null,
		setChatScrollTop: (val: number) => void,
		event: React.BaseSyntheticEvent
	) => {
		const {clientHeight, scrollTop, scrollHeight} = event.target;

		// checkForPinnedMessage();

		if (!event.target.classList.contains('chat__axis-y--thread') && isThread.current) {
			return;
		}
		setVisiblePreloader(true);
		const previousMessagesMode = isThread.current
			? threadStore.previousMessagesMode
			: roomStore.previousMessagesMode;
		const messages = isThread.current ? threadStore.messages : roomStore.messages;
		const mentionMessageArray = isThread.current
			? threadStore.mentionMessageArray
			: roomStore.mentionMessageArray;
		const chatScrollPositionBottom = isThread.current
			? threadStore.chatScrollPositionBottom
			: roomStore.chatScrollPositionBottom;

		if (previousMessagesMode) {
			// подгрузка сообщений в нижней точке скролла
			if (
				(chatMessagesRef &&
					Math.round(chatMessagesRef.offsetHeight + scrollTop) === Math.abs(scrollHeight)) ||
				(chatMessagesRef &&
					Math.floor(chatMessagesRef.offsetHeight + scrollTop) === Math.abs(scrollHeight))
			) {
				const messageLast = messages[messages.length - 1];

				if (!isLoading.current && accessToken && messageLast) {
					isLoading.current = true;
					await getMessagesByIdAndCount(
						chatMessagesRef,
						setChatScrollTop,
						setDisableScroll,
						setVisiblePreloader,
						messageLast.id,
						NUMBER_OF_MESSAGES_TO_DOWNLOAD,
						true
					);
				}
			}
		}

		const filteredNotViewedMessages = messages.filter(
			item => item.type !== MessageType.VOTE && item.isVisible && item.isNotReaded
		);
		const advertisementMessages = messages.filter(item => item.type === MessageType.ADVERTISEMENT);
		let countViewedMessages = 0;

		// подгрузка сообщений в верхней точке скролла

		if (chatMessagesRef && chatMessagesRef.offsetHeight - clientHeight === Math.abs(scrollTop)) {
			if (clientHeight !== scrollHeight) toggleChatScrollPositionBottom(false);
			const {0: messageFirst} = messages;

			if (!isLoading.current && accessToken && messageFirst) {
				isLoading.current = true;
				await getMessagesByIdAndCount(
					chatMessagesRef,
					setChatScrollTop,
					setDisableScroll,
					setVisiblePreloader,
					messageFirst.id,
					NUMBER_OF_MESSAGES_TO_DOWNLOAD
				);
			}
		}

		filteredNotViewedMessages.forEach(el => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${el.id}"]`);

			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const {offsetTop, offsetHeight} = selectedMessage;
				const messageBorderBottom = Math.floor(offsetTop + offsetHeight); // нижняя граница сообщения
				const scrollBorderBottom = Math.ceil(scrollTop + clientHeight); // нижняя граница скрола

				if (messageBorderBottom <= scrollBorderBottom) {
					updateMessage({...el, isNotReaded: false});

					if (newMessagesCount) {
						countViewedMessages += 1;
						if (!scrollToBottom) {
							setScrollToBottom(true);
						}
					}

					if (el.mentionMessage && mentionMessageArray.length !== 0) {
						reduceMentionMessageArray();
					}
				}
			}
		});

		if (filteredNotViewedMessages.length && newMessagesCount) {
			setNewMessagesCount(filteredNotViewedMessages.length - countViewedMessages);
		}

		if (advertisementMessages.length) {
			checkViewedAdvertisement(advertisementMessages, clientHeight);
		}

		if (scrollHeight - (scrollTop + clientHeight) >= BUTTON_VISIBILITY_BORDER + 48) {
			if (chatScrollPositionBottom) {
				toggleChatScrollPositionBottom(false);
			}
		} else {
			resetStateAfterScrollToBottom();

			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify({type: 'allMessagesRead'}));
				return;
			}

			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
					JSON.stringify({type: 'allMessagesRead'})
				);
				return;
			}

			window.parent.postMessage({type: 'allMessagesRead'}, '*');
		}
	};

	return {
		checkMessages,
		getMessagesWithServices,
		getMessagesByIdAndCount,
		getMessagesAround,
		scrollToMessage,
		checkScrollTop,
		getPollWithServices,
	};
};
