import throttle from 'lodash/throttle';
import {AppEmotion} from 'models/app';
import roomServices from 'store/roomService';
import emotionServices from 'store/emotionService';
import SocketIoServices from 'services/SocketIoServices';
import {useCallback} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import useAnalytics from './useAnalytics';

export default () => {
	const {sendAnalytics} = useAnalytics();
	const {roomId} = useLocalObservable(() => roomServices);
	const {emotions, addEmotion, toggleIdEmotionActive} = useLocalObservable(() => emotionServices);
	let versionAnim = true;

	const sendEmotion = useCallback(
		throttle(
			(emotion: AppEmotion, changeIdEmotionActive?: boolean) => {
				if (emotion && roomId) {
					const generateEmotion = {
						...emotion,
						uuid: uuidv4(),
						versionAnim: versionAnim ? 'first' : 'second',
					};
					addEmotion(generateEmotion);
					SocketIoServices.emitEmotion({name: emotion.name}, roomId);
					versionAnim = !versionAnim;
					if (changeIdEmotionActive) {
						toggleIdEmotionActive(emotion.id);
					}
					sendAnalytics('emoji_sent', {
						emoji_number: emotions.map(e => e.name).indexOf(emotion.name) + 1,
					});
				}
			},
			300,
			{leading: false}
		),
		[roomId]
	);

	return {sendEmotion};
};
