import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import WelcomeService from 'services/api/WelcomeService';
import appService from 'store/appService';
import userService from 'store/userService';
import welcomeService from 'store/welcomeService';

const useWelcome = () => {
	const {setWelcomeMessage} = useLocalObservable(() => welcomeService);
	const {accessToken} = useLocalObservable(() => userService);
	const {language} = useLocalObservable(() => appService);

	const getWelcomeWithServices = async () => {
		if (accessToken) {
			const response = await WelcomeService.getWelcome(accessToken, language);
			if (response.status === ResponseStatus.SUCCESS) {
				setWelcomeMessage(response.data);
			}
		}
	};

	return {getWelcomeWithServices};
};

export default useWelcome;
