import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface IShareBetSkeleton {
	visible: boolean;
}

const ShareBetSkeleton: FunctionComponent<IShareBetSkeleton> = function ShareBetSkeleton({
	visible,
}) {
	return (
		<CSSTransition in={visible} timeout={300} classNames='fade' unmountOnExit>
			<div className='share-bet__skeleton'>
				<div className='share-bet__skeleton'>
					<div className='share-bet__skeleton-bets'>
						{[0, 1, 2, 3, 4, 5].map((i: number) => (
							<div className='share-bet__skeleton-bet' key={i}>
								<LoadingSkeleton enableAnimation={false} />
							</div>
						))}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(ShareBetSkeleton);
