/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'สัญญา',
		subTitle: 'ข้อตกลงการอนุญาตใช้สิทธิ์และนโยบายความเป็นส่วนตัว',
		textFirst: 'ฉันยอมรับเงื่อนไขและยินยอมให้ประมวลผลข้อมูลส่วนบุคคลภายใต้เงื่อนไขที่กำหนดไว้',
		license: 'ข้อตกลงการอนุญาตใช้สิทธิ์และ',
		policy: 'นโยบายความเป็นส่วนตัว',
	},
	controlPanel: {
		streamEnded: 'การออกอากาศสิ้นสุดแล้ว',
		banAlert: 'คุณไม่สามารถเขียนในแชทได้',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'สติ๊กเกอร์' : 'อิโมจิ',
	},
	accountDeletedScreen: {
		title: 'ลบโปรไฟล์แล้ว',
		text: (date: string) => `คุณสามารถคืนค่าโปรไฟล์ของคุณภายใน ${date}`,
	},
	nameScreen: {
		title: 'ชื่อในแชท',
		inputPlaceholder: 'ชื่อไม่ถูกต้อง',
		nameError: 'ชื่อไม่ถูกต้อง',
	},
	avatarScreen: {
		title: 'รูปอวตาร',
		label: 'เลือกอันอื่น:',
	},
	settingsScreen: {
		title: 'การตั้งค่า',
		personal: 'การตั้งค่าส่วนบุคคล',
		room: 'การตั้งค่าห้อง',
		profileBtn: 'โปรไฟล์',
		languageBtn: 'ภาษาและอินเทอร์เฟซ',
		blockedBtn: 'ถูกบล็อก',
		rules: 'กฎการแชท',
		deleteProfile: 'ลบโปรไฟล์',
		slowModeBtn: 'โหมดส่งข้อความช้า',
		agora: {
			microphone: 'ไมโครโฟน',
			playbackDevice: 'ที่มาของเสียง',
		},
	},
	languageScreen: {
		title: 'ภาษาและอินเทอร์เฟซ',
		messagesTranslate: 'การแปลข้อความ',
		translateMessagesMode: 'แปลข้อความแชท',
		translateMessagesTo: 'แปลข้อความเป็น',
	},
	rulesScreen: {
		title: 'กฎการแชท',
	},
	profileScreen: {
		title: 'โปรไฟล์',
		selectAnotherPic: 'เลือกอวตารอื่น',
		chatNameLabel: 'ชื่อในแชท',
		changeName: 'เปลี่ยน',
	},
	blockedScreen: {
		title: 'ถูกบล็อก',
		emptyListText: 'ไม่มีผู้ใช้ที่ถูกบล็อก',
	},
	chatScreen: {
		inputPlaceholder: 'ข้อความ...',
		cantWrite: 'คุณไม่สามารถเขียนในแชทได้',
		chatSubmenu: {
			hideMessage: 'ซ่อนข้อความ',
			showMessage: 'แสดงข้อความ',
			removeFromSpeakers: 'ถอดออกจากผู้พูด',
			setSpeaker: 'อนุญาตให้เป็นผู้พูด',
			hideOnlyMessage: 'ซ่อนข้อความ',
			showOnlyMessage: 'แสดงข้อความ',
			reportMessage: 'รายงานปัญหาข้อความ',
			reportUser: 'รายงานปัญหาผู้ใช้',
			muteSpeaker: 'ปิดไมโครโฟน',
			blockUser: 'บล็อก',
			unblockUser: 'ยกเลิกบล็อก',
			reply: 'ตอบ',
			copy: 'คัดลอก',
			edit: 'เปลี่ยน',
			delete: 'ลบ',
			pinMessage: 'ปักหมุด',
			unpinMessage: 'เลิกปักหมุด',
			goToProfile: 'ไปที่โปรไฟล์',
			replyInThread: 'ตอบในเธรด',
		},
		actionMessage: {
			editing: 'การแก้ไข',
			reply: 'ตอบ',
		},
		status: {
			top: 'สูงสุด',
		},
		translating: 'กำลังแปล...',
	},
	chatUsersScreen: {
		title: 'ผู้เข้าร่วม',
		edited: 'เปลี่ยนแล้ว',
		hidden: 'Скрыто',
		unreadMessages: 'ข้อความที่ไม่ได้อ่าน',
		usersSubmenu: {
			muteSpeaker: {
				title: 'ปิดเสียงผู้เข้าร่วม',
				subtitle: 'ปิดไมโครโฟน',
			},
			setSpeaker: {
				title: 'ถอดออกจากผู้พูด',
				subtitle: 'ทักแชทเท่านั้น',
			},
			removeFromSpeaker: {
				title: 'อนุญาตให้เป็นผู้พูด',
				subtitle: 'ผู้ใช้จะสามารถพูดได้',
			},
			showMessages: {
				title: 'แสดงข้อความ',
				subtitle: 'มองเห็นได้เฉพาะผู้ดูแลระบบเท่านั้น',
			},
			hideMessages: {
				title: 'ซ่อนข้อความ',
				subtitle: 'มองเห็นได้เฉพาะผู้ดูแลระบบเท่านั้น',
			},
			blockUser: {
				title: 'บล็อก',
				subtitle: 'เพิ่มในรายการบล็อก',
			},
			unblockUser: {
				title: 'ยกเลิกบล็อก',
				subtitle: 'เลิกบล็อกผู้ใช้',
			},
			blockUserByUser: {
				title: 'บล็อก',
				subtitle: 'ซ่อนข้อความของผู้ใช้ทั้งหมด',
			},
			unblockUserByUser: {
				title: 'ยกเลิกบล็อก',
				subtitle: 'แสดงข้อความทั้งหมดของผู้ใช้',
			},
			reportUser: {
				title: 'รายงานปัญหาผู้ใช้',
				subtitle: 'การร้องเรียนจะถูกส่งโดยไม่ระบุชื่อ',
			},
		},
		days: {
			today: 'วันนี้',
			yesterday: 'เมื่อวาน',
		},
		chatCreated: 'สร้างแชทแล้ว',
	},
	alerts: {
		btns: {
			unblock: 'ยกเลิกบล็อก',
			block: 'บล็อก',
			send: 'ส่ง',
			sendAndBlock: 'ส่งและบล็อก',
			show: 'แสดง',
			showAll: 'แสดงทั้งหมด',
			cancel: 'ยกเลิก',
			yes: 'ใช่',
			hide: 'ซ่อนรายการที่เลือก',
			hideAll: 'ซ่อนทั้งหมด',
			make: 'ทำ',
			remove: 'เอาออก',
			delete: 'ลบ',
			deleteAccount: 'ลบโปรไฟล์',
			close: 'ปิด',
			pin: 'ปักหมุด',
			unpin: 'เลิกปักหมุด',
			edit: 'แก้ไข',
			end: 'เสร็จสิ้น',
			endAndShare: 'สิ้นสุดและแชร์',
			reset: 'รีเซ็ต',
			rules: 'กฎการแชท',
			contactus: 'เขียนถึงเรา',
		},
		pinMessage: {
			title: 'ต้องการปักหมุดข้อความหรือไม่?',
		},
		unPinMessage: {
			title: 'ต้องการยกเลิกปักหมุดข้อความหรือไม่?',
		},
		accountRestored: {
			title: 'กู้คืนโปรไฟล์แล้ว',
		},
		closeApp: {
			title: 'คุณแน่ใจหรือไม่ว่าต้องการออก?',
		},
		removeMessage: {
			title: 'ต้องการลบข้อความหรือไม่?',
		},
		report: {
			title: 'ต้องการยื่นเรื่องร้องเรียนหรือไม่?',
			subtitle: 'คุณยังสามารถบล็อกผู้ใช้เพื่อป้องกันไม่ให้พวกเขาเห็นโพสต์ของกันและกัน',
		},
		banUser: {
			title: (name: string) => `ต้องการบล็อก ${name} หรือไม่?`,
			subtitle: (text: string) => `ผู้ใช้จะถูกบล็อกเนื่องจาก ${text}`,
		},
		unBanUser: {
			title: (name: string) => `ต้องการเลิกบล็อก ${name} หรือไม่?`,
		},
		showUserMessages: {
			title: (name: string) => `ต้องการแสดงข้อความจาก ${name} หรือไม่?`,
			subTitle: 'ผู้ใช้คนอื่นจะสามารถดูโพสต์ของผู้ใช้คนนี้ได้',
		},
		hideUserMessages: {
			title: (name: string) => `ต้องการซ่อนข้อความจาก ${name} หรือไม่?`,
			subTitle: 'ผู้ใช้คนอื่นจะไม่สามารถดูโพสต์ของผู้ใช้คนนี้ได้อีกต่อไป',
		},
		showUserMessage: {
			title: (name: string) => `ต้องการแสดงข้อความจาก ${name} หรือไม่??`,
			subTitle: 'ผู้ใช้คนอื่นจะสามารถดูข้อความนี้ได้',
		},
		hideUserMessage: {
			title: (name: string) => `ต้องการซ่อนข้อความจาก ${name} หรือไม่?`,
			subTitle: 'ผู้ใช้คนอื่นจะไม่สามารถดูข้อความนี้ได้อีกต่อไป',
		},
		blockUserInRoom: {
			title: (name: string) => `ต้องการบล็อก ${name} หรือไม่?`,
			subTitle: 'ผู้ใช้จะไม่สามารถเข้าร่วมในห้องนี้ได้',
		},
		unBlockUserInRoom: {
			title: (name: string) => `ต้องการเลิกบล็อก ${name} หรือไม่?`,
			subTitle: 'ผู้ใช้จะไม่สามารถเข้าร่วมในห้องนี้ได้',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `ต้องการบล็อก ${name} หรือไม่?`,
			subTitle: 'คุณจะไม่เห็นข้อความของกันและกัน',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `อนุญาตให้ ${name} เป็นผู้พูดหรือไม่?`,
			unTitle: (name: string) => `ต้องการลบ ${name} ออกจากผู้พูดหรือไม่?`,
		},
		banInApp: {
			title: 'ผู้ดูแลระบบบล็อกคุณ',
			for: 'เนื่องจาก',
			subtitle: (bannedUntil: string) => `คุณถูกบล็อกจนถึง ${bannedUntil}`,
			permanent: 'คุณถูกบล็อกถาวร',
		},
		banInAppBadNickname: {
			title: 'ผู้ดูแลระบบได้บล็อกคุณเนื่องจากชื่อที่ไม่ถูกต้อง',
			subtitle: 'ไปที่โปรไฟล์ของคุณ เปลี่ยนชื่อของคุณและกลับไปหน้าแชทอีกครั้ง',
		},
		deleteAccount: {
			title: 'การลบโปรไฟล์',
			subTitle: 'ข้อมูลทั้งหมดจะถูกลบ คุณจะสามารถกู้คืนโปรไฟล์ของคุณได้ภายใน 30 วัน',
		},
		pollEdit: {
			title: 'ต้องการแก้ไขโพลหรือไม่?',
			subTitle: 'คำตอบของผู้ใช้จะถูกรีเซ็ต',
		},
		pollReset: {
			title: 'ต้องการรีเซ็ตหรือไม่?',
			subTitle: 'โพลจะไม่ถูกบันทึก',
		},
		pollEnd: {
			title: 'คุณแน่ใจหรือไม่ว่าต้องการตอบโพล?',
		},
		pollDelete: {
			title: 'ต้องการลบโพลหรือไม่?',
		},
		agoraCheck: {
			updateIOSText:
				'อัปเดตเวอร์ชัน iOS ของคุณเพื่อใช้ประโยชน์จากฟีเจอร์ต่างๆ ของแอปได้อย่างเต็มที่',
			allowAccess: {
				title: 'คุณต้องการที่จะพูดอะไรบางอย่างหรือไม่?',
				text: 'หากต้องการใช้ไมโครโฟนและลำโพง ให้ไปที่การตั้งค่าแอปพลิเคชันและยืนยันการเข้าถึง',
			},
			microphoneAndSpeakerText: 'มีไมโครโฟนและลำโพงเฉพาะตั้งแต่เวอร์ชัน 14.3 เป็นต้นไปเท่านั้น',
			microphoneText: 'สามารถใช้ไมโครโฟนได้ตั้งแต่เวอร์ชัน 14.3 เป็นต้นไป',
			btn: 'เข้าใจ',
		},
	},
	eventScreen: {
		title: 'กิจกรรมตามกำหนดการ',
		titleSoon: 'การออกอากาศจะเริ่มเร็วๆ นี้',
		subtitle: 'แชทจะเปิดเร็วๆนี้ โปรดรอสักครู่',
		days: 'วัน',
		hours: 'ชั่วโมง',
		minutes: 'นาที',
		seconds: 'วินาที',
		startTitle: 'เริ่ม',
	},
	toasts: {
		reciveComplaint: 'เราได้รับการร้องเรียนของคุณแล้ว',
		setSpeakerRoleForUser: 'ผู้ใช้ที่ได้รับการแต่งตั้งให้เป็นผู้พูด',
		unSpeakerRoleForUser: 'ผู้ใช้ถูกลบออกจากผู้พูดแล้ว',
		messagesShown: 'ข้อความที่แสดง',
		messagesHidden: 'ข้อความที่ถูกซ่อนไว้',
		messageShown: 'ข้อความที่แสดง',
		messageHidden: 'ข้อความที่ถูกซ่อนไว้',
		userBlocked: 'ผู้ใช้ถูกบล็อก',
		userUnBlocked: 'ผู้ใช้ถูกปลดบล็อก',
		youAreSpeaker: 'คุณคือผู้พูด! เริ่มพูดได้เลย',
		youAreNotSpeaker: 'ผู้ดูแลระบบได้ลบคุณออกจากผู้พูด',
		userIsSpeaker: (name: string) => `ตอนนี้ ${name} เป็นผู้พูด`,
		userNotSpeaker: (name: string) => `${name} ไม่ใช่ผู้พูด`,
		yourUserNameInChat: (name: string) => `ชื่อของคุณในแชทคือ ${name}`,
		messageRemoved: 'ลบข้อความแล้ว',
		messageCopied: 'คัดลอกข้อความแล้ว',
		obscenities: 'ลองถอดความ',
		requestSent: 'ส่งคำขอแล้ว',
		requestDecline: 'คำขอไมโครโฟนของคุณถูกปฏิเสธ',
		youareblocked: (text: string) => `คุณถูกบล็อกเนื่องจาก ${text} คุณไม่สามารถพิมพ์ในแชท`,
		youareunblocked: 'คุณถูกปลดบล็อกแล้ว คุณสามารถสามารถพิมพ์ในแชท',
		youAreModerator: 'คุณเป็นพิธีกร!',
		youAreNoModerator: 'คุณไม่ใช่พิธีกร',
		micMuted: 'ไมโครโฟนของคุณถูกปิดเสียง',
		noInet: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
		messagePinned: 'ปักหมุดโพสต์แล้ว',
		messageUnpinned: 'เลิกปักหมุดโพสต์แล้ว',
		pollPublished: 'เผยแพร่โพล',
		pollPublishedError: 'เกิดข้อผิดพลาด! โปรดลองในภายหลัง',
		pollAddPic: 'เพิ่มรูปถ่าย',
		pollAddPicError: 'เลือกภาพอื่น',
		pollDeleted: 'ลบโพลแล้ว',
		pollEnded: 'โพลเสร็จสมบูรณ์',
		sendStickerWhenSlowModeIsOn: (time: string) => `รอ ${time} เพื่อส่งสติ๊กเกอร์`,
		streamConnecting: 'มาร่วมถ่ายทอด...',
		linkProhibited: 'คุณไม่สามารถส่งลิงก์ในการแชทได้',
		phoneProhibited: 'คุณไม่สามารถส่งหมายเลขโทรศัพท์ในการแชทได้',
		profileHidden: 'ผู้ใช้ซ่อนโปรไฟล์ไว้',
		imageSizeWarning: 'คุณสามารถอัปโหลดภาพได้สูงสุด 10 MB',
		imageBlocked: 'ผู้ดูแลระบบบล็อกการอัปโหลดภาพ',
		streamKeyCopied: 'คัดลอกคีย์การออกอากาศแล้ว',
	},
	btns: {
		acceptandgo: 'ยอมรับและดำเนินการต่อ',
		saveandgo: 'บันทึกและดำเนินการต่อ',
		save: 'บันทึก',
		unblock: 'ยกเลิกบล็อก',
		block: 'บล็อก',
		restoreAccount: 'คืนค่าโปรไฟล์',
		close: 'ปิด',
	},
	coopyrights: 'ทำงานบน Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'รายงานปัญหาข้อความ' : 'รายงานการละเมิด',
			subtitle: 'การร้องเรียนจะถูกส่งโดยไม่ระบุชื่อ',
			btns: {
				violence: 'ดูหมิ่น ข่มขู่ ใช้ถ้อยคำหยายคาย',
				provocations: 'การยั่วยุ',
				personalDetails: 'ข้อมูลส่วนบุคคล',
				flood: 'น้ำท่วม',
				spam: 'สแปม',
				fraudBegging: 'การฉ้อโกง  ขอทาน',
				other: 'อื่นๆ',
			},
		},
		ban: {
			title: 'บล็อกผู้ใช้',
			subtitle: 'ระบุเหตุผลในการบล็อก',
			btns: {
				insults: 'ดูหมิ่น ข่มขู่ ใช้ถ้อยคำหยายคาย',
				personal: 'การเผยแพร่ข้อมูลส่วนบุคคล',
				flood: 'น้ำท่วม',
				links: 'ลิงก์ไปยังบริการของบุคคลที่สาม',
				violence: 'การคุกคามของความรุนแรง',
				fraud: 'ความพยายามฉ้อโกงและขอทาน',
				nickname: 'ชื่อเล่นไม่ถูกต้อง',
				politicial: 'การยั่วยุทางการเมือง ทางเพศ และการยั่วยุอื่นๆ',
				repeated: 'การละเมิดซ้ำ',
				auto: 'การร้องเรียนเรื่องการใช้แชทของผู้ใช้',
				spam: 'สแปม',
			},
		},
		waitingSpeakers: {
			text: 'คุณไม่มีผู้พูดรออยู่',
			handsUp: 'ยกมือ',
			waiting: 'รอผู้พูด',
		},
		poll: {
			supTitles: {
				typeQuiz: 'ให้คำตอบที่ถูกต้อง',
				typeQuizMultiple: 'ตรวจสอบตัวเลือกที่ถูกต้อง',
				typePoll: 'แชร์ความคิดเห็นของคุณ',
				typePollMultiple: 'สามารถทำหมายเหตุได้หลายตัวเลือก',
				default: 'โพลสาธารณะ',
			},
			btns: {
				reply: 'ตอบ',
				vote: 'โหวต',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'แบบทดสอบ',
				typePoll: 'หลายคำตอบ',
				default: 'โพลสาธารณะ',
			},
			btns: {
				edit: 'แก้ไขโพล',
				end: 'เสร็จสิ้น',
			},
		},
		pollCreated: {
			titles: {
				created: 'สร้างโพล',
				typeQuiz: 'แบบทดสอบ',
				typePoll: 'หลายคำตอบ',
				default: 'โพลสาธารณะ',
			},
		},
		changeName: {
			title: 'เปลี่ยนชื่อแชทของคุณ',
			subtitle: 'คุณถูกบล็อกเนื่องจากชื่อเล่นที่ไม่ถูกต้อง',
		},
		temporaryAgreement: {
			title: 'กฎการแชท',
			rules: {
				bePolite: {
					title: 'โปรดสุภาพ',
					text: 'ห้ามใช้ภาษาที่ไม่เป็นมิตรและหยาบคายในการแชท',
				},
				beAttentive: {
					title: 'ระวัง',
					text: 'อย่าแชร์หรือขอข้อมูลส่วนบุคคลจากผู้อื่น',
				},
				notAdvertise: {
					title: 'ห้ามโฆษณา',
					text: 'แนะนำให้แชร์ความคิดเห็นหรืออารมณ์ของคุณแทน',
				},
			},
			btns: {
				allRules: 'กฎทั้งหมด',
				acceptAndContinue: 'ยอมรับและดำเนินการต่อ',
			},
		},
		slowMode: {
			title: 'โหมดส่งข้อความช้า',
			text: 'ผู้เข้าร่วมจะไม่สามารถส่งข้อความแชทได้มากกว่าในช่วงเวลาที่เลือก',
			enable: 'เปิดโหมดส่งข้อความช้า',
			disable: 'ปิดโหมดส่งข้อความช้า',
			sendTimeout: 'หมดเวลาส่ง',
		},
		slowModeTimeout: {
			title: 'หมดเวลาส่ง',
		},
		chatBot: {
			title: 'การใช้แชทบอท',
			rules: [
				{
					title: 'ปัญญาประดิษฐ์',
					text: 'บอทจำลองการสื่อสารกับผู้คนและให้ข้อมูลตามข้อมูลที่มี อย่างไรก็ตามโปรดจำไว้ว่าคำตอบของบอทอาจมีข้อจำกัดและไม่สามารถแทนที่มนุษย์ได้',
				},
				{
					title: 'บอทสามารถทำผิดพลาดได้',
					text: 'บอทไม่สามารถรับประกันความถูกต้องและความน่าเชื่อถือของข้อมูลได้',
				},
			],
			btns: {
				close: 'ปิด',
			},
		},
		shareBet: {
			title: 'แชร์',
			noBetsTitle: 'ไม่พบการเดิมพันของคุณ',
			noBetsText: 'คุณสามารถแชร์เงินเดิมพันที่ทำภายใน 14 วัน',
			btns: {
				share: 'แชร์เงินเดิมพัน',
				copy: 'เดิมพันซ้ำ',
			},
			status: {
				null: 'ในเกมส์',
				win: 'ชนะ',
				lose: 'แพ้',
			},
			betTypeV1: {
				express: 'ด่วน',
				single: 'เดี่ยว',
			},
			betTypeV2: {
				single: 'เดี่ยว',
				multi: 'หลายรายการ',
				system: 'System',
			},
			totalFactor: 'ค่าสัมประสิทธิ์',
			bet: 'เดิมพัน',
			showMore: 'แสดงเพิ่มเติม',
			betRepeated: 'เสนอเงินเดิมพันซ้ำ',
		},
		shareGamble: {
			noGamblesText:
				'คุณยังไม่มีรางวัลที่จะเผยแพร่ในขณะนี้ เล่นต่อไปแล้วการชนะครั้งต่อไปของคุณจะแสดงที่นี่เพื่อให้คุณแบ่งปันกับผู้เล่นคนอื่น ๆ!',
		},
		record: {
			text: 'กำลังประมวลผลการบันทึกการออกอากาศ...',
		},
		agoraDevices: {
			title: 'การตั้งค่าเสียง',
			text: 'ตรวจสอบว่าได้เลือกไมโครโฟนที่ถูกต้อง',
		},
		translate: {
			title: 'การแปลข้อความ',
			chooseLanguage: 'เลือกภาษา',
			descr: 'ข้อความแชททั้งหมดจะถูกแปลเป็นภาษาที่เลือกโดยอัตโนมัติ',
			btn: 'เปิดใช้งานการแปล',
		},
		streamSettings: {
			title: 'การตั้งค่าการสตรีม',
			description: 'ดาวน์โหลดและติดตั้งตัวเข้ารหัสวิดีโอ ป้อนรหัสสตรีมในหน้าการตั้งค่า',
			shareScreen: {
				title: 'การแชร์หน้าจอ',
				description: 'ไม่ต้องตั้งค่าพิเศษ',
			},
			rtmp: {
				title: 'แหล่งสตรีมภายนอก',
				description: 'ใช้โปรแกรมเพิ่มเติมสำหรับการสตรีม',
			},
			streamKey: 'รหัสสตรีม',
			showStreamKey: 'แสดง',
			hideStreamKey: 'ซ่อน',
			btns: {
				continue: 'ดำเนินการต่อ',
				close: 'ปิด',
				ok: 'เสร็จสิ้น',
			},
		},
	},
	pinnedMessage: {
		title: 'ข้อความที่ปักหมุด',
	},
	errorPage: {
		error: 'เกิดข้อผิดพลาด',
		types: [
			// errorNumber 0
			{
				title: 'เราจำคุณไม่ได้',
				text: 'ลองเข้าแชทอีกครั้ง',
			},
			// errorNumber 1
			{
				title: 'เราหาแชทไม่เจอ',
				text: 'ลองเข้าสู่ระบบใหม่',
			},
			// errorNumber 2
			{
				title: 'โหลดแชทไม่ได้',
				text: 'ลองรีบูต',
			},
			// errorNumber 3
			{
				title: 'เราจำคุณไม่ได้',
				text: 'การแชทในโหมดไม่ระบุตัวตนไม่พร้อมใช้งาน',
			},
			// errorNumber 4
			{
				title: 'ชื่อเล่นไม่ถูกต้อง',
				text: 'ไปที่โปรไฟล์ของคุณ เปลี่ยนชื่อเล่น และเข้าแชทอีกครั้ง',
			},
		],
	},
	tooltips: {
		emotions: 'กดค้างไว้เพื่อเปลี่ยน',
		badInet: 'การเชื่อมต่ออินเทอร์เน็ตไม่ดี',
		blockedUsers: 'คุณสามารถดูรายชื่อผู้ใช้ที่ถูกบล็อกได้ที่นี่',
		waitingSpeakers: 'ผู้เข้าร่วมบางคนต้องการพูด',
		wantSpeak: 'คลิกเพื่อขอโหวต',
		slowMode: {
			header: (time: string) =>
				`เปิดใช้งานโหมดส่งข้อความช้าแล้ว ผู้ใช้สามารถส่งข้อความได้หนึ่งข้อความ <br> ทุก ${time} กฎนี้ใช้ไม่ได้กับพิธีกร`,
			btnSendMessage: (time: string) => `คุณสามารถส่งข้อความได้ <br> ทุกๆ ${time}`,
		},
		shareBet: 'แชร์เงินเดิมพันในแชท',
		translate: 'แปลข้อความเป็นภาษาอื่น',
	},
	poll: {
		toggleSubmenu: {
			create: 'สร้างโพล',
			edit: 'แก้ไขโพล',
			show: 'แสดงโพล',
			end: 'ทำโพลให้เสร็จสิ้น',
		},
		tooltip: {
			btn: 'โหวต',
		},
		settings: {
			title: 'การตั้งค่า',
			titleAdditionally: 'นอกจากนี้',
			switches: {
				quizMode: 'โหมดคำถาม',
				multipleChoice: 'ปรนัย',
				withPhoto: 'โพลพร้อมรูปถ่าย',
			},
		},
		question: {
			title: 'คำถาม',
			inputPlaceholder: 'ใส่คำถาม',
		},
		message: {
			title: 'คุณโหวตแล้ว',
		},
		answers: {
			title: 'ตัวเลือกคำตอบ',
			warning: 'คุณสามารถเพิ่มคำตอบได้สูงสุด 5 รายการ',
		},
		answer: {
			tooltip: 'เลือกคำตอบที่ถูกต้อง',
			inputPlaceholder: 'คำตอบ',
		},
		results: {
			text: 'ผลการโหวต',
			votes: 'โหวต',
			// words: ['โหวต', 'โหวต', 'โหวต'],
		},
		btns: {
			addQuestion: 'เพิ่มคำถาม',
			addAnswers: 'เพิ่มคำตอบ',
			addAnswer: 'เพิ่มคำตอบ',
			publish: 'เผยแพร่',
			vote: 'โหวต',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'ประมาณ',
				continue: 'ดำเนินการต่อ',
				checkConnection: 'ตรวจสอบการเชื่อมต่อ',
				close: 'ปิด',
			},
			quality: {
				title: 'ให้คะแนนคุณภาพเสียง',
			},
			reason: {
				title: 'เกิดข้อผิดพลาด?',
			},
			connection: {
				title: 'ทดสอบคุณภาพการเชื่อมต่อของคุณ',
				text: 'การตรวจสอบจะใช้เวลาไม่เกินหนึ่งนาทีและจะช่วยคุณค้นหาสาเหตุของปัญหา',
			},
			statistics: {
				title: 'กำลังรวบรวมสถิติ...',
			},
			done: {
				title: 'ขอบคุณ!',
				text: 'เราจะศึกษาสิ่งที่ผิดพลาดและแก้ไขอย่างแน่นอน',
			},
		},
	},
	chatBot: {
		message: 'สามารถถามบอทเรื่องอะไรก็ได้...',
		warnings: {
			disabled: 'ฉันถูกตัดการเชื่อมต่อ',
			overloaded: 'มีคำขอเข้ามาจำนวนมาก โปรดรอสักครู่',
		},
	},
	audioReason: {
		nothear: 'ไม่ได้ยิน',
		connection: 'มีเสียงรบกวน',
		microphone: 'ไม่สามารถใช้ไมโครโฟน',
	},

	copybetting: {
		orderStatus: {
			won: 'ชนะ',
			lost: 'แพ้',
			new: 'ใหม่',
			pending: 'รอดำเนินการ',
			cashedOut: 'การถอนเงิน',
			boreDraw: 'เสมอแบบไม่มีประตู',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet การยกเลิกโดยผู้ให้บริการ',
			megaBetCanceledByPlayer: 'MegaBet การยกเลิกโดยผู้เล่น',
			megaBetCanceledBySystem: 'MegaBet การยกเลิกโดยระบบ',
			betGamesPending: 'BetGames รอดำเนินการ',
			betGamesWon: 'BetGames ชนะ',
			betGamesLost: 'BetGames แพ้',
			paid: 'จ่าย',
			initialWin: 'เงินรางวัลขาออก',
			autoBetCanceledBySystem: 'AutoBet การยกเลิกโดยระบบ',
			autoBetCanceledByPlayer: 'AutoBet การยกเลิกโดยผู้เล่น',
			autobetPending: 'AutoBet รอดำเนินการ',
			goalAhead: 'จะมีเป้าหมาย',
			canceled: 'การยกเลิก',
			return: 'กลับ',
		},
		stakeStatus: {
			pending: 'รอดำเนินการ',
			won: 'ชนะ',
			lost: 'แพ้',
			return: 'กลับ',
			lostReturn: 'แพ้ | กลับ',
			wonReturn: 'ชนะ | กลับ',
			canceled: 'การยกเลิก',
			initialReturn: 'อ้างอิง กลับ',
			pendingInitialReturn: 'รอดำเนินการ | อ้างอิง กลับ',
			wonInitialReturn: 'ชนะ | อ้างอิง กลับ',
		},
	},
	screenShare: {
		title: 'การสาธิตหน้าจอ',
		stopSharing: 'หยุดออกอากาศ',
		modal: {
			title: 'คุณสามารถแชร์หน้าจอได้จากคอมพิวเตอร์เท่านั้น',
			text: 'เปิดแชทบนคอมพิวเตอร์ของคุณแล้วคลิกปุ่มแชร์หน้าจอที่มุมขวาบน',
			btn: 'คัดลอกลิงค์เข้าสู่ระบบ',
		},
	},
	threads: {
		title: 'เธรด',
		back: 'กลับ',
		replies: ['ตอบกลับ', 'การตอบกลับ', 'การตอบกลับ'],
	},
	attach: {
		submenu: {
			sendImage: 'ส่งภาพ',
			shareBet: 'แบ่งปันการเดิมพัน',
			shareGamble: 'แบ่งปันเงินรางวัล',
		},
		images: ['ภาพ', 'ภาพ', 'ภาพ'],
		addCaption: 'เพิ่มคำบรรยาย',
	},
} as unknown as Dictionary;
