import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import useL10n from 'l10n/useL10n';
import userServices from 'store/userService';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import alertService from 'store/alertService';
import RoomService from 'services/api/RoomService';
import {useLocalObservable} from 'mobx-react-lite';
import {localeLanguages} from 'constants/constants';
import {AppScreens} from 'models/enums/AppScreens.enum';

export default () => {
	const {alerts: alertsTranslate, modals} = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {
		addBlockedUsers,
		language,
		appEnableContactUsButton,
		feedbackEmail,
		changeCurrentScreen,
		appEnableRegistrationName,
	} = useLocalObservable(() => appService);
	const {addBlockedUsersForFilteredMessages} = useLocalObservable(() => roomServices);
	const {toggleRulesModal, toggleChangeNameModal} = useLocalObservable(() => modalServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);

	const banReasonTranslations = [
		modals.ban.btns.insults,
		modals.ban.btns.personal,
		modals.ban.btns.flood,
		modals.ban.btns.links,
		modals.ban.btns.violence,
		modals.ban.btns.fraud,
		modals.ban.btns.nickname,
		modals.ban.btns.politicial,
		modals.ban.btns.repeated,
		modals.ban.btns.auto,
		modals.ban.btns.spam,
	];

	const getBanUsersWithServices = async () => {
		if (accessToken) {
			const response = await RoomService.getBlockedUsers(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				addBlockedUsers(response.data);
				addBlockedUsersForFilteredMessages(response.data);
			}
		}
	};

	const showAlertForBanReason = (reason: BanReason) => {
		if (reason === BanReason.NICKNAME) {
			showAlert({
				zIndex: 999,
				title: alertsTranslate.banInAppBadNickname.title,
				subtitle: alertsTranslate.banInAppBadNickname.subtitle,
				buttons: [
					{
						text: alertsTranslate.btns.rules,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							toggleRulesModal(true);
						},
					},
				],
				closeOnBackdropPress: false,
			});
		}
	};

	const handleBans = (bans: any[]) => {
		if (!bans || !bans.length) return;

		const locale = localeLanguages.find(item => item.includes(language)) || 'en-GB';

		const ban = bans[0];
		const {reason} = ban;
		const {expires} = ban;

		if (reason === BanReason.NICKNAME) {
			if (appEnableRegistrationName) {
				showAlertForBanReason(BanReason.NICKNAME);
				return;
			}
			toggleChangeNameModal(true);
			return;
		}

		const bannedUntill =
			expires &&
			`${new Date(expires).toLocaleDateString(locale, {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			})}, ${new Date(expires).toLocaleTimeString(locale, {
				hour: 'numeric',
				minute: 'numeric',
			})}`;

		changeCurrentScreen(AppScreens.APPBAN);

		const alertButtons = [
			{
				text: alertsTranslate.btns.rules,
				type: AlertBtnType.NORMAL,
				onPress: () => {
					toggleRulesModal(true);
				},
			},
		];
		if (appEnableContactUsButton) {
			alertButtons.push({
				text: alertsTranslate.btns.contactus,
				type: AlertBtnType.NORMAL,
				onPress: () => {
					window.open(`mailto:${feedbackEmail}`, '_blank')?.focus();
				},
			});
		}
		alertButtons.push({
			text: alertsTranslate.btns.close,
			type: AlertBtnType.DESTRUCTIVE,
			onPress: () => {
				hideAlert();
			},
		});

		showAlert({
			zIndex: 500,
			title: `${alertsTranslate.banInApp.title} ${
				ban.reason !== null && typeof banReasonTranslations[reason] !== 'undefined'
					? `${alertsTranslate.banInApp.for} ${banReasonTranslations[reason].toLowerCase()}`
					: ''
			}`,
			subtitle:
				bannedUntill === null
					? alertsTranslate.banInApp.permanent
					: alertsTranslate.banInApp.subtitle(bannedUntill),
			buttons: alertButtons,
			closeOnBackdropPress: false,
		});
	};

	return {getBanUsersWithServices, showAlertForBanReason, handleBans};
};
