import {MODAL_STYLE_CENTER} from 'constants/constants';
import modalService from 'store/modalService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import './offerModal.scss';
import appService from 'store/appService';
import useAgora from 'hooks/useAgora';
import offerService from 'store/offerService';
import {Highlight} from 'pages/main/components/highlight';
import {ReactComponent as IcoCloseOffer} from 'assets/svg/ico-close-offer.svg';
import threadService from 'store/threadService';

const OfferModal: FunctionComponent = function OfferModal() {
	const {offer} = offerService;
	const {offerModalVisible, toggleOfferModalVisible} = useLocalObservable(() => modalService);
	const {deviceOs, appVoice, appEnableAgora, isAgoraLoaded, iosCheckVersionAlertShown} =
		useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {checkForSupportMicrophoneAndSpeaker} = useAgora();

	if ((!offerModalVisible && !offer) || currentThreadId) {
		return null;
	}

	const onAfterCloseHandler = () => {
		if (appVoice && appEnableAgora && isAgoraLoaded && deviceOs && !iosCheckVersionAlertShown) {
			checkForSupportMicrophoneAndSpeaker();
		}
	};

	const onCloseClickHandler = () => {
		toggleOfferModalVisible(false);
	};

	return (
		<Modal
			className='modal modal--offer'
			isOpen={offerModalVisible}
			onAfterClose={onAfterCloseHandler}
			onRequestClose={() => toggleOfferModalVisible(false)}
			ariaHideApp={false}
			shouldCloseOnOverlayClick
			style={{
				overlay: MODAL_STYLE_CENTER.overlay,
			}}>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<section className='offer-modal'>
						<div className='offer-modal__body'>
							<Button className='offer-modal__close' onClick={onCloseClickHandler}>
								<IcoCloseOffer />
							</Button>
							{offer && <Highlight advertisement={offer} isOffer />}
						</div>
					</section>
				</div>
			</div>
		</Modal>
	);
};

export default observer(OfferModal);
