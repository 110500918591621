import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';

import './sticker-suggest.scss';
import suggestService from 'store/suggestService';
import {Sticker} from 'models/room';
import useStickers from 'hooks/useStickers';
import roomService from 'store/roomService';
import slowModeService from 'store/slowModeService';
import toastService from 'store/toastService';

const StickerSuggest: FunctionComponent = function StickerSuggest() {
	const {visibleSuggestSticker, suggestStickers} = useLocalObservable(() => suggestService);
	const {roomId} = useLocalObservable(() => roomService);
	const {slowMode, slowModeCountdownEnable} = useLocalObservable(() => slowModeService);
	const {toasts} = useLocalObservable(() => toastService);

	const {sendStickerToChat} = useStickers();

	useEffect(() => {
		document.addEventListener('click', sendStickerToChat);
		return () => {
			document.removeEventListener('click', sendStickerToChat);
		};
	}, [roomId, slowMode, slowModeCountdownEnable, toasts, suggestStickers]);

	const renderStickerItem = useCallback(
		(item: Sticker) => (
			<div
				className='sticker-suggest__sticker-item'
				key={item.id}
				data-sticker-id={item.id}
				data-pack-id={item.stickerPackId}
				style={{backgroundImage: `url('${item.pic}')`}}
			/>
		),
		[suggestStickers]
	);

	return (
		<CSSTransition in={visibleSuggestSticker} timeout={500} classNames='fade' unmountOnExit>
			<div className='sticker-suggest'>
				<div className='sticker-suggest__sticker-list'>
					{suggestStickers.map(renderStickerItem)}
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(StickerSuggest);
