import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';

import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './player.scss';
import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';

interface IPlayer {
	isViewSmall: boolean;
}
const Player: FunctionComponent<IPlayer> = function Player({isViewSmall}) {
	const {roomData} = useLocalObservable(() => roomService);

	const {modals} = useL10n();

	if (!roomData?.record && !roomData?.videoRecord && !roomData?.audioRecord) {
		return (
			<div className='player player--no-audio'>
				<div className='player__text'>{modals.record.text}</div>
			</div>
		);
	}

	if (roomData?.isRecordVideo) {
		return <VideoPlayer isViewSmall={isViewSmall} />;
	}

	return <AudioPlayer />;
};

export default observer(Player);
