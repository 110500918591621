import {AnalyticsPropsForPoll} from 'models/room';
import {PollOption} from 'models/poll';
import pollServices from 'store/pollService';
import useAnalytics from 'hooks/useAnalytics';
import {useLocalObservable} from 'mobx-react-lite';

const usePollAnalytics = () => {
	const {sendAnalytics} = useAnalytics();
	const {poll} = useLocalObservable(() => pollServices);

	const sendPollAnalytics = (event: string, pollOptionsChosen?: PollOption[]) => {
		if (poll) {
			let pollPropsObj: AnalyticsPropsForPoll = {
				type: poll.isMultiple ? `multiple_${poll.type.toLowerCase()}` : poll.type.toLowerCase(),
				question: poll.text,
				img: !!(poll.options.length && poll.options.find(item => item.pic)),
			};

			if (event === 'pq_opened') {
				pollPropsObj = {
					...pollPropsObj,
					user_status: true,
				};
			}

			if (event === 'pq_answer_sent') {
				const answer: string[] = [];
				if (pollOptionsChosen?.length) {
					pollOptionsChosen.forEach(pollOptionChose => answer.push(pollOptionChose.text));
				}

				pollPropsObj = {
					...pollPropsObj,
					answer: answer.join(';'),
				};
			}

			sendAnalytics(event, pollPropsObj);
		}
	};

	return {sendPollAnalytics};
};

export default usePollAnalytics;
