import {observer, useLocalObservable} from 'mobx-react-lite';
import {ButtonProps} from 'models/hoc';
import * as React from 'react';
import appService from 'store/appService';
// import toastService from 'store/toastService';
// import {ToastIconsName} from 'components/toasts/ToastIcons';
// import useL10n from 'l10n/useL10n';

const Button: React.FC<ButtonProps> = function Button(props) {
	const {showInetToast = false, workWithoutInet = false} = props;
	// const translations = useL10n();
	const {isInternernetActive} = useLocalObservable(() => appService);
	// const {addToast} = useLocalObservable(() => toastService);

	const allEventsHandler = (event: any, callback?: any) => {
		if ((isInternernetActive && !showInetToast) || workWithoutInet) {
			callback && callback(event);
			// return;
		}
		// addToast({
		// 	iconName: ToastIconsName.noInet,
		// 	liveTime: 3000,
		// 	message: translations.toasts.noInet,
		// 	cancellable: true,
		// 	messageColor: 'rgba(255, 45, 85, 1)',
		// });
	};

	return (
		<button
			className={props.className}
			type='button'
			disabled={props.disabled}
			ref={props.innerRef}
			onClick={e => allEventsHandler(e, props.onClick)}
			onMouseEnter={e => allEventsHandler(e, props.onMouseEnter)}
			onMouseDown={e => allEventsHandler(e, props.onMouseDown)}
			onTouchStart={e => allEventsHandler(e, props.onTouchStart)}
			onTouchMove={e => allEventsHandler(e, props.onTouchMove)}
			onMouseUp={e => allEventsHandler(e, props.onMouseUp)}
			onMouseLeave={e => allEventsHandler(e, props.onMouseLeave)}
			onTouchEnd={e => allEventsHandler(e, props.onTouchEnd)}>
			{props.children}
		</button>
	);
};

export default observer(Button);
