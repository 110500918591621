import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import useUserProfile from 'hooks/useUserProfile';
import {Message} from 'models/room';
import OpenProfileType from 'models/enums/OpenProfileType.enum';
import Button from 'components/hoc/Button';
import toastService from 'store/toastService';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import threadService from 'store/threadService';

interface IButtonGoToProfileProps {
	message: Message;
}

const ButtonGoToProfile: FunctionComponent<IButtonGoToProfileProps> = function ButtonGoToProfile({
	message,
}) {
	const {user, isAutoposted} = message;
	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {setSubmenuMessage} = useLocalObservable(() =>
		currentThreadId ? threadService : roomService
	);
	const {openProfileType} = useLocalObservable(() => appService);
	const {addToast} = useLocalObservable(() => toastService);

	const {chatScreen, toasts} = useL10n();
	const {getAppIcon} = useAppData();
	const {sendAnalyticsEvent, profileUrlPostMessage} = useUserProfile();

	const {IcoSubmenuProfile} = appIcons;

	const onClickHandler = async () => {
		if (user?.id && !isAutoposted) {
			openProfileType === OpenProfileType.PM && profileUrlPostMessage(user.id);
			sendAnalyticsEvent({userId: user.id, source: 'menu'});
			setSubmenuMessage(null);
			return;
		}

		setSubmenuMessage(null);
		addToast({
			iconName: ToastIconsName.warning,
			liveTime: 3000,
			message: toasts.profileHidden,
			cancellable: true,
		});
	};

	if (openProfileType === OpenProfileType.GET && !user?.profileUrl) return null;

	return openProfileType === OpenProfileType.GET ? (
		<a
			href={user.profileUrl}
			className='chat__submenu-btn'
			onClick={onClickHandler}
			target='_blank'
			rel='noreferrer'>
			{chatScreen.chatSubmenu.goToProfile}

			{getAppIcon(IcoSubmenuProfile.pic, {className: 'chat__submenu-icon'})}
		</a>
	) : (
		<Button className='chat__submenu-btn' onClick={onClickHandler}>
			{chatScreen.chatSubmenu.goToProfile}
			{getAppIcon(IcoSubmenuProfile.pic, {className: 'chat__submenu-icon'})}
		</Button>
	);
};

export default observer(ButtonGoToProfile);
