import {Message, Advertisement} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import useAnalytics from 'hooks/useAnalytics';
import RoomService from 'services/api/RoomService';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import UserService from 'services/api/UserService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {User} from 'models/user';

type AdvertisementItem = {
	roomId: string;
	items: number[];
};

const useAdvertisement = () => {
	const userDataRef = useRef<User | null>(null);
	const isInternernetActiveRef = useRef(false);
	const {isInternernetActive} = useLocalObservable(() => appService);
	const {accessToken, userData, updateUserData} = useLocalObservable(() => userServices);
	const {roomId} = useLocalObservable(() => roomServices);
	const {sendAnalytics} = useAnalytics();

	useEffect(() => {
		userDataRef.current = userData;
	}, [userData]);

	useEffect(() => {
		isInternernetActiveRef.current = isInternernetActive;
	}, [isInternernetActive]);

	const sendViewAdvertisementViaAPI = async (advertisement: Advertisement) => {
		const {id, title, text} = advertisement;
		if (accessToken) {
			await RoomService.sendViewAdvertisement(id, accessToken);
			sendAnalytics('hl_viewed', {hl_content: title || text, type: 'in_chat'});
		}
	};

	const checkAdvertisementInViewport = (
		viewedAdvertisementsItemsId: number[],
		advertisementMessages: Message[],
		chatClientHeight: number
	) => {
		const tempIdItems: number[] = [];

		advertisementMessages.forEach(({id, advertisement}) => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${id}"]`);
			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const selectedMessageRect = selectedMessage.getBoundingClientRect();
				const selectedMessageBorder = selectedMessageRect.top + selectedMessageRect.height / 2;
				if (
					advertisement &&
					!viewedAdvertisementsItemsId.includes(advertisement.id) &&
					selectedMessageBorder >= 0 &&
					selectedMessageBorder <= chatClientHeight
				) {
					tempIdItems.push(advertisement.id);
					sendViewAdvertisementViaAPI(advertisement);
				}
			}
		});

		return tempIdItems;
	};

	const checkViewedAdvertisement = async (
		advertisementMessages: Message[],
		chatClientHeight: number
	) => {
		if (roomId && isInternernetActiveRef.current) {
			const userExtraDataParse = userDataRef.current?.extraData
				? JSON.parse(userDataRef.current.extraData)
				: {};
			const userExtraDataFindAdvertisements = Object.keys(userExtraDataParse).find(
				item => item === 'advertisement'
			);
			const commonParams: [advertisementMessages: Message[], chatClientHeight: number] = [
				advertisementMessages,
				chatClientHeight,
			];
			let extraDataForPatchUser = {};
			let advertisementIdItems: number[] = [];
			let viewedAdvertisementsData: AdvertisementItem[] = [];

			if (Object.keys(userExtraDataParse).length) {
				if (userExtraDataFindAdvertisements) {
					const viewedAdvertisements: AdvertisementItem[] = userExtraDataParse.advertisement;
					const findRoom = viewedAdvertisements.find(
						(item: AdvertisementItem) => item.roomId === roomId
					);

					if (findRoom) {
						advertisementIdItems = checkAdvertisementInViewport(findRoom.items, ...commonParams);
						if (advertisementIdItems.length) {
							viewedAdvertisementsData = viewedAdvertisements.map(el => {
								if (el.roomId === roomId) {
									return {...el, items: [...el.items, ...advertisementIdItems]};
								}
								return el;
							});
						}
					} else {
						advertisementIdItems = checkAdvertisementInViewport([], ...commonParams);
						if (advertisementIdItems.length) {
							viewedAdvertisementsData = [
								...viewedAdvertisements,
								{roomId, items: advertisementIdItems},
							];
						}
					}

					if (viewedAdvertisementsData.length) {
						extraDataForPatchUser = {
							...userExtraDataParse,
							advertisement: [...viewedAdvertisementsData],
						};
					}
				} else {
					advertisementIdItems = checkAdvertisementInViewport([], ...commonParams);
					if (advertisementIdItems.length) {
						extraDataForPatchUser = {
							...userExtraDataParse,
							advertisement: [{roomId, items: advertisementIdItems}],
						};
					}
				}
			} else {
				advertisementIdItems = checkAdvertisementInViewport([], ...commonParams);
				if (advertisementIdItems) {
					extraDataForPatchUser = {advertisement: [{roomId, items: advertisementIdItems}]};
				}
			}

			if (Object.keys(extraDataForPatchUser).length) {
				const response = await UserService.patchUserData(
					{extraData: JSON.stringify(extraDataForPatchUser)},
					accessToken
				);
				if (response.status === ResponseStatus.SUCCESS) {
					updateUserData(response.data);
				}
			}
		}
	};

	return {checkViewedAdvertisement};
};

export default useAdvertisement;
