import {MODAL_STYLE_CENTER} from 'constants/constants';
import modalService from 'store/modalService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import './agoraDevicesModal.scss';
import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import roomService from 'store/roomService';
import UserRole from 'models/enums/UserRole.enum';
import userService from 'store/userService';
import {Select} from 'components/ui';
import useAgora from 'hooks/useAgora';
import useUser from 'hooks/useUser';

const IOS_14 = [14, 3];

const AgoraDevicesModal: FunctionComponent = function AgoraDevicesModal() {
	const {agoraDevicesModalVisible, toggleAgoraDevicesModalVisible} = useLocalObservable(
		() => modalService
	);
	const {
		appIcons,
		agoraMicrophones,
		appVoice,
		agoraMicrophoneActive,
		setAgoraMicrophoneActive,
		deviceOs,
	} = useLocalObservable(() => appService);
	const {myTalker} = useLocalObservable(() => roomService);
	const {userData} = useLocalObservable(() => userService);

	const {setMicrophone} = useAgora();
	const {IcoMicDevice} = appIcons;
	const {userExtraDataCheckWelcome, userExtraDataCheckOffer, userExtraDataCheckBadge} = useUser();

	const translations = useL10n();

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const isMyTalkerSpeacker = myTalker?.role === UserRole.SPEAKER;

	if (deviceOs && deviceOs.name === 'ios') {
		if (
			deviceOs.version[0] < IOS_14[0] ||
			(deviceOs.version[0] === IOS_14[0] && deviceOs.version[1] < IOS_14[1])
		) {
			return null;
		}
	}

	if (!agoraDevicesModalVisible) {
		return null;
	}

	const onCloseClickHandler = () => {
		toggleAgoraDevicesModalVisible(false);
	};

	const agoraDataForSelect = (data: MediaDeviceInfo[]) => {
		const dataFilterFromLabel = data.filter(item => item.label !== '');
		if (dataFilterFromLabel.length) {
			return dataFilterFromLabel.map(item => {
				let cleanTitle = item.label;
				const cleanTitleSplit = cleanTitle.split(' ');
				if (
					cleanTitleSplit.length > 1 &&
					cleanTitleSplit[cleanTitleSplit.length - 1].includes('(')
				) {
					cleanTitle = cleanTitleSplit.slice(0, -1).join(' ');
				}
				return {
					id: item.deviceId,
					title: cleanTitle,
				};
			});
		}
		return [];
	};

	const onChangeAgoraMicrophoneDevice = (option: SelectOption) => {
		setMicrophone(option.id);
		const findMicrophone = agoraMicrophones.find(microphone => microphone.deviceId === option.id);
		if (findMicrophone) {
			setAgoraMicrophoneActive(findMicrophone);
		}
	};

	const onAfterCloseHandler = () => {
		if (userData) {
			userExtraDataCheckWelcome(userData, false);
			userExtraDataCheckOffer(userData, false);
			userExtraDataCheckBadge(userData, true);
		}
	};

	return (
		<Modal
			className='modal modal--agoraDevices'
			isOpen={agoraDevicesModalVisible}
			// onAfterOpen={onAfterOpenHandler}
			onAfterClose={onAfterCloseHandler}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE_CENTER.overlay,
			}}>
			<section className='agoraDevices-modal'>
				<div className='agoraDevices-modal__head'>
					<div className='agoraDevices-modal__title'>{translations.modals.agoraDevices.title}</div>
					<div className='agoraDevices-modal__text'>{translations.modals.agoraDevices.text}</div>
				</div>
				<div className='agoraDevices-modal__body'>
					{(isMyTalkerModer || isMyTalkerSpeacker) && appVoice && agoraMicrophones.length > 1 && (
						<div className='settings__group'>
							<div className='settings__items'>
								<div className='settings__item'>
									<Select
										options={agoraDataForSelect(agoraMicrophones)}
										optionIdActive={agoraMicrophoneActive?.deviceId}
										onOptionHandler={onChangeAgoraMicrophoneDevice}
										icon={IcoMicDevice.pic}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='agoraDevices-modal__footer'>
					<Button className='btn' onClick={onCloseClickHandler}>
						{translations.alerts.btns.close}
					</Button>
				</div>
			</section>
		</Modal>
	);
};

export default observer(AgoraDevicesModal);
