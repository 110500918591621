import UserRole from 'models/enums/UserRole.enum';
import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import modalService from 'store/modalService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';

interface IButtonChangeRoleProps {
	message: Message;
}

const ButtonChangeRole: FunctionComponent<IButtonChangeRoleProps> = function ButtonChangeRole({
	message,
}) {
	const {talker, user} = message;
	const {appIcons} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);
	const {hideVisibleModalUsers} = useLocalObservable(() => modalService);

	const {chatScreen} = useL10n();
	const {changeUserRole} = useSubmenuActions();
	const {getAppIcon} = useAppData();

	const {IcoSubmenuRemoveSpeaker, IcoSpeakerAdd} = appIcons;

	const isTalkerSpeaker = talker.role === UserRole.SPEAKER;

	const onChangeRoleHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user) {
			changeUserRole(
				user,
				roomId,
				talker?.role === UserRole.SPEAKER ? UserRole.GUEST : UserRole.SPEAKER
			);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={onChangeRoleHandler}>
			{isTalkerSpeaker ? (
				<>
					{chatScreen.chatSubmenu.removeFromSpeakers}
					{getAppIcon(IcoSubmenuRemoveSpeaker.pic, {className: 'chat__submenu-icon'})}
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.setSpeaker}
					{getAppIcon(IcoSpeakerAdd.pic, {className: 'chat__submenu-icon'})}
				</>
			)}
		</Button>
	);
};

export default observer(ButtonChangeRole);
