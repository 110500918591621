/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Anlaşma',
		subTitle: 'Lisans Sözleşmesi ve Gizlilik Politikası',
		textFirst: 'Şartları kabul ediyorum ',
		textSecond: 've kişisel verilerin belirli koşullar altında işlenmesine izin veriyorum',
		license: 'Lisans sözleşmesiyle',
		policy: 'Gizlilik Politikasıyla.',
	},
	controlPanel: {
		streamEnded: 'Yayın tamamlandı',
		banAlert: 'Chat’e yazamazsınız',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Çıkartmalar' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Profil silindi',
		text: (date: string) => `Profilinizi ${date} kadar yeniden yükleyebilirsiniz`,
	},
	nameScreen: {
		title: 'Chat’teki isim',
		inputPlaceholder: 'İsminizi girin',
		nameError: 'Geçersiz isim',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Başka birini seç:',
	},
	settingsScreen: {
		title: 'Ayarlar',
		personal: 'Kişisel ayarlar',
		room: 'Oda ayarları',
		profileBtn: 'Profil',
		languageBtn: 'Dil',
		blockedBtn: 'Engellenenler',
		rules: 'Chat kuralları',
		deleteProfile: 'Profili sil',
		slowModeBtn: 'Yavaş mod',
		agora: {
			microphone: 'Mikrofon',
			playbackDevice: 'Ses kaynağı',
		},
	},
	languageScreen: {
		title: 'Dil ve Arayüz',
		messagesTranslate: 'Mesajları Çevir',
		translateMessagesMode: 'Sohbet Mesajlarını Çevir',
		translateMessagesTo: 'Mesajları Şuna Çevir:',
	},
	rulesScreen: {
		title: 'Chat kuralları',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Başka bir avatar seç',
		chatNameLabel: 'Chat’teki isim',
		changeName: 'değiştir',
	},
	blockedScreen: {
		title: 'Engellenenler',
		emptyListText: 'Engellenen kullanıcı yok',
	},
	chatScreen: {
		inputPlaceholder: 'Mesaj...',
		cantWrite: 'Chat’e yazamazsınız',
		chatSubmenu: {
			hideMessage: 'Mesajları gizle',
			showMessage: 'Mesajları göster',
			removeFromSpeakers: 'Konuşmacılardan çıkartın',
			setSpeaker: 'Konuşmacı yap ',
			hideOnlyMessage: 'Mesajı gizle',
			showOnlyMessage: 'Mesajı göster',
			reportMessage: 'Mesajı şikayet et',
			reportUser: 'Kullanıcıyı şikayet et',
			muteSpeaker: 'Mikrofonu kapat',
			blockUser: 'Engelle',
			unblockUser: 'Engeli kaldır',
			reply: 'Cevap ver',
			copy: 'Kopyala',
			edit: 'Düzenle',
			delete: 'Kaldır',
			pinMessage: 'Sabitle',
			unpinMessage: 'Kaldır',
			replyInThread: 'Konuda yanıtla',
		},
		actionMessage: {
			editing: 'Düzenleme',
			reply: 'Cevap ver',
		},
		status: {
			top: 'Top',
		},
		translating: 'Prevodi se...',
	},
	chatUsersScreen: {
		title: 'Katılımcılar',
		edited: 'Değiştirildi',
		hidden: 'gizli',
		unreadMessages: 'Okunmamış mesajlar',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Katılımcıyı susturmak',
				subtitle: 'Mikrofonu kapat',
			},
			setSpeaker: {
				title: 'Konuşmacılardan çıkarın',
				subtitle: 'Sadece yazı chat’i',
			},
			removeFromSpeaker: {
				title: 'Konuşmacı yap',
				subtitle: 'Kullanıcı sesle konuşabilecektir',
			},
			showMessages: {
				title: 'Mesajları göster',
				subtitle: 'Sadece yönetici tarafından görülebilir',
			},
			hideMessages: {
				title: 'Mesajları gizle',
				subtitle: 'Sadece yönetici tarafından görülebilir',
			},
			blockUser: {
				title: 'Engelle',
				subtitle: 'Stop listesine al',
			},
			unblockUser: {
				title: 'Engeli kaldır',
				subtitle: 'Kullanıcının engelini kaldır',
			},
			blockUserByUser: {
				title: 'Engelle',
				subtitle: 'Kullanıcının tüm mesajlarını gizle',
			},
			unblockUserByUser: {
				title: 'Engeli kaldır',
				subtitle: 'Kullanıcının tüm mesajlarını göster',
			},
			reportUser: {
				title: 'Kullanıcıyı şikayet et',
				subtitle: 'Şikayet isimsiz olarak gönderilecektir',
			},
		},
		days: {
			today: 'Bugün',
			yesterday: 'Dün',
		},
		chatCreated: 'Chat oluşturuldu,',
	},
	alerts: {
		btns: {
			unblock: 'Engeli kaldır',
			block: 'Engelle',
			send: 'Gönder',
			sendAndBlock: 'Gönder ve engelle',
			show: 'Göster',
			showAll: 'Hepsini göster',
			cancel: 'İptal',
			yes: 'Evet',
			hide: 'Seçileni gizle',
			hideAll: 'Hepsini gizle',
			make: 'Yap',
			remove: 'Ortadan kaldır',
			delete: 'Kaldır',
			deleteAccount: 'Profili sil',
			close: 'Kapat ',
			pin: 'Sabitle',
			unpin: 'Sabitlemeyi kaldır',
			edit: 'Düzeltme',
			end: 'Bitir',
			endAndShare: 'Bitir ve paylaş',
			reset: 'Sıfırla',
			rules: 'Chat kuralları',
			contactus: 'Bize yaz',
		},
		pinMessage: {
			title: 'Mesajı sabitleyelim mi?',
		},
		unPinMessage: {
			title: 'Mesajın sabitlenmesini kaldıralım mı?',
		},
		accountRestored: {
			title: 'Profil geri yüklendi',
		},
		closeApp: {
			title: 'Gerçekten çıkmak istiyor musunuz?',
		},
		removeMessage: {
			title: 'Mesajı silelim mi?',
		},
		report: {
			title: 'Şikayeti gönderelim mi?',
			subtitle: 'Birbirinizin mesajlarını görmemek için de Kullanıcıyı engelleyebilirsiniz.',
		},
		banUser: {
			title: (name: string) => `${name} adını engelleyelim mi?`,
			subtitle: (text: string) => `Kullanıcı ${text} metin için engellenecek `,
		},
		unBanUser: {
			title: (name: string) => `${name} adının engelini kaldıralım mı?`,
		},
		showUserMessages: {
			title: (name: string) => `${name} ‘den gelen mesajları gösterelim mi?`,
			subTitle: 'Diğer kullanıcılar bu kullanıcının mesajlarını görebilir.',
		},
		hideUserMessages: {
			title: (name: string) => `${name} mesajları gizleyelim mi?`,
			subTitle: 'Diğer kullanıcılar bu kullanıcının mesajlarını artık göremez.',
		},
		showUserMessage: {
			title: (name: string) => `${name} 'dan mesajı gösterelim mi??`,
			subTitle: 'Diğer kullanıcılar bu mesajı görebilir.',
		},
		hideUserMessage: {
			title: (name: string) => `${name}’den mesajı gizleyelim mi? ?`,
			subTitle: 'Diğer kullanıcılar bu mesajı görebilir.',
		},
		blockUserInRoom: {
			title: (name: string) => `${name} adını engelleyelim mi??`,
			subTitle: 'Diğer kullanıcılar bu mesajı artık göremeyecekler',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name} adının engelini kaldıralım mı??`,
			subTitle: 'Kullanıcı bu odaya katılabilir',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name} adını engelleyelim mi??`,
			subTitle: 'Birbirinizin mesajlarını görmeyeceksiniz',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name} konuşmacı yapalım mı??`,
			unTitle: (name: string) => `${name} konuşmacılardan çıkaralım mı??`,
		},
		banInApp: {
			title: 'Yönetici dolayı sizi engelledi',
			for: 'için',
			subtitle: (bannedUntil: string) => `${bannedUntil} kadar engellendiniz `,
			permanent: 'Sonsuza dek engellendiniz',
		},
		banInAppBadNickname: {
			title: 'Yönetici geçersiz isminizden dolayı sizi engelledi',
			subtitle: 'Profilinize gidin, isminizi değiştirin ve sohbete tekrar giriş yapın',
		},
		deleteAccount: {
			title: 'Profili silme',
			subTitle: 'Tüm veriler silinecektir. Profili 30 gün içinde geri yükleyebileceksiniz.',
		},
		pollEdit: {
			title: 'Anketi düzenlemek ister misiniz?',
			subTitle: 'Kullanıcı yanıtları sıfırlanacak',
		},
		pollReset: {
			title: 'Sıfırlayalım mı?',
			subTitle: 'Anket kaydedilmeyecek',
		},
		pollEnd: {
			title: 'Anketi gerçekten tamamlamak istiyor musunuz?',
		},
		pollDelete: {
			title: 'Anketi silelim mi?',
		},
		agoraCheck: {
			updateIOSText: 'Tüm uygulama araçlarını kullanmak için iOS sürümünü güncelleyin.',
			allowAccess: {
				title: 'Bir şey söylemek ister misin?',
				text: 'Lütfen uygulama ayarlarını açın ve mikrofona ve hoparlöre erişime izin verin.',
			},
			microphoneAndSpeakerText: 'Mikrofon ve hoparlör kullanımı 14.3 sürümünden beri mevcuttur.',
			microphoneText: 'Mikrofon ve hoparlör kullanımı 14.3 sürümünden beri mevcuttur.',
			btn: 'Tamam!',
		},
	},
	eventScreen: {
		title: 'Planlanan etkinlik',
		titleSoon: 'Yayın yakında başlayacak',
		subtitle: 'Sohbet yakında açılacak, lütfen bekleyin.',
		days: 'gün',
		hours: 'saat',
		minutes: 'dakika',
		seconds: 'saniye',
		startTitle: 'Başlangıc ',
	},
	toasts: {
		reciveComplaint: 'Şikayetinizi aldık',
		setSpeakerRoleForUser: 'Kullanıcı konuşmacı olarak atandı',
		unSpeakerRoleForUser: 'Kullanıcı konuşmacılardan çıkartıldı',
		messagesShown: 'Mesajlar gösteriliyor',
		messagesHidden: 'Mesajlar gizlendi',
		messageShown: 'Mesaj gösteriliyor',
		messageHidden: 'Mesaj gizlendi',
		userBlocked: 'Kullanıcı engellendi',
		userUnBlocked: 'Kullanıcının engeli kaldırıldı',
		youAreSpeaker: 'Konuşmacısınız. Bir şey söyleyin lütfen',
		youAreNotSpeaker: 'Yönetici sizi konuşmacılardan çıkarttı',
		userIsSpeaker: (name: string) => `${name} şimdi konuşmacı`,
		userNotSpeaker: (name: string) => `${name} artık konuşmacı değil`,
		yourUserNameInChat: (name: string) => `Chat’teki isminiz — ${name}`,
		messageRemoved: 'Mesaj silindi',
		messageCopied: 'Metin kopyalandı',
		obscenities: 'Yeniden ifade etmeye çalışın',
		requestSent: 'Talep gönderildi',
		requestDecline: 'Talep yönetici tarafından geri alındı',
		youareblocked: (text: string) => `${text} metin için engellendiniz. Sohbete yazamazsınız`,
		youareunblocked: 'Engeliniz kaldırıldı. Sohbete mesaj yazabilirsiniz',
		youAreModerator: 'Moderatörsünüz',
		youAreNoModerator: 'Artık moderatör değilsiniz',
		micMuted: 'Mikrofonunuz kapatıldı',
		noInet: 'İnternet bağlantısı yok',
		messagePinned: 'Mesaj sabitlendi',
		messageUnpinned: 'Mesajın sabitlenmesi kaldırıldı',
		pollPublished: 'Anket yayınlandı',
		pollPublishedError: 'Hata oluştu. Daha sonra deneyin',
		pollAddPic: 'Fotoğraf ekle',
		pollAddPicError: 'Başka bir resim seçin',
		pollDeleted: 'Anket silindi',
		pollEnded: 'Anket tamamlandı',
		sendStickerWhenSlowModeIsOn: (time: string) => `Etiketi göndermek için ${time} bekleyin`,
		linkProhibited: 'Sohbet üzerinden bağlantı gönderemezsiniz.',
		phoneProhibited: 'Sohbet üzerinden telefon numaraları gönderemezsiniz.',
		profileHidden: 'Kullanıcı profillerini gizledi',
		imageSizeWarning: `10 MB'a kadar bir resim yükleyebilirsiniz`,
		imageBlocked: 'Moderatör resim yüklemeyi engelledi',
		streamKeyCopied: 'Yayın anahtarı kopyalandı',
	},
	btns: {
		acceptandgo: 'Kabul et ve devam et',
		saveandgo: 'Kaydet ve devam et',
		save: 'Kaydet',
		unblock: 'Engel kaldır',
		block: 'Engelle',
		restoreAccount: 'Profili geri yükle',
		close: 'Kapat',
	},
	coopyrights: 'Watchers.io temelinde çalışıyor',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Mesajı şikayet et' : 'Kural ihlalini rapor et',
			subtitle: 'Şikayetler isimsiz olarak gönderilir',
			btns: {
				violence: 'Hakaretler, tehditler, küfür',
				provocations: 'Provokasyonlar',
				personalDetails: 'Kişisel Veriler',
				flood: 'Flood',
				spam: 'Spam',
				fraudBegging: 'Dolandırıcılık, dilencilik',
				other: 'Diğer',
			},
		},
		ban: {
			title: 'Kullanıcıyı engelle',
			subtitle: 'Engellemenin nedenini belirtin',
			btns: {
				insults: 'ОHakaretler, tehditler, küfür',
				personal: 'Kişisel verilerin dağıtımı',
				flood: 'Flood',
				links: 'Üçüncü servislere linkler',
				violence: 'Şiddet tehditleri',
				fraud: 'Dolandırıcılık veya dilencilik girişimleri',
				nickname: 'Geçersiz nick name ',
				politicial: 'Politik, cinselleştirilmiş ve diğer provokasyonlar',
				repeated: 'Tekrarlanan ihlal',
				auto: 'Chat kullanıcılarının şikayetleri',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Bekleyen konuşmacılarınız yok',
			handsUp: 'Elini kaldırdılar',
			waiting: 'Bekleyen konuşmacılar',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Doğru cevabı verin',
				typeQuizMultiple: 'Doğru seçenekleri işaretleyin',
				typePoll: 'Görüşlerinizi paylaşın',
				typePollMultiple: 'Birkaç seçeneği işaretleyebilirsiniz ',
				default: 'Kamu anketi',
			},
			btns: {
				reply: 'Cevap ver',
				vote: 'Oy ver',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Yarışma',
				typePoll: 'Birkaç cevap',
				default: 'Kamu anketi',
			},
			btns: {
				edit: 'Anketi düzenle',
				end: 'Bitir',
			},
		},
		pollCreated: {
			titles: {
				created: 'Anket oluştur ',
				typeQuiz: 'Yarışma',
				typePoll: 'Birkaç cevap',
				default: 'Kamu anketi',
			},
		},
		changeName: {
			title: 'Chat’teki ismi değiştirin',
			subtitle: 'Geçersiz bir nickname nedeniyle engellendiniz.',
		},
		temporaryAgreement: {
			title: 'Chat kuralları',
			rules: {
				bePolite: {
					title: 'Kibar olun',
					text: 'Chatte kin ve müstehcen ifadeler yasaktır.',
				},
				beAttentive: {
					title: 'Dikkatli olun',
					text: 'Kişisel bilgilerinizi paylaşmayın ve başkalarına sormayın.',
				},
				notAdvertise: {
					title: 'Reklamcılık yapmayın',
					text: 'Düşüncelerinizi veya duygularınızı paylaşın.',
				},
			},
			btns: {
				allRules: 'Tüm kurallar',
				acceptAndContinue: 'Kabul et ve devam et',
			},
		},
		slowMode: {
			title: 'Yavaş mod',
			text: 'Katılımcılar sohbete seçilen zaman aralığından daha sık mesaj gönderemezler',
			enable: 'Yavaş modu etkinleştir',
			disable: 'Yavaş modu kapat',
			sendTimeout: 'Gönderim zaman aşımı',
		},
		slowModeTimeout: {
			title: 'Gönderim zaman aşımı',
		},
		chatBot: {
			title: 'Chat botu kullanma',
			rules: [
				{
					title: 'Yapay zeka',
					text: 'Bot, insanlarla iletişimi taklit eder ve kendi bilgi seviyesine göre bilgi sağlar. Bununla birlikte, cevaplarının sınırlı olabileceğini ve kişinin yerini tutmayacağını unutmayın.',
				},
				{
					title: 'Bot yanılabilir',
					text: 'Bot, bilgilerin doğruluğunu ve güvenirliğini garanti edemez.',
				},
			],
			btns: {
				close: 'Kapat',
			},
		},
		shareBet: {
			title: 'Paylaş',
			noBetsTitle: 'Basihlerinizi bulamadık',
			noBetsText: '14 gün içinde yapılan bahisleri paylaşmak mümkündür',
			btns: {
				share: 'Bahisi paylaş',
				copy: 'Bahisi tekrarla',
			},
			status: {
				null: 'Oyunda',
				win: 'Kazanç',
				lose: 'Yenilgi',
			},
			betTypeV1: {
				express: 'Çoklu',
				single: 'Tekil',
			},
			betTypeV2: {
				single: 'Tekil',
				multi: 'Çoklu',
				system: 'System',
			},
			totalFactor: 'Katsayı',
			bet: 'bahis',
			showMore: 'Daha fazlasını göster',
		},
		shareGamble: {
			noGamblesText:
				'Henüz paylaşabileceğiniz bir kazancınız yok. Oynamaya devam edin ve bir sonraki kazancınız burada görünecek, böylece diğer oyuncularla paylaşabilirsiniz!',
		},
		record: {
			text: 'Yayın kaydı işleniyor…',
		},
		translate: {
			title: 'Mesaj Çevirisi',
			chooseLanguage: 'Dil Seçin',
			descr: 'Sohbet mesajları otomatik olarak seçtiğiniz dile çevrilecektir',
			btn: 'Çevir',
		},
		streamSettings: {
			title: 'Yayın Ayarları',
			description: 'Bir video kodlayıcı indirin ve kurun. Yayın anahtarını ayarlarına girin',
			shareScreen: {
				title: 'Ekran Paylaşımı',
				description: 'Özel ayar gerekmiyor',
			},
			rtmp: {
				title: 'Harici Yayın Kaynağı',
				description: 'Yayın için ek bir program kullanın',
			},
			streamKey: 'Yayın Anahtarı',
			showStreamKey: 'Göster',
			hideStreamKey: 'Gizle',
			btns: {
				continue: 'Devam Et',
				close: 'Kapat',
				ok: 'Tamam',
			},
		},
	},
	pinnedMessage: {
		title: 'Sabitlenmiş mesaj',
	},
	errorPage: {
		error: 'Hata',
		types: [
			// errorNumber 0
			{
				title: 'Sizi tanıyamıyoruz',
				text: 'Chat’e yeniden girmeyi deneyin',
			},
			// errorNumber 1
			{
				title: 'Chat’I bulamıyoruz',
				text: 'Yeniden girmeyi dene',
			},
			// errorNumber 2
			{
				title: 'Chat geri yüklenemiyor',
				text: 'Yeniden yüklemeyi deneyin',
			},
			// errorNumber 3
			{
				title: 'Sizi tanıyamıyoruz',
				text: 'Gizli modta chat kullanılamıyor',
			},
			// errorNumber 4
			{
				title: 'Geçersiz nickname ',
				text: 'Profilinize girin, nickname’inizi değiştirin ve sohbete tekrar giriş yapın.',
			},
		],
	},
	tooltips: {
		emotions: 'Değiştirmek için basılı tutun',
		badInet: 'Kötü bağlantı',
		blockedUsers: 'Engellenen <br> kullanıcıların listesini buradan görebilirsiniz.',
		waitingSpeakers: 'Katılımcılardan biri konuşmak istiyor.',
		wantSpeak: 'Oy hakkı istemek için tıklayın.',
		slowMode: {
			header: (time: string) =>
				`Yavaş mod açık. Kullanıcılar her ${time} <br> bir mesaj gönderebilirler. Bu kural moderatörler için geçerli değildir`,
			btnSendMessage: (time: string) => `Her birine mesaj gönderebilirsiniz Her ${time}`,
		},
		shareBet: 'Chat’te bahisi paylaş',
		translate: 'Mesajları başka bir dile çevir',
	},
	poll: {
		toggleSubmenu: {
			create: 'Anket oluştur ',
			edit: 'Anketi düzenle',
			show: 'Anketi göster',
			end: 'Anketi sonlandır',
		},
		tooltip: {
			btn: 'Oy ver',
		},
		settings: {
			title: 'Ayarlar',
			titleAdditionally: 'Ek',
			switches: {
				quizMode: 'Yarışma modu',
				multipleChoice: 'Birden fazla cevap seçme',
				withPhoto: 'Fotoğraflı anket',
			},
		},
		question: {
			title: 'Soru',
			inputPlaceholder: 'Soruyu girin',
		},
		message: {
			title: 'Oy verdiniz',
		},
		answers: {
			title: 'Cevap seçenekleri',
			warning: `5'a kadar cevap ekleyebilirsiniz`,
		},
		answer: {
			tooltip: 'Doğru cevabı seçin',
			inputPlaceholder: 'Yanıt',
		},
		results: {
			text: 'Oylama sonuçları',
			votes: 'oyları',
			// words: ['oy', 'oylar', 'oyları'],
		},
		btns: {
			addQuestion: 'Soru ekle',
			addAnswers: 'Cevapları ekle',
			addAnswer: 'Cevap ekle',
			publish: 'Yayınla',
			vote: 'Oy ver',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Değerlendir',
				continue: 'Devam et',
				checkConnection: 'Bağlantıyı kontrol et',
				close: 'Kapat',
			},
			quality: {
				title: 'Ses kalitesini değerlendir',
			},
			reason: {
				title: 'Bir şeyler ters mi gitti?',
			},
			connection: {
				title: 'Bağlantı kalitesini test edin',
				text: 'Doğrulama bir dakikadan fazla sürmeyecek ve sorunun nedenini bulmanıza yardımcı olacaktır',
			},
			statistics: {
				title: 'İstatistikleri topluyoruz...',
			},
			done: {
				title: 'Teşekkür ederiz',
				text: 'Neyin yanlış gittiğini inceleyeceğiz ve kesinlikle düzelteceğiz.',
			},
		},
	},
	chatBot: {
		message: `Bot'a bir şey sor...`,
		warnings: {
			disabled: 'Beni kapattılar',
			overloaded: 'Biz aşırı yoğunuz',
		},
	},
	stickers: 'Stikerler',
	audioReason: {
		nothear: 'Hiçbir şey duyulmadı',
		connection: 'Ses kesintiye uğradı',
		microphone: 'Mikrofonu kullanamadım',
	},
	screenShare: {
		title: 'Ekran Paylaşımı',
		stopSharing: 'Ekran Paylaşımını Durdur',
		modal: {
			title: 'Ekran paylaşımı yalnızca bir bilgisayardan mümkündür.',
			text: `Bilgisayarınızda sohbeti açın ve sağ üst köşedeki 'Ekran Paylaşımı' düğmesine tıklayın.`,
			btn: 'Davet bağlantısını kopyalayın.',
		},
	},
	threads: {
		title: 'Konu',
		back: 'Geri',
		replies: ['Yanıt', 'Yanıtlar', 'Yanıtlar'],
	},
	attach: {
		submenu: {
			sendImage: 'Resim göndermek',
			shareBet: 'Bahis paylaş',
			shareGamble: 'Kazancı paylaş',
		},
		images: ['resim', 'resimler', 'resimler'],
		addCaption: 'Altyazı ekle',
	},
} as unknown as Dictionary;
