import AppIconsName from 'models/enums/AppIconsName.enum';
import {AppIcon} from 'models/app';

import IcoAdd from 'assets/svg/ico-add.svg';
import IcoBadinet from 'assets/svg/ico-badinet.svg';
import IcoBan from 'assets/svg/ico-ban.svg';
import IcoClose from 'assets/svg/ico-close.svg';
import IcoCopy from 'assets/svg/ico-copy.svg';
import IcoEdit from 'assets/svg/ico-edit.svg';
import IcoEditAccent from 'assets/svg/ico-edit-yellow.svg';
import IcoEmoji from 'assets/svg/ico-emoji.svg';
import IcoHand from 'assets/svg/ico-hand.svg';
import IcoHandAccent from 'assets/svg/ico-hand-yellow.svg';
import IcoHandPlain from 'assets/svg/ico-hand-plain.svg';
import IcoHandPlainAccent from 'assets/svg/ico-hand-plain-yellow.svg';
import IcoKeyboard from 'assets/svg/ico-keyboard.svg';
import IcoLeave from 'assets/svg/ico-leave.svg';
import IcoLink from 'assets/svg/ico-link.svg';
import IcoMessage from 'assets/svg/ico-message.svg';
import IcoMic from 'assets/svg/ico-mic.svg';
import IcoMicAccent from 'assets/svg/ico-mic-yellow.svg';
import IcoMute from 'assets/svg/ico-mute.svg';
import IcoNoinet from 'assets/svg/ico-noinet.svg';
import IcoPin from 'assets/svg/ico-pin.svg';
import IcoUnpin from 'assets/svg/ico-unpin.svg';
import IcoPoll from 'assets/svg/ico-poll.svg';
import IcoPollEnd from 'assets/svg/ico-pol-end.svg';
import IcoPollEndAccent from 'assets/svg/ico-pol-end-yellow.svg';
import IcoReplay from 'assets/svg/ico-replay.svg';
import IcoReplayAccent from 'assets/svg/ico-replay-yellow.svg';
import IcoReport from 'assets/svg/ico-report.svg';
import IcoReportMessage from 'assets/svg/ico-report-message.svg';
import IcoReportUser from 'assets/svg/ico-report-user.svg';
import IcoRightArrow from 'assets/svg/ico-right-bubled-arrow.svg';
import IcoSendMessage from 'assets/svg/ico-send-message.svg';
import IcoSettings from 'assets/svg/ico-settings.svg';
import IcoShare from 'assets/svg/ico-share-bet.svg';
import IcoSlowmode from 'assets/svg/ico-slowmode.svg';
import IcoSpeaker from 'assets/svg/ico-speaker.svg';
import IcoSpeakerAdd from 'assets/svg/ico-speaker-add.svg';
import IcoStar from 'assets/svg/ico-star.svg';
import IcoStickers from 'assets/svg/ico-stickers.svg';
import IcoSubmenuBlock from 'assets/svg/ico-submenu-block.svg';
import IcoSubmenuBasket from 'assets/svg/ico-submenu-busket.svg';
import IcoSubmenuHide from 'assets/svg/ico-submenu-hide.svg';
import IcoSubmenuPin from 'assets/svg/ico-submenu-pin.svg';
import IcoSubmenuRemoveSpeaker from 'assets/svg/ico-submenu-remove-speaker.svg';
import IcoSubmenuShow from 'assets/svg/ico-submenu-show.svg';
import IcoSubmenuUnblock from 'assets/svg/ico-submenu-unblock.svg';
import IcoToastBasket from 'assets/svg/ico-toast-basket.svg';
import IcoToastBrickRed from 'assets/svg/ico-toast-brick-red.svg';
import IcoToastBrick from 'assets/svg/ico-toast-brick-yellow.svg';
import IcoToastClose from 'assets/svg/ico-toast-close.svg';
import IcoToastCopy from 'assets/svg/ico-toast-copy.svg';
import IcoToastPoll from 'assets/svg/ico-toast-poll.svg';
import IcoToastPin from 'assets/svg/ico-toast-pin.svg';
import IcoToastUnpin from 'assets/svg/ico-toast-unpin.svg';
import IcoToastUserAddedSpeakers from 'assets/svg/ico-toast-user-added-speakers.svg';
import IcoToastUserRemovedSpeakers from 'assets/svg/ico-toast-user-removed-speakers.svg';
import IcoToastUserBlocked from 'assets/svg/ico-toast-user-blocked.svg';
import IcoToastUserWarning from 'assets/svg/ico-toast-warning.svg';
import IcoToastMessageShown from 'assets/svg/ico-toast-message-shown.svg';
import IcoToastMessageHidden from 'assets/svg/ico-toast-message-hidden.svg';
import IcoUsers from 'assets/svg/ico-users.svg';
import IcoWarn from 'assets/svg/ico-warn.svg';
import IcoPlay from 'assets/svg/ico-player-play.svg';
import IcoPause from 'assets/svg/ico-player-pause.svg';
import IcoSeekBack from 'assets/svg/ico-player-seek-back.svg';
import IcoSeekForward from 'assets/svg/ico-player-seek-forward.svg';
import IcoChevron from 'assets/svg/ico-chevron.svg';
import IcoShareDesktop from 'assets/svg/ico-share-desktop.svg';
import IcoShareAndroid from 'assets/svg/ico-share-android.svg';
import IcoShareIos from 'assets/svg/ico-share-ios.svg';
import IcoCalendar from 'assets/svg/ico-calendar.svg';
import IcoShareRoom from 'assets/svg/ico-share-room.svg';
import IcoToastConnection from 'assets/svg/ico-stream.svg';
import IcoMicDevice from 'assets/svg/ico-mic-device.svg';
import IcoSpeakerDevice from 'assets/svg/ico-speaker-device.svg';
import IcoSubmenuProfile from 'assets/svg/ico-submenu-profile.svg';
import IcoTranslateOn from 'assets/svg/ico-translate-on.svg';
import IcoTranslateOff from 'assets/svg/ico-translate-off.svg';
import IcoScreenShare from 'assets/svg/ico-screenshare.svg';
import IcoSubmenuThread from 'assets/svg/ico-thread.svg';
import IcoAdmin from 'assets/svg/ico-admin.svg';
import IcoAttach from 'assets/svg/ico-attach.svg';
import IcoImage from 'assets/svg/ico-image.svg';
import IcoFollowing from 'assets/svg/ico-following.svg';
import IcoDisabledChat from 'assets/svg/ico-disabled.svg';

export const icons: AppIcon[] = [
	{id: 0, name: AppIconsName.IcoAdd, pic: IcoAdd},
	{id: 1, name: AppIconsName.IcoBadinet, pic: IcoBadinet},
	{id: 2, name: AppIconsName.IcoBan, pic: IcoBan},
	{id: 3, name: AppIconsName.IcoClose, pic: IcoClose},
	{id: 4, name: AppIconsName.IcoCopy, pic: IcoCopy},
	{id: 5, name: AppIconsName.IcoEdit, pic: IcoEdit},
	{id: 6, name: AppIconsName.IcoEditAccent, pic: IcoEditAccent},
	{id: 7, name: AppIconsName.IcoEmoji, pic: IcoEmoji},
	{id: 8, name: AppIconsName.IcoHand, pic: IcoHand},
	{id: 9, name: AppIconsName.IcoHandAccent, pic: IcoHandAccent},
	{id: 10, name: AppIconsName.IcoHandPlain, pic: IcoHandPlain},
	{id: 11, name: AppIconsName.IcoHandPlainAccent, pic: IcoHandPlainAccent},
	{id: 12, name: AppIconsName.IcoKeyboard, pic: IcoKeyboard},
	{id: 13, name: AppIconsName.IcoLeave, pic: IcoLeave},
	{id: 14, name: AppIconsName.IcoLink, pic: IcoLink},
	{id: 15, name: AppIconsName.IcoMessage, pic: IcoMessage},
	{id: 16, name: AppIconsName.IcoMic, pic: IcoMic},
	{id: 17, name: AppIconsName.IcoMicAccent, pic: IcoMicAccent},
	{id: 18, name: AppIconsName.IcoMute, pic: IcoMute},
	{id: 19, name: AppIconsName.IcoNoinet, pic: IcoNoinet},
	{id: 20, name: AppIconsName.IcoPin, pic: IcoPin},
	{id: 21, name: AppIconsName.IcoUnpin, pic: IcoUnpin},
	{id: 22, name: AppIconsName.IcoPoll, pic: IcoPoll},
	{id: 23, name: AppIconsName.IcoPollEnd, pic: IcoPollEnd},
	{id: 24, name: AppIconsName.IcoPollEndAccent, pic: IcoPollEndAccent},
	{id: 25, name: AppIconsName.IcoReplay, pic: IcoReplay},
	{id: 26, name: AppIconsName.IcoReplayAccent, pic: IcoReplayAccent},
	{id: 27, name: AppIconsName.IcoReport, pic: IcoReport},
	{id: 28, name: AppIconsName.IcoReportMessage, pic: IcoReportMessage},
	{id: 29, name: AppIconsName.IcoReportUser, pic: IcoReportUser},
	{id: 30, name: AppIconsName.IcoRightArrow, pic: IcoRightArrow},
	{id: 31, name: AppIconsName.IcoSendMessage, pic: IcoSendMessage},
	{id: 32, name: AppIconsName.IcoSettings, pic: IcoSettings},
	{id: 33, name: AppIconsName.IcoShare, pic: IcoShare},
	{id: 34, name: AppIconsName.IcoSlowmode, pic: IcoSlowmode},
	{id: 35, name: AppIconsName.IcoSpeaker, pic: IcoSpeaker},
	{id: 36, name: AppIconsName.IcoSpeakerAdd, pic: IcoSpeakerAdd},
	{id: 37, name: AppIconsName.IcoStar, pic: IcoStar},
	{id: 38, name: AppIconsName.IcoStickers, pic: IcoStickers},
	{id: 39, name: AppIconsName.IcoSubmenuBlock, pic: IcoSubmenuBlock},
	{id: 40, name: AppIconsName.IcoSubmenuBasket, pic: IcoSubmenuBasket},
	{id: 41, name: AppIconsName.IcoSubmenuHide, pic: IcoSubmenuHide},
	{id: 42, name: AppIconsName.IcoSubmenuPin, pic: IcoSubmenuPin},
	{id: 43, name: AppIconsName.IcoSubmenuRemoveSpeaker, pic: IcoSubmenuRemoveSpeaker},
	{id: 44, name: AppIconsName.IcoSubmenuShow, pic: IcoSubmenuShow},
	{id: 45, name: AppIconsName.IcoSubmenuUnblock, pic: IcoSubmenuUnblock},
	{id: 46, name: AppIconsName.IcoToastBasket, pic: IcoToastBasket},
	{id: 47, name: AppIconsName.IcoToastBrickRed, pic: IcoToastBrickRed},
	{id: 48, name: AppIconsName.IcoToastBrick, pic: IcoToastBrick},
	{id: 49, name: AppIconsName.IcoToastClose, pic: IcoToastClose},
	{id: 50, name: AppIconsName.IcoToastCopy, pic: IcoToastCopy},
	{id: 51, name: AppIconsName.IcoToastPoll, pic: IcoToastPoll},
	{id: 52, name: AppIconsName.IcoToastPin, pic: IcoToastPin},
	{id: 53, name: AppIconsName.IcoToastUnpin, pic: IcoToastUnpin},
	{id: 54, name: AppIconsName.IcoToastUserAddedSpeakers, pic: IcoToastUserAddedSpeakers},
	{id: 55, name: AppIconsName.IcoToastUserRemovedSpeakers, pic: IcoToastUserRemovedSpeakers},
	{id: 56, name: AppIconsName.IcoToastUserBlocked, pic: IcoToastUserBlocked},
	{id: 57, name: AppIconsName.IcoToastUserWarning, pic: IcoToastUserWarning},
	{id: 56, name: AppIconsName.IcoToastMessageShown, pic: IcoToastMessageShown},
	{id: 57, name: AppIconsName.IcoToastMessageHidden, pic: IcoToastMessageHidden},
	{id: 58, name: AppIconsName.IcoUsers, pic: IcoUsers},
	{id: 59, name: AppIconsName.IcoWarn, pic: IcoWarn},
	{id: 60, name: AppIconsName.IcoPlay, pic: IcoPlay},
	{id: 61, name: AppIconsName.IcoPause, pic: IcoPause},
	{id: 62, name: AppIconsName.IcoSeekBack, pic: IcoSeekBack},
	{id: 63, name: AppIconsName.IcoSeekForward, pic: IcoSeekForward},
	{id: 64, name: AppIconsName.IcoChevron, pic: IcoChevron},
	{id: 65, name: AppIconsName.IcoShareDesktop, pic: IcoShareDesktop},
	{id: 66, name: AppIconsName.IcoShareAndroid, pic: IcoShareAndroid},
	{id: 67, name: AppIconsName.IcoShareIos, pic: IcoShareIos},
	{id: 68, name: AppIconsName.IcoCalendar, pic: IcoCalendar},
	{id: 69, name: AppIconsName.IcoShareRoom, pic: IcoShareRoom},
	{id: 70, name: AppIconsName.IcoToastConnection, pic: IcoToastConnection},
	{id: 71, name: AppIconsName.IcoMicDevice, pic: IcoMicDevice},
	{id: 72, name: AppIconsName.IcoSpeakerDevice, pic: IcoSpeakerDevice},
	{id: 73, name: AppIconsName.IcoSubmenuProfile, pic: IcoSubmenuProfile},
	{id: 74, name: AppIconsName.IcoTranslateOn, pic: IcoTranslateOn},
	{id: 75, name: AppIconsName.IcoTranslateOff, pic: IcoTranslateOff},
	{id: 76, name: AppIconsName.IcoScreenShare, pic: IcoScreenShare},
	{id: 77, name: AppIconsName.IcoSubmenuThread, pic: IcoSubmenuThread},
	{id: 78, name: AppIconsName.IcoAdmin, pic: IcoAdmin},
	{id: 79, name: AppIconsName.IcoAttach, pic: IcoAttach},
	{id: 80, name: AppIconsName.IcoImage, pic: IcoImage},
	{id: 81, name: AppIconsName.IcoFollowing, pic: IcoFollowing},
	{id: 82, name: AppIconsName.IcoDisabledChat, pic: IcoDisabledChat},
];
