import {
	MODAL_STYLE_THREAD,
	MODAL_STYLE_THREAD_SCREEN_SHARE,
	MODAL_STYLE_THREAD_TINKOFF_VIDEO,
} from 'constants/constants';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import Modal from 'react-modal';
import modalService from 'store/modalService';
import './threadModal.scss';
import {Chat} from 'pages/main/components/chat';
import {MainBottom} from 'pages/main/components/mainBottom';
import agoraService from 'store/agoraService';
import appService from 'store/appService';
import classNames from 'classnames';
import {isDesktop} from 'react-device-detect';

interface IThreadModal {
	isStreamVideo?: boolean;
}

const ThreadModal: FunctionComponent<IThreadModal> = function ThreadModal({isStreamVideo}) {
	const {visibleThreadModal} = useLocalObservable(() => modalService);
	const {appEnableFullscreenOption} = useLocalObservable(() => appService);
	const {isScreenSharing, isFullScreenVideo} = useLocalObservable(() => agoraService);

	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const threadModalClasses = classNames('thread-modal', {
		'thread-modal--fullscreen-mode': isFullScreenMode,
	});

	const getOverlayStyle = () => {
		if (isStreamVideo) {
			return MODAL_STYLE_THREAD_TINKOFF_VIDEO.overlay;
		}
		if (isScreenSharing) {
			if (appEnableFullscreenOption && isFullScreenVideo && isDesktop) {
				return {...MODAL_STYLE_THREAD_SCREEN_SHARE.overlay, height: '100%'};
			}
			return MODAL_STYLE_THREAD_SCREEN_SHARE.overlay;
		}
		return MODAL_STYLE_THREAD.overlay;
	};

	return (
		<Modal
			className='modal modal--thread'
			isOpen={visibleThreadModal}
			ariaHideApp={false}
			style={{
				overlay: getOverlayStyle(),
			}}>
			<section className={threadModalClasses}>
				<Chat isThread />
				<MainBottom />
			</section>
		</Modal>
	);
};

export default observer(ThreadModal);
