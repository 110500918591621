import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useSearchParams from 'hooks/useSearchParams';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

interface IErrorProps {
	errorNumber?: number;
}

const Error: FunctionComponent<IErrorProps> = function Error({errorNumber}) {
	const {appIcons} = useLocalObservable(() => appService);

	const {errorPage} = useL10n();
	const {getAppIcon} = useAppData();
	const {userIdFromUrl, roomIdFromUrl, errorNumberFromUrl} = useSearchParams();

	const [errorNumberLocal, setErrorNumberLocal] = useState(
		errorNumber || (errorNumberFromUrl && parseInt(errorNumberFromUrl, 10)) || 0
	);

	const {IcoWarn} = appIcons;

	useEffect(() => {
		if (errorNumberLocal < 3) {
			if (userIdFromUrl === '') {
				setErrorNumberLocal(0);
				roomIdFromUrl === null && setErrorNumberLocal(2);
				return;
			}
			roomIdFromUrl === null && setErrorNumberLocal(1);
		}
	}, [roomIdFromUrl, userIdFromUrl]);

	if (errorNumberLocal > errorPage.types.length - 1) {
		setErrorNumberLocal(0);
		return null;
	}

	return (
		<div className='error'>
			<div className='error__header'>
				<div className='error__header-overico'>{getAppIcon(IcoWarn.pic)}</div>
				<div className='error__header-content'>
					<p className='error__title'>{errorPage.types[errorNumberLocal].title}</p>
					<p className='error__subtitle'>{errorPage.error}</p>
				</div>
			</div>
			<p className='error__text'>{errorPage.types[errorNumberLocal].text}</p>
		</div>
	);
};

export default observer(Error);
