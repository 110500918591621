enum BetTypesV1 {
	SINGLE = 'SINGLE',
	EXPRESS = 'EXPRESS',
}

enum BetTypesV2 {
	SINGLE = 'SINGLE',
	MULTI = 'MULTI',
	SYSTEM = 'SYSTEM',
}

enum BetOrderStatusV1 {
	NULL = 'NULL',
	WIN = 'WIN',
	LOSE = 'LOSE',
}

enum BetStakeStatus {
	'Pending' = 1,
	'Won',
	'Lost',
	'Return',
	'Lost | Return',
	'Won | Return',
	'Canceled',
	'Initial Return',
	'Pending | Initial Return',
	'Won | Initial Return',
}

enum BetOrderStatusV2 {
	WON = 'won',
	LOST = 'lost',
	NEW = 'new',
	PENDING = 'pending',
	CASHED_OUT = 'cashedOut',
	BORE_DRAW = 'boreDraw',
	CASHBACK = 'cashback',
	MEGABET_NEW = 'megaBetNew',
	MEGABET_CANCELED_BY_OPERATOR = 'megaBetCanceledByOperator',
	MEGABET_CANCELED_BY_PLAYER = 'megaBetCanceledByPlayer',
	MEGABET_CANCELED_BY_SYSTEM = 'megaBetCanceledBySystem',
	BETGAMES_PENDING = 'betGamesPending',
	BETGAMES_WON = 'betGamesWon',
	BETGAMES_LOST = 'betGamesLost',
	PAID = 'paid',
	INITIAL_WIN = 'initialWin',
	AUTOBET_CANCELED_BY_SYSTEM = 'autoBetCanceledBySystem',
	AUTOBET_CANCELED_BY_PLAYER = 'autoBetCanceledByPlayer',
	AUTOBET_PENDING = 'autobetPending',
	GOAL_AHEAD = 'goalAhead',
	CANCELED = 'canceled',
	RETURN = 'return',
}

export {BetTypesV1, BetTypesV2, BetOrderStatusV1, BetStakeStatus, BetOrderStatusV2};
