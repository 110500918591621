import {action, makeObservable, observable} from 'mobx';

class ControlPanelServices {
	@observable
	public visibleButtons = true;

	@observable
	public attachSubmenuVisible = false;

	@action
	public toggleVisibleButtons = (value: boolean) => {
		this.visibleButtons = value;
	};

	@action
	public toggleAttachSubmenuVisible = (value: boolean) => {
		this.attachSubmenuVisible = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ControlPanelServices();
