import {GenerateReaction} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import emotionService from 'store/emotionService';
import useReactions from 'hooks/useReactions';
import useOnboarding from 'hooks/useOnboarding';
import {FunctionComponent, useCallback} from 'react';
import './reactions.scss';
import {observer, useLocalObservable} from 'mobx-react-lite';
import threadService from 'store/threadService';

interface IReactionsProps {
	reactions: GenerateReaction[];
}

const Reactions: FunctionComponent<IReactionsProps> = function Reactions({reactions}) {
	const {appReadOnly, appEnableOnboarding} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {myTalker} = useLocalObservable(() => (currentThreadId ? threadService : roomServices));
	const {emotions} = useLocalObservable(() => emotionService);

	const {onBoardingStageHandler} = useOnboarding();
	const {reactionItemHandler} = useReactions();

	const onReactionItemHandler = (item: GenerateReaction) => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			if (!appEnableOnboarding || (appEnableOnboarding && userData?.isOnboard)) {
				reactionItemHandler(item);
				return;
			}
			onBoardingStageHandler();
		}
	};

	const renderReactionItem = useCallback(
		(item: GenerateReaction) => {
			const findReactionData = emotions.find(e => e.name === item.name);
			if (findReactionData) {
				return (
					<div className='reactions__item' key={item.id}>
						<button
							type='button'
							className={`reactions__item-btn ${
								item.items.find(r => r.emotion === item.name && r.talkerId === myTalker?.id)
									? 'reactions__item-btn--selected'
									: ''
							}`}
							onClick={() => onReactionItemHandler(item)}>
							<img src={findReactionData.pic} alt='' className='reactions__item-img' />
							{item.items.length}
						</button>
					</div>
				);
			}
			return null;
		},
		[reactions, myTalker, userData?.isOnboard]
	);

	return (
		<div className='reactions'>
			<div className='reactions__items'>{reactions.map(renderReactionItem)}</div>
		</div>
	);
};

export default observer(Reactions);
