import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface IUsersModalSkeleton {
	visible: boolean;
}

const UsersModalSkeleton: FunctionComponent<IUsersModalSkeleton> = function UsersModalSkeleton({
	visible,
}) {
	return (
		<CSSTransition in={visible} timeout={300} classNames='skeleton' unmountOnExit>
			<div className='users-modal__skeleton'>
				<div className='users-modal__skeleton'>
					<div className='users-modal__skeleton-bets'>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
							<div className='users-modal__skeleton-user' key={i}>
								<div className='users-modal__skeleton-user-avatar'>
									<LoadingSkeleton circle enableAnimation={false} />
								</div>
								<div className='users-modal__skeleton-user-body'>
									<LoadingSkeleton enableAnimation={false} />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(UsersModalSkeleton);
