import {qs} from 'utils/helpers';
import appService from 'store/appService';
import {useLocalObservable} from 'mobx-react-lite';
import AppThemes from 'models/enums/AppThemes.enum';

const htmlAttrTheme = 'theme';
const htmlAttrThemeValue = 'partner';

const getCssRules = (data: any) => {
	let rootCssRules = `html[${htmlAttrTheme}="${htmlAttrThemeValue}"]:root {`;

	Object.entries(data).forEach(v => {
		rootCssRules = `${rootCssRules} ${v[0]}: ${v[1]};`;
	});

	return `${rootCssRules} }`;
};

const useTheme = () => {
	const {setAppTheme} = useLocalObservable(() => appService);

	const setTheme = (data: any) => {
		if (!data.colors) return;

		const {colors, themeId} = data;
		const colorsParse = JSON.parse(colors);

		const rootVars: any = {};

		const getVariableValue = (variableValue: string) => {
			const variableValueClear = variableValue.replace(/(^{|}$)/g, '');
			const variableValueCss = `--${variableValueClear.replace(/\./g, '-')}`;

			if (rootVars[variableValueCss]) {
				return `var(${variableValueCss})`;
			}

			return variableValue;
		};

		const addItemToRootVars = (incomingKey: string, incomingValue: string) => {
			if (incomingValue.includes('{') && getVariableValue(incomingValue)) {
				rootVars[incomingKey] = getVariableValue(incomingValue);
				return;
			}

			rootVars[incomingKey] = incomingValue;
		};

		Object.entries(colorsParse).forEach(([key]) => {
			const colorKey = colorsParse[key];

			if (colorKey.value) {
				rootVars[`--${key}`] = colorKey.value;
				return;
			}

			if (Object.entries(colorKey).length) {
				Object.entries(colorKey).forEach(([keyLevel2]) => {
					const colorKeyLevel2 = colorKey[keyLevel2];
					const concatenateKeyLevel2 = `--${key}-${keyLevel2}`;

					if (colorKeyLevel2.value) {
						addItemToRootVars(concatenateKeyLevel2, colorKeyLevel2.value);
						return;
					}

					if (Object.entries(colorKeyLevel2).length) {
						Object.entries(colorKeyLevel2).forEach(([keyLevel3]) => {
							const colorKeyLevel3 = colorKeyLevel2[keyLevel3];
							const concatenateKeyLevel3 = `${concatenateKeyLevel2}-${keyLevel3}`;

							if (colorKeyLevel3.value) {
								addItemToRootVars(concatenateKeyLevel3, colorKeyLevel3.value);
								return;
							}

							if (Object.entries(colorKeyLevel3).length) {
								Object.entries(colorKeyLevel3).forEach(([keyLevel4]) => {
									const colorKeyLevel4 = colorKeyLevel3[keyLevel4];
									const concatenateKeyLevel4 = `${concatenateKeyLevel3}-${keyLevel4}`;

									if (colorKeyLevel4.value) {
										addItemToRootVars(concatenateKeyLevel4, colorKeyLevel4.value);
										return;
									}

									if (Object.entries(colorKeyLevel4).length) {
										Object.entries(colorKeyLevel4).forEach(([keyLevel5]) => {
											const colorKeyLevel5 = colorKeyLevel4[keyLevel5];
											const concatenateKeyLevel5 = `${concatenateKeyLevel4}-${keyLevel5}`;

											if (colorKeyLevel5.value) {
												addItemToRootVars(concatenateKeyLevel5, colorKeyLevel5.value);
												return;
											}

											if (Object.entries(colorKeyLevel5).length) {
												Object.entries(colorKeyLevel5).forEach(([keyLevel6]) => {
													const colorKeyLevel6 = colorKeyLevel5[keyLevel6];
													const concatenateKeyLevel6 = `${concatenateKeyLevel5}-${keyLevel6}`;

													if (colorKeyLevel6.value) {
														addItemToRootVars(concatenateKeyLevel6, colorKeyLevel6.value);
													}
												});
											}
										});
									}
								});
							}
						});
					}
				});
			}
		});

		if (Object.keys(rootVars).length) {
			const headTag = document.getElementsByTagName('head')[0];
			const styleTag = document.createElement('style');

			qs('html')?.setAttribute(htmlAttrTheme, htmlAttrThemeValue);
			styleTag.innerHTML = getCssRules(rootVars);
			headTag.appendChild(styleTag);
		}

		if (themeId) {
			setAppTheme(themeId === 1 ? {id: 1, name: AppThemes.LIGHT} : {id: 2, name: AppThemes.DARK});
		}
	};

	const setFont = (data: {name: string; urls: any}) => {
		if (!data) {
			const headTag = document.getElementsByTagName('head')[0];
			const styleTag = document.createElement('style');

			styleTag.innerHTML = `html[${htmlAttrTheme}="${htmlAttrThemeValue}"]:root { --font-primary: 'SFProText', sans-serif; }`;
			headTag.appendChild(styleTag);
			return;
		}

		const {name, urls} = data;
		const parseUrls = JSON.parse(urls);
		let fontRules = '';
		const fonts = [
			{
				fontWeight: 400,
				fontName: 'Regular',
			},
			{
				fontWeight: 500,
				fontName: 'Medium',
			},
			{
				fontWeight: 600,
				fontName: 'Semibold',
			},
		];

		if (Object.entries(parseUrls).length) {
			Object.entries(parseUrls).forEach(([fontWeight]) => {
				const fontItem = parseUrls[fontWeight];

				fontRules = `${fontRules} @font-face {
					font-family: ${name}; 
					src: local('${name}'), local('${name}-${
					fonts.find(el => el.fontWeight === +fontWeight)?.fontName
				}'), url('${fontItem.woff2}') format('woff2'), 
						url('${fontItem.woff}') format('woff'), 
						url('${fontItem.ttf}') format('truetype');
					font-weight: ${fontWeight}; 
					font-style: normal;
					font-display: swap;
				} \n`;
			});

			if (fontRules.length) {
				const headTag = document.getElementsByTagName('head')[0];
				const styleTag = document.createElement('style');

				styleTag.innerHTML = `html[${htmlAttrTheme}="${htmlAttrThemeValue}"]:root { --font-primary: ${name}, sans-serif; } \n ${fontRules}`;
				headTag.appendChild(styleTag);
			}
		}
	};

	return {setTheme, setFont};
};

export default useTheme;
