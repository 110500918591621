import {FunctionComponent} from 'react';
import classNames from 'classnames';
import roomServices from 'store/roomService';
import {useLocalObservable} from 'mobx-react-lite';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';

interface IChatMessagesPreloaderProps {
	visible: boolean;
}

const ChatMessagesPreloader: FunctionComponent<IChatMessagesPreloaderProps> =
	function ChatMessagesPreloader({visible = false}) {
		const {pinnedMessages} = useLocalObservable(() => roomServices);
		const {appLoaderIcon} = useLocalObservable(() => appService);
		const {getAppIcon} = useAppData();

		const chatMessageClasses = classNames('chat__messages-preloader', {
			'chat__messages-preloader--visible': visible,
			'chat__messages-preloader--down': pinnedMessages?.length,
		});

		return <div className={chatMessageClasses}>{getAppIcon(appLoaderIcon)}</div>;
	};

export default ChatMessagesPreloader;
