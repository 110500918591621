import chatServices from 'store/chatService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import appService from 'store/appService';

import GreetingImg from 'assets/images/greeting2.png';
import classNames from 'classnames';
import agoraService from 'store/agoraService';
import roomService from 'store/roomService';

const ChatIntro: FunctionComponent = function ChatIntro() {
	const {greetingMessage} = useLocalObservable(() => chatServices);
	const {project} = useLocalObservable(() => appService);
	const {isScreenSharing, isFullScreenVideo} = useLocalObservable(() => agoraService);
	const {isFilterBySubscription} = useLocalObservable(() => roomService);

	const chatIntroClasses = classNames('chat__intro', {
		'chat__intro--screen-share': isScreenSharing && !isFullScreenVideo,
	});

	return (
		<div className={chatIntroClasses}>
			{project.includes('toto-') ? (
				<div className='chat__intro-wrap'>
					<img className='chat__intro-img' src={GreetingImg} alt='' />
				</div>
			) : (
				<div className='chat__intro-messages'>
					{[0, 1].map((i: number) => (
						<div className='chat__intro-message' key={i}>
							<div className='chat__intro-message-avatar'>
								<LoadingSkeleton circle enableAnimation={false} />
							</div>
							<div className='chat__intro-message-body'>
								<LoadingSkeleton count={2} enableAnimation={false} />
							</div>
						</div>
					))}
				</div>
			)}
			{isFilterBySubscription && (
				<div className='chat__intro-content'>
					<p className='chat__intro-text'>
						В чате пока нет сообщений от игроков, на которых вы подписаны
					</p>
				</div>
			)}
			{!isFilterBySubscription &&
				greetingMessage &&
				(greetingMessage.title || greetingMessage.text) && (
					<div className='chat__intro-content'>
						{greetingMessage.title && (
							<h3 className='chat__intro-title'>{greetingMessage.title}</h3>
						)}
						{greetingMessage.text && <p className='chat__intro-text'>{greetingMessage.text}</p>}
					</div>
				)}
		</div>
	);
};

export default observer(ChatIntro);
