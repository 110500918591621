/* eslint-disable jsx-a11y/label-has-associated-control */
import {FunctionComponent} from 'react';
import {v4 as uuidv4} from 'uuid';
import {ReactComponent as IcoCheckbox} from 'assets/svg/ico-checkbox.svg';
import './checkbox.scss';

interface ICheckboxProps {
	checked: boolean;
	text?: string;
	onChange?: () => void;
}

const Checkbox: FunctionComponent<ICheckboxProps> = function Checkbox({text, checked, onChange}) {
	const id = uuidv4();
	return (
		<div className='checkbox'>
			<input
				id={id}
				type='checkbox'
				className='checkbox__input'
				defaultChecked={checked}
				onChange={onChange}
			/>
			<label className='checkbox__label' htmlFor={id}>
				<span className='checkbox__label-ico'>
					<IcoCheckbox />
				</span>
				{text}
			</label>
		</div>
	);
};

export default Checkbox;
