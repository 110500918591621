import AppIconsName from 'models/enums/AppIconsName.enum';
import {AppIcon} from 'models/app';

let iconObj: {[key in AppIconsName]: AppIcon};

const transformationAppIcons = (data: AppIcon[]) => {
	data.forEach(icon => {
		if (typeof AppIconsName[icon.name] !== 'undefined') {
			const keyItem = icon.name;
			iconObj = {
				...iconObj,
				[keyItem]: {...icon},
			};
		}
	});

	return iconObj;
};

export {transformationAppIcons};
