import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import Button from 'components/hoc/Button';
import userService from 'store/userService';
import threadService from 'store/threadService';

interface IButtonBlockProps {
	message: Message;
}

const ButtonBlock: FunctionComponent<IButtonBlockProps> = function ButtonBlock({message}) {
	const {talker, user} = message;
	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {roomId, myTalker, setSubmenuMessage, blockedUsersForFilteredMessages} = useLocalObservable(
		() => (currentThreadId ? threadService : roomService)
	);
	const {userData} = useLocalObservable(() => userService);

	const {chatScreen} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {blockUser, unBlockUser, blockUserByUser} = useSubmenuActions();
	const {getAppIcon} = useAppData();

	const {IcoSubmenuUnblock, IcoSubmenuBlock} = appIcons;

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const isTalkerBanned = !!talker.bans.length;

	const checkForModerator = isTalkerBanned && isMyTalkerModer;
	const checkForUser =
		isTalkerBanned && user && user.id && blockedUsersForFilteredMessages.includes(user.id);

	const checkForAllRoles = checkForModerator || checkForUser;

	const blockUserBtnClasses = classNames('chat__submenu-btn ', {
		'chat__submenu-btn--warn': !checkForAllRoles,
		'chat__submenu-btn--success': checkForAllRoles,
	});

	const onBlockUserHandler = () => {
		if (roomId && user) {
			setSubmenuMessage(null);
			sendAnalytics('ban_pressed');
			if (isMyTalkerModer) {
				isTalkerBanned ? unBlockUser(user, roomId) : blockUser(user);
				return;
			}
			blockUserByUser(user);
		}
	};

	return (
		<Button className={blockUserBtnClasses} onClick={onBlockUserHandler}>
			{checkForAllRoles ? (
				<>
					{chatScreen.chatSubmenu.unblockUser}
					{getAppIcon(IcoSubmenuUnblock.pic, {className: 'chat__submenu-icon'})}
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.blockUser}
					{getAppIcon(IcoSubmenuBlock.pic, {className: 'chat__submenu-icon'})}
				</>
			)}
		</Button>
	);
};

export default observer(ButtonBlock);
