enum LanguageTag {
	ru = 'ru',
	en = 'en',
	hy = 'hy',
	sr = 'sr',
	de = 'de',
	es = 'es',
	ar = 'ar',
	ro = 'ro',
	tr = 'tr',
	hi = 'hi',
	pt = 'pt',
	zh = 'zh',
	ms = 'ms',
	bn = 'bn',
	th = 'th',
	fr = 'fr',
	fi = 'fi',
	is = 'is',
	ko = 'ko',
	pl = 'pl',
	az = 'az',
	uz = 'uz',
	uk = 'uk',
	ja = 'ja',
}

export default LanguageTag;
