import {PollType} from 'models/enums/Poll.enum';
import {PollMessageData, PollOption} from 'models/poll';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import './pollMessage.scss';
import {ReactComponent as IcoCorrectMarker} from 'assets/svg/ico-correct-marker.svg';
import {ReactComponent as IcoIncorrectMarker} from 'assets/svg/ico-incorrect-marker.svg';
import {ReactComponent as IcoDefaultMarker} from 'assets/svg/ico-default-marker.svg';

interface IPollMessageProps {
	pollMessageData: PollMessageData;
}

const PollMessage: FunctionComponent<IPollMessageProps> = function PollMessage({pollMessageData}) {
	const {poll: pollTranslate} = useL10n();
	const {poll, pollOptionsChosen} = pollMessageData;

	if (!poll || !pollOptionsChosen?.length) {
		return null;
	}

	const {text, type} = poll;
	const isQuiz = type === PollType.QUIZ;
	const correct = isQuiz && pollOptionsChosen.length === 1 && pollOptionsChosen[0].isRight;
	const incorrect = isQuiz && pollOptionsChosen.length === 1 && !pollOptionsChosen[0].isRight;
	const isMultiple = isQuiz && pollOptionsChosen.length > 1;

	const pollMessageClasses = classNames('poll-message', {
		'poll-message--quiz': isQuiz,
		'poll-message--quiz-multiple': isMultiple,
		'poll-message--correct': correct,
		'poll-message--not-correct': incorrect,
	});

	const pollMessageItemClasses = (option: PollOption) => {
		let modifier = '';
		if (isQuiz) {
			if (option.isRight) {
				modifier = 'poll-message__item--correct';
			} else {
				modifier = 'poll-message__item--not-correct';
			}
		}
		return `poll-message__item ${modifier}`;
	};

	const renderPollOptionChosen = (option: PollOption) => (
		<li className={pollMessageItemClasses(option)} key={option.id}>
			{option.pic && (
				<div className='poll-message__item-img' style={{backgroundImage: `url(${option.pic})`}} />
			)}
			<div className='poll-message__item-content'>
				<p className='poll-message__item-title'>{option.text}</p>
				{(correct || (isMultiple && option.isRight)) && (
					<IcoCorrectMarker className='poll-message__item-ico poll-message__item-ico--correct' />
				)}
				{(incorrect || (isMultiple && !option.isRight)) && (
					<IcoIncorrectMarker className='poll-message__item-ico poll-message__item-ico--incorrect' />
				)}
				{!isQuiz && (
					<IcoDefaultMarker className='poll-message__item-ico poll-message__item-ico--default' />
				)}
			</div>
		</li>
	);

	return (
		<div className={pollMessageClasses}>
			<div className='poll-message__container'>
				<p className='poll-message__title'>{pollTranslate.message.title}</p>
				<p className='poll-message__question'>{text}</p>
				<ul className='poll-message__items'>{pollOptionsChosen.map(renderPollOptionChosen)}</ul>
			</div>
		</div>
	);
};

export default observer(PollMessage);
