/* eslint-disable max-lines */
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Acord',
		subTitle: 'Acord de licență și Politică de confidențialitate',
		textFirst: 'Accept condițiile',
		textSecond:
			'și îmi dau consimțământul pentru prelucrarea datelor cu caracter personal în condițiile impuse de',
		license: 'Contractul de licență și',
		policy: 'Politica de confidențialitate.',
	},
	controlPanel: {
		streamEnded: 'Transmisiunea s-a încheiat',
		banAlert: 'Nu puteți scrie în chat',
	},
	accountDeletedScreen: {
		title: 'Profil șters',
		text: (date: string) => `Vă puteți restabili profilul până la ${date}`,
	},
	nameScreen: {
		title: 'Nume în chat',
		inputPlaceholder: 'Introduceți numele',
		nameError: 'Nume invalid',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Alegeți altă opțiune:',
	},
	settingsScreen: {
		title: 'Setări',
		personal: 'Setări personale',
		room: 'Setările sesiunii',
		profileBtn: 'Profil',
		languageBtn: 'Limbă',
		blockedBtn: 'Blocări',
		rules: 'Regulile chatului',
		deleteProfile: 'Ștergeți profilul',
		slowModeBtn: 'Modul lent',
		agora: {
			microphone: 'Microfon',
			playbackDevice: 'Sursa de sunet',
		},
	},
	languageScreen: {
		title: 'Limba și interfața',
		messagesTranslate: 'Traducere mesaje',
		translateMessagesMode: 'Traduceți mesajele în chat',
		translateMessagesTo: 'Traduceți mesajele în:',
	},
	rulesScreen: {
		title: 'Regulile chatului',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Alegeți un alt avatar',
		chatNameLabel: 'Nume în chat',
		changeName: 'faceți modificări',
	},
	blockedScreen: {
		title: 'Blocări',
		emptyListText: 'Niciun utilizator blocat',
	},
	chatScreen: {
		inputPlaceholder: 'Mesaj...',
		cantWrite: 'Nu puteți scrie în chat',
		chatSubmenu: {
			hideMessage: 'Ascundeți mesajele',
			showMessage: 'Afișați mesajele',
			removeFromSpeakers: 'Ștergeți vorbitorul',
			setSpeaker: 'Adăugați în calitate de vorbitor',
			hideOnlyMessage: 'Ascundeți mesajul',
			showOnlyMessage: 'Afișați mesajul',
			reportMessage: 'Raportați mesajul',
			reportUser: 'Raportați utilizatorul',
			muteSpeaker: 'Porniți microfonul',
			blockUser: 'Blocați',
			unblockUser: 'Deblocați',
			reply: 'Răspundeți',
			copy: 'Copiați',
			edit: 'Modificați',
			delete: 'Ștergeți',
			pinMessage: 'Fixați',
			unpinMessage: 'Anulați fixarea',
			replyInThread: 'Răspunde în fir',
		},
		actionMessage: {
			editing: 'Editare',
			reply: 'Răspundeți',
		},
		status: {
			top: 'Top',
		},
		translating: 'Traducere...',
	},
	chatUsersScreen: {
		title: 'Participanți',
		edited: 'Au fost făcute modificări',
		hidden: 'ascuns',
		unreadMessages: 'Mesaje necitite',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Dezactivați sunetul participantului',
				subtitle: 'Închideți microfonul',
			},
			setSpeaker: {
				title: 'Ștergeți vorbitorul',
				subtitle: 'Doar chat scris',
			},
			removeFromSpeaker: {
				title: 'Adăugați în calitate de vorbitor',
				subtitle: 'Utilizatorul poate trimite mesaje vocale',
			},
			showMessages: {
				title: 'Afișați mesajele',
				subtitle: 'Vizibil doar pentru administrator',
			},
			hideMessages: {
				title: 'Ascundeți mesajele',
				subtitle: 'Vizibil doar pentru administrator',
			},
			blockUser: {
				title: 'Blocați',
				subtitle: 'Adăugați pe lista neagră',
			},
			unblockUser: {
				title: 'Deblocați',
				subtitle: 'Deblocați utilizatorul',
			},
			blockUserByUser: {
				title: 'Blocați',
				subtitle: 'Ascundeți toate mesajele utilizatorului',
			},
			unblockUserByUser: {
				title: 'Deblocați',
				subtitle: 'Afișați toate mesajele utilizatorului',
			},
			reportUser: {
				title: 'Raportați utilizatorul',
				subtitle: 'Reclamația va fi trimisă anonim',
			},
		},
		days: {
			today: 'Azi',
			yesterday: 'Ieri',
		},
		chatCreated: 'Chat creat,',
	},
	alerts: {
		btns: {
			unblock: 'Deblocați',
			block: 'Blocați',
			send: 'Trimiteți',
			sendAndBlock: 'Trimiteți și blocați',
			show: 'Afișați',
			showAll: 'Afișați tot',
			cancel: 'Anulare',
			yes: 'Da',
			hide: 'Ascundeți selecția',
			hideAll: 'Ascundeți tot',
			make: 'Faceți',
			remove: 'Eliminați',
			delete: 'Ștergeți',
			deleteAccount: 'Ștergeți profil',
			close: 'Închideți',
			pin: 'Fixați',
			unpin: 'Anulați fixarea',
			edit: 'Editați',
			end: 'Încheiați',
			endAndShare: 'Încheiați și distribuiți',
			reset: 'Resetați',
			rules: 'Regulile chatului',
			contactus: 'Scrieți-ne',
		},
		pinMessage: {
			title: 'Fixați un mesaj?',
		},
		unPinMessage: {
			title: 'Anulați fixarea unui mesaj?',
		},
		accountRestored: {
			title: 'Profilul a fost reactivat',
		},
		closeApp: {
			title: 'Chiar vreți să vă deconectați?',
		},
		removeMessage: {
			title: 'Ștergeți mesajul?',
		},
		report: {
			title: 'Faceți o reclamație?',
			subtitle:
				'De asemenea, puteți bloca un utilizator pentru a-l împiedica să vadă postările celuilalt.',
		},
		banUser: {
			title: (name: string) => `Blocați pe ${name}?`,
			subtitle: (text: string) => `Utilizatorul va fi blocat pentru ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Deblocați ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Afișați mesajele de la ${name}?`,
			subTitle: 'Ceilalți utilizatori vor putea vedea comentariile acestui utilizator',
		},
		hideUserMessages: {
			title: (name: string) => `Ascundeți mesajele de ${name}?`,
			subTitle: 'Ceilalți utilizatori nu vor mai putea vedea comentariile acestui utilizator',
		},
		showUserMessage: {
			title: (name: string) => `Afișați mesajul de la ${name}?`,
			subTitle: 'Ceilalți utilizatori vor putea vedea acest mesaj',
		},
		hideUserMessage: {
			title: (name: string) => `Ascundeți mesajul de la ${name}?`,
			subTitle: 'Ceilalți utilizatori nu vor mai putea vedea acest mesaj',
		},
		blockUserInRoom: {
			title: (name: string) => `Blocați pe ${name}?`,
			subTitle: 'Utilizatorul nu va putea participa la această sesiune',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Deblocați ${name}?`,
			subTitle: 'Utilizatorul va putea participa la această sesiune',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Deblocați ${name}?`,
			subTitle: 'Nu veți mai vedea mesajele unul celuilalt',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Adăugați ${name} în calitate de vorbitor?`,
			unTitle: (name: string) => `Îl eliminați pe ${name} ca vorbitor?`,
		},
		banInApp: {
			title: 'Administratorul v-a blocat',
			for: 'pentru',
			subtitle: (bannedUntil: string) => `Blocarea durează până la ${bannedUntil}`,
			permanent: 'Blocare pentru totdeauna',
		},
		banInAppBadNickname: {
			title: 'Administratorul v-a blocat pentru numele de utilizator invalid',
			subtitle: 'Accesați profilul, schimbați-vă numele și reintrați în chat',
		},
		deleteAccount: {
			title: 'Ștergerea profilului',
			subTitle: 'Toate datele vor fi șterse. Veți putea reactiva profilul în termen de 30 de zile.',
		},
		pollEdit: {
			title: 'Editați sondajul?',
			subTitle: 'Răspunsurile utilizatorilor vor fi resetate',
		},
		pollReset: {
			title: 'Resetați?',
			subTitle: 'Sondajul nu va fi salvat',
		},
		pollEnd: {
			title: 'Sigur vreți să finalizați sondajul?',
		},
		pollDelete: {
			title: 'Ștergeți sondajul?',
		},
		agoraCheck: {
			updateIOSText: 'Actualizați versiunea iOS pentru a utiliza toate instrumentele aplicației.',
			allowAccess: {
				title: 'Vrei să spui ceva?',
				text: 'Vă rugăm să deschideți setările aplicației și să permiteți accesul la microfon și difuzor.',
			},
			microphoneAndSpeakerText:
				'Utilizarea microfonului și a difuzorului este disponibilă începând cu versiunea 14.3.',
			microphoneText:
				'Utilizarea microfonului și a difuzorului este disponibilă începând cu versiunea 14.3.',
			btn: 'Okay!',
		},
	},
	eventScreen: {
		title: 'Eveniment programat',
		titleSoon: 'Transmisiunea va începe în curând',
		subtitle: 'Chat-ul va fi disponibil în curând; vă rugăm să așteptați.',
		days: 'zile',
		hours: 'ore',
		minutes: 'minute',
		seconds: 'secunde',
		startTitle: 'Începeți',
	},
	toasts: {
		reciveComplaint: 'Am primit reclamația dvs',
		setSpeakerRoleForUser: 'Utilizator a fost desemnat vorbitor',
		unSpeakerRoleForUser: 'Utilizatorul a fost șters din lista de vorbitori',
		messagesShown: 'Mesaje afișate',
		messagesHidden: 'Mesaje ascunse',
		messageShown: 'Mesaj afișat',
		messageHidden: 'Mesaj ascuns',
		userBlocked: 'Utilizator blocat',
		userUnBlocked: 'Utilizator deblocat',
		youAreSpeaker: 'Sunteți vorbitor! Spuneți ceva',
		youAreNotSpeaker: 'Administratorul v-a șters din lista de vorbitori',
		userIsSpeaker: (name: string) => `${name} este acum vorbitor`,
		userNotSpeaker: (name: string) => `${name} nu mai este vorbitor`,
		yourUserNameInChat: (name: string) => `Numele dvs. în chat este ${name}`,
		messageRemoved: 'Mesaj șters',
		messageCopied: 'Text copiat',
		obscenities: 'Încercați să parafrazați',
		requestSent: 'Solicitarea a fost trimisă',
		requestDecline: 'Solicitare retrasă de administrator',
		youareblocked: (text: string) => `Blocare pentru ${text}. Nu puteți scrie în chat`,
		youareunblocked: 'Deblocare. Puteți scrie mesaje în chat',
		youAreModerator: 'Sunteți moderator!',
		youAreNoModerator: 'Nu mai sunteți moderator',
		micMuted: 'Microfonul este dezactivat',
		noInet: 'Fără conexiune la internet',
		messagePinned: 'Postarea a fost fixată',
		messageUnpinned: 'Fixarea postării a fost anulată',
		pollPublished: 'Sondaj publicat',
		pollPublishedError: 'Eroare! Încercați mai târziu',
		pollAddPic: 'Adăugați o fotografie',
		pollAddPicError: 'Alegeți o altă imagine',
		pollDeleted: 'Sondajul a fost șters',
		pollEnded: 'Sondaj finalizat',
		sendStickerWhenSlowModeIsOn: (time: string) =>
			`Așteptați ${time} pentru ca stickerull să fie trimis`,
		linkProhibited: 'Nu puteți trimite link-uri în chat.',
		phoneProhibited: 'Nu puteți trimite numere de telefon în chat.',
		profileHidden: 'Utilizatorul și-a ascuns profilul',
		imageSizeWarning: 'Puteți încărca o imagine de până la 10 MB',
		imageBlocked: 'Moderatorul a blocat trimiterea imaginii',
		streamKeyCopied: 'Cheia de difuzare a fost copiată',
	},
	btns: {
		acceptandgo: 'Acceptați și continuați',
		saveandgo: 'Salvați si continuați',
		save: 'Salvați',
		unblock: 'Deblocați',
		block: 'Blocați',
		restoreAccount: 'Reactivați profilul',
		close: 'Închideți',
	},
	coopyrights: 'Produs de Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Raportați un mesaj' : 'Raportați o încălcare a unei reguli',
			subtitle: 'Reclamațiile sunt trimise anonim',
			btns: {
				violence: 'Injurii, amenințări',
				provocations: 'Provocări',
				personalDetails: 'Informații cu caracter personal',
				flood: 'Fluviu',
				spam: 'Spam',
				fraudBegging: 'Fraude, cerșit',
				other: 'Altele',
			},
		},
		ban: {
			title: 'Blocați utilizatorul',
			subtitle: 'Specificați motivul blocării',
			btns: {
				insults: 'Injurii, amenințări',
				personal: 'Răspândirea datelor personale',
				flood: 'Potop',
				links: 'Link-uri către servicii terțe',
				violence: 'Amenințări cu violență',
				fraud: 'Tentative de fraudă și cerșetorie',
				nickname: 'Nume nevalid',
				politicial: 'Provocări politice, sexualizate și alte provocări',
				repeated: 'Încălcare repetată a regulilor',
				auto: 'Reclamațiile utilizatorilor chatului',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Nu aveți vorbitori în așteptare',
			handsUp: 'Ridicați mâna',
			waiting: 'Vorbitori în așteptare',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Dați răspunsul corect',
				typeQuizMultiple: 'Verificați opțiunile corecte',
				typePoll: 'Împărtășiți-vă părerea',
				typePollMultiple: 'Puteți selecta mai multe opțiuni',
				default: 'Sondaj public',
			},
			btns: {
				reply: 'Răspundeți',
				vote: 'Votare',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Test',
				typePoll: 'Mai multe răspunsuri',
				default: 'Sondaj public',
			},
			btns: {
				edit: 'Editați sondajul',
				end: 'Finalizare',
			},
		},
		pollCreated: {
			titles: {
				created: 'Creare sondaj',
				typeQuiz: 'Test',
				typePoll: 'Mai multe răspunsuri',
				default: 'Sondaj public',
			},
		},
		changeName: {
			title: 'Schimbați-vă numele în chat',
			subtitle: 'Sunteți blocat pentru numele de utilizator nevalid',
		},
		temporaryAgreement: {
			title: 'Regulile chatului',
			rules: {
				bePolite: {
					title: 'Fiți politicoși',
					text: 'Limbajul ostil și obscen este interzis în chat.',
				},
				beAttentive: {
					title: 'Atenție',
					text: 'Nu distribuiți și nu cereți altora informații cu caracter personal.',
				},
				notAdvertise: {
					title: 'Nu faceți publicitate',
					text: 'Este mai bine să vă împărtășiți părerea sau emoțiile.',
				},
			},
			btns: {
				allRules: 'Toate regulile',
				acceptAndContinue: 'Acceptați și continuați',
			},
		},
		slowMode: {
			title: 'Modul lent',
			text: 'Participanții nu vor putea trimite mesaje în chat mai des decât în perioada de timp selectată',
			enable: 'Activați modul lent',
			disable: 'Dezactivați modul lent',
			sendTimeout: 'Trimitere timp limită',
		},
		slowModeTimeout: {
			title: 'Trimitere timp limită',
		},
		chatBot: {
			title: 'Folosirea chat-bot-ului',
			rules: [
				{
					title: 'Inteligența artificială',
					text: 'Botul simulează comunicarea cu oamenii și oferă informații pe baza cunoștințelor sale. Cu toate acestea, rețineți că răspunsurile sale pot fi limitate și nu înlocuiesc o persoană.',
				},
				{
					title: 'Botul poate face greșeli',
					text: 'Botul nu poate garanta acuratețea și fiabilitatea informațiilor.',
				},
			],
			btns: {
				close: 'Închideți',
			},
		},
		shareBet: {
			title: 'Distribuiți',
			noBetsTitle: 'Nu au fost găsite pariuri',
			noBetsText: 'Puteți partaja pariurile care au fost făcute în 14 zile',
			btns: {
				share: 'Distribuiți pariul',
				copy: 'Repetați pariul',
			},
			status: {
				null: 'În joc',
				win: 'Câștig',
				lose: 'Pierdere',
			},
			betTypeV1: {
				express: 'Multiplu',
				single: 'Simplu',
			},
			betTypeV2: {
				single: 'Simplu',
				multi: 'Multiplu',
				system: 'System',
			},
			totalFactor: 'Coef',
			bet: 'pariu',
			showMore: 'Afișați mai multe',
		},
		shareGamble: {
			noGamblesText:
				'Nu aveți încă câștiguri de publicat. Continuați să jucați, iar următorul câștig va apărea aici pentru a-l putea împărtăși cu alți jucători!',
		},
		record: {
			text: 'Înregistrarea transmisiei este în curs de procesare...',
		},
		translate: {
			title: 'Traducere mesaje',
			chooseLanguage: 'Alegeți limba',
			descr: 'Mesajele din chat vor fi traduse automat în limba pe care o selectați',
			btn: 'Traduce',
		},

		streamSettings: {
			title: 'Setări de transmisie',
			description:
				'Descărcați și instalați un codificator video. Introduceți cheia de transmisie în setările acestuia',
			shareScreen: {
				title: 'Partajare ecran',
				description: 'Nu sunt necesare setări speciale',
			},
			rtmp: {
				title: 'Sursă externă de transmisie',
				description: 'Utilizați un program suplimentar pentru a transmite fluxul',
			},
			streamKey: 'Cheie de transmisie',
			showStreamKey: 'Afișează',
			hideStreamKey: 'Ascunde',
			btns: {
				continue: 'Continuă',
				close: 'Închide',
				ok: 'Gata',
			},
		},
	},
	pinnedMessage: {
		title: 'Fixați mesajul',
	},
	errorPage: {
		error: 'Eroare',
		types: [
			// errorNumber 0
			{
				title: 'Nu vă recunoaștem',
				text: 'Încercați să reintrați în chat',
			},
			// errorNumber 1
			{
				title: 'Nu găsim chatul',
				text: 'Încercați să vă reconectați',
			},
			// errorNumber 2
			{
				title: 'Nu se poate încărca chatul',
				text: 'Încercați să reporniți',
			},
			// errorNumber 3
			{
				title: 'Nu vă recunoaștem',
				text: 'Chatul în modul incognito nu este disponibil',
			},
			// errorNumber 4
			{
				title: 'Nume de utilizator nevalid',
				text: 'Accesați profilul, schimbați-vă numele și intrați din nou în chat.',
			},
		],
	},
	tooltips: {
		emotions: 'Țineți apăsat pentru a schimba',
		badInet: 'Conexiune slabă la rețea',
		blockedUsers: 'ПPuteți vizualiza lista utilizatorilor <br>blocați aici.',
		waitingSpeakers: 'Unii dintre participanți vor să vorbească.',
		wantSpeak: 'Faceți clic pentru a solicita dreptul de a vorbi.',
		slowMode: {
			header: (time: string) =>
				`Modul lent este activat. Utilizatorii pot trimite un mesaj <br>o data la ${time}. Această regulă nu se aplică moderatorilor.`,
			btnSendMessage: (time: string) => `Puteți trimite mesaje <br> o dată la ${time}.`,
		},
		shareBet: 'Distribuiți pariul în chat',
		translate: 'Traduce mesajele în altă limbă',
	},
	poll: {
		toggleSubmenu: {
			create: 'Creare sondaj',
			edit: 'Editare sondaj',
			show: 'Afișare sondajul',
			end: 'Sondaj finalizat',
		},
		tooltip: {
			btn: 'Votare',
		},
		settings: {
			title: 'Setări',
			titleAdditionally: 'În plus',
			switches: {
				quizMode: 'Modul test',
				multipleChoice: 'Alegere multiplă',
				withPhoto: 'Sondaj cu poză',
			},
		},
		question: {
			title: 'Întrebare',
			inputPlaceholder: 'Introduceți o întrebare',
		},
		message: {
			title: 'Ați votat',
		},
		answers: {
			title: 'Opțiuni de răspuns',
			warning: 'Puteți adăuga până la 5 răspunsuri',
		},
		answer: {
			tooltip: 'Alegeți răspunsul corect',
			inputPlaceholder: 'Răspuns',
		},
		results: {
			text: 'Rezultatele votului',
			votes: 'voturi',
			// words: ['vot', 'vot', 'voturi'],
		},
		btns: {
			addQuestion: 'Adăugați o întrebare',
			addAnswers: 'Adăugați răspunsuri',
			addAnswer: 'Adăugați un răspuns',
			publish: 'Publicare',
			vote: 'Vot',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Estimare',
				continue: 'Continuare',
				checkConnection: 'Verificați conexiunea',
				close: 'Închideți',
			},
			quality: {
				title: 'Evaluați calitatea sunetului',
			},
			reason: {
				title: 'Ceva n-a mers bine?',
			},
			connection: {
				title: 'Testați calitatea conexiunii',
				text: 'Verificarea nu va dura mai mult de un minut și vă va ajuta să găsiți cauza problemei.',
			},
			statistics: {
				title: 'Colectarea datelor...',
			},
			done: {
				title: 'Mulțumim!',
				text: 'Vom studia ce nu a mers bine și ne vom asigura că vom găsi o soluție.',
			},
		},
	},
	chatBot: {
		message: 'Întrebați botul orice...',
		warnings: {
			disabled: 'M-au deconectat',
			overloaded: 'Suntem supraîncărcați',
		},
	},
	stickers: 'Stickere',
	audioReason: {
		nothear: 'Nu s-a auzit nimic',
		connection: 'Sunetul a fost întrerupt',
		microphone: 'Nu am putut folosi microfonul',
	},
	copybetting: {
		orderStatus: {
			won: 'Câștigat',
			lost: 'Pierdut',
			new: 'Nou',
			pending: 'În așteptare',
			cashedOut: 'Cash-out efectuat',
			boreDraw: 'Remiză fără goluri',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet nou',
			megaBetCanceledByOperator: 'MegaBet anulat de operator',
			megaBetCanceledByPlayer: 'MegaBet anulat de jucător',
			megaBetCanceledBySystem: 'MegaBet anulat de sistem',
			betGamesPending: 'BetGames în așteptare',
			betGamesWon: 'BetGames câștigat',
			betGamesLost: 'BetGames pierdut',
			paid: 'Plătit',
			initialWin: 'Câștig inițial',
			autoBetCanceledBySystem: 'AutoBet anulat de sistem',
			autoBetCanceledByPlayer: 'AutoBet anulat de jucător',
			autobetPending: 'AutoBet în așteptare',
			goalAhead: 'Gol în avans',
			canceled: 'Anulat',
			return: 'Returnare',
		},
		stakeStatus: {
			pending: 'În așteptare',
			won: 'Câștigat',
			lost: 'Pierdut',
			return: 'Returnare',
			lostReturn: 'Pierdut | Returnare',
			wonReturn: 'Câștigat | Returnare',
			canceled: 'Anulat',
			initialReturn: 'Inițial Returnare',
			pendingInitialReturn: 'În așteptare | Inițial Returnare',
			wonInitialReturn: 'Câștigat | Inițial Returnare',
		},
	},
	screenShare: {
		title: 'Demonstrație pe ecran',
		stopSharing: 'Oprire demonstrație pe ecran',
		modal: {
			title: 'Partajarea ecranului este disponibilă doar de pe un computer.',
			text: 'Deschideți chatul pe computerul dumneavoastră și faceți clic pe butonul „Demonstrație pe ecran” în colțul din dreapta sus.',
			btn: 'Copiați linkul de invitație.',
		},
	},
	threads: {
		title: 'Fir',
		back: 'Înapoi',
		replies: ['Răspuns', 'Răspunsuri', 'Răspunsuri'],
	},
	attach: {
		submenu: {
			sendImage: 'Trimite imagine',
			shareBet: 'Împărtășește pariul',
			shareGamble: 'Împărtășește câștigurile',
		},
		images: ['imagine', 'imagini', 'imagini'],
		addCaption: 'Adăugați legendă',
	},
} as unknown as Dictionary;
