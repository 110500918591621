import {apiPath} from 'constants/api';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {PartnerAuthData} from 'models/responses';
import axios from 'axios';
import checkResponse from 'utils/checkResponse';
import Log from 'utils/log';

export default class PartnerService {
	static auth = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<PartnerAuthData>(apiPath.partnerAuth(apiKey));
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Auth partner error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
