/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'اتفاق',
		subTitle: 'اتفاقية الترخيص وسياسة الخصوصية',
		textFirst: 'أوافق على شروط',
		textSecond: 'وأعطي موافقتي على معالجة البيانات الشخصية بموجب الشروط،',
		license: 'في اتفاقية الترخيص',
		policy: 'وسياسة الخصوصية',
	},
	controlPanel: {
		streamEnded: 'انتهى البث',
		banAlert: 'لا يمكنك الكتابة في الدردشة',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'لملصقات' : 'الرموز التعبيرية',
	},
	accountDeletedScreen: {
		title: 'تم حذف ملفك الشخصي',
		text: (date: string) => `يمكنك استعادة ملفك الشخصي حتى ${date}`,
	},
	nameScreen: {
		title: 'اسمك في الدردشة',
		inputPlaceholder: 'أدخل الاسم',
		nameError: 'الاسم غير مقبول',
	},
	avatarScreen: {
		title: 'الصورة الرمزية',
		label: 'اختر أخرى:',
	},
	settingsScreen: {
		title: 'الإعدادات',
		personal: 'الإعدادات الشخصية',
		room: 'إعدادات الغرفة',
		profileBtn: 'الملف الشخصي',
		languageBtn: 'اللغة',
		blockedBtn: 'المحظورون',
		rules: 'قواعد الدردشة',
		deleteProfile: 'حذف الملف الشخصي',
		slowModeBtn: 'الوضع البطيء',
		agora: {
			microphone: 'الميكروفون',
			playbackDevice: 'مصدر الصوت',
		},
	},
	languageScreen: {
		title: 'اللغة والواجهة',
		messagesTranslate: 'ترجمة الرسائل',
		translateMessagesMode: 'ترجمة رسائل الدردشة',
		translateMessagesTo: 'ترجمة الرسائل إلى:',
	},
	rulesScreen: {
		title: 'قواعد الدردشة',
	},
	profileScreen: {
		title: 'الملف الشخصي',
		selectAnotherPic: 'اختيار صورة رمزية أخرى',
		chatNameLabel: 'اسمك في الدردشة',
		changeName: 'تغيير',
	},
	blockedScreen: {
		title: 'المحظورون',
		emptyListText: 'لا يوجد مستخدمين محظورين',
	},
	chatScreen: {
		inputPlaceholder: 'رسالة...',
		cantWrite: 'لا يمكنك الكتابة في الدردشة',
		chatSubmenu: {
			hideMessage: 'إخفاء الرسائل',
			showMessage: 'إظهار الرسائل',
			removeFromSpeakers: 'حذف من بين المتحدثين',
			setSpeaker: 'اجعله متحدثاً',
			hideOnlyMessage: 'إخفاء الرسالة',
			showOnlyMessage: 'إظهار الرسالة',
			reportMessage: 'الإبلاغ عن الرسالة',
			reportUser: 'الإبلاغ عن المستخدم',
			muteSpeaker: 'إغلاق المكروفون',
			blockUser: 'حظر',
			unblockUser: 'إلغاء الحظر',
			reply: 'رد',
			copy: 'نسخ',
			edit: 'تعديل',
			delete: 'حذف',
			pinMessage: 'تثبيت',
			unpinMessage: 'إلغاء التثبيت',
			replyInThread: 'الرد في الموضوع',
		},
		actionMessage: {
			editing: 'تعديل',
			reply: 'رد',
		},
		status: {
			top: 'التفضيل ',
		},
		translating: 'يترجم...',
	},
	chatUsersScreen: {
		title: 'المشاركون',
		edited: 'تم التغيير',
		hidden: 'مخفي',
		unreadMessages: 'رسائل غير مقروءة',
		usersSubmenu: {
			muteSpeaker: {
				title: 'كتم صوت المشارك',
				subtitle: 'إغلاق المكروفون',
			},
			setSpeaker: {
				title: 'حذف من المتحدثين',
				subtitle: 'الدردشة النصية فقط',
			},
			removeFromSpeaker: {
				title: 'اجعله متحدثاً',
				subtitle: 'سيتمكن المستخدم من التكلم بصوته',
			},
			showMessages: {
				title: 'إظهار الرسائل',
				subtitle: 'لا يرى إلا الإداري',
			},
			hideMessages: {
				title: 'إخفاء الرسائل',
				subtitle: 'لا يرى إلا الإداري',
			},
			blockUser: {
				title: 'حظر',
				subtitle: 'إدراج في القائمة السوداء',
			},
			unblockUser: {
				title: 'إلغاء الحظر',
				subtitle: 'قم بإلغاء حظر المستخدم',
			},
			blockUserByUser: {
				title: 'حظر',
				subtitle: 'إخفاء كل رسائل المستخدم',
			},
			unblockUserByUser: {
				title: 'إلغاء الحظر',
				subtitle: 'إظهار كل رسائل المستخدم',
			},
			reportUser: {
				title: 'الإبلاغ عن المستخدم',
				subtitle: 'سيتم إرسال البلاغ دون الكشف عن هوية صاحبه',
			},
		},
		days: {
			today: 'ليوم',
			yesterday: 'أمس',
		},
		chatCreated: 'تم إنشاء غرفة الدردشة',
	},
	alerts: {
		btns: {
			unblock: 'إلغاء الحظر',
			block: 'حظر',
			send: 'إرسال',
			sendAndBlock: 'إرسال وحظر',
			show: 'إظهار',
			showAll: 'إظهار الكل',
			cancel: 'إلغاء',
			yes: 'نعم',
			hide: 'إخفاء المحدد',
			hideAll: 'إخفاء الكل',
			make: 'فعل',
			remove: 'إزالة',
			delete: 'حذف',
			deleteAccount: 'حذف الملف الشخصي',
			close: 'غلاق',
			pin: 'تثبيت',
			unpin: 'إلغاء التثبيت',
			edit: 'تعديل',
			end: 'إكمال',
			endAndShare: 'إكمال ومشاركة',
			reset: 'إعادة ضبط',
			rules: 'قواعد الدردشة',
			contactus: 'اكتب لنا',
		},
		pinMessage: {
			title: 'ثبت الرسالة؟',
		},
		unPinMessage: {
			title: 'إلغاء تثبيت الرسالة؟',
		},
		accountRestored: {
			title: 'تمت استعادة الملف الشخصي',
		},
		closeApp: {
			title: 'هل تريد الخروج فعلاً؟',
		},
		removeMessage: {
			title: 'حذف الرسالة؟',
		},
		report: {
			title: 'أرسل الإبلاغ؟',
			subtitle: 'يجوز لك أيضاً حظر المستخدم كي لا تريا رسائل بضعكما.',
		},
		banUser: {
			title: (name: string) => `حظر ${name}؟`,
			subtitle: (text: string) => `سيتم حظر المستخدم بسبب ${text}`,
		},
		unBanUser: {
			title: (name: string) => `إلغاء حظر ${name}؟?`,
		},
		showUserMessages: {
			title: (name: string) => `ظهار الرسائل من ${name}؟?`,
			subTitle: 'سيستطيع المستخدمون الآخرون رؤية رسائل هذا المستخدم',
		},
		hideUserMessages: {
			title: (name: string) => `إخفاء الرسائل من ${name}؟`,
			subTitle: 'لن يستطيع المستخدمون الآخرون من الآن رؤية رسائل هذا المستخدم',
		},
		showUserMessage: {
			title: (name: string) => `إخفاء الرسائل من ${name}؟?`,
			subTitle: 'لن يستطيع المستخدمون الآخرون من الآن رؤية رسائل هذا المستخدم',
		},
		hideUserMessage: {
			title: (name: string) => `إظهار الرسالة من ${name}؟?`,
			subTitle: 'سيستطيع المستخدمون الآخرون رؤية هذه الرسالة',
		},
		blockUserInRoom: {
			title: (name: string) => `حظر ${name}؟`,
			subTitle: 'لن يستطيع المستخدم المشاركة في هذه الغرفة',
		},
		unBlockUserInRoom: {
			title: (name: string) => `إلغاء حظر ${name}؟`,
			subTitle: 'سيستطيع المستخدم المشاركة في هذه الغرفة',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `حظر ${name}؟?`,
			subTitle: 'لن تريا رسائل بعضكما',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `اجعل ${name} متحدثاً؟`,
			unTitle: (name: string) => `هل تريد إزالة ${name} من المتحدثين؟`,
		},
		banInApp: {
			title: 'لقد قام الإداري بحظرك بسبب',
			for: '',
			subtitle: (bannedUntil: string) => `أنت محظور لغاية' ${bannedUntil}`,
			permanent: 'أنت محظور إلى الأبد',
		},
		banInAppBadNickname: {
			title: 'لقد قام الإداري بحظرك بسبب الاسم غير المقبول',
			subtitle: 'ادخل إلى ملفك الشخصي وغير اسمك ثم ادخل إلى الدردشة من جديد',
		},
		deleteAccount: {
			title: 'حذف الملف الشخصي',
			subTitle: 'ستُحذَف كل البيانات. يمكنك استعادة ملفك الشخصي خلال 30 يوماً.',
		},
		pollEdit: {
			title: 'تعديل الاستطلاع؟',
			subTitle: 'ستلغى ردود المستخدمين',
		},
		pollReset: {
			title: 'إعادة ضبط؟',
			subTitle: 'لن يتم حفظ الاستطلاع',
		},
		pollEnd: {
			title: 'هل تريد فعلاً إنهاء الاستطلاع؟',
		},
		pollDelete: {
			title: 'حذف الاستطلاع؟',
		},
		agoraCheck: {
			updateIOSText: 'قم بتحديث إصدار iOS لاستخدام جميع أدوات التطبيق.',
			allowAccess: {
				title: 'هل تريد قول شيء؟',
				text: 'يرجى فتح إعدادات التطبيق والسماح بالوصول إلى الميكروفون ومكبر الصوت.',
			},
			microphoneAndSpeakerText: 'استخدام الميكروفون ومكبر الصوت متاح منذ الإصدار 14.3.',
			microphoneText: 'استخدام الميكروفون ومكبر الصوت متاح منذ الإصدار 14.3.',
			btn: 'نعم!',
		},
	},
	eventScreen: {
		title: 'الحدث المقرر',
		titleSoon: 'سيبدأ البث قريباً',
		subtitle: 'الدردشة ستكون متاحة قريبًا. يرجى الانتظار.',
		days: 'أيام',
		hours: 'ساعات',
		minutes: 'دقائق',
		seconds: 'ثوانٍ',
		startTitle: 'البداية',
	},
	toasts: {
		reciveComplaint: 'لقد استلمنا شكواك',
		setSpeakerRoleForUser: 'تم تعيين المستخدم متحدثاً',
		unSpeakerRoleForUser: 'تمت إزالة المستخدم من المتحدثين',
		messagesShown: 'تم إظهار الرسائل',
		messagesHidden: 'تم إظهار الرسالة',
		messageShown: 'تم إظهار الرسالة',
		messageHidden: 'تم إخفاء الرسالة',
		userBlocked: 'تم حظر المستخدم',
		userUnBlocked: 'تم إلغاء حظر المستخدم',
		youAreSpeaker: 'أنت متحدث! قل شيئاً!',
		youAreNotSpeaker: 'لقد قام الإداري بإزالتك من المتحدثين',
		userIsSpeaker: (name: string) => `${name} الآن متحدث`,
		userNotSpeaker: (name: string) => `${name} لم يعد متحدثاً`,
		yourUserNameInChat: (name: string) => `اسمك في الدردشة: ${name}`,
		messageRemoved: 'تم حذف الرسالة',
		messageCopied: 'تم نسخ النص',
		obscenities: 'حاول إعادة الصياغة',
		requestSent: 'تم إرسال الطلب',
		requestDecline: 'تم سحب الطلب من قبل الإداري',
		youareblocked: (text: string) => `لقد تم حظرك بسبب ${text}t لا يمكنك الكتابة في الدردشة`,
		youareunblocked: 'لقد ألغي حظرك. يمكنك كتابة رسائل في الدردشة',
		youAreModerator: 'أنت وسيط!',
		youAreNoModerator: 'أنت لم تعد وسيطاً',
		micMuted: 'لقد تم كتم صوت مكرفونك',
		noInet: 'لا يوجد اتصال بالإنترنت',
		messagePinned: 'تم تثبيت الرسالة',
		messageUnpinned: 'تم إلغاء تثبيت الرسالة',
		pollPublished: 'تم نشر الاستطلاع',
		pollPublishedError: 'خطأ! حاول لاحقاً',
		pollAddPic: 'أضف صورة',
		pollAddPicError: 'اختر صورة أخرى',
		pollDeleted: 'تم حذف الاستطلاع',
		pollEnded: 'انتهى الاستطلاع',
		sendStickerWhenSlowModeIsOn: (time: string) => `انتظر ${time} إلى أن يرسل الملصق`,
		linkProhibited: 'لا يمكنك إرسال روابط في الدردشة.',
		phoneProhibited: 'ا يمكنك إرسال أرقام الهواتف في الدردشة.',
		profileHidden: 'المستخدم قام بإخفاء ملفه الشخصي',
		imageSizeWarning: 'يمكنك تحميل صورة بحجم يصل إلى 10 ميجابايت',
		imageBlocked: 'قام المشرف بحظر الصورة',
		streamKeyCopied: 'تم نسخ مفتاح البث',
	},
	btns: {
		acceptandgo: 'قبول ومتابعة',
		saveandgo: 'حفظ ومتابعة',
		save: 'حفظ',
		unblock: 'رفع الحظر',
		block: 'حظر',
		restoreAccount: 'استعادة الملف الشخصي',
		close: 'غلاق',
	},
	coopyrights: 'يعمل على أساس Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'إبلاغ عن الرسالة' : 'إبلاغ عن مخالفة',
			subtitle: 'يتم إرسال الإبلاغات دون ذكر اسم صاحبها',
			btns: {
				violence: 'الإهانات، التهديدات، الشتائم',
				provocations: 'لاستفزازات',
				personalDetails: 'البيانات الشخصية',
				flood: 'سبام/دعاية',
				spam: 'محتوى جنسي',
				fraudBegging: 'الاحتيال، التسول',
				other: 'غير ذلك',
			},
		},
		ban: {
			title: 'حظر المستخدم',
			subtitle: 'اذكر سبب الحظر',
			btns: {
				insults: 'الإهانات، التهديدات، الشتائم',
				personal: 'نشر البيانات الشخصية',
				flood: 'الإغراق/الإفاضة',
				links: 'الروابط إلى خدمات خارجية',
				violence: 'تهديدات بالعنف',
				fraud: 'محاولة الاحتيال والتسول',
				nickname: 'لقب غير مقبول',
				politicial: 'الاستفزازات السياسية أو الجنسية أو غيرها',
				repeated: 'تكرار المخالفة',
				auto: 'شكاوى مستخدمي الدردشة',
				spam: 'سبام/دعاية',
			},
		},
		waitingSpeakers: {
			text: 'ليس لديك متحدثين ينتظرونك',
			handsUp: 'رفعوا أيديهم',
			waiting: 'المتحدثون المنتظرون',
		},
		poll: {
			supTitles: {
				typeQuiz: 'أعط الإجابة الصحيحة',
				typeQuizMultiple: 'أشر إلى الخيارات الصحيحة',
				typePoll: 'شارك رأيك',
				typePollMultiple: 'يجوز انتقاء عدة خيارات',
				default: 'استطلاع عام',
			},
			btns: {
				reply: 'رد',
				vote: 'تصويت',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'مسابقة',
				typePoll: 'عدة أجوبة',
				default: 'استطلاع عام',
			},
			btns: {
				edit: 'تعديل الاستطلاع',
				end: 'إنهاء',
			},
		},
		pollCreated: {
			titles: {
				created: 'إنشاء الاستطلاع',
				typeQuiz: 'مسابقة',
				typePoll: 'عدة أجوبة',
				default: 'استطلاع عام',
			},
		},
		changeName: {
			title: 'تغيير الاسم في الدردشة',
			subtitle: 'تم حظرك بسبب اللقب غير المقبول',
		},
		temporaryAgreement: {
			title: 'قواعد الدردشة',
			rules: {
				bePolite: {
					title: 'كن مهذباً',
					text: 'تمنع في الدردشة الألفاظ العدائية والبذيئة.',
				},
				beAttentive: {
					title: 'كن منتبهاً',
					text: 'لا تقدم لأحد بياناتك الشخصية ولا تطلبها من غيرك.',
				},
				notAdvertise: {
					title: 'لا تقم بالإعلان',
					text: 'من الأفضل أن تشارك غيرك رأيك أو عواطفك.',
				},
			},
			btns: {
				allRules: 'كافة القواعد',
				acceptAndContinue: 'القبول والمتابعة',
			},
		},
		slowMode: {
			title: 'الوضع البطيء',
			text: 'لن يتمكن المستخدمون من إرسال رسائل إلى الدردشة أكثر من الفاصل الزمني الذي اخترته',
			enable: 'تشغيل الوضع البطيء',
			disable: 'إيقاف تشغيل الوضع البطيء',
			sendTimeout: 'مهلة الإرسال',
		},
		slowModeTimeout: {
			title: 'مهلة الإرسال',
		},
		chatBot: {
			title: 'استخدام بوت الدردشة',
			rules: [
				{
					title: 'الذكاء الاصطناعي',
					text: ' يقوم الذكاء الاصطناعي البوت بتقليد التواصل مع الناس ويقدم البيانات ضمن نطاق معارفه. ولكن لا تنسوا أن أجوبته قد تكون محدودة ولن تكون بديلاً عن الإنسان.',
				},
				{
					title: 'قد يكون الروبوت مخطئا',
					text: 'لا يمكن للبوت أن يضمن دقة وموثوقية المعلومات',
				},
			],
			btns: {
				close: 'إغلاق',
			},
		},
		shareBet: {
			title: 'لمشاركة',
			noBetsTitle: ' لم نتمكن من العثور على أي مراهنات لك',
			noBetsText: 'بإمكانك تقاسم المراهنات التي تمت خلال ١٤ يوماً',
			btns: {
				share: 'مشاركة الرهان',
				copy: 'كرر الرهان:',
			},
			status: {
				null: 'في اللعبة',
				win: 'فوز',
				lose: 'خسارة',
			},
			betTypeV1: {
				express: 'متعدد',
				single: 'فردي',
			},
			betTypeV2: {
				single: 'فردي',
				multi: 'متعدد',
				system: 'System',
			},
			totalFactor: 'لمعامل',
			bet: 'الرهان',
			showMore: 'أظهر المزيد',
		},
		shareGamble: {
			noGamblesText:
				'ليس لديك أرباح لمشاركتها بعد. استمر في اللعب، وستظهر أرباحك التالية هنا لتتمكن من مشاركتها مع لاعبين آخرين!',
		},
		record: {
			text: 'تتم معالجة تسجيل البث...',
		},
		translate: {
			title: 'ترجمة الرسائل',
			chooseLanguage: 'اختر اللغة',
			descr: 'سيتم ترجمة رسائل الدردشة تلقائيًا إلى اللغة التي تختارها',
			btn: 'ترجمة',
		},
		streamSettings: {
			title: 'إعدادات البث',
			description: 'قم بتنزيل وتثبيت مشفر الفيديو. أدخل مفتاح البث في إعداداته',
			shareScreen: {
				title: 'مشاركة الشاشة',
				description: 'لا تتطلب إعدادات خاصة',
			},
			rtmp: {
				title: 'مصدر بث خارجي',
				description: 'استخدم برنامجًا إضافيًا لبث الفيديو',
			},
			streamKey: 'مفتاح البث',
			showStreamKey: 'إظهار',
			hideStreamKey: 'إخفاء',
			btns: {
				continue: 'متابعة',
				close: 'إغلاق',
				ok: 'تم',
			},
		},
	},
	pinnedMessage: {
		title: 'رسالة مثبتة',
	},
	errorPage: {
		error: 'خطأ',
		types: [
			// errorNumber 0
			{
				title: 'لم نتمكن من التعرف عليك',
				text: 'حاول إعادة الدخول إلى الدردشة',
			},
			// errorNumber 1
			{
				title: 'لم نتمكن من إيجاد الدردشة',
				text: 'حاول إعادة الدخول',
			},
			// errorNumber 2
			{
				title: 'لا يمكن تحميل الدردشة',
				text: 'حاول إعادة التشغيل',
			},
			// errorNumber 3
			{
				title: 'لم نتمكن من التعرف عليك',
				text: 'الدردشة في وضع التصفح المتخفي غير متاحة',
			},
			// errorNumber 4
			{
				title: 'اللقب غير مقبول',
				text: 'ادخل إلى ملفك الشخصي وغير اللقب ثم ادخل إلى الدردشة من جديد заново.',
			},
		],
	},
	tooltips: {
		emotions: 'امسك من أجل التغيير',
		badInet: 'الاتصال مع الشبكة ضعيف',
		blockedUsers: 'المحظورين هنا <br>يجوز مشاهدة قائمة',
		waitingSpeakers: 'أحد المشاركين يريد الإفصاح عن رأيه.',
		wantSpeak: 'اضغط كي تنال حق التصويت',
		slowMode: {
			header: (time: string) =>
				`تم تشغيل الوضع البطيء. سيتمكن المستخدمون من إرسال رسالة واحدة <br> كل ${time}. هذه القاعدة لا تخص الوسطاء`,
			btnSendMessage: (time: string) => `تستطيع إرسال رسائل <br> كل ${time}.`,
		},
		shareBet: 'مشاركة الرهان في الدردشة',
		translate: 'ترجمة الرسائل إلى لغة أخرى',
	},
	poll: {
		toggleSubmenu: {
			create: 'إنشاء الاستطلاع',
			edit: 'تعديل الاستطلاع',
			show: 'إظهار الاستطلاع',
			end: 'إنهاء الاستطلاع',
		},
		tooltip: {
			btn: 'تصويت',
		},
		settings: {
			title: 'الإعدادات',
			titleAdditionally: 'إضافية',
			switches: {
				quizMode: 'نظام المسابقة',
				multipleChoice: 'اختيار عدة أجوبة',
				withPhoto: 'استطلاع مع الصور',
			},
		},
		question: {
			title: 'سؤال',
			inputPlaceholder: 'أدخل السؤال',
		},
		message: {
			title: 'خيارات الإجابة',
		},
		answers: {
			title: 'خيارات الإجابة',
			warning: 'المزيد حوالي 5 صفحات',
		},
		answer: {
			tooltip: 'اختر الإجابة الصحيحة',
			inputPlaceholder: 'الإجابة',
		},
		results: {
			text: 'نتيجة التصويت',
			votes: 'الأصوات',
			// words: ['صوت', 'تصويت', 'الأصوات'],
		},
		btns: {
			addQuestion: 'إضافة سؤال',
			addAnswers: 'إضافة أجوبة',
			addAnswer: 'إضافة جواب',
			publish: 'نشر',
			vote: 'صوّت',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'تقدير',
				continue: 'استمرار',
				checkConnection: 'التحقق من الاتصال',
				close: 'غلاق',
			},
			quality: {
				title: 'قيم جودة الصوت',
			},
			reason: {
				title: 'أين الخطأ؟',
			},
			connection: {
				title: 'اختبر جودة الاتصال',
				text: 'لن يستغرق الاختبار أكثر من دقيقة وسيساعدك على الكشف عن سبب الخطأ',
			},
			statistics: {
				title: 'نجمع الإحصائيات...',
			},
			done: {
				title: 'شكراً!',
				text: 'سنقوم بدراسة الخطأ وسنصححه بكل تأكيد.',
			},
		},
	},
	chatBot: {
		message: 'اسأل الروبوت عما تريد معرفته',
		warnings: {
			disabled: ' تم فصلي',
			overloaded: 'فرط التحميل',
		},
	},
	audioReason: {
		nothear: 'لم أسمع شيئاً',
		connection: 'الصوت متقطع',
		microphone: 'لم أتمكن من استخدام المكروفون',
	},
	screenShare: {
		title: 'مشاركة الشاشة',
		stopSharing: 'إيقاف مشاركة الشاشة',
		modal: {
			title: 'مشاركة الشاشة متاحة فقط من جهاز كمبيوتر.',
			text: 'افتح الدردشة على جهاز الكمبيوتر الخاص بك وانقر فوق زر "مشاركة الشاشة" في الزاوية اليمنى العليا.',
			btn: 'انسخ رابط الدعوة.',
		},
	},
	threads: {
		title: 'موضوع',
		back: 'رجوع',
		replies: ['رد', 'ردود', 'ردود'],
	},
	attach: {
		submenu: {
			sendImage: 'إرسال الصورة',
			shareBet: 'مشاركة الرهان',
			shareGamble: 'مشاركة الأرباح',
		},
		images: [' صور', 'صور', 'صورة'],
		addCaption: 'إضافة تسمية توضيحية',
	},
} as unknown as Dictionary;
