/* eslint-disable max-lines */
import debounce from 'lodash/debounce';
import LanguageTag from 'models/enums/LanguageTag.enum';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import emotionServices from 'store/emotionService';
import useDetectDeviceAndBrowser from 'hooks/useDetectDeviceAndBrowser';
import pollServices from 'store/pollService';
import SocketIoServices from 'services/SocketIoServices';
import {containsClass, getParent} from 'utils/helpers';
import useSearchParams from 'hooks/useSearchParams';
import useAnalytics from 'hooks/useAnalytics';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Switch, Route} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile} from 'react-device-detect';
import Error from 'pages/error/Error';
import Restore from 'pages/restore/Restore';
import Main from 'pages/main/Main';

import {Preloader} from 'components/preloader';
import {Header} from 'components/header';
import ToastList from 'components/toasts/ToastList';
import Alert from 'components/alert/Alert';

import './app.scss';
import useSendEventWithTimeouts from 'hooks/useSendEventWithTimeouts';
import {roomStatus} from 'events/roomStatus';
import InterfaceDirection from 'models/enums/InterfaceDirection.enum';
import {rtlLanguages} from 'constants/constants';
import axios from 'axios';
import {ResponseStatusCode} from 'models/enums/Network.enum';
import ResponseMessage from 'models/enums/ResponseMessage.enum';
import AppAuthStatus from 'models/enums/AppAuthStatus.enum';
import threadService from 'store/threadService';
import useAgora from 'hooks/useAgora';
import RoomStatus from 'models/enums/RoomStatus.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import agoraService from 'store/agoraService';
import controlPanelService from 'store/controlPanelService';
import modalService from 'store/modalService';
import copybettingService from 'store/copybettingService';

const App = function App() {
	const {
		project,
		toggleAppReadOnly,
		currentScreen,
		setLanguage,
		setShareData,
		appEnableAgora,
		isAgoraLoaded,
		setInterfaceDirection,
		appUserCounterButtonVisible,
		setAppAuthStatus,
		appEnableDomainWhitelist,
		appDomainWhitelist,
		enableRoomSpeak,
		agoraStatus,
		isStand,
		appEnableWidgetText,
	} = useLocalObservable(() => appService);
	const {userId: storeUserId, userData} = useLocalObservable(() => userServices);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const roomStore = useLocalObservable(() => roomServices);
	const threadStore = useLocalObservable(() => threadService);
	const isThread = useRef<boolean | null>(null);
	const {emotionsVisible, toggleEmotionsVisible} = useLocalObservable(() => emotionServices);
	const {initDetectDeviceAndBrowser, initAppVersion} = useDetectDeviceAndBrowser();
	const {pollTooltipVisible, toggllePollTooltipVisible} = useLocalObservable(() => pollServices);
	const {isLocalAudioTrack} = useLocalObservable(() => agoraService);
	const {attachSubmenuVisible, toggleAttachSubmenuVisible} = useLocalObservable(
		() => controlPanelService
	);
	const {setCurrentBetForShare} = useLocalObservable(() => copybettingService);
	const {toggleWidgetPreviewModalVisible, toggleShareBetModal} = useLocalObservable(
		() => modalService
	);

	const {sendAnalytics} = useAnalytics();
	const {sessionTime} = useSendEventWithTimeouts();
	const {setMuteClientAudio} = useAgora();
	const {
		roomIdFromUrl,
		langFromUrl,
		roomNameFromUrl,
		readonlyFromUrl,
		osFromUrl,
		osVersionFromUrl,
		appVersionFromUrl,
	} = useSearchParams();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [eventSessionStartedSent, setEventSessionStartedSent] = useState(false);
	const [eventRoomOpenedSent, setEventRoomOpenedSent] = useState(false);

	const HTMLNode = document.querySelector('html');

	const isStream = !!roomStore.roomData?.streamUrl && roomStore.roomData.status === RoomStatus.LIVE;

	const sendPostMessage = useCallback(
		debounce(cs => {
			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify({type: 'changeScreen', body: cs}));
				return;
			}

			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
					JSON.stringify({type: 'changeScreen', body: cs})
				);
				return;
			}

			window.parent.postMessage({type: 'changeScreen', body: cs}, '*');
		}, 500),
		[]
	);

	const saveExternalRoomId = (externalRoomId: string) => {
		roomStore.setRoomId(externalRoomId);
	};

	const onClickDocumentHandler = (event: any) => {
		const eventTarget = event.target;
		const submenuMessage = isThread.current ? threadStore.submenuMessage : roomStore.submenuMessage;
		if (
			!getParent(eventTarget, 'chat__message-body') &&
			!getParent(eventTarget, 'chat__submenu') &&
			submenuMessage
		) {
			isThread.current ? threadStore.setSubmenuMessage(null) : roomStore.setSubmenuMessage(null);
		}

		if (
			!containsClass(eventTarget, 'emotions__item') &&
			!containsClass(eventTarget, 'control-panel__btn--emotions') &&
			emotionsVisible
		) {
			toggleEmotionsVisible(false);
		}

		if (!getParent(eventTarget, 'poll-toggle') && pollTooltipVisible) {
			toggllePollTooltipVisible(false);
		}

		if (!getParent(eventTarget, 'control-panel__attach') && attachSubmenuVisible) {
			toggleAttachSubmenuVisible(false);
		}
	};

	const onGesturestarthandler = (event: any) => {
		event.preventDefault();
	};

	const onMessageHandler = (e: MessageEvent) => {
		const postMessage = e.data;
		if (postMessage.type === 'betRepeat' && postMessage.body.action === 'sendToChat') {
			const bet = postMessage.body.data[0];
			sendAnalytics('betshare_list_bet_shared', {
				bet,
				event_id: roomIdFromUrl,
				chat_event_name: roomNameFromUrl || '',
				bets_id: bet.positions.map((el: any) => el.eventId).toString(),
			});

			if (appEnableWidgetText) {
				setCurrentBetForShare(bet);
				toggleWidgetPreviewModalVisible(true);
				toggleShareBetModal(false);
				return;
			}
			roomIdFromUrl &&
				SocketIoServices.emitShareBet({
					externalRoomId: roomIdFromUrl,
					bet,
				});
		}
	};

	const watchersioPMListener = (e: MessageEvent) => {
		const postMessage = e.data;
		if (postMessage.watchersio) {
			setShareData(postMessage.watchersio.body);
		}
	};

	const onLoadedPM = () => {
		const msg = {
			type: 'app',
			body: {action: 'loaded', data: {...roomStore.roomData}},
		};

		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(JSON.parse(JSON.stringify(msg)), '*');
	};

	useEffect(() => {
		isThread.current = !!currentThreadId;
	}, [currentThreadId]);

	useEffect(() => {
		currentScreen !== null && sendPostMessage(currentScreen);

		window.onmessage = e => {
			if (e.data.type === 'getCurrentScreen') {
				if (typeof WatchersChannel !== 'undefined') {
					WatchersChannel.postMessage(
						JSON.stringify({type: 'getCurrentScreen', body: currentScreen})
					);
					return;
				}

				if ((window as any).webkit?.messageHandlers) {
					(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
						JSON.stringify({body: currentScreen})
					);
					return;
				}

				window.parent.postMessage({type: 'getCurrentScreen', body: currentScreen}, '*');
			}

			if (e.data.watchersTitleUpdate) {
				roomStore.setRoomName(e.data.watchersTitleUpdate);
			}
		};
	}, [currentScreen]);

	useEffect(() => {
		if (userData?.externalId && roomStore.roomId)
			window.addEventListener('message', onMessageHandler);
		return () => {
			window.removeEventListener('message', onMessageHandler);
		};
	}, [userData, roomStore.roomId]);

	useEffect(() => {
		window.addEventListener('message', watchersioPMListener);

		if (isStand) {
			window.parent.postMessage({watchersWindowReady: true}, '*');
		}
		return () => {
			window.removeEventListener('message', watchersioPMListener);
		};
	}, [isStand]);

	useEffect(() => {
		if (roomStore.roomData) onLoadedPM();
	}, [roomStore.roomData]);

	useEffect(() => {
		if (
			langFromUrl &&
			Object.values(LanguageTag).includes(langFromUrl.toLowerCase() as LanguageTag)
		) {
			setLanguage(langFromUrl.toLowerCase() as LanguageTag);
			setInterfaceDirection(
				rtlLanguages.includes(langFromUrl) ? InterfaceDirection.RTL : InterfaceDirection.LTR
			);
			HTMLNode?.setAttribute('rtl', rtlLanguages.includes(langFromUrl) ? 'true' : 'false');
		}

		if (roomNameFromUrl) {
			roomStore.setRoomName(roomNameFromUrl);
		}

		if (readonlyFromUrl && readonlyFromUrl === 'true') {
			toggleAppReadOnly(true);
		}

		if (isMobile) {
			document.addEventListener('gesturestart', onGesturestarthandler);
		}

		if (osFromUrl && osVersionFromUrl) initDetectDeviceAndBrowser(osFromUrl, osVersionFromUrl);

		if (appVersionFromUrl) initAppVersion(appVersionFromUrl);

		return () => {
			if (isMobile) {
				document.removeEventListener('gesturestart', onGesturestarthandler);
			}
		};
	}, []);

	useEffect(() => {
		if (roomIdFromUrl) {
			saveExternalRoomId(roomIdFromUrl);
		}
	}, []);

	useEffect(() => {
		if (
			(enableRoomSpeak || roomStore.roomData?.isSpeak) &&
			roomStore.roomData?.status === RoomStatus.LIVE &&
			agoraStatus === AgoraStatus.INITED &&
			isLocalAudioTrack
		)
			if ('mediaSession' in navigator) {
				navigator.mediaSession.metadata = new MediaMetadata({
					title: roomStore.roomData?.name || '',
					artist: roomStore.roomData?.about || '',
					artwork: [{src: roomStore.roomData?.pic800 || '', sizes: '800x800', type: 'image/jpeg'}],
				});
				navigator.mediaSession.setActionHandler('play', () => {
					setMuteClientAudio(false);
					navigator.mediaSession.playbackState = 'playing';
				});
				navigator.mediaSession.setActionHandler('pause', () => {
					setMuteClientAudio(true);
					navigator.mediaSession.playbackState = 'paused';
				});

				navigator.mediaSession.setActionHandler('previoustrack', null);
				navigator.mediaSession.setActionHandler('seekbackward', null);
				navigator.mediaSession.setActionHandler('seekforward', null);
			}

		if (roomStore.roomData?.status === RoomStatus.ENDED && agoraStatus === AgoraStatus.DESTROYED) {
			if ('mediaSession' in navigator) {
				navigator.mediaSession.metadata = null;
				navigator.mediaSession.setActionHandler('play', null);
				navigator.mediaSession.setActionHandler('pause', null);

				navigator.mediaSession.setActionHandler('previoustrack', null);
				navigator.mediaSession.setActionHandler('nexttrack', null);
				navigator.mediaSession.setActionHandler('seekbackward', null);
				navigator.mediaSession.setActionHandler('seekforward', null);
			}
		}
	}, [roomStore.roomData, enableRoomSpeak, agoraStatus, isLocalAudioTrack]);

	useEffect(() => {
		if (storeUserId && roomStore.roomId && !eventSessionStartedSent && roomStore.roomData) {
			setEventSessionStartedSent(true);
			sendAnalytics('session_started', {
				room_status: roomStatus.find(el => el.name === roomStore.roomData?.status)?.event,
			});
			sessionTime('session_time');
		}
	}, [storeUserId, roomStore.roomId, roomStore.roomData]);

	useEffect(() => {
		const submenuMessage = isThread.current ? threadStore.submenuMessage : roomStore.submenuMessage;
		if (submenuMessage || emotionsVisible || pollTooltipVisible || attachSubmenuVisible) {
			document.addEventListener('touchstart', onClickDocumentHandler);
			document.addEventListener('mousedown', onClickDocumentHandler);
		}

		return () => {
			document.removeEventListener('touchstart', onClickDocumentHandler);
			document.removeEventListener('mousedown', onClickDocumentHandler);
		};
	}, [
		roomStore.submenuMessage,
		threadStore.submenuMessage,
		emotionsVisible,
		pollTooltipVisible,
		attachSubmenuVisible,
	]);

	useEffect(() => {
		const {talkersCountLoaded} = roomStore.restApiDataLoaded;
		if (
			(talkersCountLoaded || !appUserCounterButtonVisible) &&
			SocketIoServices.socket &&
			(!appEnableAgora || (appEnableAgora && isAgoraLoaded))
		) {
			setVisiblePreloader(false);
		}
	}, [roomStore.restApiDataLoaded, SocketIoServices.socket, isAgoraLoaded, appEnableAgora]);

	useEffect(() => {
		if (roomStore.restApiDataLoaded.messagesLoaded && !eventRoomOpenedSent) {
			setEventRoomOpenedSent(true);
			sendAnalytics('room_opened', {
				chat_event_name: roomNameFromUrl || '',
			});
		}
	}, [roomStore.restApiDataLoaded.messagesLoaded]);

	useEffect(() => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (
					error.response?.status === ResponseStatusCode.Forbidden &&
					error.response?.data?.message === ResponseMessage.ServiceDisabled
				) {
					setAppAuthStatus(AppAuthStatus.ERROR);
				}

				if (
					error.response?.status &&
					error.response?.status === ResponseStatusCode.Forbidden &&
					(error.response.data.message === ResponseMessage.InvalidToken ||
						error.response.data.message === ResponseMessage.RequiredToken)
				) {
					window.location.reload();
				}

				return Promise.reject(error);
			}
		);
	}, []);

	useEffect(() => {
		if (appEnableDomainWhitelist && appDomainWhitelist) {
			const parentOrigin = document.referrer;
			const ref = parentOrigin?.match(/:\/\/(.[^/]+)/);
			if (ref) {
				const domain = ref[1];
				if (
					parentOrigin &&
					!appDomainWhitelist.includes(domain) &&
					!document.referrer.includes(window.parent.location.origin)
				) {
					setAppAuthStatus(AppAuthStatus.ERROR);
				}
			}
		}
	}, [appEnableDomainWhitelist, appDomainWhitelist]);

	return (
		<Switch>
			<Route exact path='/'>
				<Preloader visible={visiblePreloader} />
				{isStream && roomStore.roomData && (
					<iframe
						src={roomStore.roomData.streamUrl}
						width='100%'
						height='100%'
						allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
						style={{border: 'none'}}
						frameBorder='0'
						allowFullScreen
						title='tinkoff'
						className='stream-video-iframe'
					/>
				)}

				<Header />
				<Main isStreamVideo={isStream} />
				<ToastList />
				<Alert />
			</Route>

			<Route path='/restore'>
				<Restore />
			</Route>

			<Route path='/error'>
				<Error />
			</Route>
		</Switch>
	);
};

export default observer(App);
