function qs(className: string, root: any = document) {
	return root.querySelector(className);
}

function qsAll(className: string, root: any = document) {
	return root.querySelectorAll(className);
}

function getParent(element: any, className: string) {
	while (element && element.parentNode) {
		// eslint-disable-next-line no-param-reassign
		element = element.parentNode;
		if (element.classList && element.classList.contains(className)) return element;
	}
	return false;
}

function getParentByAttribute(element: any, attribute: string) {
	while (element && element.parentNode) {
		// eslint-disable-next-line no-param-reassign
		element = element.parentNode;
		if (element && element.attributes) {
			if (element.hasAttribute(attribute)) return element;
		}
	}
	return false;
}

function updateUrlParameter(url: string, key: string, value: string) {
	const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
	const separator = url.indexOf('?') !== -1 ? '&' : '?';

	if (url.match(re)) {
		return url.replace(re, `$1${key}=${value}$2`);
	}
	return `${url + separator + key}=${value}`;
}

function textEnding(number: number, txt: string[]) {
	const cases = [2, 0, 1, 1, 1, 2];
	return txt[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

function lowercaseKeys(obj: any) {
	if (obj) {
		return Object.keys(obj).reduce((acc, key) => {
			let newKey = '';
			if (key === key.toUpperCase()) {
				newKey = key;
			} else newKey = key.charAt(0).toLowerCase() + key.slice(1);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-param-reassign
			acc[newKey] = obj[key];
			return acc;
		}, {});
	}
	return null;
}

const containsClass = (element: any, className: string) => element.classList.contains(className);

const compareArraysByIsVisible = (arr1: any[], arr2: any[]) => {
	const maxLength = Math.max(arr1.length, arr2.length);

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < maxLength; i++) {
		if (arr1[i]?.isVisible !== arr2[i]?.isVisible && !arr1[i]?.isVisible) {
			return true;
		}
	}

	return false;
};

const getPreviousElementInArray = (array: any[], currentId: number) => {
	const currentIndex = array.findIndex(element => element.id === currentId);

	if (currentIndex === -1 || currentIndex === 0) {
		return null;
	}

	const previousIndex = currentIndex - 1;

	return {...array[previousIndex]};
};

const getNextElementInArray = (array: any[], currentId: number) => {
	const currentIndex = array.findIndex(element => element.id === currentId);

	if (currentIndex === -1 || currentIndex === array.length - 1) {
		return null;
	}

	const nextIndex = currentIndex + 1;

	return {...array[nextIndex]};
};

export {
	qs,
	qsAll,
	getParent,
	getParentByAttribute,
	containsClass,
	updateUrlParameter,
	textEnding,
	lowercaseKeys,
	compareArraysByIsVisible,
	getPreviousElementInArray,
	getNextElementInArray,
};
