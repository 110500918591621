enum AppIconsName {
	IcoAdd = 'IcoAdd',
	IcoBadinet = 'IcoBadinet',
	IcoBan = 'IcoBan',
	IcoClose = 'IcoClose',
	IcoCopy = 'IcoCopy',
	IcoEdit = 'IcoEdit',
	IcoEditAccent = 'IcoEditAccent',
	IcoEmoji = 'IcoEmoji',
	IcoHand = 'IcoHand',
	IcoHandAccent = 'IcoHandAccent',
	IcoHandPlain = 'IcoHandPlain',
	IcoHandPlainAccent = 'IcoHandPlainAccent',
	IcoKeyboard = 'IcoKeyboard',
	IcoLeave = 'IcoLeave',
	IcoLink = 'IcoLink',
	IcoMessage = 'IcoMessage',
	IcoMic = 'IcoMic',
	IcoMicAccent = 'IcoMicAccent',
	IcoMute = 'IcoMute',
	IcoNoinet = 'IcoNoinet',
	IcoPin = 'IcoPin',
	IcoUnpin = 'IcoUnpin',
	IcoPoll = 'IcoPoll',
	IcoPollEnd = 'IcoPollEnd',
	IcoPollEndAccent = 'IcoPollEndAccent',
	IcoReplay = 'IcoReplay',
	IcoReplayAccent = 'IcoReplayAccent',
	IcoReport = 'IcoReport',
	IcoReportMessage = 'IcoReportMessage',
	IcoReportUser = 'IcoReportUser',
	IcoRightArrow = 'IcoRightArrow',
	IcoSendMessage = 'IcoSendMessage',
	IcoSettings = 'IcoSettings',
	IcoShare = 'IcoShare',
	IcoSlowmode = 'IcoSlowmode',
	IcoSpeaker = 'IcoSpeaker',
	IcoSpeakerAdd = 'IcoSpeakerAdd',
	IcoStar = 'IcoStar',
	IcoStickers = 'IcoStickers',
	IcoSubmenuBlock = 'IcoSubmenuBlock',
	IcoSubmenuBasket = 'IcoSubmenuBasket',
	IcoSubmenuHide = 'IcoSubmenuHide',
	IcoSubmenuPin = 'IcoSubmenuPin',
	IcoSubmenuRemoveSpeaker = 'IcoSubmenuRemoveSpeaker',
	IcoSubmenuShow = 'IcoSubmenuShow',
	IcoSubmenuUnblock = 'IcoSubmenuUnblock',
	IcoToastBasket = 'IcoToastBasket',
	IcoToastBrickRed = 'IcoToastBrickRed',
	IcoToastBrick = 'IcoToastBrick',
	IcoToastClose = 'IcoToastClose',
	IcoToastCopy = 'IcoToastCopy',
	IcoToastPoll = 'IcoToastPoll',
	IcoToastPin = 'IcoToastPin',
	IcoToastUnpin = 'IcoToastUnpin',
	IcoToastUserAddedSpeakers = 'IcoToastUserAddedSpeakers',
	IcoToastUserRemovedSpeakers = 'IcoToastUserRemovedSpeakers',
	IcoToastUserBlocked = 'IcoToastUserBlocked',
	IcoToastUserWarning = 'IcoToastUserWarning',
	IcoToastMessageShown = 'IcoToastMessageShown',
	IcoToastMessageHidden = 'IcoToastMessageHidden',
	IcoUsers = 'IcoUsers',
	IcoWarn = 'IcoWarn',
	IcoPlay = 'IcoPlay',
	IcoPause = 'IcoPause',
	IcoSeekBack = 'IcoSeekBack',
	IcoSeekForward = 'IcoSeekForward',
	IcoChevron = 'IcoChevron',
	IcoShareDesktop = 'IcoShareDesktop',
	IcoShareAndroid = 'IcoShareAndroid',
	IcoShareIos = 'IcoShareIos',
	IcoCalendar = 'IcoCalendar',
	IcoShareRoom = 'IcoShareRoom',
	IcoMicDevice = 'IcoMicDevice',
	IcoSpeakerDevice = 'IcoSpeakerDevice',
	IcoToastConnection = 'IcoToastConnection',
	IcoSubmenuProfile = 'IcoSubmenuProfile',
	IcoTranslateOn = 'IcoTranslateOn',
	IcoTranslateOff = 'IcoTranslateOff',
	IcoScreenShare = 'IcoScreenShare',
	IcoSubmenuThread = 'IcoSubmenuThread',
	IcoAdmin = 'IcoAdmin',
	IcoAttach = 'IcoAttach',
	IcoImage = 'IcoImage',
	IcoFollowing = 'IcoFollowing',
	IcoDisabledChat = 'IcoDisabledChat',
}

export default AppIconsName;
