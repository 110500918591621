/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import usePlayer from 'hooks/usePlayer';
import useSearchParams from 'hooks/useSearchParams';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import appService from 'store/appService';
import playerService from 'store/playerService';
import roomService from 'store/roomService';
import {getParent} from 'utils/helpers';

const playbackRateItems = [
	{id: 0, value: 1},
	{id: 1, value: 1.5},
	{id: 2, value: 2},
];

const AudioPlayer: FunctionComponent = function AudioPlayer() {
	const mediaAudioRef = useRef<any>(null);
	const progressBarRef = useRef<HTMLInputElement>(null);
	const {roomData} = useLocalObservable(() => roomService);
	const {appIcons} = useLocalObservable(() => appService);
	const {playPressed, pausePressed, setPausePressed, setPlayPressed, rewind} = useLocalObservable(
		() => playerService
	);
	const {getAppIcon} = useAppData();
	const {sendAnalytics} = useAnalytics();
	const {IcoPlay, IcoPause, IcoSeekBack, IcoSeekForward} = appIcons;
	const {audioPostMessage} = usePlayer();
	const {platformFromUrl} = useSearchParams();

	const [isPlaying, setIsPlaying] = useState(false);
	const [isDisabledSkipBack, setIsDisabledSkipBack] = useState(true);
	const [currentTimeTrack, setCurrentTimeTrack] = useState(0);
	const [durationTrack, setDurationTrack] = useState(0);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isDisabledSkipForward, setIsDisabledSkipForward] = useState(true);
	const [isVisiblePlayerSelectDropdown, setIsVisiblePlayerSelectDropdown] = useState(false);

	const getPlatform = () => (platformFromUrl ? platformFromUrl.toLowerCase() : 'web');

	const playerSelectToggleClasses = classNames('player__select-toggle', {
		'player__select-toggle--active': isVisiblePlayerSelectDropdown,
	});

	const formatTime = (time: number) => {
		if (time) {
			const hours = Math.floor(time / 3600);
			const minutes = Math.floor((time % 3600) / 60);
			const seconds = Math.floor(time % 60);

			const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
			const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			return hours > 0
				? `${hours}:${formatMinutes}:${formatSeconds}`
				: `${minutes}:${formatSeconds}`;
		}
		return '0:00';
	};

	const onLoadedMetadataHandler = () => {
		if (mediaAudioRef.current) {
			const {currentTime, duration} = mediaAudioRef.current;
			setCurrentTimeTrack(currentTime);
			setDurationTrack(duration);

			if (progressBarRef.current) {
				progressBarRef.current.max = duration.toFixed(0);
			}

			if (duration - currentTime > 15) {
				setIsDisabledSkipForward(false);
			}
		}
	};

	const onTimeUpdateHandler = (event: any) => {
		if (progressBarRef.current && mediaAudioRef.current) {
			const {currentTime, duration} = mediaAudioRef.current;

			const rangeValue = ((currentTime / duration) * 100).toFixed();
			setCurrentTimeTrack(currentTime);
			progressBarRef.current.value = `${currentTime}`;
			progressBarRef.current.style.setProperty('--range-progress', `${rangeValue}%`);

			if (duration > 15) {
				if (currentTime >= 15) {
					setIsDisabledSkipBack(false);
				} else {
					setIsDisabledSkipBack(true);
				}

				if (duration - currentTime > 15) {
					setIsDisabledSkipForward(false);
				} else {
					setIsDisabledSkipForward(true);
				}
			}
		}
	};

	const onProgressBarHandler = () => {
		if (mediaAudioRef.current && progressBarRef.current) {
			mediaAudioRef.current.currentTime = parseInt(progressBarRef.current.value, 10);
		}
	};

	const onPlayerSelectButtonHandler = (value: number) => {
		if (value !== playbackRate && mediaAudioRef.current) {
			setPlaybackRate(value);
			setIsVisiblePlayerSelectDropdown(false);
			mediaAudioRef.current.playbackRate = value;
			sendAnalytics('records_speed_changed', {Speed: value});
		}
	};

	const renderPlayerSelectItem = (item: {id: number; value: number}) => {
		return (
			<div className='player__select-item' key={item.id}>
				<button
					type='button'
					className='player__select-button'
					onClick={() => onPlayerSelectButtonHandler(item.value)}>
					{item.value}x
				</button>
			</div>
		);
	};

	const onControlsPlayHandler = () => {
		if (mediaAudioRef.current) {
			if (isPlaying) {
				mediaAudioRef.current.pause();
				setIsPlaying(prev => !prev);
				sendAnalytics('records_pause', {time_stamp: mediaAudioRef.current.currentTime.toFixed(0)});
				return;
			}

			mediaAudioRef.current.play();

			setIsPlaying(prev => !prev);
			sendAnalytics('records_play', {
				time_stamp: mediaAudioRef.current.currentTime.toFixed(0),
				platform: getPlatform(),
			});
		}
	};

	const onControlsSkipHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLButtonElement;
		const skipDirection = target.getAttribute('data-skip');

		if (mediaAudioRef.current) {
			if (skipDirection === 'forward') {
				if (
					!isDisabledSkipForward &&
					mediaAudioRef.current.currentTime + 15 < mediaAudioRef.current.duration
				) {
					mediaAudioRef.current.currentTime += 15;
					sendAnalytics('records_forward', {Duration: 15});
				}

				return;
			}

			if (!isDisabledSkipBack && mediaAudioRef.current.currentTime > 16) {
				mediaAudioRef.current.currentTime -= 15;
				sendAnalytics('records_back', {Duration: 15});
			}
		}
	};

	const onEndedHandler = () => {
		setIsPlaying(false);
		setIsDisabledSkipBack(true);
		setIsDisabledSkipForward(true);
		setCurrentTimeTrack(0);
		setPlaybackRate(1);
		if (mediaAudioRef.current) {
			mediaAudioRef.current.currentTime = 0;
			mediaAudioRef.current.remove();
		}
	};

	const onPlayHandler = () => {
		setIsPlaying(true);
		audioPostMessage('PLAY');
	};

	const onPauseHandler = () => {
		setIsPlaying(false);
		audioPostMessage('PAUSE');
	};

	const onClickPlayerHandler = (e: any) => {
		const eventTarget = e.target;
		if (eventTarget && !getParent(eventTarget, 'player__select') && isVisiblePlayerSelectDropdown) {
			setIsVisiblePlayerSelectDropdown(false);
		}
	};

	const playHandler = () => {
		if (mediaAudioRef.current) {
			mediaAudioRef.current.play();
			setIsPlaying(true);
			sendAnalytics('records_play', {time_stamp: mediaAudioRef.current.currentTime.toFixed(0)});
		}
	};

	const pauseHandler = () => {
		if (mediaAudioRef.current) {
			mediaAudioRef.current.pause();
			setIsPlaying(false);
			sendAnalytics('records_pause', {time_stamp: mediaAudioRef.current.currentTime.toFixed(0)});
		}
	};

	useEffect(() => {
		if (playPressed) {
			playHandler();
			setPlayPressed(false);
		}
	}, [playPressed]);

	useEffect(() => {
		if (pausePressed) {
			pauseHandler();
			setPausePressed(false);
		}
	}, [pausePressed]);

	useEffect(() => {
		if (rewind) {
			if (mediaAudioRef.current) {
				if (rewind.direction === 'FORWARD') {
					if (
						!isDisabledSkipForward &&
						mediaAudioRef.current.currentTime + rewind.seconds < mediaAudioRef.current.duration
					) {
						mediaAudioRef.current.currentTime += rewind.seconds;
						sendAnalytics('records_forward', {Duration: rewind.seconds});
					}

					return;
				}

				if (!isDisabledSkipBack) {
					if (mediaAudioRef.current.currentTime > rewind.seconds + 1) {
						mediaAudioRef.current.currentTime -= rewind.seconds;
					} else {
						mediaAudioRef.current.currentTime = 0;
					}
					sendAnalytics('records_back', {Duration: rewind.seconds});
				}
			}
		}
	}, [rewind]);

	useEffect(() => {
		document.addEventListener('click', onClickPlayerHandler);
		return () => {
			document.removeEventListener('click', onClickPlayerHandler);
		};
	}, [isVisiblePlayerSelectDropdown]);

	useEffect(() => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: roomData?.name || '',
				artist: roomData?.about || '',
				artwork: [{src: roomData?.pic800 || '', sizes: '800x800', type: 'image/jpeg'}],
			});
		}
	}, [roomData]);

	return (
		<div className='player'>
			<div className='player__head'>
				<div className='player__progressbar'>
					<audio
						controls
						preload='metadata'
						src={roomData?.audioRecord || roomData?.record}
						className='player__audio'
						onLoadedMetadata={onLoadedMetadataHandler}
						onTimeUpdate={onTimeUpdateHandler}
						onEnded={onEndedHandler}
						onPlay={onPlayHandler}
						onPause={onPauseHandler}
						ref={mediaAudioRef}
						title={roomData?.name || ''}
					/>
					<input
						type='range'
						defaultValue='0'
						className='player__progressbar-range'
						onChange={onProgressBarHandler}
						ref={progressBarRef}
					/>
					<div className='player__progressbar-times'>
						<div className='player__progressbar-time'>{formatTime(currentTimeTrack)}</div>
						<div className='player__progressbar-time'>{formatTime(durationTrack)}</div>
					</div>
				</div>
			</div>
			<div className='player__body'>
				<div className='player__controls'>
					<div className='player__select'>
						<button
							type='button'
							className={playerSelectToggleClasses}
							onClick={() => setIsVisiblePlayerSelectDropdown(!isVisiblePlayerSelectDropdown)}>
							{playbackRate}x
						</button>
						{isVisiblePlayerSelectDropdown && (
							<div className='player__select-dropdown'>
								<div className='player__select-items'>
									{playbackRateItems.map(renderPlayerSelectItem)}
								</div>
							</div>
						)}
					</div>
					<button
						type='button'
						className='player__controls-skip'
						data-skip='back'
						disabled={isDisabledSkipBack}
						onClick={onControlsSkipHandler}>
						{getAppIcon(IcoSeekBack.pic)}
					</button>
					<button
						type='button'
						className='player__controls-play'
						aria-label='play'
						onClick={onControlsPlayHandler}>
						{isPlaying ? getAppIcon(IcoPause.pic) : getAppIcon(IcoPlay.pic)}
					</button>
					<button
						type='button'
						className='player__controls-skip'
						data-skip='forward'
						disabled={isDisabledSkipForward}
						onClick={onControlsSkipHandler}>
						{getAppIcon(IcoSeekForward.pic)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default observer(AudioPlayer);
