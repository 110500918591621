import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import './star.scss';

interface IStarProps {
	selected: boolean;
}

const Star: FunctionComponent<IStarProps> = function Star({selected}) {
	const {appIcons} = useLocalObservable(() => appService);

	const {getAppIcon} = useAppData();

	const {IcoStar} = appIcons;

	const starClasses = classNames('star', {
		'star--selected': selected,
	});

	return <div className={starClasses}>{getAppIcon(IcoStar.pic, {className: 'star__ico'})}</div>;
};

export default observer(Star);
