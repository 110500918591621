import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import {AudioFeedback} from 'models/feedback';
import {action, makeObservable, observable} from 'mobx';

class FeedbackService {
	@observable
	public audioFeedbackData: AudioFeedback | null = null;

	@observable
	public audioFeedbackActiveStep: AudioFeedbackSteps = AudioFeedbackSteps.QUALITY;

	@action
	public setAudioFeedbackData = (value: any) => {
		this.audioFeedbackData = {...this.audioFeedbackData, ...value};
	};

	@action
	public removeAudioFeedbackData = () => {
		this.audioFeedbackData = null;
	};

	@action
	public toggleAudioFeedbackActiveStep = (value: AudioFeedbackSteps) => {
		this.audioFeedbackActiveStep = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new FeedbackService();
