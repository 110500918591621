import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import {DoubleEncryptedUserIdResponseData, RegisterResponseData} from 'models/responses';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';
import {RegisterRequest} from 'models/requests';

export default class OauthService {
	static registerUser = async (userData: RegisterRequest, token?: string | null) => {
		try {
			const {data, status} = await axios.post<RegisterResponseData>(
				apiPath.register,
				userData,
				token
					? {
							headers: {Authorization: `Bearer ${token}`},
					  }
					: {}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static checkUser = async (userData: RegisterRequest) => {
		try {
			const {externalId} = userData;

			const params = {
				externalId,
			};

			const {data, status} = await axios.post(apiPath.check, params);

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Check user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static decryptUserId = async (userId: string, apiKey: string) => {
		try {
			const params = {
				key: userId,
				apiKey,
			};

			const {data, status} = await axios.post(apiPath.decrypt, params);

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Decrypt userId error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDecryptedUserId = async (userId: number) => {
		try {
			const params = {
				key: userId,
			};

			const {data, status} = await axios.post(apiPath.decryptid, params);

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Get decryptedUserId error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDoubleEncryptedUserId = async (userId: number, token: UUID) => {
		try {
			const {data, status} = await axios.get<DoubleEncryptedUserIdResponseData>(
				apiPath.getDoubleEncryptedUserId(userId),
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Get doubleEncryptedUserId error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getProfileUrl = async (userId: number, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getProfileUrl(userId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Get profileUrl error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
