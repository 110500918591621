import {ActionType} from 'models/enums/ActionType.enum';
import {Message} from 'models/room';
import {action, makeObservable, observable} from 'mobx';

type ActionData = {
	type: ActionType;
	message: Message;
};

class ActionMessageService {
	@observable
	public isVisibleActionMessage = false;

	@observable
	public actionData: ActionData | null = null;

	@action
	public toggleIsVisibleActionMessage = (value: boolean, clearActionData?: boolean) => {
		this.isVisibleActionMessage = value;
		if (clearActionData) {
			this.actionData = null;
		}
	};

	@action
	public setActionData = (data: ActionData | null) => {
		this.actionData = data;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ActionMessageService();
