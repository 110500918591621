import AdvertisementTemplateType from 'models/enums/AdvertisementTemplateType';
import {Advertisement as MessageAdvertisement} from 'models/room';
import {FunctionComponent} from 'react';
import HighlightContainer from './HighlightContainer';
import './highlight.scss';

interface IChatAdvertisementProps {
	advertisement: MessageAdvertisement;
	isOffer?: boolean;
}

const Advertisement: FunctionComponent<IChatAdvertisementProps> = function Advertisement(props) {
	const {advertisement, isOffer} = props;
	const templateName = AdvertisementTemplateType[advertisement.template];

	return (
		<div className={`highlight ${templateName ? `highlight--${templateName.toLowerCase()}` : ''}`}>
			<HighlightContainer
				advertisement={advertisement}
				templateName={templateName}
				isOffer={isOffer}
			/>
		</div>
	);
};

export default Advertisement;
