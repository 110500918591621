const isPhoneNumber = (text: string) => {
	const phoneRegex1 = /\+?\d{0,3}\s?\d{1,3}\s?\d{1,3}\s?\w*\s?\d{1,3}\s?\w*\s?\d{1,3}/gim;
	const phoneRegex2 =
		/[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{1,3}[-\s.]?\w*?[0-9]{1,3}[-\s.]?\w*?[0-9]{2,4}/gim;

	const totalDigits = /(?:\d\s*){10,}/.test(text);

	return totalDigits && (phoneRegex1.test(text) || phoneRegex2.test(text));
};

export {isPhoneNumber};
