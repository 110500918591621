import {FunctionComponent} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';

interface IAvatarProps {
	alt: string;
	src: string;
	className: string;
	width?: string;
	height?: string;
	afterLoadHandler?: () => void;
}

const Avatar: FunctionComponent<IAvatarProps> = function Avatar(props) {
	return (
		<LazyLoadImage
			src={props.src}
			alt={props.alt}
			height={props.width || '100%'}
			width={props.height || '100%'}
			className={props.className}
			afterLoad={props.afterLoadHandler}
		/>
	);
};

export default Avatar;
