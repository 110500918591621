import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {AntimatCheckResponseData} from 'models/responses';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';

export default class AntimatService {
	static containsMat = async (
		message: string,
		lang: string,
		project: string,
		antimatUrl: string,
		callBackForError?: () => void
	) => {
		try {
			const params = {
				message,
				lang,
				project,
			};

			const {data, status} = await axios.post<AntimatCheckResponseData>(
				`${antimatUrl}/check/`,
				params
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (callBackForError) {
				callBackForError();
			}

			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			Log.error('Antimat chek error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static checkName = async (
		name: string,
		lang: string,
		project: string,
		antimatUrl: string,
		callBackForError?: () => void
	) => {
		try {
			const params = {
				name,
				lang,
				project,
			};

			const {data, status} = await axios.post<AntimatCheckResponseData>(
				`${antimatUrl}/checkName/`,
				params
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (callBackForError) {
				callBackForError();
			}

			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			Log.error('Antimat chek error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
