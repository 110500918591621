import {Sticker, StickerPack} from 'models/room';
import {action, makeObservable, observable} from 'mobx';

class StickersEmojiService {
	@observable
	public visibleStickersEmoji = false;

	@observable
	public stickerPacks: StickerPack[] = [];

	@action
	public togglleVisibleStickersEmoji = (value: boolean) => {
		this.visibleStickersEmoji = value;
	};

	@action
	public setStickerPacks = (data: StickerPack[]) => {
		this.stickerPacks = data;
	};

	@action
	public removeSticker = (stickerId: number) => {
		this.stickerPacks = this.stickerPacks.map(pack => {
			return {...pack, stickers: pack.stickers.filter(sticker => sticker.id !== stickerId)};
		});
	};

	constructor() {
		makeObservable(this);
	}
}

export default new StickersEmojiService();
