import {action, makeObservable, observable} from 'mobx';
import {Offer} from 'models/offer';

class OfferService {
	@observable
	public offers: Offer[] | null = null;

	@observable
	public offer: Offer | null = null;

	@action
	public setOffers = (value: Offer[]) => {
		this.offers = value;
	};

	@action
	public setOffer = (value: Offer | null) => {
		this.offer = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new OfferService();
