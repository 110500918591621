import {Sticker} from 'models/room';
import roomServices from 'store/roomService';
import stickersEmojiServices from 'store/stickersEmojiService';
import slowModeServices from 'store/slowModeService';
import toastService from 'store/toastService';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect} from 'react';
import './stickers-picker.scss';
import {ReactComponent as IcoStickersChevron} from 'assets/svg/ico-stickers-chevron.svg';
import useStickers from 'hooks/useStickers';

const StickersPicker: FunctionComponent = function StickersPicker() {
	const {roomId} = useLocalObservable(() => roomServices);
	const {stickerPacks} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, slowModeCountdownEnable} = useLocalObservable(() => slowModeServices);
	const {toasts} = useLocalObservable(() => toastService);

	const {sendStickerToChat} = useStickers();

	const renderStickerItem = useCallback(
		(item: Sticker) => (
			<div
				className='stickers-picker__item'
				key={item.id}
				data-sticker-id={item.id}
				data-pack-id={item.stickerPackId}
				style={{backgroundImage: `url('${item.pic}')`}}
			/>
		),
		[stickerPacks]
	);

	useEffect(() => {
		document.addEventListener('click', sendStickerToChat);
		return () => {
			document.removeEventListener('click', sendStickerToChat);
		};
	}, [roomId, slowMode, slowModeCountdownEnable, toasts, stickerPacks]);

	return (
		<div className='stickers-picker'>
			{stickerPacks.map(el => {
				return (
					<div className='stickers-picker__pack' key={el.id}>
						{stickerPacks.length > 1 && (
							<div className='stickers-picker__pack-header'>
								<p className='stickers-picker__pack-name'>{el.name}</p>
								<div className='stickers-picker__pack-chevron'>
									<IcoStickersChevron />
								</div>
							</div>
						)}

						<div className='stickers-picker__items'>{el.stickers.map(renderStickerItem)}</div>
					</div>
				);
			})}
		</div>
	);
};

export default observer(StickersPicker);
