import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';

export default class GambleService {
	static getGamble = async (token: UUID, userId: number, offset: number, limit: number) => {
		try {
			const {data, status} = await axios.get(apiPath.getGamble(userId, offset, limit), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response?.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};
}
