import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';

interface IButtonPinnedProps {
	message: Message;
}

const ButtonPinned: FunctionComponent<IButtonPinnedProps> = function ButtonPinned({message}) {
	const {isPinned: messageIsPinned} = message;
	const {appIcons} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);

	const {chatScreen} = useL10n();
	const {togglePinMessage} = useSubmenuActions();
	const {getAppIcon} = useAppData();

	const {IcoSubmenuPin, IcoUnpin} = appIcons;

	const messagePinHandler = () => {
		if (roomId) {
			togglePinMessage(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={messagePinHandler}>
			{messageIsPinned ? (
				<>
					{chatScreen.chatSubmenu.unpinMessage}
					{getAppIcon(IcoUnpin.pic, {className: 'chat__submenu-icon'})}
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.pinMessage}
					{getAppIcon(IcoSubmenuPin.pic, {className: 'chat__submenu-icon'})}
				</>
			)}
		</Button>
	);
};

export default observer(ButtonPinned);
