import {action, computed, makeObservable, observable} from 'mobx';
import {User} from 'models/user';

class UserService {
	@observable
	public userData: User | null = null;

	@observable
	public accessToken: UUID = '';

	@observable
	public userId = '';

	@observable
	public decryptedUserId = '';

	@observable
	public isUserModer: boolean | null = null;

	@observable
	public translateMode = {
		enable: false,
		lang: {
			languageCode: '',
			displayName: '',
		},
	};

	@action
	public setUserModer = (value: boolean) => {
		this.isUserModer = value;
	};

	@action
	public setUserId = (id: string) => {
		this.userId = id;
	};

	@action
	public setDecryptedUserId = (id: string) => {
		this.decryptedUserId = id;
	};

	@action
	public setUserData = (userData: User) => {
		this.userData = userData;
	};

	@action
	public updateUserData = (data: User) => {
		this.userData = {...this.userData, ...data};
	};

	@action
	public setAccessToken = (value: UUID) => {
		this.accessToken = value;
	};

	@action
	public clearUserData = () => {
		this.userData = null;
		this.accessToken = '';
		this.userId = '';
	};

	@action
	public setTranslateMode = (value: any) => {
		this.translateMode = {...this.translateMode, ...value};
	};

	@computed
	get isUserExternalIdCorrect() {
		return this.userData && this.userData.externalId !== '0';
	}

	constructor() {
		makeObservable(this);
	}
}

export default new UserService();
