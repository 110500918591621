import {AppEmotion} from 'models/app';
import emotionServices from 'store/emotionService';
import {FunctionComponent, useEffect, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './emotions-anim.scss';

const TIMEOUT_ANIMATION = 5000;

interface IEmotionAnimItemProps {
	emotion: AppEmotion;
}

const EmotionAnimItem: FunctionComponent<IEmotionAnimItemProps> = function EmotionAnimItem({
	emotion,
}) {
	const emotionRef: {current: NodeJS.Timeout | null} = useRef(null);
	const {emotions, removeEmotion} = useLocalObservable(() => emotionServices);

	const getEmotionSrc = () => {
		const findEmotion = emotions.find(e => e.id === emotion.id);
		return `url('${findEmotion?.animation || findEmotion?.pic}')`;
	};

	const clearEmotionRef = () => {
		if (emotionRef.current) {
			clearTimeout(emotionRef.current);
		}
	};

	useEffect(() => {
		clearEmotionRef();
		emotionRef.current = setTimeout(() => {
			removeEmotion(emotion);
		}, TIMEOUT_ANIMATION);
		return () => {
			clearEmotionRef();
		};
	}, []);

	return (
		<div
			className='emotions-anim__item'
			data-anim={emotion.versionAnim}
			style={{backgroundImage: getEmotionSrc()}}
		/>
	);
};

export default observer(EmotionAnimItem);
