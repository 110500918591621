enum MessageType {
	USER = 'USER',
	VOTE = 'VOTE',
	ADVERTISEMENT = 'ADVERTISEMENT',
	POLLRESULTS = 'POLL_RESULTS',
	STICKER = 'STICKER',
	BET = 'BET',
	GAMBLE = 'GAMBLE',
	PIC = 'PIC',
}

export default MessageType;
