/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {START_NUMBER_OF_MESSAGES, MAXIMUM_NUMBER_OF_MESSAGES} from 'constants/constants';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import {
	RegisterResponseData,
	GetBlockedUsersData,
	BlockedUserData,
	DeleteBlockedUserData,
	GetMessagesData,
	GetAllDataOnLoad,
	GetStickersData,
	GetUserLastMessageData,
} from 'models/responses';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';
import {PollCreated, PollCreatedOption} from 'models/poll';

export default class RoomService {
	static getRoomData = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getRoomData(externalRoomId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get room data error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBlockedUsers = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<GetBlockedUsersData>(apiPath.blockUser, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get blocked users error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static blockUser = async (targetId: number, token: UUID) => {
		try {
			const params = {
				targetId,
			};

			const {data, status} = await axios.post<BlockedUserData>(apiPath.blockUser, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Block user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteBlockedUser = async (targetId: number, token: UUID) => {
		try {
			const params = {
				targetId,
			};

			const {data, status} = await axios.delete<DeleteBlockedUserData>(apiPath.blockUser, {
				headers: {Authorization: `Bearer ${token}`},
				data: params,
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Delete blocked user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static reportUser = async (messageId: number, reason: string, token: UUID) => {
		try {
			const params = {
				messageId,
				reason,
			};

			const {data, status} = await axios.post<RegisterResponseData>(apiPath.reportUser, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Report user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDataOnLoad = async (
		token: UUID,
		externalRoomId: string,
		limit?: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = apiPath.getAllDataOnload(externalRoomId);

			apiUrl = `${apiUrl}?limit=${limit}`;
			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get<GetAllDataOnLoad>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get messages error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessages = async (
		token: UUID,
		externalRoomId: string,
		limit?: number,
		firstId?: number,
		lastId?: number,
		onlySubscriptions?: number
	) => {
		try {
			const creationApiUrl = () => {
				let apiUrl = apiPath.getMessages(externalRoomId);
				apiUrl = `${apiUrl}?limit=${limit || START_NUMBER_OF_MESSAGES}`;

				if (typeof firstId !== 'undefined') {
					apiUrl = `${apiUrl}&firstId=${firstId}`;
				}

				if (lastId && typeof lastId !== 'undefined') {
					apiUrl = `${apiUrl}&lastId=${lastId}`;
				}

				if (onlySubscriptions !== undefined)
					apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

				return apiUrl;
			};

			const {data, status} = await axios.get<GetMessagesData>(creationApiUrl(), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getMessages users error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessagesAround = async (
		token: UUID,
		externalRoomId: string,
		messageId: number,
		limit?: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = `${apiPath.getMessagesAround(externalRoomId)}?limit=${
				limit || MAXIMUM_NUMBER_OF_MESSAGES
			}&messageId=${messageId}`;

			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get<GetMessagesData>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get messages around error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPinnedMessages = async (token: UUID, externalRoomId: string) => {
		try {
			const apiUrl = apiPath.getPinnedMessages(externalRoomId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse({}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get pinned messages error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getUserLastMessage = async (token: UUID, externalRoomId: string, userId: number) => {
		try {
			const apiUrl = apiPath.getUserLastMessage(externalRoomId, userId);
			const {data, status} = await axios.get<GetUserLastMessageData>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get user last message error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTalkersCount = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getTalkersCount(externalRoomId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getTalkersCount user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTalkers = async (
		externalRoomId: string,
		token: UUID,
		limit: number,
		offset: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = apiPath.getActiveTalkers(externalRoomId, limit, offset);
			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getTalkers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSpeakers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getActiveSpeakers(externalRoomId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getSpeakers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBannedTalkers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getBannedTalkers(externalRoomId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getBannedTalkers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getWaitingSpeakers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.getWaitingSpeakers(externalRoomId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getWaitingSpeakers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPoll = async (token: UUID, externalRoomId: string) => {
		try {
			const apiUrl = apiPath.getPoll(externalRoomId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse({}, status); // hack -_-

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getPoll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setPoll = async (
		pollCreateData: PollCreated,
		token: UUID,
		pollWithPics: {value: boolean}
	) => {
		try {
			let elementsWoLocalId: any = pollCreateData;
			if (pollCreateData.options) {
				elementsWoLocalId = pollCreateData.options.map((el: PollCreatedOption) => {
					if (!pollWithPics.value) {
						const {id, pic, ...elRest} = el;
						return elRest;
					}
					const {id, ...elRest} = el;
					return elRest;
				});
			}

			const {data, status} = await axios.post(
				apiPath.setPoll,
				{...pollCreateData, options: elementsWoLocalId},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Set vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadPollOptionPicture = async (FormData: FormData, token: UUID) => {
		try {
			const {data, status} = await axios.post(apiPath.uploadPollOptionPic, FormData, {
				headers: {Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data'},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('uploadPollOptionPicture error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendPollVote = async (externalRoomId: string, optionIds: number[], token: UUID) => {
		try {
			const params = {
				optionIds,
			};

			const {data, status} = await axios.post(apiPath.sendPollVote(externalRoomId), params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static endPoll = async (externalRoomId: string, token: UUID, share?: boolean) => {
		try {
			let apiPathEndPoll = apiPath.endPoll(externalRoomId);

			if (typeof share !== 'undefined') {
				apiPathEndPoll = `${apiPathEndPoll}/?share=${share ? 1 : 0}`;
			}

			const {data, status} = await axios.get(apiPathEndPoll, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('End poll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static removePoll = async (externalRoomId: string, pollId: number, token: UUID) => {
		try {
			const {data, status} = await axios.delete(apiPath.deletePoll(externalRoomId, pollId), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Delete poll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendViewAdvertisement = async (advertisementId: number, token: UUID) => {
		try {
			const {data, status} = await axios.post(
				apiPath.viewAdvertisement(advertisementId),
				{},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStickers = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<GetStickersData>(apiPath.sticker, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get stickers error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendPicMessage = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(apiPath.picMessage, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStreamKey = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(apiPath.getStreamKey, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
