import showErrorCritical from 'utils/showErrorCritical';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import Auth from 'components/auth/index';
import Disabled from 'pages/disabled/Disabled';

const rootNode = document.getElementById('root');

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PROJECT) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_PROJECT,
		integrations: [
			new BrowserTracing({
				heartbeatInterval: 1000,
				startTransactionOnPageLoad: false,
			}),
		],
		tracesSampleRate: 0.6,
	});
}

try {
	ReactDOM.render(
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>
				<Switch>
					<Route path='/disabled'>
						<Disabled />
					</Route>
					<Auth />
				</Switch>
			</DndProvider>
		</BrowserRouter>,
		rootNode
	);
} catch (e: any) {
	showErrorCritical(e);
}
