/* eslint-disable max-lines */
import {PollEditActionType} from 'models/enums/Poll.enum';
import {Talker} from 'models/room';
import {action, computed, makeObservable, observable} from 'mobx';
import {User} from 'models/user';

class ModalService {
	@observable
	public textareaHeight = 0;

	@observable
	public settingsModalVisible = false;

	@observable
	public profileModalVisible = false;

	@observable
	public blockedModalVisible = false;

	@observable
	public visibleModalUsers = false;

	@observable
	public waitingSpeakersModalVisible = false;

	@observable
	public languageModalVisible = false;

	@observable
	public pollCreateModalVisible = false;

	@observable
	public onboardModalVisible = false;

	@observable
	public pollEditModalVisible: {
		visible: boolean;
		typeAction: PollEditActionType;
	} = {
		visible: false,
		typeAction: PollEditActionType.EDIT,
	};

	@observable
	public pollModalVisible = false;

	@observable
	public rulesModalVisible = false;

	@observable
	public changeNameModal = false;

	@observable
	public feedbackAudioModal = false;

	@observable
	public slowModeModal = false;

	@observable
	public slowModeTimeoutModal = false;

	@observable
	public chatBotRulesModal = false;

	@observable
	public shareBetModal = false;

	@observable
	public recordingModalVisible = false;

	@observable
	public agreementAndChatRulesModal = false;

	@observable
	public reportModalVisible = false;

	@observable
	public welcomeModalVisible = false;

	@observable
	public agoraDevicesModalVisible = false;

	@observable
	public translateModalVisible = false;

	@observable
	public languagesModalVisible = false;

	@observable
	public screenShareWarnModalVisible = false;

	@observable
	public offerModalVisible = false;

	@observable
	public shareGambleModalVisible = false;

	@observable
	public visibleThreadModal = false;

	@observable
	public badgeModalVisible = false;

	@observable
	public imagePreviewModalVisible = false;

	@observable
	public widgetPreviewModalVisible = false;

	@observable
	public streamSettingsModalVisible = false;

	@observable
	public reportModalProps: {
		report?: {
			talker: Talker;
			user?: User;
			target: 'message' | 'user';
			messageId?: number;
			blockUserByUserAvailable?: boolean;
		};
		ban?: {name: string; userId: number};
	} | null = null;

	@action
	public setTextareaHeight = (visible: number) => {
		this.textareaHeight = visible;
	};

	@action
	public toggleChangeNameModal = (visible: boolean) => {
		this.changeNameModal = visible;
	};

	@action
	public showSettingsModal = () => {
		this.settingsModalVisible = true;
	};

	@action
	public hideSettingsModal = () => {
		this.settingsModalVisible = false;
	};

	@action
	public showProfileModal = () => {
		this.profileModalVisible = true;
	};

	@action
	public hideProfileModal = () => {
		this.profileModalVisible = false;
	};

	@action
	public showBlockedModal = () => {
		this.blockedModalVisible = true;
	};

	@action
	public hideBlockedModal = () => {
		this.blockedModalVisible = false;
	};

	@action
	public hideVisibleModalUsers = () => {
		this.visibleModalUsers = false;
	};

	@action
	public showVisibleModalUsers = () => {
		this.visibleModalUsers = true;
	};

	@action
	public toggleWaitingSpeakersModal = (visible: boolean) => {
		this.waitingSpeakersModalVisible = visible;
	};

	@action
	public toggleLanguageModal = (visible: boolean) => {
		this.languageModalVisible = visible;
	};

	@action
	public togglePollCreateModalVisible = (visible: boolean) => {
		this.pollCreateModalVisible = visible;
	};

	@action
	public togglePollEditModalVisible = (
		visible: boolean,
		typeAction: PollEditActionType = PollEditActionType.EDIT
	) => {
		this.pollEditModalVisible = {visible, typeAction};
	};

	@action
	public togglePollModalVisible = (visible: boolean) => {
		this.pollModalVisible = visible;
	};

	@action
	public toggleRulesModal = (visible: boolean) => {
		this.rulesModalVisible = visible;
	};

	@action
	public toggleOnboardModal = (visible: boolean) => {
		this.onboardModalVisible = visible;
	};

	@action
	public toggleFeedbackAudioModal = (visible: boolean) => {
		this.feedbackAudioModal = visible;
	};

	@action
	public toggleSlowModeModal = (visible: boolean) => {
		this.slowModeModal = visible;
	};

	@action
	public toggleSlowModeTimeoutModal = (visible: boolean) => {
		this.slowModeTimeoutModal = visible;
	};

	@action
	public toggleChatBotRulesModal = (visible: boolean) => {
		this.chatBotRulesModal = visible;
	};

	@action
	public toggleShareBetModal = (visible: boolean) => {
		this.shareBetModal = visible;
	};

	@action
	public toggleRecordingModal = (visible: boolean) => {
		this.recordingModalVisible = visible;
	};

	@action
	public toggleAgreementAndChatRulesModal = (visible: boolean) => {
		this.agreementAndChatRulesModal = visible;
	};

	@action
	public toggleWelcomeModalVisible = (visible: boolean) => {
		this.welcomeModalVisible = visible;
	};

	@action
	public toggleAgoraDevicesModalVisible = (visible: boolean) => {
		this.agoraDevicesModalVisible = visible;
	};

	@action
	public toggleTranslateModalVisible = (visible: boolean) => {
		this.translateModalVisible = visible;
	};

	@action
	public toggleLanguagesModalVisible = (visible: boolean) => {
		this.languagesModalVisible = visible;
	};

	@action
	public setScreenShareWarnModalVisible = (visible: boolean) => {
		this.screenShareWarnModalVisible = visible;
	};

	@action
	public toggleOfferModalVisible = (visible: boolean) => {
		this.offerModalVisible = visible;
	};

	@action
	public toggleShareGambleModalVisible = (visible: boolean) => {
		this.shareGambleModalVisible = visible;
	};

	@action
	public toggleVisibleThreadModal = (visible: boolean) => {
		this.visibleThreadModal = visible;
	};

	@action
	public toggleBadgeModalVisible = (visible: boolean) => {
		this.badgeModalVisible = visible;
	};

	@action
	public toggleImagePreviewModalVisible = (visible: boolean) => {
		this.imagePreviewModalVisible = visible;
	};

	@action
	public toggleWidgetPreviewModalVisible = (visible: boolean) => {
		this.widgetPreviewModalVisible = visible;
	};

	@action
	public toggleStreamSettingsModalVisible = (visible: boolean) => {
		this.streamSettingsModalVisible = visible;
	};

	@action
	public setReportModal = (props: {
		report?: {
			talker: Talker;
			user?: User;
			target: 'message' | 'user';
			messageId?: number;
			blockUserByUserAvailable?: boolean;
		};
		ban?: {name: string; userId: number};
	}) => {
		this.reportModalProps = {...props};
		this.reportModalVisible = true;
	};

	@action
	public toggleReportModalVisible = (visible: boolean) => {
		this.reportModalVisible = visible;
	};

	@action
	public hideAllModals = (data?: {keepThreadModalVisible: boolean}) => {
		this.settingsModalVisible = false;
		this.profileModalVisible = false;
		this.blockedModalVisible = false;
		this.visibleModalUsers = false;
		this.waitingSpeakersModalVisible = false;
		this.languageModalVisible = false;
		this.onboardModalVisible = false;
		this.pollCreateModalVisible = false;
		this.pollEditModalVisible = {
			visible: false,
			typeAction: PollEditActionType.EDIT,
		};
		this.pollModalVisible = false;
		this.rulesModalVisible = false;
		this.changeNameModal = false;
		this.feedbackAudioModal = false;
		this.slowModeModal = false;
		this.slowModeTimeoutModal = false;
		this.chatBotRulesModal = false;
		this.shareBetModal = false;
		this.recordingModalVisible = false;
		this.agreementAndChatRulesModal = false;
		this.reportModalVisible = false;
		this.reportModalProps = null;
		this.welcomeModalVisible = false;
		this.agoraDevicesModalVisible = false;
		this.screenShareWarnModalVisible = false;
		this.offerModalVisible = false;
		this.shareGambleModalVisible = false;
		if (!data?.keepThreadModalVisible) this.visibleThreadModal = false;
		this.badgeModalVisible = false;
		this.imagePreviewModalVisible = false;
		this.widgetPreviewModalVisible = false;
		this.streamSettingsModalVisible = false;
	};

	@action
	public hideAllPollModals = () => {
		this.pollCreateModalVisible = false;
		this.pollEditModalVisible = {
			visible: false,
			typeAction: PollEditActionType.EDIT,
		};
		this.pollModalVisible = false;
		this.rulesModalVisible = false;
	};

	@computed
	get isModalOpen() {
		return (
			this.settingsModalVisible ||
			this.profileModalVisible ||
			this.blockedModalVisible ||
			this.visibleModalUsers ||
			this.waitingSpeakersModalVisible ||
			this.languageModalVisible ||
			this.onboardModalVisible ||
			this.pollCreateModalVisible ||
			this.pollEditModalVisible.visible ||
			this.pollModalVisible ||
			this.rulesModalVisible ||
			this.changeNameModal ||
			this.feedbackAudioModal ||
			this.slowModeModal ||
			this.slowModeTimeoutModal ||
			this.chatBotRulesModal ||
			this.shareBetModal ||
			this.recordingModalVisible ||
			this.agreementAndChatRulesModal ||
			this.reportModalVisible ||
			this.welcomeModalVisible ||
			this.agoraDevicesModalVisible ||
			this.translateModalVisible ||
			this.languagesModalVisible ||
			this.screenShareWarnModalVisible ||
			this.offerModalVisible ||
			this.shareGambleModalVisible ||
			this.visibleThreadModal ||
			this.badgeModalVisible ||
			this.imagePreviewModalVisible ||
			this.widgetPreviewModalVisible ||
			this.streamSettingsModalVisible
		);
	}

	constructor() {
		makeObservable(this);
	}
}

export default new ModalService();
