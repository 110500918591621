const containsOnlyOneEmojiSymbol = (input: string) => {
	return (
		input.trim().length === 2 &&
		input.match(
			/^\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\p{Emoji_Presentation}|\p{Extended_Pictographic}$/u
		) !== null
	);
};

export default containsOnlyOneEmojiSymbol;
