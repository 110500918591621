/* eslint-disable max-lines */
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import AppAuthStatus from 'models/enums/AppAuthStatus.enum';
import AppThemes from 'models/enums/AppThemes.enum';
import appService from 'store/appService';
import userServices from 'store/userService';
import slowModeServices from 'store/slowModeService';
import chatServices from 'store/chatService';
import emotionService from 'store/emotionService';
import SettingsService from 'services/api/SettingsService';
import useSearchParams from 'hooks/useSearchParams';
import useTheme from 'hooks/useTheme';
import {useLocalObservable} from 'mobx-react-lite';
import * as amplitude from '@amplitude/analytics-browser';
import InterfaceDirection from 'models/enums/InterfaceDirection.enum';
import {Settings} from 'models/settings';
import {rtlLanguages} from 'constants/constants';
import {set} from 'lodash';

const HTMLNode = document.querySelector('html');

const useSettings = () => {
	const {
		language,
		setAppAuthStatus,
		setAppAvatars,
		setAppIcons,
		setAppLoaderIcon,
		setLanguage,
		setRules,
		setFeedbackEmail,
		toggleAppExitButtonVisible,
		toggleAppSettingsButtonVisible,
		toggleAppBettingButtonVisible,
		toggleAppUserCounterButtonVisible,
		toggleAppShowAudioQualityCheckPopup,
		toggleEnableAppAgreementAndRules,
		toggleEnableAvatarChange,
		toggleEnableNameChange,
		toggleAppEnableOnboarding,
		toggleAppEnableProfileDeletion,
		setAmplitudeApiKey,
		setInterfaceDirection,
		toggleAppEnableUserStatus,
		toggleAppEnableDecrypt,
		toggleAppEnableRegistrationName,
		toggleAppEnableRegistrationPicUrl,
		toggleAppEnableRegistrationPicId,
		toggleAppEnableNameVerification,
		setIsAppSettings,
		toggleAppEnableAgora,
		toggleAppShowAddToCalendarButton,
		toggleAppShowShareButton,
		setCopybettingType,
		setCopybettingVersion,
		toggleAppLowerUsersModule,
		setShowDefaultStickers,
		setEnableOpenProfile,
		setOpenProfileType,
		setEnableLinksBlock,
		setEnableMessageTranslation,
		setEnableOauth,
		toggleAppEnableWelcome,
		toggleWelcomeShowOnce,
		setWelcomeShowFrequency,
		toggleWelcomeIsDelayed,
		setWelcomeDelay,
		setEnableScreenSharing,
		setEnableRoomSpeak,
		setAppEnableGamble,
		toggleAppShowNameToast,
		setAppEnableDomainWhitelist,
		setAppDomainWhitelist,
		toggleAppEnableMessagePics,
		setAppShowAnimatedEmotions,
		setRoomStatuses,
		setAppEnableSubscriptionFilter,
		setAppEnableThreads,
		setProjectLanguage,
		setIsStand,
		setaApEnableFullscreenOption,
		setaAppGambleOpenType,
		setaAppEnableWidgetText,
		setAppEnableContactUsButton,
	} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {setGreetingMessage, setChatBot} = useLocalObservable(() => chatServices);
	const {setEmotions} = useLocalObservable(() => emotionService);

	const {colorSchemeModeFromUrl, langFromUrl} = useSearchParams();
	const {setTheme, setFont} = useTheme();

	const applySettings = (settings: Settings) => {
		const {
			enableSlowmode,
			slowmodeDelayMS,
			colors,
			lang,
			font,
			icons,
			loader,
			avatars,
			emotions,
			greeting,
			showBettingButton,
			showExitButton,
			showProfileSettings,
			showUserCounter,
			showAudioQualityCheckPopup,
			showRules,
			feedbackEmail,
			enableAvatarChange,
			enableNameChange,
			enableOnboarding,
			amplitudeApiKey,
			enableProfileDeletion,
			enableUserStatus,
			enableDecrypt,
			enableRegistrationName,
			enableRegistrationPicUrl,
			enableRegistrationPicId,
			enableNameVerification,
			enableAgora,
			showAddToCalendarButton,
			showShareButton,
			copybettingType,
			enableLowerUsersModule,
			copybettingVersion,
			showDefaultStickers,
			enableOpenProfile,
			openProfileType,
			enableLinksBlock,
			enableMessageTranslation,
			enableOauth,
			enableWelcome,
			welcomeShowOnce,
			welcomeShowFrequency,
			welcomeIsDelayed,
			welcomeDelay,
			enableScreenSharing,
			enableRoomSpeak,
			enableGamble,
			showNameToast,
			enableDomainWhitelist,
			domainWhitelist,
			enableMessagePics,
			showAnimatedEmotions,
			roomStatuses,
			enableOnlySubscriptionsFilter,
			enableThreads,
			enableFullscreenOption,
			isStand,
			gambleOpenType,
			enableWidgetText,
			enableContactUsButton,
		} = settings;

		if (typeof enableSlowmode !== 'undefined' && typeof slowmodeDelayMS !== 'undefined') {
			togglleSlowMode({
				global: {
					enable: enableSlowmode,
					time: slowmodeDelayMS || 1000,
				},
			});
		}

		if (lang && !langFromUrl) {
			setLanguage(lang);
			if (rtlLanguages.includes(lang)) {
				setInterfaceDirection(InterfaceDirection.RTL);
				HTMLNode?.setAttribute('rtl', 'true');
			}
		}

		if (lang) setProjectLanguage(lang);

		if (colors?.colors && Object.entries(JSON.parse(colors.colors)).length) {
			setTheme(colors);
		}

		// if (font?.urls && Object.entries(JSON.parse(font.urls)).length) {
		setFont(font);
		// }

		if (icons?.length) {
			setAppIcons(icons);
		}

		if (loader) {
			setAppLoaderIcon(loader);
		}

		if (avatars?.length) {
			setAppAvatars(avatars);
		}

		if (emotions?.length) {
			setEmotions(emotions);
		}

		if (greeting) {
			setGreetingMessage(greeting);
		}

		if (feedbackEmail) {
			setFeedbackEmail(feedbackEmail);
		}

		if (amplitudeApiKey) {
			setAmplitudeApiKey(amplitudeApiKey);
			amplitude.init(amplitudeApiKey);
		}

		if (typeof showExitButton !== 'undefined') {
			toggleAppExitButtonVisible(showExitButton);
		}

		if (typeof showProfileSettings !== 'undefined') {
			toggleAppSettingsButtonVisible(showProfileSettings);
		}

		if (typeof showBettingButton !== 'undefined') {
			toggleAppBettingButtonVisible(showBettingButton);
		}

		if (typeof showUserCounter !== 'undefined') {
			toggleAppUserCounterButtonVisible(showUserCounter);
		}

		if (typeof showAudioQualityCheckPopup !== 'undefined') {
			toggleAppShowAudioQualityCheckPopup(showAudioQualityCheckPopup);
		}

		if (typeof showRules !== 'undefined') {
			toggleEnableAppAgreementAndRules(showRules);
		}

		if (typeof enableAvatarChange !== 'undefined') {
			toggleEnableAvatarChange(enableAvatarChange);
		}

		if (typeof enableNameChange !== 'undefined') {
			toggleEnableNameChange(enableNameChange);
		}

		if (typeof enableOnboarding !== 'undefined') {
			toggleAppEnableOnboarding(enableOnboarding);
		}

		if (typeof enableProfileDeletion !== 'undefined') {
			toggleAppEnableProfileDeletion(enableProfileDeletion);
		}

		if (typeof enableUserStatus !== 'undefined') {
			toggleAppEnableUserStatus(enableUserStatus);
		}

		if (typeof enableDecrypt !== 'undefined') {
			toggleAppEnableDecrypt(enableDecrypt);
		}

		if (typeof enableRegistrationName !== 'undefined') {
			toggleAppEnableRegistrationName(enableRegistrationName);
		}

		if (typeof enableRegistrationPicId !== 'undefined') {
			toggleAppEnableRegistrationPicId(enableRegistrationPicId);
		}

		if (typeof enableRegistrationPicUrl !== 'undefined') {
			toggleAppEnableRegistrationPicUrl(enableRegistrationPicUrl);
		}

		if (typeof enableNameVerification !== 'undefined') {
			toggleAppEnableNameVerification(enableNameVerification);
		}

		if (typeof enableAgora !== 'undefined') {
			toggleAppEnableAgora(enableAgora);
		}

		if (showAddToCalendarButton) {
			toggleAppShowAddToCalendarButton(showAddToCalendarButton);
		}

		if (showShareButton) {
			toggleAppShowShareButton(showShareButton);
		}

		if (copybettingType) setCopybettingType(copybettingType);

		if (copybettingVersion) setCopybettingVersion(copybettingVersion);

		if (enableLowerUsersModule) toggleAppLowerUsersModule(enableLowerUsersModule);

		if (typeof showDefaultStickers !== 'undefined') setShowDefaultStickers(showDefaultStickers);

		if (typeof enableOpenProfile !== 'undefined') setEnableOpenProfile(enableOpenProfile);

		if (typeof openProfileType !== 'undefined') setOpenProfileType(openProfileType);

		if (typeof enableLinksBlock !== 'undefined') setEnableLinksBlock(enableLinksBlock);

		if (typeof enableMessageTranslation !== 'undefined')
			setEnableMessageTranslation(enableMessageTranslation);

		if (typeof enableOauth !== 'undefined') setEnableOauth(enableOauth);

		if (enableWelcome) toggleAppEnableWelcome(enableWelcome);
		if (welcomeShowOnce) toggleWelcomeShowOnce(welcomeShowOnce);
		if (welcomeShowFrequency) setWelcomeShowFrequency(welcomeShowFrequency);
		if (welcomeIsDelayed) toggleWelcomeIsDelayed(welcomeIsDelayed);
		if (welcomeDelay) setWelcomeDelay(welcomeDelay);

		if (enableScreenSharing) setEnableScreenSharing(enableScreenSharing);

		if (enableRoomSpeak) setEnableRoomSpeak(enableRoomSpeak);

		if (enableGamble) setAppEnableGamble(enableGamble);
		if (showNameToast) toggleAppShowNameToast(showNameToast);
		if (enableDomainWhitelist) setAppEnableDomainWhitelist(enableDomainWhitelist);
		if (domainWhitelist) setAppDomainWhitelist(domainWhitelist);
		if (typeof enableMessagePics !== 'undefined') toggleAppEnableMessagePics(enableMessagePics);
		if (typeof showAnimatedEmotions !== 'undefined')
			setAppShowAnimatedEmotions(showAnimatedEmotions);

		if (roomStatuses && roomStatuses.length) setRoomStatuses(roomStatuses);

		if (typeof setAppEnableSubscriptionFilter !== 'undefined')
			setAppEnableSubscriptionFilter(enableOnlySubscriptionsFilter);

		if (typeof enableThreads !== 'undefined') setAppEnableThreads(enableThreads);
		if (isStand) setIsStand(isStand);
		if (typeof enableFullscreenOption !== 'undefined')
			setaApEnableFullscreenOption(enableFullscreenOption);
		if (typeof gambleOpenType !== 'undefined') setaAppGambleOpenType(gambleOpenType);
		if (typeof enableWidgetText !== 'undefined') setaAppEnableWidgetText(enableWidgetText);
		if (typeof enableContactUsButton !== 'undefined')
			setAppEnableContactUsButton(enableContactUsButton);

		setIsAppSettings(true);
		setAppAuthStatus(AppAuthStatus.SUCCESS);
	};

	const getSettingsPartnerWithServices = async (apiKey?: string) => {
		if (apiKey) {
			if (
				colorSchemeModeFromUrl &&
				Object.values(AppThemes).includes(colorSchemeModeFromUrl.toUpperCase() as AppThemes)
			) {
				HTMLNode?.setAttribute('mode', colorSchemeModeFromUrl.toLowerCase());

				const responseSettingsThemes = await SettingsService.getSettingsThemes(apiKey);
				if (responseSettingsThemes.status === ResponseStatus.SUCCESS) {
					const findThemeId = responseSettingsThemes.data.find(
						themeItem => themeItem.name === colorSchemeModeFromUrl.toUpperCase()
					)?.id;
					if (findThemeId) {
						const responseSettingsTheme = await SettingsService.getSettingsTheme(
							apiKey,
							findThemeId
						);
						if (responseSettingsTheme.status === ResponseStatus.SUCCESS) {
							applySettings(responseSettingsTheme.data);
							return;
						}
					}
				}
			}

			const responseSettings = await SettingsService.getSettings(apiKey);
			if (responseSettings.status === ResponseStatus.SUCCESS) {
				applySettings(responseSettings.data);
				return;
			}
		}
		setAppAuthStatus(AppAuthStatus.ERROR);
	};

	const getRulesWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getRules(accessToken, language);
			if (response.status === ResponseStatus.SUCCESS) {
				setRules(response.data);
			}
		}
	};

	const getChatBotWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getChatBot(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setChatBot(response.data);
			}
		}
	};

	const getGreetingWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getGreetingMessage(accessToken, language);
			if (response.status === ResponseStatus.SUCCESS) {
				setGreetingMessage(response.data);
			}
		}
	};

	return {
		getSettingsPartnerWithServices,
		getRulesWithServices,
		getChatBotWithServices,
		getGreetingWithServices,
	};
};

export default useSettings;
