import {times} from 'data/slowModeData';
import {MODAL_STYLE} from 'constants/constants';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import slowModeServices from 'store/slowModeService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoCheckmark} from 'assets/svg/ico-checkmark.svg';
import './slowModeTime.scss';

const SlowModeTimeoutModal: FunctionComponent = function SlowModeTimeoutModal() {
	const {modals} = useL10n();
	const {language, appIcons} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomServices);
	const {slowMode, togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {slowModeTimeoutModal, toggleSlowModeTimeoutModal} = useLocalObservable(
		() => modalServices
	);

	const {getAppIcon} = useAppData();

	const {IcoClose} = appIcons;

	const checkSlowModeActiveTime = (time: number) => {
		if (slowMode.local.time === time) {
			return true;
		}
		return false;
	};

	const onCloseModalHandler = () => {
		toggleSlowModeTimeoutModal(false);
	};

	const onClickSlowModeTimeHandler = (time: number) => {
		if (roomId) {
			togglleSlowMode({
				local: {
					enable: slowMode.local.enable,
					time,
				},
			});
			SocketIoServices.emitSlowMode({
				externalRoomId: roomId,
				isSlowmode: slowMode.local.enable,
				slowmodeDelayMS: time,
			});
		}
	};

	const renderTime = useCallback(
		(item: {
			id: number;
			title: {
				ru: {
					default: string;
					prefix?: string;
				};
				en: {
					default: string;
					prefix?: string;
				};
			};
			time: number;
		}) => {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				item.title,
				language,
				{default: item.title.ru.default}
			);
			return (
				<div className='slow-mode-time__item' key={item.id}>
					<Button
						className='slow-mode-time__button'
						onClick={() => onClickSlowModeTimeHandler(item.time)}>
						{findTitle.default}
						{checkSlowModeActiveTime(item.time) && (
							<span className='slow-mode-time__button-ico'>
								<IcoCheckmark />
							</span>
						)}
					</Button>
				</div>
			);
		},
		[slowMode, language]
	);

	if (!slowModeTimeoutModal) {
		return null;
	}

	return (
		<Modal
			className='modal'
			isOpen={slowModeTimeoutModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.slowModeTimeout.title}</div>
				<Button className='modal__close' onClick={onCloseModalHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='slow-mode-time'>
						<div className='slow-mode-time__items'>{times.map(renderTime)}</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(SlowModeTimeoutModal);
