import {action, makeObservable, observable} from 'mobx';

class AttachService {
	@observable
	public files: {file: File | null; isChecked?: boolean; isVisible?: boolean}[] = [];

	@observable
	public isMessagePreview = false;

	@observable
	public isTyping = false;

	@action
	public setFiles = (
		data: {file: File | null; isChecked?: boolean; url?: string; isVisible?: boolean}[]
	) => {
		this.files = data;
	};

	@action
	public setIsMessagePreview = (data: boolean) => {
		this.isMessagePreview = data;
	};

	@action
	public setIsTyping = (data: boolean) => {
		this.isTyping = data;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new AttachService();
