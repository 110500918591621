import {PollType} from 'models/enums/Poll.enum';
import {PollOption} from 'models/poll';
import pollServices from 'store/pollService';
import usePollActions from 'hooks/usePollActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {PollOptionVoting} from '../pollOptionVoting';

interface IPollOptionProps {
	option: PollOption;
	isInteractive: boolean;
	isSelected: boolean;
	isViewTypeEdit: boolean;
	voteVisible: boolean;
}

const PollOptionItem: FunctionComponent<IPollOptionProps> = function PollOptionItem({
	option,
	isInteractive,
	isSelected,
	isViewTypeEdit,
	voteVisible,
}) {
	const {id, pic, text, isRight} = option;
	const {onSendPollVote, calcPollOptionVote} = usePollActions();
	const {poll, pollAlreadyVoted, addPollOptionChosen, removePollOptionChosen} = useLocalObservable(
		() => pollServices
	);

	const isMultiple = typeof poll?.isMultiple !== 'undefined' && poll.isMultiple;
	const highlightOptionItem = !isViewTypeEdit && poll?.type === PollType.QUIZ && pollAlreadyVoted;

	const pollOptionItemClasses = classNames('poll-options__item', {
		// если нет картинки при редактировании
		'poll-options__item--no-pic': isViewTypeEdit && !pic,
		// выбранный вариант
		'poll-options__item--selected': isSelected,
		// верный вариант для викторины
		'poll-options__item--correct': highlightOptionItem && isRight,
		// неверный вариант для викторины
		'poll-options__item--not-correct': highlightOptionItem && !isRight,
	});

	const onChangePolloptionHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {target} = event;

		if (isMultiple) {
			if (target.checked) {
				addPollOptionChosen(option);
				return;
			}
			removePollOptionChosen(option);
			return;
		}

		addPollOptionChosen(option);
		onSendPollVote(id);
	};

	return (
		<div className={pollOptionItemClasses}>
			<div className='poll-options__item-container'>
				{pic && <div className='poll-options__item-img' style={{backgroundImage: `url(${pic})`}} />}
				<div className='poll-options__item-content'>
					<p className='poll-options__item-title'>{text}</p>
					{voteVisible && !!poll?.options.length && (
						<PollOptionVoting voteNumber={calcPollOptionVote(poll.options, id)} />
					)}
				</div>
			</div>

			{!isViewTypeEdit && <div className='poll-options__item-ico' />}

			{isInteractive && (
				<label htmlFor={`poll-option${id.toString()}`} className='poll-options__item-label'>
					<input
						type={!isMultiple ? 'radio' : 'checkbox'}
						name={!isMultiple ? 'poll-multiple' : ''}
						id={`poll-option${id.toString()}`}
						className='poll-options__item-label-input'
						onChange={onChangePolloptionHandler}
					/>
				</label>
			)}
		</div>
	);
};

export default observer(PollOptionItem);
