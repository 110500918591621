import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';

import useAppData from 'hooks/useAppData';
import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalService';
import gambleService from 'store/gambleService';

import {MODAL_STYLE} from 'constants/constants';

import {Gamble} from 'models/gamble';
import Button from 'components/hoc/Button';

import './shareGamble.scss';
import debounce from 'lodash/debounce';
import appService from 'store/appService';
import useGamble from 'hooks/useGamble';

import GambleItem from 'components/gamble/GambleItem';

import ShareGambleSkeleton from './ShareGambleSkeleton';
import ShareGamblePreloader from './ShareGamblePreloader';

const ShareGambleModal: FunctionComponent = function ShareGamble() {
	const {modals} = useL10n();
	const {appIcons, project, isStand} = useLocalObservable(() => appService);
	const {gamblesError, gambles, setGambles, clearGambles} = useLocalObservable(() => gambleService);
	const {shareGambleModalVisible, toggleShareGambleModalVisible} = useLocalObservable(
		() => modalServices
	);

	const [visiblePreloaderBottom, setVisiblePreloaderBottom] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const userGamblesRef = useRef<HTMLDivElement>(null);
	const userGamblesScrollRef = useRef<HTMLDivElement>(null);

	const {getGamblesWithServices, checkScrollBottom} = useGamble();
	const {getAppIcon} = useAppData();

	const {IcoClose} = appIcons;

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollBottom(scrollEvent, userGamblesScrollRef.current, setVisiblePreloaderBottom),
			50
		),
		[gambles, visiblePreloader, visiblePreloaderBottom]
	);

	const getGambles = async () => {
		setVisiblePreloader(true);
		await getGamblesWithServices();
		setVisiblePreloader(false);
	};

	const closeClickHandler = () => {
		clearGambles();
		toggleShareGambleModalVisible(false);
	};

	useEffect(() => {
		if (shareGambleModalVisible) getGambles();
	}, [shareGambleModalVisible]);

	const renderUserGamble = (item: Gamble, key: number | string) => (
		<GambleItem key={key} gamble={item} isShareGambleModal />
	);

	if (!shareGambleModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--share-gamble'
			isOpen={shareGambleModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.shareBet.title}</div>
				<Button className='modal__close' onClick={closeClickHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='share-gamble'>
						<ShareGambleSkeleton visible={visiblePreloader} />
						{gamblesError && <p className='share-gamble__error'>{gamblesError}</p>}
						{!gambles.length && !gamblesError && !visiblePreloader ? (
							<div className='share-gamble__stub'>
								<div className='share-gamble__stub-pic'> </div>
								<div className='share-gamble__stub-content'>
									<p className='share-gamble__stub-text'>{modals.shareGamble.noGamblesText}</p>
								</div>
							</div>
						) : (
							!visiblePreloader && (
								<div
									className='share-gamble__axis-y'
									onScroll={onScrollHandler}
									ref={userGamblesScrollRef}>
									<div className='share-gamble__list' ref={userGamblesRef}>
										{gambles.map(renderUserGamble)}
									</div>

									{visiblePreloaderBottom && <ShareGamblePreloader />}
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ShareGambleModal);
