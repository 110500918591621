import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {FunctionComponent, useCallback, useState, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import {v4 as uuidv4} from 'uuid';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoCheckmark} from 'assets/svg/ico-checkmark.svg';
import './select.scss';

interface ISelectProps {
	options: SelectOption[];
	onOptionHandler: (option: SelectOption) => void;
	optionIdActive?: string;
	icon?: string;
}

const Select: FunctionComponent<ISelectProps> = function Select({
	options,
	onOptionHandler,
	optionIdActive,
	icon,
}) {
	const selectRef = useRef(null);
	const [visibleDropdown, setVisibleDropdown] = useState(false);
	const {appIcons} = useLocalObservable(() => appService);
	const {getAppIcon} = useAppData();

	const {IcoChevron} = appIcons;

	const selectClasses = classNames('select', {
		'select--opened': visibleDropdown,
	});

	useOnClickOutside(selectRef, () => setVisibleDropdown(false));

	const renderOption = useCallback(
		(option: SelectOption) => (
			<div className='select__item' key={uuidv4()}>
				<Button
					onClick={() => {
						onOptionHandler(option);
						setVisibleDropdown(false);
					}}
					className='select__item-button'>
					{option.title}
					{optionIdActive && option.id === optionIdActive && (
						<span className='select__item-button-icon'>
							<IcoCheckmark />
						</span>
					)}
				</Button>
			</div>
		),
		[optionIdActive]
	);

	return (
		<div className={selectClasses} ref={selectRef}>
			<Button onClick={() => setVisibleDropdown(!visibleDropdown)} className='select__toggle'>
				{icon && <span className='select__toggle-icon'>{getAppIcon(icon)}</span>}
				{options.find(option => option.id === optionIdActive)?.title}
				<span className='select__toggle-arrow'>{getAppIcon(IcoChevron.pic)}</span>
			</Button>
			{visibleDropdown && (
				<div className='select__dropdown'>
					<div className='select__axis-y'>
						<div className='select__items'>{options.map(renderOption)}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Select;
