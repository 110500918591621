import {PollScreens} from 'models/enums/Poll.enum';
import appService from 'store/appService';
import pollService from 'store/pollService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import checkRealTextLength from 'utils/checkRealTextLength';
import {createRef, FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {Field} from 'components/hoc';
import Button from 'components/hoc/Button';
import './pollQuestion.scss';

const TEXT_MAX_LENGTH = 150;

const PollQuestion: FunctionComponent = function PollQuestion() {
	const textInputRef = createRef<HTMLTextAreaElement>();
	const {appIcons} = useLocalObservable(() => appService);
	const {pollCreated, setScreen, updatePollCreated} = useLocalObservable(() => pollService);
	const [inputText, setInputText] = useState(pollCreated?.text ? pollCreated.text : '');

	const {poll: pollTranslate} = useL10n();
	const {getAppIcon} = useAppData();

	const {IcoRightArrow} = appIcons;

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {value} = e.target;
		setInputText(value);
	};

	const counterClasses = classNames('poll-question__icounter', {
		'poll-question__icounter--top': inputText.length > 0,
	});

	const onInputHandler = () => {
		if (textInputRef.current) {
			textInputRef.current.style.height = 'auto';
			textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
		}
	};

	const nextStep = () => {
		updatePollCreated({text: inputText});
		setScreen(PollScreens.ANSWERS);
	};

	useEffect(() => {
		onInputHandler();
	}, []);

	return (
		<div className='poll-question'>
			<p className='poll-question__title'>{pollTranslate.question.title}</p>
			<div className='poll-question__input-container'>
				<Field
					fieldType='textarea'
					innerRef={textInputRef}
					className='poll-question__input'
					placeholder={pollTranslate.question.inputPlaceholder}
					maxLength={TEXT_MAX_LENGTH}
					rows={1}
					value={inputText}
					onChange={onChangeHandler}
					onInput={onInputHandler}
				/>
				<span className={counterClasses}>{TEXT_MAX_LENGTH - checkRealTextLength(inputText)} </span>
			</div>
			<Button onClick={nextStep} className='btn btn--poll' disabled={inputText.trim().length === 0}>
				{pollTranslate.btns.addAnswers}
				{getAppIcon(IcoRightArrow.pic, {className: 'btn__right-icon'})}
			</Button>
		</div>
	);
};

export default observer(PollQuestion);
