/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: '合意',
		subTitle: 'ライセンス契約とプライバシーポリシー',
		textFirst: '規約に同意します',
		textSecond: 'そして私は指定された条件の下で個人データを処理することに同意します',
		license: 'ライセンス契約',
		policy: 'プライバシーポリシー',
	},
	controlPanel: {
		streamEnded: '放送は終了しました',
		banAlert: 'チャットに書き込むことはできません',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'ステッカー' : '絵文字',
	},
	accountDeletedScreen: {
		title: 'プロフィールが削除されました。',
		text: (date: string) => `プロファイルは${date}まで復元できます `,
	},
	nameScreen: {
		title: 'チャット名',
		inputPlaceholder: 'あなたの名前を入力してください。',
		nameError: '無効な名前',
	},
	avatarScreen: {
		title: 'アバター',
		label: 'ほかを選ぶ：',
	},
	settingsScreen: {
		title: '設定',
		personal: '個人設定',
		room: 'ルームセッティング',
		profileBtn: 'プロフィール',
		languageBtn: '言語とインターフェイス',
		blockedBtn: 'ブロックされた。',
		rules: 'チャットのルール',
		deleteProfile: 'プロフィールの削除',
		slowModeBtn: 'スローモード',
		agora: {
			microphone: 'マイクロフォン ',
			playbackDevice: '音源 ',
		},
	},
	languageScreen: {
		title: '言語とインターフェイス',
		messagesTranslate: 'メッセージの翻訳 ',
		translateMessagesMode: 'チャットでメッセージを翻訳します。 ',
		translateMessagesTo: 'メッセージを次のように翻訳します',
	},
	rulesScreen: {
		title: 'チャットのルール',
	},
	profileScreen: {
		title: 'プロフィール',
		selectAnotherPic: '別のアバターを選択してください。',
		chatNameLabel: 'チャット名',
		changeName: '変更する',
	},
	blockedScreen: {
		title: 'ブロックされた。',
		emptyListText: 'ブロックされたユーザーはいません',
	},
	chatScreen: {
		inputPlaceholder: 'メッセージ...',
		cantWrite: 'チャットに書き込むことはできません',
		chatSubmenu: {
			hideMessage: '複数のメッセージを隠す',
			showMessage: '複数のメッセージを表示する',
			removeFromSpeakers: 'スピーカーから除外する',
			setSpeaker: 'スピーカーにする',
			hideOnlyMessage: 'メッセージを非表示にします',
			showOnlyMessage: 'メッセージを表示する',
			reportMessage: 'メッセージを報告する',
			reportUser: 'ユーザーを報告する',
			muteSpeaker: 'マイクをミュートする',
			blockUser: 'ブロックする',
			unblockUser: 'ブロックを解除する',
			reply: '回答する',
			copy: 'コピーする',
			edit: '変化する',
			delete: '削除する',
			pinMessage: 'ピン',
			unpinMessage: 'ピン留めを外す',
			goToProfile: 'プロフィールに移動する',
			replyInThread: 'スレッドに返信する',
		},
		actionMessage: {
			editing: '編集',
			reply: '回答する',
		},
		status: {
			top: 'トップ',
		},
		translating: '翻訳中',
	},
	chatUsersScreen: {
		title: '参加者',
		edited: '変更された',
		hidden: '隠されています',
		unreadMessages: '未読メッセージ',
		usersSubmenu: {
			muteSpeaker: {
				title: '参加者をミュートする。',
				subtitle: 'マイクをミュートする',
			},
			setSpeaker: {
				title: 'スピーカーから除外する',
				subtitle: 'テキストチャットのみ',
			},
			removeFromSpeaker: {
				title: 'スピーカーにする',
				subtitle: 'ユーザーは音声で話すことができるようになります。',
			},
			showMessages: {
				title: '複数のメッセージを表示する',
				subtitle: '管理者のみに表示されます。',
			},
			hideMessages: {
				title: '複数のメッセージを隠す',
				subtitle: '管理者のみに表示されます。',
			},
			blockUser: {
				title: 'ブロックする',
				subtitle: 'ストップリストに追加する。',
			},
			unblockUser: {
				title: 'ブロックを解除する',
				subtitle: 'ユーザーのブロックを解除してください。',
			},
			blockUserByUser: {
				title: 'ブロックする',
				subtitle: 'すべてのユーザーメッセージを非表示にする。',
			},
			unblockUserByUser: {
				title: 'ブロックを解除する',
				subtitle: 'すべてのユーザーメッセージを表示します。',
			},
			reportUser: {
				title: 'すべてのユーザーメッセージを表示します',
				subtitle: 'クレームは匿名で送信されます。',
			},
		},
		days: {
			today: '今日',
			yesterday: '昨日',
		},
		chatCreated: 'チャットが作成されます 。,',
	},
	alerts: {
		btns: {
			unblock: 'ブロックを解除する',
			block: 'ブロックする',
			send: '送信する',
			sendAndBlock: '送信してブロックする',
			show: '表示する',
			showAll: 'すべて表示する',
			cancel: 'キャンセル',
			yes: 'はい',
			hide: '選択したものを非表示にする',
			hideAll: 'すべて非表示にする',
			make: '実行する',
			remove: '削除する',
			delete: '削除する',
			deleteAccount: 'プロフィールの削除',
			close: '閉じる',
			pin: 'ピン',
			unpin: 'ピン留めを外す',
			edit: '編集する',
			end: '完了する',
			endAndShare: '完了して共有する',
			reset: 'リセットする',
			rules: 'チャットのルール',
			contactus: '当社まで書いてください。',
		},
		pinMessage: {
			title: 'メッセージをピン留めしますか？',
		},
		unPinMessage: {
			title: 'メッセージのピン留めを外しますか?',
		},
		accountRestored: {
			title: 'プロファイルが復元されました。',
		},
		closeApp: {
			title: '終了してもよろしいですか?',
		},
		removeMessage: {
			title: 'メッセージを削除しますか?',
		},
		report: {
			title: 'クレームを提出しますか?',
			subtitle: 'ユーザーをブロックして、お互いの投稿を見られないようにすることもできます。',
		},
		banUser: {
			title: (name: string) => `${name}ブロックする ?`,
			subtitle: (text: string) => `ユーザーは${text}に対してブロックされます。 `,
		},
		unBanUser: {
			title: (name: string) => `${name} のブロックを解除しますか?`,
		},
		showUserMessages: {
			title: (name: string) => `${name} からのメッセージを表示しますか?`,
			subTitle: '他のユーザーはこのユーザーのメッセージを見ることができます。',
		},
		hideUserMessages: {
			title: (name: string) => `${name} からのメッセージを非表示にしますか?`,
			subTitle: '他のユーザーはこのユーザーのメッセージを見ることができなくなります。',
		},
		showUserMessage: {
			title: (name: string) => `${name} からのメッセージを表示しますか?`,
			subTitle: '他のユーザーもこのメッセージを見ることができます。',
		},
		hideUserMessage: {
			title: (name: string) => `${name} からのメッセージを非表示にしますか?`,
			subTitle: '他のユーザーはこのメッセージを見ることができなくなります。',
		},
		blockUserInRoom: {
			title: (name: string) => `${name}ブロックする?`,
			subTitle: '他のユーザーはこのメッセージを見ることができなくなります。',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name}ブロックを解除する ?`,
			subTitle: 'ユーザーはこのルームに参加できるようになります。',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `ブロックする ${name}?`,
			subTitle: 'お互いのメッセージは表示されなくなります。',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name} をスピーカーにしますか?`,
			unTitle: (name: string) => `${name} をスピーカーから削除しますか?`,
		},
		banInApp: {
			title: '管理者があなたをブロックしました',
			for: '',
			subtitle: (bannedUntil: string) => `あなたは${bannedUntil}までブロックされます。`,
			permanent: 'あなたは永久にブロックされています。',
		},
		banInAppBadNickname: {
			title: '容認できない名前があるため、管理者によってあなたがブロックされました。',
			subtitle: 'プロフィールに移動し、名前を変更して再度チャットに参加してください。',
		},
		deleteAccount: {
			title: 'プロファイルの削除',
			subTitle:
				'すべてのデータが削除されます。 30 日以内であればプロファイルを復元できるようになります。',
		},
		pollEdit: {
			title: 'アンケートを編集しますか?',
			subTitle: 'ユーザーの応答がリセットされるようになります。',
		},
		pollReset: {
			title: 'リセットしますか？',
			subTitle: 'アンケートは保存されません。',
		},
		pollEnd: {
			title: 'アンケートを完了してもよろしいですか?',
		},
		pollDelete: {
			title: 'アンケートを削除しますか？',
		},
		agoraCheck: {
			updateIOSText: 'アプリを最大限に活用するには、iOS バージョンを更新してください。',
			allowAccess: {
				title: '何か言いたいですか？',
				text: 'マイクとスピーカーを使用するには、アプリケーションの設定に移動し、アクセスを確認します。',
			},
			microphoneAndSpeakerText: 'マイクとスピーカーは、バージョン14.3からのみ利用できます 。',
			microphoneText: 'マイクはバージョン14.3から入手できます ',
			btn: 'それは明らかです',
		},
	},
	eventScreen: {
		title: 'スケジュールされたイベント',
		titleSoon: 'まもなく放送が始まります。',
		subtitle: 'すぐにチャットが開きますので、お待ちください。',
		days: '日々',
		hours: '時間',
		minutes: '分',
		seconds: '秒',
		startTitle: 'スタート',
	},
	toasts: {
		reciveComplaint: 'あなたのクレームを受領しました。',
		setSpeakerRoleForUser: 'ユーザーがスピーカーに任命されました。',
		unSpeakerRoleForUser: 'ユーザーがスピーカーから削除されました。',
		messagesShown: 'すべてのメッセージが表示された',
		messagesHidden: 'すべてのメッセージが非表示された',
		messageShown: 'メッセージが表示された',
		messageHidden: 'メッセージが非表示された',
		userBlocked: 'ユーザーがブロックされました',
		userUnBlocked: 'ユーザーのブロックが解除されました',
		youAreSpeaker: 'あなたはスピーカーです！何か言ってください。',
		youAreNotSpeaker: '管理者があなたをスピーカーから削除しました。',
		userIsSpeaker: (name: string) => `${name} がスピーカーになりました。`,
		userNotSpeaker: (name: string) => `${name} はスピーカーではなくなりました`,
		yourUserNameInChat: (name: string) => `チャットでのあなたの名前は ${name} です`,
		messageRemoved: 'メッセージが削除されました',
		messageCopied: 'テキストがコピーされました',
		obscenities: '言い換えてみてください',
		requestSent: 'リクエストが送信されました',
		requestDecline: 'マイクのリクエストは拒否されます ',
		youareblocked: (text: string) =>
			`あなたは ${text} に対してブロックされました。チャットに書き込むことはできません。`,
		youareunblocked: 'ブロックが解除されました。チャットにメッセージを書き込むことができます。',
		youAreModerator: 'あなたはモデレータです!',
		youAreNoModerator: 'あなたはもうモデレーターではありません。',
		micMuted: 'あなたのマイクがミュートになっています。',
		noInet: 'Оインターネット接続がありません。',
		messagePinned: 'メッセージがピンされています',
		messageUnpinned: 'メッセージのピン留めが解除されました',
		pollPublished: 'アンケートが公開されました',
		pollPublishedError: 'エラー！後で試してください。',
		pollAddPic: '写真を追加してください',
		pollAddPicError: '別の画像を選択してください',
		pollDeleted: 'アンケートが削除されました。',
		pollEnded: 'アンケートが完了されました。',
		sendStickerWhenSlowModeIsOn: (time: string) =>
			`ステッカーが送信されるまで ${time} を待ってください。`,
		streamConnecting: '放送に参加しましょう...',
		linkProhibited: 'チャットでリンクを送信することはできません。',
		phoneProhibited: 'チャットで電話番号を送信することはできません。',
		profileHidden: 'ユーザーはプロフィールを非表示にしました。',
		imageSizeWarning: '10MB以下の画像をアップロードできます。',
		imageBlocked: 'モデレーターが画像の送信をブロックしました。',
		streamKeyCopied: 'ブロードキャストキーがコピーされました',
	},
	btns: {
		acceptandgo: '同意して続行する',
		saveandgo: '保存して続行する',
		save: '保存する',
		unblock: 'ブロックを解除する。',
		block: 'ブロックする',
		restoreAccount: 'プロファイルを復元する',
		close: '閉じる',
	},
	coopyrights: 'Watchers.io によって提供されます。',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'ユーザーを報告する' : '違反を報告する',
			subtitle: 'クレームは匿名で送信されます',
			btns: {
				violence: '侮辱、脅迫、悪口',
				provocations: '挑発',
				personalDetails: '個人データ',
				flood: 'フラッド',
				spam: 'スパム',
				fraudBegging: '詐欺、物乞い',
				other: 'その他',
			},
		},
		ban: {
			title: 'ユーザをブロックする',
			subtitle: 'ブロックする理由を指定してください',
			btns: {
				insults: '侮辱、脅迫、悪口',
				personal: '個人データの流布',
				flood: 'フラッド',
				links: 'サードパーティサービスへのリンク',
				violence: '暴力の脅威',
				fraud: '詐欺の試みと物乞い',
				nickname: '許可されていないニックネーム',
				politicial: '政治的、性的、その他の挑発行為',
				repeated: '度重なる違反',
				auto: 'チャットユーザーのクレーム',
				spam: 'スパム',
			},
		},
		waitingSpeakers: {
			text: '保留中のスピーカーはいません',
			handsUp: '手を挙げた',
			waiting: '待機中のスピーカー',
		},
		poll: {
			supTitles: {
				typeQuiz: '正しい答えを出してください。',
				typeQuizMultiple: '正しいオプションを確認してください。',
				typePoll: '意見を共有してください。',
				typePollMultiple: 'いくつかのオプションに注意することができます',
				default: '世論調査',
			},
			btns: {
				reply: '回答する。',
				vote: '投票する',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'クイズモード',
				typePoll: 'いくつかの答え',
				default: '世論調査',
			},
			btns: {
				edit: 'アンケートを編集する',
				end: 'を完了する',
			},
		},
		pollCreated: {
			titles: {
				created: 'アンケートを作成する',
				typeQuiz: 'クイズ',
				typePoll: '複数の選択肢',
				default: '世論調査',
			},
		},
		changeName: {
			title: 'チャット名を変更してください',
			subtitle: '不正なニックネームのためブロックされています',
		},
		temporaryAgreement: {
			title: 'チャットのルール',
			rules: {
				bePolite: {
					title: '礼儀正しくしてください',
					text: 'チャット内での敵対的および卑猥な言葉は禁止されています。',
				},
				beAttentive: {
					title: '注意してください',
					text: '他人に個人情報を共有したり、個人情報を求めたりしないでください。',
				},
				notAdvertise: {
					title: '宣伝しないでください。',
					text: '自分の意見や感情を共有した方が良いです。',
				},
			},
			btns: {
				allRules: 'すべてのルール',
				acceptAndContinue: '同意して続行する',
			},
		},
		slowMode: {
			title: 'スローモード',
			text: '参加者は、選択した期間よりも頻繁にチャット メッセージを送信できなくなります。',
			enable: 'スローモードを有効にする',
			disable: 'スローモードをオフにする',
			sendTimeout: '送信タイムオーバー',
		},
		slowModeTimeout: {
			title: '送信タイムオーバー',
		},
		chatBot: {
			title: 'チャットボットの使用',
			rules: [
				{
					title: '人工知能',
					text: 'ボットは人々とのコミュニケーションをシミュレートし、その知識に基づいて情報を提供します。ただし、彼の答えは限られている可能性があり、人に代わるものではないことを覚えておいてください。',
				},
				{
					title: 'ボットが間違っている可能性があります',
					text: 'ボットは、情報の正確性と信頼性を保証することはできません。 ',
				},
			],
			btns: {
				close: '閉じる',
			},
		},
		shareBet: {
			title: 'シェアする',
			noBetsTitle: 'あなたのベットを見つけることができませんでした。',
			noBetsText: '14日以内に終了するベットを共有できます',
			btns: {
				share: 'ベットをシェアする',
				copy: 'ベットをリピートする',
			},
			status: {
				null: 'ゲーム中',
				win: '勝利',
				lose: '損失 ',
			},
			betTypeV1: {
				express: 'エクスプレス',
				single: 'シングルベット',
			},
			betTypeV2: {
				single: 'シングルベット',
				multi: 'マルチ.',
				system: 'System',
			},
			totalFactor: '奇数',
			bet: 'ベット',
			showMore: 'もっと見せる ',
			betRepeated: '賭けを繰り返した',
		},
		shareGamble: {
			noGamblesText:
				'まだ共有できる勝利がありません。プレイを続けてください。次の勝利はここに表示され、他のプレイヤーと共有できるようになります！',
		},
		record: {
			text: 'エーテルの記録は処理されます',
		},
		agoraDevices: {
			title: 'オーディオ設定',
			text: '正しいマイクが選択されていることを確認してください 。',
		},
		translate: {
			title: 'メッセージの翻訳 ',
			chooseLanguage: '言語を選択する 。',
			descr: 'チャット内のすべてのメッセージは、選択した言語に自動的に変換されます。 ',
			btn: '翻訳をオンにします',
		},
		streamSettings: {
			title: 'ストリーム設定',
			description:
				'ビデオエンコーダーをダウンロードしてインストールします。ストリームキーを設定に入力してください',
			shareScreen: {
				title: '画面共有',
				description: '特別な設定は不要です',
			},
			rtmp: {
				title: '外部ストリームソース',
				description: '別のプログラムを使ってストリームを送信します',
			},
			streamKey: 'ストリームキー',
			showStreamKey: '表示',
			hideStreamKey: '非表示',
			btns: {
				continue: '続ける',
				close: '閉じる',
				ok: '完了',
			},
		},
	},
	pinnedMessage: {
		title: 'ピン留めされたメッセージ',
	},
	errorPage: {
		error: 'エラー',
		types: [
			// errorNumber 0
			{
				title: 'あなたを認識できません。',
				text: 'チャットに再入力してみてください。',
			},
			// errorNumber 1
			{
				title: 'チャットが見つかりません。',
				text: 'チャットに再入力してみてください。',
			},
			// errorNumber 2
			{
				title: 'チャットを読み込むことができません。',
				text: '再ログインしてみてください。',
			},
			// errorNumber 3
			{
				title: 'あなたを認識できません。',
				text: 'シークレット モードでのチャットは利用できません。',
			},
			// errorNumber 4
			{
				title: '無効なニックネームです。',
				text: 'プロフィールに移動し、ニックネームを変更して、再度チャットに参加してください。',
			},
		],
	},
	tooltips: {
		emotions: '変更するために押し続けてください',
		badInet: 'ネットワーク接続が悪い',
		blockedUsers: 'ここでブロックされたユーザーのリストを確認できます',
		waitingSpeakers: '参加者の中には発言したい人もいます',
		wantSpeak: '議決権をリクエストするためにクリックしてください',
		slowMode: {
			header: (time: string) =>
				`スローモードが有効です。ユーザーは、<br>${time} ごとに 1 つのメッセージを送信できます。このルールはモデレーターには適用されません。`,
			btnSendMessage: (time: string) => `${time} のメッセージを毎__送信できます `,
		},
		shareBet: 'チャットでベットをシェアする',
		translate: 'メッセージを別の言語に翻訳する',
	},
	poll: {
		toggleSubmenu: {
			create: 'アンケートを作成する',
			edit: 'アンケートを編集する。',
			show: 'アンケートを表示する。',
			end: 'アンケートを完了する',
		},
		tooltip: {
			btn: '投票する',
		},
		settings: {
			title: '設定',
			titleAdditionally: '追加で',
			switches: {
				quizMode: 'クイズモード',
				multipleChoice: '複数の選択肢',
				withPhoto: '写真付きアンケート',
			},
		},
		question: {
			title: '質問',
			inputPlaceholder: '質問を入力してください',
		},
		message: {
			title: 'あなたは投票しました',
		},
		answers: {
			title: '回答オプション',
			warning: '最大 5 つの回答を追加できます。',
		},
		answer: {
			tooltip: '正しい答えを選択してください。',
			inputPlaceholder: '回答',
		},
		results: {
			text: '投票結果',
			votes: '票',
			// words: ['票', '票', '票'],
		},
		btns: {
			addQuestion: '質問を追加する',
			addAnswers: '複数の回答を追加する',
			addAnswer: '回答を追加する',
			publish: 'リリースする',
			vote: '投票してください',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: '評価する',
				continue: '続く',
				checkConnection: '接続を確認する',
				close: '閉じる',
			},
			quality: {
				title: '音質を評価してください',
			},
			reason: {
				title: '何か問題が発生しましたか？',
			},
			connection: {
				title: '接続品質をテストしてください。',
				text: 'チェックには 1 分もかからず、問題の原因を見つけることができます。',
			},
			statistics: {
				title: '統計を収集中…',
			},
			done: {
				title: 'ありがとう！',
				text: '何が問題だったかを調査し、必ず修正します',
			},
		},
	},
	chatBot: {
		message: '何かについてボットに聞いてください... ',
		warnings: {
			disabled: '私はオフになりました。 ',
			overloaded: '過負荷になってしまいました',
		},
	},
	audioReason: {
		nothear: '何も聞こえませんでした ',
		connection: '音が途切れていました ',
		microphone: 'マイクを使用することはできませんでした',
	},

	copybetting: {
		orderStatus: {
			won: '勝利',
			lost: '損失 ',
			new: '新しい',
			pending: 'ドロー待ち',
			cashedOut: '撤退',
			boreDraw: 'ゴールレスドロー',
			cashback: 'キャッシュバック',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet オペレーターによるキャンセル',
			megaBetCanceledByPlayer: 'MegaBet プレイヤーによるキャンセル',
			megaBetCanceledBySystem: 'MegaBet システムによるキャンセル',
			betGamesPending: 'BetGames 保留中',
			betGamesWon: 'BetGames 勝利',
			betGamesLost: 'BetGames 損失 ',
			paid: '支払った',
			initialWin: '出金賞金',
			autoBetCanceledBySystem: 'AutoBet プレイヤーによるキャンセル',
			autoBetCanceledByPlayer: 'AutoBet ユーザーによる払い戻し',
			autobetPending: 'AutoBet 保留中',
			goalAhead: 'ゴールはあるだろう',
			canceled: 'キャンセル',
			return: '返金',
		},
		stakeStatus: {
			pending: 'ドロー待ち',
			won: '勝利',
			lost: '損失 ',
			return: 'Возврат',
			lostReturn: '損失  | 戻る',
			wonReturn: '勝利 | 戻る',
			canceled: '返金',
			initialReturn: 'ユーザーによる払い戻し',
			pendingInitialReturn: 'ドロー待ち | ユーザーによる払い戻し',
			wonInitialReturn: '勝利 | ユーザーによる払い戻し',
		},
	},
	screenShare: {
		title: '画面デモ',
		stopSharing: 'ストリーミングを停止',
		modal: {
			title: '画面を共有できるのはコンピュータからのみです。',
			text: 'コンピュータでチャットを開き、右上隅にある「画面共有」ボタンをクリックします。',
			btn: 'ログインリンクをコピーする。',
		},
	},
	threads: {
		title: 'スレッド',
		back: '戻る',
		replies: ['回答', '回答', '回答'],
	},

	attach: {
		submenu: {
			sendImage: '画像を送信する',
			shareBet: 'ベットをシェアする',
			shareGamble: '賞金をシェアする',
		},
		images: ['画像', '画像', '画像'],
		addCaption: '署名を追加する',
	},
} as unknown as Dictionary;
