import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useSearchParams from 'hooks/useSearchParams';
import React, {useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import userServices from 'store/userService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import UserService from 'services/api/UserService';
import alertService from 'store/alertService';
import {AlertBtnType} from 'models/enums/Alert.enum';
import Button from 'components/hoc/Button';
import appService from 'store/appService';

import './restore.scss';
import {localeLanguages} from 'constants/constants';

const DAYS_BEFORE_DELETE = 30;

const Restore: React.FC = function Restore() {
	const history = useHistory();
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {search} = useSearchParams();

	const {language} = useLocalObservable(() => appService);
	const {userData, accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);

	const [deletedDate, setDeletedDate] = useState(new Date());
	const locale = localeLanguages.find(item => item.includes(language)) || 'en-GB';

	useEffect(() => {
		if (userData?.deletedAt) {
			const localDate = new Date(userData?.deletedAt);
			userData?.deletedAt &&
				localDate.setDate(new Date(userData?.deletedAt).getDate() + DAYS_BEFORE_DELETE);
			setDeletedDate(localDate);
		}
	}, [userData]);

	const restoreUserHandler = async () => {
		if (accessToken) {
			const response = await UserService.restoreMyUser(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				sendAnalytics('account_restored');
				history.push({
					pathname: '/',
					search,
				});
				showAlert({
					title: translations.alerts.accountRestored.title,
					buttons: [
						{
							text: translations.alerts.btns.close,
							type: AlertBtnType.NORMAL,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
			}
		}
	};

	return (
		<div className='restore'>
			<p className='restore__title'>{translations.accountDeletedScreen.title}</p>
			<p className='restore__text'>
				{translations.accountDeletedScreen.text(
					`${deletedDate.toLocaleDateString(locale, {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					})}, ${deletedDate.toLocaleTimeString(locale, {
						hour: 'numeric',
						minute: 'numeric',
					})}`
				)}
			</p>
			<div className='restore__wrapper'>
				{/* <p className='restore__watchers'>{translations.coopyrights}</p> */}
				<Button onClick={restoreUserHandler} className='btn restore__footer-btn'>
					{translations.btns.restoreAccount}
				</Button>
			</div>
		</div>
	);
};

export default observer(Restore);
