import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import threadService from 'store/threadService';

interface IButtonRemoveProps {
	message: Message;
}

const ButtonRemove: FunctionComponent<IButtonRemoveProps> = function ButtonRemove({message}) {
	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {roomId} = useLocalObservable(() => (currentThreadId ? threadService : roomService));

	const {chatScreen} = useL10n();
	const {removeMessage} = useSubmenuActions();
	const {getAppIcon} = useAppData();

	const {IcoSubmenuBasket} = appIcons;

	const onRemoveHandler = () => {
		if (roomId) {
			removeMessage(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn chat__submenu-btn--warn' onClick={onRemoveHandler}>
			{chatScreen.chatSubmenu.delete}
			{getAppIcon(IcoSubmenuBasket.pic, {className: 'chat__submenu-icon'})}
		</Button>
	);
};

export default observer(ButtonRemove);
