import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalService';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {v4 as uuidv4} from 'uuid';
import Button from 'components/hoc/Button';
import './chatBotRulesModal.scss';

const ChatBotRulesModal: FunctionComponent = function ChatBotRulesModal() {
	const {chatBotRulesModal, toggleChatBotRulesModal} = useLocalObservable(() => modalServices);
	const {modals} = useL10n();

	const onCloseChatBotRulesModalHandler = () => {
		toggleChatBotRulesModal(false);
	};

	const renderChatBotRulesItem = (item: {title: string; text: string}) => (
		<div className='chat-bot-rules__item' key={uuidv4()}>
			<div className='chat-bot-rules__item-title'>{item.title}</div>
			<div className='chat-bot-rules__item-text'>{item.text}</div>
		</div>
	);

	if (!chatBotRulesModal) {
		return null;
	}

	return (
		<Modal
			className='modal modal--chat-bot-rules'
			isOpen={chatBotRulesModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='chat-bot-rules'>
						<div className='chat-bot-rules__container'>
							<div className='chat-bot-rules__title'>{modals.chatBot.title}</div>
							<div className='chat-bot-rules__items'>
								{modals.chatBot.rules.map(renderChatBotRulesItem)}
							</div>
							<div className='chat-bot-rules__buttons'>
								<Button className='chat-bot-rules__close' onClick={onCloseChatBotRulesModalHandler}>
									{modals.chatBot.btns.close}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ChatBotRulesModal);
