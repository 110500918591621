import {ActionType} from 'models/enums/ActionType.enum';
import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import actionMessageService from 'store/actionMessageService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import threadService from 'store/threadService';

interface IButtonReplayProps {
	message: Message;
}

const ButtonReplay: FunctionComponent<IButtonReplayProps> = function ButtonReplay({message}) {
	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {setSubmenuMessage} = useLocalObservable(() =>
		currentThreadId ? threadService : roomService
	);
	const {toggleIsVisibleActionMessage, setActionData} = useLocalObservable(
		() => actionMessageService
	);

	const {chatScreen} = useL10n();
	const {getAppIcon} = useAppData();

	const {IcoReplay} = appIcons;

	const setFocusInTextarea = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		chatTextarea?.focus();
	};

	const onReplayHandler = () => {
		setActionData({type: ActionType.REPLAY, message});
		toggleIsVisibleActionMessage(true);
		setSubmenuMessage(null);
		setFocusInTextarea();
	};

	return (
		<Button className='chat__submenu-btn' onClick={onReplayHandler}>
			{chatScreen.chatSubmenu.reply}
			{getAppIcon(IcoReplay.pic, {className: 'chat__submenu-icon'})}
		</Button>
	);
};

export default observer(ButtonReplay);
