/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Sopimus',
		subTitle: 'Lisenssisopimus ja tietosuojakäytäntö',
		textFirst: 'Hyväksyn ehdot ja annan suostumukseni henkilötietojen käsittelyyn',
		license: 'lisenssisopimuksessa',
		policy: 'ja tietosuojakäytännössä',
		textSecond: 'määriteltyjen ehtojen mukaisesti.',
	},
	controlPanel: {
		streamEnded: 'Lähetys on päättynyt.',
		banAlert: 'ВEtte voi lähettää viestejä chatissa',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stickers' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Profiili poistettu',
		text: (date: string) => `Voitte palauttaa profiilinne ${date}`,
	},
	nameScreen: {
		title: 'Nimi chatissa',
		inputPlaceholder: 'Kirjoittakaa nimi',
		nameError: 'Virheellinen nimi',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Valitse toinen:',
	},
	settingsScreen: {
		title: 'Asetukset',
		personal: 'Henkilökohtaiset asetukset',
		room: 'Tila-asetukset',
		profileBtn: 'Profiili',
		languageBtn: 'Kieli ja käyttöliittymä',
		blockedBtn: 'Estetyt',
		rules: 'Chatti-säännöt',
		deleteProfile: 'Poista profiili',
		slowModeBtn: 'Hidas toimintatapa',
		agora: {
			microphone: 'Mikrofoni',
			playbackDevice: 'Äänilähde',
		},
	},
	languageScreen: {
		title: 'Kieli ja käyttöliittymä',
		messagesTranslate: 'Viestien kääntäminen',
		translateMessagesMode: 'Käännä viestejä chatissa',
		translateMessagesTo: 'Käännä viestit seuraavasti',
	},
	rulesScreen: {
		title: 'Chatti-säännöt',
	},
	profileScreen: {
		title: 'Profiili',
		selectAnotherPic: 'Valitse toinen avatar',
		chatNameLabel: 'Nimi chatissa',
		changeName: 'Muuta',
	},
	blockedScreen: {
		title: 'Estetty',
		emptyListText: 'Estettyjä käyttäjiä ei ole',
	},
	chatScreen: {
		inputPlaceholder: 'Viesti...',
		cantWrite: 'Te ette voi lähettää viestejä chatissa',
		chatSubmenu: {
			hideMessage: 'Piilota viestit',
			showMessage: 'Näytä viestit',
			removeFromSpeakers: 'Poista puhujien listalta',
			setSpeaker: 'Aseta puhujaksi',
			hideOnlyMessage: 'Piilota viesti',
			showOnlyMessage: 'Näytä viesti',
			reportMessage: 'Valita viestistä',
			reportUser: 'Valita käyttäjästä',
			muteSpeaker: 'Hiljennä osallistuja',
			blockUser: 'Estä',
			unblockUser: 'Poista esto',
			reply: 'Vastaa',
			copy: 'Kopioi',
			edit: 'Muuta',
			delete: 'Poista',
			pinMessage: 'Kiinnitä',
			unpinMessage: 'Poista kiinnitys',
			goToProfile: 'Siirry profiiliin',
			replyInThread: 'Vastaa säikeessä',
		},
		actionMessage: {
			editing: 'Editointi',
			reply: 'Vastaa',
		},
		status: {
			top: 'Top',
		},
		translating: 'Me käännämme...',
	},
	chatUsersScreen: {
		title: 'Osallistujat',
		edited: 'Muutettu',
		hidden: 'Piilotettu',
		unreadMessages: 'Lukemattomat viestit',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Hiljennä osallistuja',
				subtitle: 'Pidä mikrofoni kiinni',
			},
			setSpeaker: {
				title: 'Poista puhujalistalta',
				subtitle: 'Vain tekstikeskustelu',
			},
			removeFromSpeaker: {
				title: 'Nimetä puhujaksi',
				subtitle: 'Käyttäjä voi puhua äänellään',
			},
			showMessages: {
				title: 'Näytä viestit',
				subtitle: 'Vain järjestelmänvalvoja näkee',
			},
			hideMessages: {
				title: 'Piilota viestit',
				subtitle: 'Vain järjestelmänvalvoja näkee',
			},
			blockUser: {
				title: 'Estä ',
				subtitle: 'Lisää pysäytyslistaan',
			},
			unblockUser: {
				title: 'Poista esto',
				subtitle: 'Avaa käyttäjän lukitus',
			},
			blockUserByUser: {
				title: 'Estä ',
				subtitle: 'Piilota kaikki käyttäjän viestit',
			},
			unblockUserByUser: {
				title: 'Poista esto',
				subtitle: 'Näytä kaikki käyttäjän viestit',
			},
			reportUser: {
				title: 'Valita käyttäjästä',
				subtitle: 'Valitus lähetetään nimettömänä',
			},
		},
		days: {
			today: 'Tänään',
			yesterday: 'Eilen',
		},
		chatCreated: 'Chat-tila on luotu,',
	},
	alerts: {
		btns: {
			unblock: 'Poista esto',
			block: 'Estä ',
			send: 'Lähetä',
			sendAndBlock: 'Lähetä ja estä',
			show: 'Näytä',
			showAll: 'Näytä kaikki',
			cancel: 'Peruutus',
			yes: 'Kyllä',
			hide: 'Piilota valittu',
			hideAll: 'Piilota kaikki',
			make: 'Tee',
			remove: 'Ota pois',
			delete: 'Poista',
			deleteAccount: 'Poista profiili',
			close: 'Sulje',
			pin: 'Kiinnitä',
			unpin: 'Poista kiinnitys',
			edit: 'Muokkaa',
			end: 'Lopeta',
			endAndShare: 'Lopeta ja jaa',
			reset: 'Nollaa',
			rules: 'Chatti-säännöt',
			contactus: 'Kirjoita meille',
		},
		pinMessage: {
			title: 'Täytyykö kiinnittää viesti?',
		},
		unPinMessage: {
			title: 'Täytyykö poistaa viestin kiinnitys?',
		},
		accountRestored: {
			title: 'Profiili palautettu',
		},
		closeApp: {
			title: 'Haluatko varmasti kirjautua ulos?',
		},
		removeMessage: {
			title: 'Täytyykö poistaa viesti?',
		},
		report: {
			title: 'Täytyykö lähettää valitus?',
			subtitle: 'Te voitte myös estää käyttäjän, jotta ette näe toistensa viestejä.',
		},
		banUser: {
			title: (name: string) => `Täytyykö estää ${name}?`,
			subtitle: (text: string) => `Käyttäjä estetään ${text} viestin takia`,
		},
		unBanUser: {
			title: (name: string) => `Täytyykö poistaa ${name} esto?`,
		},
		showUserMessages: {
			title: (name: string) => `Täytyykö näyttää ${name} viestit?`,
			subTitle: 'Muut käyttäjät voivat nähdä tämän viestin',
		},
		hideUserMessages: {
			title: (name: string) => `Täytyykö piilottaa ${name} viestit?`,
			subTitle: 'Muut käyttäjät eivät enää näe tämän käyttäjän viestejä',
		},
		showUserMessage: {
			title: (name: string) => `Täytyykö näyttää ${name} viestit?`,
			subTitle: 'Muut käyttäjät voivat nähdä tämän viestin',
		},
		hideUserMessage: {
			title: (name: string) => `Täytyykö piilottaa ${name} viesti?`,
			subTitle: 'Muut käyttäjät eivät enää näe tätä viestiä',
		},
		blockUserInRoom: {
			title: (name: string) => `Täytyykö estää ${name}?`,
			subTitle: 'Käyttäjä ei voi osallistua tähän tilaan.',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Täytyykö poistaa ${name} esto?`,
			subTitle: 'Käyttäjä voi osallistua tähän tilaan',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Täytyykö estää ${name}?`,
			subTitle: 'Te ette näe toistenne viestejä',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Täytyykö tehdä  ${name} puhujaksi?`,
			unTitle: (name: string) => `Täytyykö poistaa ${name} puhujien joukosta?`,
		},
		banInApp: {
			title: 'Järjestelmänvalvoja on estänyt teidät',
			for: 'koska',
			subtitle: (bannedUntil: string) => `Teidät on estetty, kunnes ${bannedUntil}`,
			permanent: 'Teidät on estetty pysyvästi ulos',
		},
		banInAppBadNickname: {
			title: 'Järjestelmänvalvoja on estänyt teidät virheellisen nimen vuoksi.',
			subtitle: 'Menkää profiiliinne, vaihtakaa nimenne ja kirjautukaa chattiin uudelleen',
		},
		deleteAccount: {
			title: 'Profiilin poistaminen',
			subTitle: 'Kaikki tiedot poistetaan. Voitte palauttaa profiilinne 30 päivän kuluessa.',
		},
		pollEdit: {
			title: 'Täytyykö muokata kysely?',
			subTitle: 'Käyttäjän vastaukset nollataan',
		},
		pollReset: {
			title: 'Täytyykö nollata?',
			subTitle: 'Kyselyä ei tallenneta',
		},
		pollEnd: {
			title: 'Haluatteko varmasti lopettaa kyselyn?',
		},
		pollDelete: {
			title: 'Täytyykö poistaa kysely?',
		},
		agoraCheck: {
			updateIOSText: 'Päivittäkää iOS-versionne käyttääksenne kaikkia sovelluksen ominaisuuksia.',
			allowAccess: {
				title: 'Haluatteko sanoa jotain?',
				text: 'Jos haluatte käyttää mikrofonia ja kaiutinta, siirtykää sovelluksen asetuksiin ja sallikaa niiden käyttö.',
			},
			microphoneAndSpeakerText: 'Mikrofoni ja kaiutin saatavilla vasta versiosta 14.3 alkaen.',
			microphoneText: 'Mikrofoni saatavilla versiosta 14.3 alkaen',
			btn: 'Okay',
		},
	},
	eventScreen: {
		title: 'Aikataulutettu tapahtuma',
		titleSoon: 'Lähetys alkaa pian',
		subtitle: 'Chatti avataan pian, odottakaa vain',
		days: 'päivät',
		hours: 'tunti',
		minutes: 'minuutti',
		seconds: 'sekunti',
		startTitle: 'Alkaa',
	},
	toasts: {
		reciveComplaint: 'Olemme saaneet valituksenne',
		setSpeakerRoleForUser: 'Käyttäjä on nimetty puhujaksi',
		unSpeakerRoleForUser: 'Käyttäjä on poistettu puhujista',
		messagesShown: 'Viestit on näytetty',
		messagesHidden: 'Viestit on piilotettu',
		messageShown: 'Viesti on näytetty',
		messageHidden: 'Viesti on piilotettu',
		userBlocked: 'Käyttäjä on estetty',
		userUnBlocked: 'Käyttäjän esto on poistettu',
		youAreSpeaker: 'Te olette puhuja! Sanokaa jotain',
		youAreNotSpeaker: 'Järjestelmänpitäjä on poistanut teidät puhujalistalta.',
		userIsSpeaker: (name: string) => `${name} on nyt puhuja`,
		userNotSpeaker: (name: string) => `${name} ei ole enää puhuja`,
		yourUserNameInChat: (name: string) => `Chatin nimenne on ${name}`,
		messageRemoved: 'Viesti on poistettu',
		messageCopied: 'Teksti on kopioitu',
		obscenities: 'Yritä muotoilla se uudelleen',
		requestSent: 'Pyyntö on lähetetty',
		requestDecline: 'Teidän mikrofonipyyntönne on hylätty.',
		youareblocked: (text: string) => `Sinut on estetty ${text} vuoksi. Et voi kirjoittaa chattiin`,
		youareunblocked: 'Teidät on poistettu estolistalta. Voitte nyt lähettää viestejä chatissa',
		youAreModerator: 'Te olette moderaattori!',
		youAreNoModerator: 'Te ette ole enää moderaattori',
		micMuted: 'Teidän mikrofoninne on mykistetty',
		noInet: 'Ei ole nettiyhteyttä',
		messagePinned: 'Viesti on kiinnitetty',
		messageUnpinned: 'Viesti on irrotettu',
		pollPublished: 'Kysely on julkaistu',
		pollPublishedError: 'Virhe! Yrittäkää myöhemmin uudelleen',
		pollAddPic: 'Lisätkää valokuva',
		pollAddPicError: 'Valitkaa toinen kuva',
		pollDeleted: 'Kysely on poistettu',
		pollEnded: 'Kysely on päättynyt',
		sendStickerWhenSlowModeIsOn: (time: string) => `Odota ${time}, että tarra lähetetään.`,
		streamConnecting: 'Liitymme lähetykseen...',
		linkProhibited: 'Ette voi lähettää linkkejä chatissa.',
		phoneProhibited: 'Ette voi lähettää puhelinnumeroita chatissa.',
		profileHidden: 'Käyttäjä on piilottanut profiilinsa.',
		imageSizeWarning: 'Voit ladata enintään 10 Mt kuvan',
		imageBlocked: 'Moderaattori esti kuvien lähettämisen',
		streamKeyCopied: 'Lähetysavain kopioitu',
	},
	btns: {
		acceptandgo: 'Hyväksy ja jatka',
		saveandgo: 'Tallenna ja jatka',
		save: 'Tallenna',
		unblock: 'Poista esto',
		block: 'Estä ',
		restoreAccount: 'Palauta profiili',
		close: 'Sulje',
	},
	coopyrights: 'Toimii Watchers.io-alustalla',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Valita viestistä' : 'Ilmoita rikkomuksesta',
			subtitle: 'Valitukset lähetetään nimettöminä',
			btns: {
				violence: 'Loukkaukset, uhkaukset, kirosanat',
				provocations: 'Provokaatiot',
				personalDetails: 'Henkilötiedot',
				flood: 'Kommenttien tai viestien massapostitus',
				spam: 'Roskaposti',
				fraudBegging: 'Huijausyritykset ja kerjäys',
				other: 'Muu',
			},
		},
		ban: {
			title: 'Estä käyttäjä',
			subtitle: 'Ilmoita eston syy',
			btns: {
				insults: 'Loukkaukset, uhkaukset, kirosanat',
				personal: 'Henkilötiedot',
				flood: 'Kommenttien tai viestien massapostitus',
				links: 'Linkit kolmansien osapuolien palveluihin',
				violence: 'Väkivallalla uhkailu',
				fraud: 'Huijausyritykset ja kerjäys',
				nickname: 'Virheellinen käyttäjänimi',
				politicial: 'Poliittiset, seksualisoidut ja muut provokaatiot',
				repeated: 'Toistuva rike',
				auto: 'Valitukset chatin käyttäjiltä',
				spam: 'Roskaposti',
			},
		},
		waitingSpeakers: {
			text: 'Te nostitte kätenne',
			handsUp: 'Odottavat puhujat',
			waiting: 'Odottavat puhujat',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Antakaa oikea vastaus',
				typeQuizMultiple: 'Rastittakaa oikeat vaihtoehdot',
				typePoll: 'Kertokaa mielipiteenne',
				typePollMultiple: 'Vaihtoehtoja voi olla useita',
				default: 'Julkinen kysely',
			},
			btns: {
				reply: 'Vastaa',
				vote: 'Äänestä',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Visailu',
				typePoll: 'Muutamat vastaukset',
				default: 'Julkinen kysely',
			},
			btns: {
				edit: 'Muokkaa mielipidekyselyä',
				end: 'Lopeta',
			},
		},
		pollCreated: {
			titles: {
				created: 'Tee kysely',
				typeQuiz: 'Visailun toimintatila',
				typePoll: 'Valitse useita vastauksia',
				default: 'Kysely valokuvan kanssa',
			},
		},
		changeName: {
			title: 'Muuttakaa nimeänne chatissa.',
			subtitle: 'Olette estetty virheellisen käyttäjänimen vuoksi',
		},
		temporaryAgreement: {
			title: 'Chatti-säännöt',
			rules: {
				bePolite: {
					title: 'Olkaa kohteliaita',
					text: 'Vihamielinen ja ruma kielenkäyttö ei ole sallittua chatissa.',
				},
				beAttentive: {
					title: 'Olkaa varovaisia',
					text: 'Älkää jaako henkilökohtaisia tietoja tai pyytäkö niitä muilta.',
				},
				notAdvertise: {
					title: 'Älkää tehkö mainontaa',
					text: 'On parempi kertoa mielipiteenne tai tunteenne.',
				},
			},
			btns: {
				allRules: 'Kaikki säännöt',
				acceptAndContinue: 'Hyväksy ja jatka',
			},
		},
		slowMode: {
			title: 'Hidas toimintatapa',
			text: 'Osallistujat eivät voi lähettää viestejä chattiin useammin kuin valitun aikavälin mukaan.',
			enable: 'Aktivoida hidas tila',
			disable: 'Deaktivoida hidas tila',
			sendTimeout: 'Lähetysaika päättyy',
		},
		slowModeTimeout: {
			title: 'Lähetysaika päättyy',
		},
		chatBot: {
			title: 'Chatbotin käyttö',
			rules: [
				{
					title: 'Tekoäly',
					text: 'Botti jäljittelee ihmisten viestintää ja tarjoaa tietoa tietämyksensä avulla. Älkää unohtako kuitenkaan, että sen vastaukset voivat olla rajoitettuja, eivätkä ne korvaa ihmistä.',
				},
				{
					title: 'Botti voi tehdä virheitä',
					text: 'Botti ei voi taata tietojen tarkkuutta ja luotettavuutta.',
				},
			],
			btns: {
				close: 'Sulje',
			},
		},
		shareBet: {
			title: 'Jaa',
			noBetsTitle: 'Ei ole vedonlyöntiä jaettavaksi',
			noBetsText: 'Te voitte jakaa vedot, jotka on tehty 14 päivän kuluessa.',
			btns: {
				share: 'Jaa panos',
				copy: 'Toista veto:',
			},
			status: {
				null: 'Pelissä.',
				win: 'Voitto',
				lose: 'Tappio',
			},
			betTypeV1: {
				express: 'Express',
				single: 'Yksittäinen',
			},
			betTypeV2: {
				single: 'Yksittäinen.',
				multi: 'Множ.',
				system: 'System',
			},
			totalFactor: 'Kerroin',
			bet: 'Vedonlyönti',
			showMore: 'Näytä lisää',
			betRepeated: 'on toistanut vedon',
		},
		shareGamble: {
			noGamblesText:
				'Sinulla ei ole vielä voittoja jaettavaksi. Jatka pelaamista, ja seuraava voitto ilmestyy tähän, jotta voit jakaa sen muiden pelaajien kanssa!',
		},
		record: {
			text: 'Lähetystallennusta käsitellään parhaillaan...',
		},
		agoraDevices: {
			title: 'Ääniasetukset',
			text: 'Tarkistakaa, että oikea mikrofoni on valittu',
		},
		translate: {
			title: 'Viestien kääntäminen',
			chooseLanguage: 'Valitse kieli',
			descr: 'Kaikki chat-viestit käännetään automaattisesti valitulle kielelle.',
			btn: 'Ota käännös käyttöön',
		},
		streamSettings: {
			title: 'Suoratoistoasetukset',
			description: 'Lataa ja asenna videokooderi. Syötä suoratoistoavain sen asetuksiin',
			shareScreen: {
				title: 'Näytön jakaminen',
				description: 'Ei erityisasetuksia vaadita',
			},
			rtmp: {
				title: 'Ulkoinen suoratoistolähde',
				description: 'Käytä ylimääräistä ohjelmaa suoratoiston lähettämiseen',
			},
			streamKey: 'Suoratoistoavain',
			showStreamKey: 'Näytä',
			hideStreamKey: 'Piilota',
			btns: {
				continue: 'Jatka',
				close: 'Sulje',
				ok: 'Valmis',
			},
		},
	},
	pinnedMessage: {
		title: 'Kiinnitetty viesti',
	},
	errorPage: {
		error: 'Virhe',
		types: [
			// errorNumber 0
			{
				title: 'Me emme tunnista sinua',
				text: 'Yrittäkää kirjautua uudelleen chattiin',
			},
			// errorNumber 1
			{
				title: 'Me emme löydä chattia',
				text: 'Yrittäkää kirjautua uudelleen',
			},
			// errorNumber 2
			{
				title: 'Chattia ei voida ladata',
				text: 'Yrittäkää ladata uudelleen',
			},
			// errorNumber 3
			{
				title: 'Me emme tunnista sinua',
				text: 'Incognito-chatti ei ole käytettävissä',
			},
			// errorNumber 4
			{
				title: 'Virheellinen käyttäjänimi',
				text: 'Menkää profiiliinne, vaihtakaa käyttäjänimenne ja kirjautukaa uudelleen chattiin.',
			},
		],
	},
	tooltips: {
		emotions: 'Pidä painettuna vaihtaaksesi',
		badInet: 'Heikko verkkoyhteys',
		blockedUsers: 'Voitte tarkastella estettyjen käyttäjien listaa täällä.',
		waitingSpeakers: 'Joku osallistujista haluaa sanoa jotain.',
		wantSpeak: 'Pyydä äänioikeutta klikkaamalla.',
		slowMode: {
			header: (time: string) =>
				`Hidas toimintapa on päällä. Käyttäjät voivat lähettää yhden viestin joka ${time}. Tämä sääntö ei koske moderaattoreita.`,
			btnSendMessage: (time: string) => `Voit lähettää viestejä <br> joka ${time}.`,
		},
		shareBet: 'Jaa veto chatissa',
		translate: 'Käännä viestit toiselle kielelle',
	},
	poll: {
		toggleSubmenu: {
			create: 'Tee kysely',
			edit: 'Muokkaa kyselyä',
			show: 'Näytä kysely',
			end: 'Lopeta kysely',
		},
		tooltip: {
			btn: 'Äänestä',
		},
		settings: {
			title: 'Asetukset',
			titleAdditionally: 'Lisäksi',
			switches: {
				quizMode: 'Visailun toimintatila',
				multipleChoice: 'Valitse useita vastauksia',
				withPhoto: 'Kysely valokuvan kanssa',
			},
		},
		question: {
			title: 'Kysymys',
			inputPlaceholder: 'Tehkää kysymys',
		},
		message: {
			title: 'Te äänestitte',
		},
		answers: {
			title: 'Vastausvaihtoehdot',
			warning: 'Voitte lisätä enintään 5 vastausta',
		},
		answer: {
			tooltip: 'Valitkaa oikea vastaus',
			inputPlaceholder: 'Vastaus',
		},
		results: {
			text: 'Äänestystulokset',
			votes: 'ääntä',
			// words: ['ääni', 'ääntä', 'ääntä'],
		},
		btns: {
			addQuestion: 'Kysy lisää',
			addAnswers: 'Lisää vastauksia',
			addAnswer: 'Lisää vastaus',
			publish: 'Julkaise',
			vote: 'Äänestäkää',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Arvioi',
				continue: 'Jatka',
				checkConnection: 'Tarkista yhteys',
				close: 'Sulje',
			},
			quality: {
				title: 'Arvioi äänenlaatua',
			},
			reason: {
				title: 'Mitä meni pieleen?',
			},
			connection: {
				title: 'Testaa yhteyden laatu',
				text: 'Tarkistus kestää enintään minuutin ja auttaa löytämään ongelman syyn',
			},
			statistics: {
				title: 'Me keräämme tilastoja...',
			},
			done: {
				title: 'Kiitos!',
				text: 'Me tutkimme, mikä meni pieleen, ja korjaamme sen varmasti.',
			},
		},
	},
	chatBot: {
		message: 'Kysykää botilta mitä tahansa...',
		warnings: {
			disabled: 'Minulta katkaistiin yhteys',
			overloaded: 'Me olemme ylikuormitettuja',
		},
	},
	audioReason: {
		nothear: 'Mitään ei kuulunut',
		connection: 'Ääni keskeytyi',
		microphone: 'Mikrofonin käyttö ei onnistunut',
	},

	copybetting: {
		orderStatus: {
			won: 'Voitto',
			lost: 'Tappio',
			new: 'New',
			pending: 'Pending',
			cashedOut: 'Cashed out',
			boreDraw: 'Bore draw',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Canceled By Operator',
			megaBetCanceledByPlayer: 'MegaBet Canceled By Player',
			megaBetCanceledBySystem: 'MegaBet Canceled By System',
			betGamesPending: 'BetGames Pending',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'Paid',
			initialWin: 'Initial Win',
			autoBetCanceledBySystem: 'AutoBet Canceled By System',
			autoBetCanceledByPlayer: 'AutoBet Canceled By Player',
			autobetPending: 'Autobet Pending',
			goalAhead: 'Goal Ahead',
			canceled: 'Canceled',
			return: 'Return',
		},
		stakeStatus: {
			pending: 'Pending',
			won: 'Won',
			lost: 'Lost',
			return: 'Return',
			lostReturn: 'Lost | Return',
			wonReturn: 'Won | Return',
			canceled: 'Canceled',
			initialReturn: 'Initial Return',
			pendingInitialReturn: 'Pending | Initial Return',
			wonInitialReturn: 'Won | Initial Return',
		},
	},
	screenShare: {
		title: 'Näytön esittely',
		stopSharing: 'Lopeta lähetystoiminta',
		modal: {
			title: 'Voitte jakaa näytön vain tietokoneeltanne',
			text: 'Avatkaa keskustelu tietokoneella ja napsauttakaa näytönäyttöpainiketta oikeassa yläkulmassa.',
			btn: 'Kopioi kirjautumislinkki',
		},
	},
	threads: {
		title: 'Säie',
		back: 'Takaisin',
		replies: ['Vastaus', 'Vastaukset', 'Vastaukset'],
	},
	attach: {
		submenu: {
			sendImage: 'Lähetä kuva',
			shareBet: 'Jaa veto',
			shareGamble: 'Jaa voitto',
		},
		images: ['kuva', 'kuvia', 'kuvien'],
		addCaption: 'Lisää kuvateksti',
	},
} as unknown as Dictionary;
