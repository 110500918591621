import modalService from 'store/modalService';
import badgeService from 'store/badgeService';
import React, {FunctionComponent, Suspense, lazy} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {AgreementAndRulesModal} from './agreementAndRulesModal/index';
import {ProfileModal} from './profileModal/index';
import {SettingsModal} from './settingsModal/index';
import {ReportModal} from './reportModal/index';
import {UsersModal} from './usersModal/index';
import {BlockedModal} from './blockedModal/index';
import {LanguageModal} from './languageModal/index';
import {WaitingSpeakersModal} from './waitingSpeakersModal/index';
import {PollCreatedModal} from './pollCreatedModal/index';
import {PollEditModal} from './pollEditModal/index';
import {PollModal} from './pollModal/index';
import {RulesModal} from './rulesModal/index';
import {OnboardModal} from './onboardModal/index';
import {ChangeNameModal} from './changeNameModal/index';
import {AudioModal} from './feedbackModals/index';
import {SlowModeModal} from './slowModeModal/index';
import {SlowModeTimeoutModal} from './slowModeTimeoutModal/index';
import {ChatBotRulesModal} from './chatBotRulesModal/index';
import {ShareBetModal} from './shareBetModal/index';
import {RecordingModal} from './recordingModal/index';
import {WelcomeModal} from './welcomeModal/index';
import {AgoraDevicesModal} from './agoraDevicesModal/index';
import {TranslateModal} from './translateModal/index';
import {LanguagesModal} from './languagesModal/index';
import {ScreenShareWarnModal} from './sceenShareWarnModal';
import {OfferModal} from './offerModal';
import {ShareGambleModal} from './shareGambleModal/index';
import {ThreadModal} from './threadModal/index';
import {ImagePreviewModal} from './imagePreviewModal';
import {WidgetPreviewModal} from './widgetPreviewModal';
import {StreamSettingsModal} from './streamSettingsModal';

// import {BadgeModal} from './badgeModal';

interface IModals {
	isStreamVideo?: boolean;
}

const Modals: FunctionComponent<IModals> = function Modals({isStreamVideo}) {
	const {isModalOpen} = useLocalObservable(() => modalService);
	const {badgePopupArray} = useLocalObservable(() => badgeService);

	const BadgeModal = lazy(() => import('./badgeModal'));

	if (isModalOpen) {
		return (
			<>
				<RecordingModal />
				<SettingsModal />
				<ProfileModal startScreen='main' />
				<BlockedModal />
				<LanguageModal />
				<SlowModeModal />
				<SlowModeTimeoutModal />
				<WaitingSpeakersModal />
				<ReportModal />
				<PollCreatedModal />
				<PollEditModal />
				<PollModal />
				<OnboardModal />
				<ChangeNameModal />
				<UsersModal />
				<AudioModal />
				<ShareBetModal />
				<AgreementAndRulesModal />
				<ChatBotRulesModal />
				<RulesModal />
				<WelcomeModal />
				<AgoraDevicesModal />
				<TranslateModal />
				<LanguagesModal />
				<ScreenShareWarnModal />
				<OfferModal />
				<ShareGambleModal />
				<ThreadModal isStreamVideo={isStreamVideo} />
				<Suspense fallback={<div>{}</div>}>
					{badgePopupArray && badgePopupArray?.length > 0 && <BadgeModal />}
				</Suspense>
				<ImagePreviewModal />
				<WidgetPreviewModal />
				<StreamSettingsModal />
			</>
		);
	}

	return null;
};

export default observer(Modals);
