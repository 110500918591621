import {BetOrderStatusV2, BetStakeStatus} from 'models/enums/Bet.enum';
import useL10n from 'l10n/useL10n';

const useBetStatus = () => {
	const {copybetting} = useL10n();

	const betOrderStatuses = [
		{
			id: 1,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.WON],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 2,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.LOST],
			isCopybetBtn: false,
			color: 'red',
		},
		{
			id: 3,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.NEW],
			isCopybetBtn: true,
			color: 'orange',
		},
		{
			id: 4,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.PENDING],
			isCopybetBtn: true,
			color: 'orange',
		},
		{
			id: 5,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.CASHED_OUT],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 6,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.PENDING],
			isCopybetBtn: true,
			color: 'orange',
		},
		{
			id: 7,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.WON],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 8,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.LOST],
			isCopybetBtn: false,
			color: 'red',
		},
		{
			id: 9,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.BORE_DRAW],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 10,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.CASHBACK],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 11,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.MEGABET_NEW],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 12,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.MEGABET_CANCELED_BY_OPERATOR],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 13,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.MEGABET_CANCELED_BY_PLAYER],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 14,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.MEGABET_CANCELED_BY_SYSTEM],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 15,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.BETGAMES_PENDING],
			isCopybetBtn: false,
			color: 'orange',
		},
		{
			id: 16,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.BETGAMES_WON],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 17,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.BETGAMES_LOST],
			isCopybetBtn: false,
			color: 'red',
		},
		{
			id: 18,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.PAID],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 19,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.INITIAL_WIN],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 20,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.AUTOBET_CANCELED_BY_SYSTEM],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 21,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.AUTOBET_CANCELED_BY_PLAYER],
			isCopybetBtn: false,
			color: 'gray',
		},
		{
			id: 22,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.AUTOBET_PENDING],
			isCopybetBtn: false,
			color: 'orange',
		},
		{
			id: 23,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.GOAL_AHEAD],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 24,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.CANCELED],
			isCopybetBtn: false,
			color: 'green',
		},
		{
			id: 25,
			orderStatus: copybetting.orderStatus[BetOrderStatusV2.RETURN],
			isCopybetBtn: false,
			color: 'green',
		},
	];

	const betStakeStatuses = [
		{
			id: 1,
			stakeStatus: copybetting.stakeStatus.pending,
			name: BetStakeStatus[1],
		},
		{
			id: 2,
			stakeStatus: copybetting.stakeStatus.won,
			name: BetStakeStatus[2],
		},
		{
			id: 3,
			stakeStatus: copybetting.stakeStatus.lost,
			name: BetStakeStatus[3],
		},
		{
			id: 4,
			stakeStatus: copybetting.stakeStatus.return,
			name: BetStakeStatus[4],
		},
		{
			id: 5,
			stakeStatus: copybetting.stakeStatus.lostReturn,
			name: BetStakeStatus[5],
		},
		{
			id: 6,
			stakeStatus: copybetting.stakeStatus.wonReturn,
			name: BetStakeStatus[6],
		},
		{
			id: 7,
			stakeStatus: copybetting.stakeStatus.canceled,
			name: BetStakeStatus[7],
		},
		{
			id: 8,
			stakeStatus: copybetting.stakeStatus.initialReturn,
			name: BetStakeStatus[8],
		},
		{
			id: 9,
			stakeStatus: copybetting.stakeStatus.pendingInitialReturn,
			name: BetStakeStatus[9],
		},
		{
			id: 10,
			stakeStatus: copybetting.stakeStatus.wonInitialReturn,
			name: BetStakeStatus[10],
		},
	];

	return {betOrderStatuses, betStakeStatuses};
};
export default useBetStatus;
