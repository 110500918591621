import {MODAL_STYLE} from 'constants/constants';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import userServices from 'store/userService';
import modalServices from 'store/modalService';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import ChatNameForm from 'components/chatNameForm/ChatNameForm';
import AvatarForm from 'components/avatarForm/AvatarForm';
import {Preloader} from 'components/preloader';

import './onboard.scss';

const Onboard: FunctionComponent = function Onboard() {
	const translations = useL10n();
	const [, setCurrentScreen] = useState('loader');
	const {onboardModalVisible} = useLocalObservable(() => modalServices);
	const {userData} = useLocalObservable(() => userServices);

	const setCurrentScreenHandler = (screenName: string) => {
		setCurrentScreen(screenName);
	};

	const renderPage = () => {
		switch (userData?.onboardStage) {
			case OnboardStage.NAME:
				return (
					<>
						<p className='onboard__title'>{translations.nameScreen.title}</p>
						<ChatNameForm
							setCurrentScreen={() => setCurrentScreenHandler('avatar')}
							checkNameChange={false}
							fromChangeNameModal={false}
						/>
					</>
				);
			case OnboardStage.AVATAR:
				return (
					<>
						<p className='onboard__title'>{translations.avatarScreen.title}</p>
						<AvatarForm currentScreen='intro' />
					</>
				);
			default:
				return <Preloader visible />;
		}
	};

	if (!onboardModalVisible) {
		return null;
	}

	return (
		<Modal
			className='onboard'
			isOpen={onboardModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			{renderPage()}
		</Modal>
	);
};

export default observer(Onboard);
