import {getParent} from 'utils/helpers';
import appService from 'store/appService';
import userService from 'store/userService';
import roomService from 'store/roomService';
import pollService from 'store/pollService';
import modalService from 'store/modalService';
import agoraService from 'store/agoraService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useOnboarding from 'hooks/useOnboarding';
import usePollAnalytics from 'hooks/usePollAnalytics';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {isDesktop} from 'react-device-detect';

import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {ReactComponent as IcoPoll} from 'assets/svg/ico-poll.svg';
import {ReactComponent as IcoTooltipArrowVertical} from 'assets/svg/ico-tooltip-arrow-vertical.svg';
import Button from 'components/hoc/Button';
import PollToggleOptions from './PollToggleOptions';
import PollToggleSubmenu from './PollToggleSubmenu';

import './pollToggle.scss';

const POLL_TOGGLE_BUTTON_ACCENT_ADD_TIMEOUT = 4000;
const POLL_TOGGLE_BUTTON_ACCENT_REMOVE_TIMEOUT = 8000;
const POLL_TOGGLE_TOOLTIP_VISIBILITY_DURATION = 350;

const PollToggle: FunctionComponent = function PollToggle() {
	const pollToggleButtonAccentAddRef: {current: NodeJS.Timeout | null} = useRef(null);
	const pollToggleButtonAccentRemoveRef: {current: NodeJS.Timeout | null} = useRef(null);
	const {appReadOnly, appIcons, appEnableOnboarding} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userService);
	const {myTalker, pinnedMessages, restApiDataLoaded} = useLocalObservable(() => roomService);
	const {
		poll,
		pollAlreadyVoted,
		pollTooltipVisible,
		pollToggleButtonAccent,
		toggllePollTooltipVisible,
		toggllePollToggleButtonAccent,
	} = useLocalObservable(() => pollService);
	const {togglePollModalVisible} = useLocalObservable(() => modalService);
	const {isScreenSharing, isMyTalkerShareScreen, isFullScreenVideo} = useLocalObservable(
		() => agoraService
	);
	const {appEnableFullscreenOption} = useLocalObservable(() => appService);

	const [visiblePollToggleSubmenu, setVisiblePollToggleSubmenu] = useState(false);

	const {poll: pollTranslate} = useL10n();
	const {getAppIcon} = useAppData();
	const {onBoardingStageHandler} = useOnboarding();
	const {sendPollAnalytics} = usePollAnalytics();

	const {IcoClose} = appIcons;

	const checkUserIsOnboarding =
		!appEnableOnboarding || (userData?.isOnboard && appEnableOnboarding);
	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const checkVisibleToggleOptions =
		poll &&
		poll.options.length &&
		!poll.options.filter(option => option.pic === '' || option.pic === null).length;

	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const pollToggleClasses = classNames('poll-toggle', {
		'poll-toggle--active': poll && !pollAlreadyVoted,
		'poll-toggle--down': pinnedMessages?.length,
		'poll-toggle--screen-sharing-speaker': isMyTalkerShareScreen,
		'poll-toggle--screen-sharing': isScreenSharing,
		'poll-toggle--fullscreen-mode': isFullScreenMode,
	});

	const pollToggleButtonContentClasses = classNames('poll-toggle__button-content', {
		'poll-toggle__button-content--accent': pollToggleButtonAccent && !pollTooltipVisible,
	});

	const onPollToggleHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && checkUserIsOnboarding) {
			if (isMyTalkerModer) {
				setVisiblePollToggleSubmenu(!visiblePollToggleSubmenu);
				return;
			}
			toggllePollTooltipVisible(false);
			togglePollModalVisible(true);
			if (pollAlreadyVoted) {
				sendPollAnalytics('pq_viewed');
				return;
			}
			sendPollAnalytics('pq_opened');
		}
	};

	const onClickEventHandler = (event: any) => {
		if (
			!appReadOnly &&
			isUserExternalIdCorrect &&
			!getParent(event.target, 'poll-toggle__submenu')
		) {
			setVisiblePollToggleSubmenu(false);
		}
	};

	const onPollToggleTooltipCloseHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			toggllePollTooltipVisible(false);
		}
	};

	const clearAllpollToggleButtonAccentTimeouts = () => {
		if (pollToggleButtonAccentAddRef.current) {
			clearTimeout(pollToggleButtonAccentAddRef.current);
		}
		if (pollToggleButtonAccentRemoveRef.current) {
			clearTimeout(pollToggleButtonAccentRemoveRef.current);
		}
	};

	const pollToggleTooltip = useCallback(
		() => (
			<div className='poll-toggle__tooltip'>
				<Button className='poll-toggle__tooltip-close' onClick={onPollToggleTooltipCloseHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
				<div className='poll-toggle__tooltip-content'>
					<div className='poll-toggle__tooltip-text'>{poll?.text}</div>
					<Button
						className='poll-toggle__tooltip-vote'
						onClick={checkUserIsOnboarding ? onPollToggleHandler : onBoardingStageHandler}>
						{pollTranslate.tooltip.btn}
					</Button>
				</div>
				<div className='poll-toggle__tooltip-arrow'>
					<IcoTooltipArrowVertical />
				</div>
			</div>
		),
		[poll?.text]
	);

	useEffect(() => {
		if (restApiDataLoaded.pollLoaded) {
			if (!pollAlreadyVoted && !pollTooltipVisible && !isMyTalkerModer) {
				pollToggleButtonAccentAddRef.current = setTimeout(() => {
					if (!pollAlreadyVoted && !pollTooltipVisible && !isMyTalkerModer) {
						toggllePollToggleButtonAccent(true);
						pollToggleButtonAccentRemoveRef.current = setTimeout(() => {
							toggllePollToggleButtonAccent(false);
						}, POLL_TOGGLE_BUTTON_ACCENT_REMOVE_TIMEOUT);
						return;
					}
					clearAllpollToggleButtonAccentTimeouts();
				}, POLL_TOGGLE_BUTTON_ACCENT_ADD_TIMEOUT);
			}
		}

		return () => {
			clearAllpollToggleButtonAccentTimeouts();
		};
	}, [restApiDataLoaded.pollLoaded, isMyTalkerModer]);

	useEffect(() => {
		if (pollTooltipVisible) {
			toggllePollToggleButtonAccent(false);
			clearAllpollToggleButtonAccentTimeouts();
		}
	}, [pollTooltipVisible]);

	useEffect(() => {
		if (visiblePollToggleSubmenu) {
			document.addEventListener('touchstart', onClickEventHandler);
			document.addEventListener('mousedown', onClickEventHandler);
		}

		return () => {
			document.removeEventListener('touchstart', onClickEventHandler);
			document.removeEventListener('mousedown', onClickEventHandler);
		};
	}, [visiblePollToggleSubmenu]);

	return (
		<div className={pollToggleClasses} data-hint='polltoggle'>
			<Button
				className='poll-toggle__button'
				onClick={checkUserIsOnboarding ? onPollToggleHandler : onBoardingStageHandler}>
				<div className={pollToggleButtonContentClasses}>
					<div className='poll-toggle__button-ico'>
						<IcoPoll />
					</div>
					<div className='poll-toggle__button-text'>{pollTranslate.btns.vote}</div>
				</div>
				{!!checkVisibleToggleOptions && <PollToggleOptions />}
			</Button>
			{isMyTalkerModer && visiblePollToggleSubmenu && (
				<PollToggleSubmenu callBackFunctionAfterClick={setVisiblePollToggleSubmenu} />
			)}
			<CSSTransition
				in={pollTooltipVisible && !pollAlreadyVoted && !isMyTalkerModer}
				timeout={POLL_TOGGLE_TOOLTIP_VISIBILITY_DURATION}
				classNames='fade'
				unmountOnExit>
				{pollToggleTooltip()}
			</CSSTransition>
		</div>
	);
};

export default observer(PollToggle);
