import appService from 'store/appService';
import toastService from 'store/toastService';
import useCopy from 'hooks/useCopy';
import {useEffect, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import useL10n from 'l10n/useL10n';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import './share-dropdown.scss';
import roomService from 'store/roomService';

interface IShareDropdown {
	isShareDropDownHovered: boolean;
}

const ShareDropdown: React.FC<IShareDropdown> = function ShareDropdown({isShareDropDownHovered}) {
	const {shareData} = useLocalObservable(() => appService);
	const {addToast} = useLocalObservable(() => toastService);
	const {roomId} = useLocalObservable(() => roomService);
	const translations = useL10n();

	const {copied, copy, reset} = useCopy(`${shareData.url}?roomId=${roomId}`);
	const shareDropdownRef = useRef<HTMLDivElement>(null);

	const onCopyHandler = async () => {
		await copy();
	};

	const checkIfReachEdge = () => {
		if (shareDropdownRef.current) {
			const left = shareDropdownRef.current?.getBoundingClientRect().left;
			if (left < 0) {
				shareDropdownRef.current.style.right = 'auto';
				shareDropdownRef.current.style.left = '-40px';
			}
		}
	};

	useEffect(() => {
		if (isShareDropDownHovered) {
			checkIfReachEdge();
		}
	}, [isShareDropDownHovered]);

	useEffect(() => {
		if (copied) {
			addToast({
				iconName: ToastIconsName.copy,
				liveTime: 3000,
				message: translations.toasts.messageCopied,
				cancellable: true,
				deathCallback: () => {
					reset();
				},
			});
		}
	}, [copied]);

	return (
		<div className='share-dropdown' ref={shareDropdownRef}>
			<div className='share-dropdown__items'>
				<div className='share-dropdown__item'>
					<div className='share-dropdown__item-title'>{`${shareData.url}?${roomId}`}</div>
					<button
						type='button'
						className='share-dropdown__item-button share-dropdown__item-button--copy'
						aria-label='copy'
						onClick={onCopyHandler}
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(ShareDropdown);
