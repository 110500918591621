import RoomStatus from 'models/enums/RoomStatus.enum';
import {GenerateReaction} from 'models/room';
import roomServices from 'store/roomService';
import emotionServices from 'store/emotionService';
import SocketIoServices from 'services/SocketIoServices';
import useAnalytics from 'hooks/useAnalytics';
import {useCallback} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import throttle from 'lodash/throttle';
import threadService from 'store/threadService';

export default () => {
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {myTalker, roomId, roomData} = useLocalObservable(() =>
		currentThreadId ? threadService : roomServices
	);

	const {emotions} = useLocalObservable(() => emotionServices);
	const {sendAnalytics} = useAnalytics();

	const reactionItemHandler = useCallback(
		throttle(
			(reaction: GenerateReaction) => {
				const {items, messageId, name, messageUserId} = reaction;

				if (roomId && roomData?.status === RoomStatus.LIVE) {
					const emitObj = {
						emotion: name,
						externalRoomId: currentThreadId || roomId,
						messageId,
					};
					if (items.find(item => item.emotion === name && item.talkerId === myTalker?.id)) {
						SocketIoServices.emitDeleteReaction(emitObj);
						return;
					}
					SocketIoServices.emitCreateReaction(emitObj);
					sendAnalytics('message_reaction_sent', {
						message_reaction_number: emotions.map(emotion => emotion.name).indexOf(name) + 1,
						message_id: messageId,
						message_sender_user_id: messageUserId,
					});
				}
			},
			250,
			{leading: true, trailing: false}
		),
		[myTalker, roomId, roomData]
	);

	return {reactionItemHandler};
};
