import chatServices from 'store/chatService';
import suggestServices from 'store/suggestService';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import Button from 'components/hoc/Button';
import ChatBotLocalAvatar from 'assets/images/bot.png';
import './suggest.scss';

const Suggest: FunctionComponent = function Suggest() {
	const {chatBot, setTextareaValue, setVisibleTextareaSendBtn} = useLocalObservable(
		() => chatServices
	);
	const {visibleSuggest, toggleVisibleSuggest} = useLocalObservable(() => suggestServices);
	const {chatBot: chatBotTranslate} = useL10n();

	const onSuggestWbotHandler = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		setVisibleTextareaSendBtn(false);
		setTextareaValue(`@${chatBot?.name} `);
		chatTextarea?.focus();
		toggleVisibleSuggest(false);
	};

	return (
		<CSSTransition in={visibleSuggest} timeout={500} classNames='fade' unmountOnExit>
			<div className='suggest'>
				<div className='suggest__items'>
					<div className='suggest__item'>
						<Button className='suggest__item-button' onClick={onSuggestWbotHandler}>
							<div className='suggest__item-container'>
								<div
									className='suggest__item-img'
									style={{backgroundImage: `url(${chatBot?.pic || ChatBotLocalAvatar})`}}
								/>
								<div className='suggest__item-info'>
									<div className='suggest__item-name'>@{chatBot?.name}</div>
									<div className='suggest__item-text'>{chatBotTranslate.message}</div>
								</div>
							</div>
						</Button>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(Suggest);
