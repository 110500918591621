import ChatNameForm from 'components/chatNameForm/ChatNameForm';
import useL10n from 'l10n/useL10n';

interface IProfileEditNameScreenProps {
	setCurrentScreen: (val: string) => void;
}

const ProfileEditNameScreen: React.FC<IProfileEditNameScreenProps> = function ProfileEditNameScreen(
	props
) {
	const {setCurrentScreen} = props;
	const translations = useL10n();

	return (
		<section className='profile-screens'>
			<p className='profile-screens__title'>{translations.nameScreen.title}</p>
			<ChatNameForm setCurrentScreen={() => setCurrentScreen('main')} fromChangeNameModal />
		</section>
	);
};

export default ProfileEditNameScreen;
