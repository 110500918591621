import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';

interface IButtonMuteProps {
	message: Message;
}

const ButtonMute: FunctionComponent<IButtonMuteProps> = function ButtonMute({message}) {
	const {appIcons} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);

	const {chatScreen} = useL10n();
	const {muteUser} = useSubmenuActions();
	const {getAppIcon} = useAppData();

	const {IcoMute} = appIcons;

	const onMuteHandler = () => {
		if (roomId) {
			muteUser(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={onMuteHandler}>
			{chatScreen.chatSubmenu.muteSpeaker}
			{getAppIcon(IcoMute.pic, {className: 'chat__submenu-icon'})}
		</Button>
	);
};

export default observer(ButtonMute);
