/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Acuerdo',
		subTitle: 'Acuerdo de licencia y política de privacidad',
		textFirst: 'Acepto los términos',
		textSecond:
			'Y doy mi consentimiento para el tratamiento de mis datos personales en los términos establecidos',
		license: 'Acuerdo de licencia',
		policy: 'Política de privacidad',
	},
	controlPanel: {
		streamEnded: 'Transmisión finalizada',
		banAlert: 'Usted no puede escribir en el chat',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Etiquetas' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Perfil eliminado',
		text: (date: string) => `Usted puede recuperar su perfil hasta ${date}`,
	},
	nameScreen: {
		title: 'Nombre en el chat',
		inputPlaceholder: 'Ingrese su nombre',
		nameError: 'Nombre no permitido',
	},
	avatarScreen: {
		title: 'Foto de perfil',
		label: 'Elegir otro:',
	},
	settingsScreen: {
		title: 'Ajustes',
		personal: 'Ajustes personales',
		room: 'Ajustes de sala',
		profileBtn: 'Perfil',
		languageBtn: 'Idioma',
		blockedBtn: 'Bloqueados',
		rules: 'Reglas del chat',
		deleteProfile: 'Eliminar perfil',
		slowModeBtn: 'Modo lento',
		agora: {
			microphone: 'Micrófono',
			playbackDevice: 'Fuente de sonido',
		},
	},
	languageScreen: {
		title: 'Idioma e interfaz',
		messagesTranslate: 'Traducción de mensajes',
		translateMessagesMode: 'Traducir mensajes en el chat',
		translateMessagesTo: 'Traducir mensajes a:',
	},
	rulesScreen: {
		title: 'Reglas del chat',
	},
	profileScreen: {
		title: 'Perfil',
		selectAnotherPic: 'Elegir otra foto de perfil',
		chatNameLabel: 'Nombre en el chat',
		changeName: 'cambiar',
	},
	blockedScreen: {
		title: 'Bloqueados',
		emptyListText: 'No hay usuarios bloqueados',
	},
	chatScreen: {
		inputPlaceholder: 'Mensaje…',
		cantWrite: 'Usted no puede escribir en el chat',
		chatSubmenu: {
			hideMessage: 'Ocultar mensajes',
			showMessage: 'Mostrar mensajes',
			removeFromSpeakers: 'Quitar de los oradores',
			setSpeaker: 'Hacer orador',
			hideOnlyMessage: 'Ocultar mensaje',
			showOnlyMessage: 'Mostrar mensaje',
			reportMessage: 'Reportar mensaje',
			reportUser: 'Reportar usuario',
			muteSpeaker: 'Apagar micrófono',
			blockUser: 'Bloquear',
			unblockUser: 'Desbloquear',
			reply: 'Contestar',
			copy: 'Copiar',
			edit: 'Cambiar',
			delete: 'Eliminar',
			pinMessage: 'Fijar',
			unpinMessage: 'Quitar',
			replyInThread: 'Responder en el hilo',
		},
		actionMessage: {
			editing: 'Redacción',
			reply: 'Contestar',
		},
		status: {
			top: 'Top',
		},
		translating: 'Traduciendo...',
	},
	chatUsersScreen: {
		title: 'Participantes',
		edited: 'Cambiado',
		hidden: 'oculto',
		unreadMessages: 'Mensajes no leídos',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Callar al participante',
				subtitle: 'Apagar el micrófono',
			},
			setSpeaker: {
				title: 'Quitar de oradores',
				subtitle: 'Solo chat escrito',
			},
			removeFromSpeaker: {
				title: 'Hacer orador',
				subtitle: 'El usuario puede hablar',
			},
			showMessages: {
				title: 'Mostrar mensajes',
				subtitle: 'Visible solo para el administrador',
			},
			hideMessages: {
				title: 'Ocultar mensajes',
				subtitle: 'Visible solo para el administrador',
			},
			blockUser: {
				title: 'Bloquear',
				subtitle: 'Añadir a la lista de parada',
			},
			unblockUser: {
				title: 'Desbloquear',
				subtitle: 'Desbloquear al usuario',
			},
			blockUserByUser: {
				title: 'Bloquear',
				subtitle: 'Ocultar todos los mensajes del usuario',
			},
			unblockUserByUser: {
				title: 'Desbloquear',
				subtitle: 'Mostrar todos los mensajes del usuario',
			},
			reportUser: {
				title: 'Reportar usuario',
				subtitle: 'El reporte será enviado anónimamente',
			},
		},
		days: {
			today: 'Hoy',
			yesterday: 'Ayer',
		},
		chatCreated: 'Сhat creado,',
	},
	alerts: {
		btns: {
			unblock: 'Desbloquear',
			block: 'Bloquear',
			send: 'Enviar',
			sendAndBlock: 'Enviar y bloquear',
			show: 'Mostrar',
			showAll: 'Mostrar todos',
			cancel: 'Cancelar',
			yes: 'Sí',
			hide: 'Ocultar seleccionado',
			hideAll: 'Ocultar todos',
			make: 'Hacer',
			remove: 'Quitar',
			delete: 'Eliminar',
			deleteAccount: 'Eliminar perfil',
			close: 'Cerrar',
			pin: 'Fijar',
			unpin: 'Quitar',
			edit: 'Editar',
			end: 'Finalizar',
			endAndShare: 'Finalizar y compartir',
			reset: 'Deshacer',
			rules: 'Reglas del chat',
			contactus: 'Envíenos un mensaje',
		},
		pinMessage: {
			title: '¿Fijar mensaje?',
		},
		unPinMessage: {
			title: '¿Quitar de mensajes fijados?',
		},
		accountRestored: {
			title: 'Perfil recuperado',
		},
		closeApp: {
			title: '¿Está seguro de que quiere salir?',
		},
		removeMessage: {
			title: '¿Borrar mensaje??',
		},
		report: {
			title: '¿Enviar reporte?',
			subtitle: 'Usted también puede desbloquear al usuario, para no ver los mensajes mutuamente.',
		},
		banUser: {
			title: (name: string) => `¿Bloquear ${name}?`,
			subtitle: (text: string) => `El usuario será bloqueado por ${text}`,
		},
		unBanUser: {
			title: (name: string) => `¿Desbloquear ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `¿Mostrar los mensajes de ${name}?`,
			subTitle: 'Los demás usuarios podrán ver mensajes de este usuario',
		},
		hideUserMessages: {
			title: (name: string) => `¿Ocultar los mensajes de ${name}?`,
			subTitle: 'Los demás usuarios no podrán ver más los mensajes de este usuario',
		},
		showUserMessage: {
			title: (name: string) => `¿Mostrar los mensajes de ${name}?`,
			subTitle: 'Los demás usuarios podrán ver este mensaje',
		},
		hideUserMessage: {
			title: (name: string) => `¿Ocultar mensajes de ${name}?`,
			subTitle: 'Los demás usuarios no podrán ver más este mensaje',
		},
		blockUserInRoom: {
			title: (name: string) => `¿Bloquear ${name}?`,
			subTitle: 'El usuario no podrá participar en esta sala',
		},
		unBlockUserInRoom: {
			title: (name: string) => `¿Desbloquear ${name}?`,
			subTitle: 'El usuario podrá participar en esta sala',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `¿Bloquear ${name}?`,
			subTitle: 'Usted no podrá ver los mensajes mutuamente',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `¿Hacer que ${name} sea orador?`,
			unTitle: (name: string) => `¿Quitar a ${name} de oradores?`,
		},
		banInApp: {
			title: 'El administrador lo ha bloqueado',
			for: 'por',
			subtitle: (bannedUntil: string) => `Usted ha sido bloqueado hasta ${bannedUntil}`,
			permanent: 'Usted ha sido bloqueado para siempre',
		},
		banInAppBadNickname: {
			title: 'El administrador lo ha bloqueado a usted por tener nombre inapropiado',
			subtitle: 'Ingrese en su perfil, cambie de nombre e ingrese nuevamente al chat',
		},
		deleteAccount: {
			title: 'Eliminación de perfil',
			subTitle:
				'Todos los datos serán eliminados. Podrá recuperar su perfil en un plazo de 30 días.',
		},
		pollEdit: {
			title: '¿Editar encuesta?',
			subTitle: 'Las respuestas de los usuarios serán borradas',
		},
		pollReset: {
			title: '¿Borrar?',
			subTitle: 'La encuesta no será guardada',
		},
		pollEnd: {
			title: '¿Está seguro que quiere finalizar la encuesta?',
		},
		pollDelete: {
			title: '¿Eliminar encuesta?',
		},
		agoraCheck: {
			updateIOSText:
				'Actualiza la versión de iOS para utilizar todas las herramientas de la aplicación.',
			allowAccess: {
				title: '¿Quieres decir algo?',
				text: 'Por favor, abre la configuración de la aplicación y permite el acceso al micrófono y al altavoz. ',
			},
			microphoneAndSpeakerText:
				'El uso del micrófono y el altavoz está disponible desde la versión 14.3.',
			microphoneText: 'El uso del micrófono y el altavoz está disponible desde la versión 14.3.',
			btn: '¡De acuerdo!',
		},
	},
	eventScreen: {
		title: 'Evento programado',
		titleSoon: 'La transmisión comenzará en breve',
		subtitle: 'El chat de la transmisión estará habilitado en:',
		days: 'días',
		hours: 'horas',
		minutes: 'minutos',
		seconds: 'segundos',
		startTitle: 'Comienzo',
	},
	toasts: {
		reciveComplaint: 'Hemos recibido su reporte',
		setSpeakerRoleForUser: 'El usuario ahora es orador',
		unSpeakerRoleForUser: 'El usuario ha sido borrado de oradores',
		messagesShown: 'Mensajes mostrados',
		messagesHidden: 'Mensajes ocultos',
		messageShown: 'Mensaje mostrado',
		messageHidden: 'Mensaje oculto',
		userBlocked: 'Usuario bloqueado',
		userUnBlocked: 'Usuario desbloqueado',
		youAreSpeaker: '¡Usted es el orador! Diga algo',
		youAreNotSpeaker: 'El administrador lo ha eliminado de oradores',
		userIsSpeaker: (name: string) => `${name} ahora es orador`,
		userNotSpeaker: (name: string) => `$ name ya no es orador`,
		yourUserNameInChat: (name: string) => `Su nombre en el chat — ${name}`,
		messageRemoved: 'Mensaje borrado',
		messageCopied: 'Texto copiado',
		obscenities: 'Intente perefrasear',
		requestSent: 'Solicitud enviada',
		requestDecline: 'Solicitud rechazada por el administrador',
		youareblocked: (text: string) =>
			`Usted ha sido bloqueado por ${text}. Usted no puede escribir en el chat.`,
		youareunblocked: 'Usted ha sido desbloqueado. Puede escribir mensajes en el chat.',
		youAreModerator: '¡Usted es el moderador!',
		youAreNoModerator: '¡Usted no es más el moderador!',
		micMuted: 'Su micrófono está silenciado',
		noInet: 'No hay conexión a internet',
		messagePinned: 'Mensaje fijado',
		messageUnpinned: 'Mensaje quitado de fijados',
		pollPublished: 'Encuesta publicada',
		pollPublishedError: '¡Error! Intente de nuevo más tarde',
		pollAddPic: 'Añada foto',
		pollAddPicError: 'Eliga otra imagen',
		pollDeleted: 'Encuesta borrada',
		pollEnded: 'Encuesta finalizada',
		sendStickerWhenSlowModeIsOn: (time: string) => `Espere ${time} para enviar el sticker`,
		linkProhibited: 'No puedes enviar enlaces en el chat.',
		phoneProhibited: 'No puedes enviar números de teléfono en el chat.',
		profileHidden: 'El usuario ha ocultado su perfil',
		imageSizeWarning: 'Puedes subir una imagen de hasta 10 MB',
		imageBlocked: 'El moderador bloqueó la subida de la imagen',
		streamKeyCopied: 'Clave de transmisión copiada',
	},
	btns: {
		acceptandgo: 'Aceptar y continuar',
		saveandgo: 'Guardar y continuar',
		save: 'Guardar',
		unblock: 'Desbloquear',
		block: 'Bloquear',
		restoreAccount: 'Recuperar el perfil',
		close: 'Cerrar',
	},
	coopyrights: 'Funciona en base a Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Reportar mensaje' : 'Reportar violación de uso',
			subtitle: 'Las quejas se envían de forma anónima',
			btns: {
				violence: 'Insultos, amenazas, malas palabras',
				provocations: 'Provocaciones',
				personalDetails: 'Datos personales',
				flood: 'Flood',
				spam: 'Spam',
				fraudBegging: 'Estafa, mendicidad',
				other: 'otro',
			},
		},
		ban: {
			title: 'Bloquear usuario',
			subtitle: 'Señale el motivo para bloquear',
			btns: {
				insults: 'Insultos, amenazas, malas palabras',
				personal: 'Difusión de datos personales',
				flood: 'Flood',
				links: 'Links a servicios externos',
				violence: 'Insultos, amenazas, malas palabras',
				fraud: 'Intento de estafa y mendicidad otro',
				nickname: 'Nombre de usuario inadecuado',
				politicial: 'Políticas, sexuales y otras provocaciones',
				repeated: 'Violación de condiciones de uso repetitiva',
				auto: 'Reportes de los usuarios del chat',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Usted no tiene oradores en la lista de espera',
			handsUp: 'Levantar la mano',
			waiting: 'Oradores en espera',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Dé la respuesta correcta',
				typeQuizMultiple: 'Señale las opciones correctas',
				typePoll: 'Comparta su opinión',
				typePollMultiple: 'Se puede señalar varias opciones',
				default: 'Encuesta abierta',
			},
			btns: {
				reply: 'Contestar',
				vote: 'Votar',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Concurso',
				typePoll: 'Varias respuestas',
				default: 'Encuesta abierta',
			},
			btns: {
				edit: 'Editar encuesta',
				end: 'Finalizar',
			},
		},
		pollCreated: {
			titles: {
				created: 'Crear encuesta',
				typeQuiz: 'Concurso',
				typePoll: 'Varias respuestas',
				default: 'Encuesta abierta',
			},
		},
		changeName: {
			title: 'Cambiar nombre en el chat',
			subtitle: 'Usted ha sido bloqueado por poseer nombre de usuario inadecuado',
		},
		temporaryAgreement: {
			title: 'Reglas del chat',
			rules: {
				bePolite: {
					title: 'Sea educado',
					text: 'En el chat están prohibidos los enunciados de odio y las malas palabras.',
				},
				beAttentive: {
					title: 'Esté atento',
					text: 'No comparta datos personales y no se los pida a otros.',
				},
				notAdvertise: {
					title: 'No haga publicidad',
					text: 'Es mejor que comparta su opinión o sus emociones.',
				},
			},
			btns: {
				allRules: 'Todas las reglas',
				acceptAndContinue: 'Aceptar y continuar',
			},
		},
		slowMode: {
			title: 'Modo lento',
			text: 'Los participantes no pueden enviar mensajes al chat más frecuente del tiempo estimado',
			enable: 'Activar el modo lento',
			disable: 'Desactivar el modo lento',
			sendTimeout: 'Tiempo de espera de envío',
		},
		slowModeTimeout: {
			title: 'Tiempo de espera de envío',
		},
		chatBot: {
			title: 'Uso del bot de chat',
			rules: [
				{
					title: 'La inteligencia artificial',
					text: 'El bot imita la comunicación con las personas y da información en base a sus conocimientos. Sin embargo, no se olvide de que sus respuestas pueden ser limitadas y no noten a la persona.',
				},
				{
					title: 'El bot puede equivocarse',
					text: 'El bot no puede garantizar la exactitud y veracidad de la información.',
				},
			],
			btns: {
				close: 'Cerrar',
			},
		},
		shareBet: {
			title: 'Compartir',
			noBetsTitle: 'No hemos podido encontrar su apuesta',
			noBetsText: 'Se pueden compartir las apuestas que fueron hechas durante los últimos 14 días',
			btns: {
				share: 'Compartir apuesta',
				copy: 'Repetir apuesta',
			},
			status: {
				null: 'En el juego',
				win: 'Ganancia',
				lose: 'Pérdida',
			},
			betTypeV1: {
				express: 'Múltiple',
				single: 'Simple',
			},
			betTypeV2: {
				single: 'Simple',
				multi: 'Múltiple',
				system: 'System',
			},
			totalFactor: 'Coef.',
			bet: 'apuestas',
			showMore: 'Mostrar más',
		},
		shareGamble: {
			noGamblesText:
				'Aún no tienes premios para compartir. ¡Sigue jugando, y tu próxima victoria aparecerá aquí para que puedas compartirla con otros jugadores!',
		},
		record: {
			text: 'La grabación de la transmisión está siendo procesada',
		},
		translate: {
			title: 'Traducción de mensajes',
			chooseLanguage: 'Seleccionar idioma',
			descr: 'Los mensajes del chat se traducirán automáticamente al idioma que elijas',
			btn: 'Traducir',
		},
		streamSettings: {
			title: 'Configuración de la transmisión',
			description:
				'Descarga e instala un codificador de video. Ingresa la clave de transmisión en su configuración',
			shareScreen: {
				title: 'Compartir pantalla',
				description: 'No se requieren configuraciones especiales',
			},
			rtmp: {
				title: 'Fuente de transmisión externa',
				description: 'Utiliza un programa adicional para transmitir',
			},
			streamKey: 'Clave de transmisión',
			showStreamKey: 'Mostrar',
			hideStreamKey: 'Ocultar',
			btns: {
				continue: 'Continuar',
				close: 'Cerrar',
				ok: 'Listo',
			},
		},
	},
	pinnedMessage: {
		title: 'Mensaje fijado',
	},
	errorPage: {
		error: 'Error',
		types: [
			// errorNumber 0
			{
				title: 'No podemos reconocerlo a usted',
				text: 'Intente ingresar de nuevo al chat',
			},
			// errorNumber 1
			{
				title: 'No podemos encontrar el chat',
				text: 'Intente ingresar de nuevo',
			},
			// errorNumber 2
			{
				title: 'Imposible cargar chat',
				text: 'Intente reiniciar',
			},
			// errorNumber 3
			{
				title: 'No podemos reconocerlo a usted',
				text: 'El chat en modo incógnito no está permitido',
			},
			// errorNumber 4
			{
				title: 'Nombre de usuario inadecuado',
				text: 'Ingrese en su perfil, cambie de nombre e ingrese nuevamente al chat',
			},
		],
	},
	tooltips: {
		emotions: 'Sostenga para cambiar ',
		badInet: 'Mala conexión a internet',
		blockedUsers: 'Ver lista de usuarios bloqueados <br>de los usuarios se puede poner aquí.',
		waitingSpeakers: 'Uno de los participantes quiere hablar.',
		wantSpeak: 'Oprima para pedir derecho a voz.',
		slowMode: {
			header: (time: string) =>
				`Régimen lento activado. Los usuarios <br>pueden enviar un mensaje <br>cada{time}. Esta regla no abarca a los moderadores`,
			btnSendMessage: (time: string) => `Usted puede enviar mensajes <br>cada ${time}.`,
		},
		shareBet: 'Compartir apuesta en el chat',
		translate: 'Traducir los mensajes a otro idioma',
	},
	poll: {
		toggleSubmenu: {
			create: 'Crear encuesta',
			edit: 'Editar encuesta',
			show: 'Mostrar encuesta',
			end: 'Finalizar encuesta',
		},
		tooltip: {
			btn: 'Votar',
		},
		settings: {
			title: 'Ajustes',
			titleAdditionally: 'Complementario',
			switches: {
				quizMode: 'Modo de concurso',
				multipleChoice: 'Elección de varias respuestas',
				withPhoto: 'Encuesta con fotografías',
			},
		},
		question: {
			title: 'Pregunta',
			inputPlaceholder: 'Ingrese la pregunta',
		},
		message: {
			title: 'Usted ha votado',
		},
		answers: {
			title: 'Opciones de respuesta',
			warning: 'Usted puede añadir hasta 5 respuestas',
		},
		answer: {
			tooltip: 'Elija la respuesta correcta',
			inputPlaceholder: 'Respuesta',
		},
		results: {
			text: 'Resultados de la votación',
			votes: 'De votos',
			// words: ['voto', 'de votos', 'de votos'],
		},
		btns: {
			addQuestion: 'Añadir pregunta',
			addAnswers: 'Añadir respuestas',
			addAnswer: 'Añadir respuesta',
			publish: 'Publicar ',
			vote: 'Vote',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Valorar',
				continue: 'Continuar',
				checkConnection: 'Comprobar conexión',
				close: 'Fijar',
			},
			quality: {
				title: 'Valore la calidad del sonido',
			},
			reason: {
				title: '¿Cuál ha sido el problema?',
			},
			connection: {
				title: 'Pruebe la calidad de la conexión',
				text: 'La comprobación tomará menos de un minuto y ayudará a detectar la causa de los problemas',
			},
			statistics: {
				title: 'Recogemos estadística...',
			},
			done: {
				title: '¡Gracias!',
				text: 'La comprobación tomará menos de un minuto y ayudará a detectar la causa de los problemas.',
			},
		},
	},
	chatBot: {
		message: 'Pregúntele algo al bot...',
		warnings: {
			disabled: 'Me han desconectado',
			overloaded: 'Estamos sobrecargados',
		},
	},
	audioReason: {
		nothear: 'No se ha oído nada',
		connection: 'El sonido se interrumpe',
		microphone: 'No se ha podido utilizar el micrófono',
	},
	screenShare: {
		title: 'Compartir pantalla',
		stopSharing: 'Detener compartir pantalla',
		modal: {
			title: 'Compartir pantalla solo está disponible desde una computadora.',
			text: 'Abre el chat en tu ordenador y haz clic en el botón "Compartir pantalla" en la esquina superior derecha.',
			btn: 'Copia el enlace de invitación.',
		},
	},
	threads: {
		title: 'Hilo',
		back: 'Atrás',
		replies: ['Respuesta', 'Respuestas', 'Respuestas'],
	},
	attach: {
		submenu: {
			sendImage: 'Enviar imagen',
			shareBet: 'Compartir apuesta',
			shareGamble: 'Compartir ganancias',
		},
		images: ['imagen', 'imágenes', 'imágenes'],
		addCaption: 'Agregar título',
	},
} as unknown as Dictionary;
