import {USER_UNNAMED} from 'constants/constants';
import userServices from 'store/userService';
import React, {useCallback} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import './profile-main.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import Button from 'components/hoc/Button';
import roomService from 'store/roomService';
import {ReactComponent as ModerIcon} from 'assets/svg/moder.svg';
import {ReactComponent as GuestIcon} from 'assets/svg/guest.svg';
import appService from 'store/appService';

interface IProfileMainScreenProps {
	setCurrentScreen: (val: string) => void;
}

const ProfileMainScreen: React.FC<IProfileMainScreenProps> = function ProfileMainScreen({
	setCurrentScreen,
}) {
	const {userData} = useLocalObservable(() => userServices);
	const {myTalker} = useLocalObservable(() => roomService);
	const {appEnableAvatarChange, appEnableNameChange} = useLocalObservable(() => appService);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const talkerIsModer = !!myTalker?.isModer || !!userData?.isModer;

	const renderUserBadges = useCallback(() => {
		if (userData) {
			const {isVip, vipStatus} = userData;
			return (
				<div className='profile-main__status'>
					{isVip && vipStatus && (
						<div
							className={`profile-main__status-item profile-main__status-item--${vipStatus?.toLowerCase()}`}>
							{vipStatus.toLowerCase()}
						</div>
					)}
					{userData.badges &&
						userData.badges?.length > 0 &&
						userData.badges.map(el => {
							return (
								<div
									key={el.id}
									className='profile-main__status-item profile-main__status-item--badge'
									style={{
										backgroundColor: el.backgroundColor,
										color: el.textColor,
									}}>
									{el.text}
								</div>
							);
						})}
				</div>
			);
		}
		return null;
	}, [userData]);

	const clickOnchageAvatarHandler = () => {
		sendAnalytics('change_avatar_pressed');
		setCurrentScreen('avatar');
	};

	const clickOnchageNameHandler = () => {
		sendAnalytics('change_name_pressed');
		setCurrentScreen('name');
	};

	return (
		<section className='profile-main profile-screens'>
			<p className='profile-screens__title'>{translations.profileScreen.title}</p>
			{appEnableAvatarChange && (
				<div className='profile-main__avatar'>
					<div
						className={`profile-main__avatar-image background-color-${myTalker?.user?.color || 0}`}
						style={{
							backgroundImage: `url(${userData && userData.pic !== '' ? userData.pic : AnonimAva})`,
						}}
					/>
					{renderUserBadges()}
					<Button className='profile-main__avatar-change' onClick={clickOnchageAvatarHandler}>
						{translations.profileScreen.selectAnotherPic}
					</Button>
				</div>
			)}

			<div className='profile-main__name'>
				<p className='profile-main__label'>{translations.profileScreen.chatNameLabel}</p>
				<div className='profile-main__input'>
					<div className='profile-main__input-wrapper'>
						<span className='profile-main__input-text'>
							{userData && userData.name !== '' ? userData.name : USER_UNNAMED}
						</span>
						{talkerIsModer && !userData?.isInvitedGuest && <ModerIcon />}
						{userData?.isInvitedGuest && <GuestIcon />}
					</div>

					{appEnableNameChange && (
						<Button className='profile-main__input-edit' onClick={clickOnchageNameHandler}>
							{translations.profileScreen.changeName}
						</Button>
					)}
				</div>
			</div>
		</section>
	);
};

export default ProfileMainScreen;
