import {MODAL_STYLE} from 'constants/constants';
import {AppScreens} from 'models/enums/AppScreens.enum';
import appService from 'store/appService';
import modalServices from 'store/modalService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {WaitingSpeakers} from 'components/waitingSpeakers';
import Button from 'components/hoc/Button';

const WaitingSpeakersModal: React.FC = function WaitingSpeakersModal() {
	const {changeCurrentScreen, appIcons} = useLocalObservable(() => appService);
	const {waitingSpeakersModalVisible, toggleWaitingSpeakersModal} = useLocalObservable(
		() => modalServices
	);
	const translations = useL10n();
	const {getAppIcon} = useAppData();

	const {IcoHandAccent, IcoClose} = appIcons;

	useEffect(() => {
		waitingSpeakersModalVisible
			? changeCurrentScreen(AppScreens.WAITINIGSPEAKERS)
			: changeCurrentScreen(AppScreens.CHAT);
	}, [waitingSpeakersModalVisible]);

	if (!waitingSpeakersModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--variant-two'
			isOpen={waitingSpeakersModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__head-overico'>{getAppIcon(IcoHandAccent.pic)}</div>
				<div className='modal__head-rigth'>
					<div className='modal__title'>{translations.modals.waitingSpeakers.handsUp}</div>
					<div className='modal__subtitle'>{translations.modals.waitingSpeakers.waiting}</div>
				</div>
				<Button className='modal__close' onClick={() => toggleWaitingSpeakersModal(false)}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<WaitingSpeakers />
				</div>
			</div>
		</Modal>
	);
};

export default observer(WaitingSpeakersModal);
