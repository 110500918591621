/* eslint-disable react/no-danger */
import {CSSProperties, FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import {autoUpdate, arrow, flip, shift, useFloating, computePosition} from '@floating-ui/react-dom';
import classNames from 'classnames';
import {ReactComponent as IcoTooltipArrow} from 'assets/svg/ico-tooltip-arrow.svg';

import './tooltip.scss';

interface ITooltipProps {
	text: string;
	callbackOnHide: () => void;
	referenceElement: any;
	delay?: number;
	position?: TooltipPositoinType;
	center?: boolean;
	visibleDate?: boolean;
}

const Tooltip: FunctionComponent<ITooltipProps> = function Tooltip(props) {
	const {
		delay,
		text,
		callbackOnHide,
		position = TooltipPositoinType.TOP,
		referenceElement,
		center,
		visibleDate,
	} = props;
	const timeoutCallbackRef: {current: NodeJS.Timeout | null} = useRef(null);
	const [mounted, setMouned] = useState(false);
	const [badgeY, setBadgeY] = useState<number>(0);
	const arrowEl = useRef<HTMLDivElement>(null);

	const {
		x,
		y,
		middlewareData: {arrow: {x: arrowX, y: arrowY} = {}},
		floating,
		reference,
		strategy,
	} = useFloating({
		placement: center ? undefined : 'bottom-start',
		middleware: [
			// flip(),
			shift({
				padding: 8,
			}),
			arrow({
				element: arrowEl,
			}),
		],
		whileElementsMounted(...args) {
			const cleanup = autoUpdate(...args, {animationFrame: true});
			setMouned(true);
			return cleanup;
		},
	});

	let arrowStyles: CSSProperties = {};
	arrowStyles = {
		left: arrowX != null ? `${arrowX}px` : '',
		top: arrowY != null ? `${arrowY}px` : '',
	};

	const toolTipClasses = classNames('tooltip', {
		'tooltip--top': position === TooltipPositoinType.TOP,
		'tooltip--bottom': position === TooltipPositoinType.BOTTOM,
	});

	useEffect(() => {
		if (delay) {
			timeoutCallbackRef.current = setTimeout(() => {
				callbackOnHide();
			}, delay);
		}

		return () => {
			if (timeoutCallbackRef.current) {
				clearTimeout(timeoutCallbackRef.current);
				setBadgeY(0);
			}
		};
	}, []);

	useEffect(() => {
		if (!badgeY && y) {
			setBadgeY(y);
		}
	}, [y]);

	useEffect(() => {
		reference(referenceElement);
	}, [reference, referenceElement, y]);

	return (
		<div
			className={toolTipClasses}
			ref={floating}
			style={{
				position: strategy,
				left: center && x ? x - 5 : x || 0,
				bottom:
					center && badgeY && position === TooltipPositoinType.TOP
						? `calc(100% - ${badgeY / 2 - (visibleDate ? 4 : 16)}px)`
						: '',
				opacity: mounted ? 1 : 0,
			}}>
			<span
				className='tooltip__arrow'
				ref={arrowEl}
				style={center ? arrowStyles : {left: x ? -x + 8 : 8}}>
				<IcoTooltipArrow />
			</span>
			<p className='tooltip__text' dangerouslySetInnerHTML={{__html: text}} />
		</div>
	);
};

export default observer(Tooltip);
