import {AppEmotion} from 'models/app';
import roomServices from 'store/roomService';
import emotionServices from 'store/emotionService';
import useEmotions from 'hooks/useEmotions';
import {useCallback, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useDraggable} from 'react-use-draggable-scroll';
import './emotions.scss';

const Emotions: React.FC = function Emotions() {
	const emotionsAxisXRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const {roomId} = useLocalObservable(() => roomServices);
	const {emotions, idEmotionActive} = useLocalObservable(() => emotionServices);

	const {sendEmotion} = useEmotions();
	const {events} = useDraggable(emotionsAxisXRef, {
		applyRubberBandEffect: true,
	});

	const renderEmotion = useCallback(
		(emotion: AppEmotion) => (
			<button
				type='button'
				key={emotion.id}
				data-id={emotion.id}
				className={`emotions__item ${
					emotion.id === idEmotionActive ? 'emotions__item--active' : null
				}`}
				style={{backgroundImage: `url(${emotion.pic})`}}
				onClick={() => sendEmotion(emotion, true)}
				aria-hidden='true'
			/>
		),
		[roomId, idEmotionActive]
	);

	return (
		<div className='emotions'>
			<div className='emotions__axis-x' {...events} ref={emotionsAxisXRef}>
				<div className='emotions__items'>{emotions.map(renderEmotion)}</div>
			</div>
		</div>
	);
};

export default observer(Emotions);
