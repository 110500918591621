import {AlertBody} from 'models/alert';
import {action, makeObservable, observable} from 'mobx';

class AlertService {
	@observable
	public alert: AlertBody = null;

	@observable
	public isVisible = false;

	@action
	public showAlert = (alert: AlertBody) => {
		this.alert = alert;
		this.isVisible = true;
	};

	@action
	public hideAlert = () => {
		this.isVisible = false;
		this.alert = null;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new AlertService();
