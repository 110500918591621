import {FunctionComponent} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';

interface IUsersModalPreloader {
	visible: boolean;
}
const UsersModalPreloader: FunctionComponent<IUsersModalPreloader> =
	function ChatMessagesPreloader({visible}) {
		const {appLoaderIcon} = useLocalObservable(() => appService);
		const {getAppIcon} = useAppData();

		return (
			<div
				className={`users-modal__preloader users-modal__preloader--${
					visible ? 'visible' : 'hidden'
				}`}>
				{getAppIcon(appLoaderIcon)}
			</div>
		);
	};

export default UsersModalPreloader;
