import appService from 'store/appService';
import roomService from 'store/roomService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Player} from 'components/player';
import './recording.scss';
import {localeLanguages} from 'constants/constants';

interface IRecording {
	isViewSmall: boolean;
}
const Recording: FunctionComponent<IRecording> = function Recording({isViewSmall}) {
	const {language} = useLocalObservable(() => appService);
	const {roomData} = useLocalObservable(() => roomService);
	const locale = localeLanguages.find(item => item.includes(language)) || 'en-GB';

	return (
		<div className='recording'>
			<div className='recording__head'>
				{roomData?.pic && !roomData.isRecordVideo && (
					<img className='recording__img' src={roomData?.pic} alt='' />
				)}
				{roomData?.name && (
					<div className='recording__suptitle'>
						{roomData?.startTime
							? new Date(roomData.startTime).toLocaleDateString(locale, {
									day: 'numeric',
									month: 'long',
							  })
							: ''}
						{roomData?.about && <span>, {roomData?.about}</span>}
					</div>
				)}
				{roomData?.name && <div className='recording__title'>{roomData?.name}</div>}
			</div>
			<div className='recording__body'>
				<Player isViewSmall={isViewSmall} />
			</div>
		</div>
	);
};

export default observer(Recording);
