import {PollOption} from 'models/poll';
import roomServices from 'store/roomService';
import pollServices from 'store/pollService';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import PollVotesAnimItem from './PollVotesAnimItem';
import './pollVotesAnim.scss';

const PollVotesAnim: FunctionComponent = function PollVotesAnim() {
	const {pinnedMessages} = useLocalObservable(() => roomServices);
	const {pollVotesAnim} = useLocalObservable(() => pollServices);

	const pollVotesAnimClasses = classNames('poll-votes-anim', {
		'poll-votes-anim--down': pinnedMessages?.length,
	});

	const renderPollVotesAnim = useCallback(
		(pollOptionAnim: PollOption) => (
			<PollVotesAnimItem key={pollOptionAnim.uuid} pollVote={pollOptionAnim} />
		),
		[pollVotesAnim]
	);

	if (!pollVotesAnim.length) {
		return null;
	}

	return <div className={pollVotesAnimClasses}>{pollVotesAnim.map(renderPollVotesAnim)}</div>;
};

export default observer(PollVotesAnim);
