import Button from 'components/hoc/Button';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import modalService from 'store/modalService';
import roomService from 'store/roomService';
import threadService from 'store/threadService';
import SocketIoServices from 'services/SocketIoServices';
import {Message} from 'models/room';
import actionMessageService from 'store/actionMessageService';

interface IButtonReplyInThreadProps {
	message: Message;
}

const ButtonReplyInThread: FunctionComponent<IButtonReplyInThreadProps> =
	function ButtonReplyInThread({message}) {
		const {chatScreen} = useL10n();
		const {appIcons, language} = useLocalObservable(() => appService);
		const {toggleVisibleThreadModal} = useLocalObservable(() => modalService);
		const {setSubmenuMessage} = useLocalObservable(() => roomService);
		const {setCurrentThreadId, setThreadMessageId, toggleRestApiDataLoaded, addMessage} =
			useLocalObservable(() => threadService);
		const {isVisibleActionMessage, toggleIsVisibleActionMessage} = useLocalObservable(
			() => actionMessageService
		);
		const {getAppIcon} = useAppData();

		const {IcoSubmenuThread} = appIcons;

		const onReplyInThreadHandler = async () => {
			if (isVisibleActionMessage) toggleIsVisibleActionMessage(false);
			setSubmenuMessage(null);
			setThreadMessageId(message.id);
			if (message.thread && message.thread.name) {
				SocketIoServices.emitJoin(message.thread.externalRoomId, language, message.thread.name);
				setCurrentThreadId(message.thread.externalRoomId);
				toggleVisibleThreadModal(true);
				return;
			}
			setCurrentThreadId('new');
			toggleRestApiDataLoaded({messagesLoaded: true, pinnedMessageLoaded: true});
			setTimeout(() => {
				addMessage({...message, id: 0});
			}, 100);

			toggleVisibleThreadModal(true);
		};

		return (
			<Button className='chat__submenu-btn' onClick={onReplyInThreadHandler}>
				{chatScreen.chatSubmenu.replyInThread}
				{getAppIcon(IcoSubmenuThread.pic, {className: 'chat__submenu-icon'})}
			</Button>
		);
	};
export default observer(ButtonReplyInThread);
