import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import threadService from 'store/threadService';

interface IButtonMessagesVisibleProps {
	message: Message;
}

const ButtonMessagesVisible: FunctionComponent<IButtonMessagesVisibleProps> =
	function ButtonMessagesVisible({message}) {
		const {isVisible: messageIsVisible} = message;
		const {appIcons} = useLocalObservable(() => appService);
		const {currentThreadId} = useLocalObservable(() => threadService);
		const {roomId, setSubmenuMessage} = useLocalObservable(() =>
			currentThreadId ? threadService : roomService
		);

		const {chatScreen} = useL10n();
		const {messageVisible} = useSubmenuActions();
		const {getAppIcon} = useAppData();

		const {IcoSubmenuHide, IcoSubmenuShow} = appIcons;

		const onMessagesVisibleHandler = () => {
			if (roomId) {
				messageVisible(message, roomId, messageIsVisible);
				setSubmenuMessage(null);
			}
		};

		return (
			<Button className='chat__submenu-btn' onClick={onMessagesVisibleHandler}>
				{messageIsVisible ? (
					<>
						{chatScreen.chatSubmenu.hideOnlyMessage}
						{getAppIcon(IcoSubmenuHide.pic, {className: 'chat__submenu-icon'})}
					</>
				) : (
					<>
						{chatScreen.chatSubmenu.showOnlyMessage}
						{getAppIcon(IcoSubmenuShow.pic, {className: 'chat__submenu-icon'})}
					</>
				)}
			</Button>
		);
	};

export default observer(ButtonMessagesVisible);
