import useL10n from 'l10n/useL10n';
import ChatBotWarnings from 'models/enums/ChatBotWarnings';
import MessageType from 'models/enums/MessageType.enum';
import {Message} from 'models/room';
import {useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';
import roomService from 'store/roomService';
import userService from 'store/userService';
import RoomService from 'services/api/RoomService';
import attachService from 'store/attachService';

export default () => {
	const {chatBot, modals} = useL10n();

	const {appEnableMessageTranslation} = useLocalObservable(() => appService);
	const {isMessageTranslateInProgress} = useLocalObservable(() => roomService);
	const {userData, translateMode, accessToken} = useLocalObservable(() => userService);
	const {files} = useLocalObservable(() => attachService);

	const visibleMessageElements = (
		index: number,
		message: Message,
		messages: Message[],
		visibleDateForFirstMessage: boolean
	) => {
		const {talker, createdAt} = message;

		let visibleAvatar = true;
		let visibleName = true;
		let visibleDate = false;

		const nextMessage = messages[index + 1];
		const prevMessage = messages[index - 1];

		if (
			nextMessage &&
			nextMessage.talker.id === talker.id &&
			nextMessage.createdAt.split('T')[0] === createdAt.split('T')[0]
		) {
			visibleAvatar = false;
		}

		if (prevMessage) {
			if (
				(prevMessage.type === MessageType.STICKER || prevMessage.type === MessageType.PIC) &&
				messages[index - 2]?.type === MessageType.USER &&
				messages[index - 2]?.talker.id === talker.id
			) {
				visibleName = false;
			}

			if (
				prevMessage.talker.id === talker.id &&
				prevMessage.createdAt.split('T')[0] === createdAt.split('T')[0] &&
				prevMessage.type !== MessageType.STICKER &&
				prevMessage.type !== MessageType.PIC
			) {
				visibleName = false;
			}

			visibleDate = prevMessage.createdAt.split('T')[0] !== createdAt.split('T')[0];
		} else {
			visibleDate = visibleDateForFirstMessage;
		}

		return {visibleAvatar, visibleName, visibleDate};
	};

	const checkingMessageFromChatBot = (messageText: string | null) => {
		switch (messageText) {
			case ChatBotWarnings.DISABLED:
				return chatBot.warnings.disabled;
			case ChatBotWarnings.OVERLOADED:
				return chatBot.warnings.overloaded;
			default:
				return messageText;
		}
	};

	const getText = (message: Message) => {
		if (
			(message.type === MessageType.BET || message.type === MessageType.GAMBLE) &&
			!message.isVisible &&
			message.user?.id !== userData?.id
		) {
			return null;
		}
		if (appEnableMessageTranslation) {
			if (translateMode.enable)
				return !isMessageTranslateInProgress && message.translate?.text
					? message.translate.text
					: message.text;
			return message.text;
		}
		return message.text;
	};

	const getMessageText = (messageData: Message) => {
		return messageData.type === MessageType.POLLRESULTS && messageData.pollText
			? JSON.parse(messageData.pollText).poll.text || ''
			: checkingMessageFromChatBot(getText(messageData)) ?? '';
	};

	const getBetTitle = (messageData: Message) => {
		const bet = JSON.parse(messageData.bet.data);
		return `${bet.betType}. ${bet.positions.length} ${modals.shareBet.bet} - ${modals.shareBet.totalFactor}: ${bet.totalFactor}`;
	};

	const getMentionBetTitle = (mentionMessageData: string) => {
		const data = JSON.parse(mentionMessageData);
		return `${data.bet.type}. ${data.bet.positionsLength} ${modals.shareBet.bet} - ${modals.shareBet.totalFactor}: ${data.bet.totalFactor}`;
	};

	const getMentionGambleTitle = (mentionMessageData: string) => {
		const data = JSON.parse(mentionMessageData);
		return data.title;
	};

	const sendPicMessage = async (data: any) => {
		const body: {[key: string]: string} = {
			...data,
			type: MessageType.PIC,
		};

		const formData = new FormData();

		Object.entries(body).forEach(([key, value]: [string, string]) => {
			formData.append(key, value);
		});

		Array.from(files)
			.filter(el => el.isChecked)
			.forEach((file, index) => {
				if (file.file) formData.append('pics', file.file);
			});

		await RoomService.sendPicMessage(accessToken, formData);
	};

	return {
		getMessageText,
		checkingMessageFromChatBot,
		getBetTitle,
		getMentionBetTitle,
		getMentionGambleTitle,
		visibleMessageElements,
		sendPicMessage,
	};
};
