/* eslint-disable jsx-a11y/label-has-associated-control */

import {MODAL_STYLE_BET_PREVIEW, MODAL_STYLE_GAMBLE_PREVIEW} from 'constants/constants';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import modalService from 'store/modalService';

import Button from 'components/hoc/Button';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import stickersEmojiService from 'store/stickersEmojiService';

import copybettingService from 'store/copybettingService';
import BetItem from 'components/bet/BetItem';
import gambleService from 'store/gambleService';
import GambleItem from 'components/gamble/GambleItem';

const BetPreviewModal: FunctionComponent = function BetPreviewModal() {
	const {appIcons, appEnableGamble} = useLocalObservable(() => appService);
	const {widgetPreviewModalVisible, toggleWidgetPreviewModalVisible, textareaHeight} =
		useLocalObservable(() => modalService);
	const {visibleStickersEmoji} = useLocalObservable(() => stickersEmojiService);
	const {currentBetForShare, setCurrentBetForShare} = useLocalObservable(() => copybettingService);
	const {currentGambleForShare, setCurrentGambleForShare} = useLocalObservable(() => gambleService);

	const {getAppIcon} = useAppData();
	const {IcoClose} = appIcons;

	const getOverlay = (textareaOffset: number) => {
		if (currentGambleForShare) {
			if (visibleStickersEmoji) {
				return textareaOffset > 34
					? {...MODAL_STYLE_GAMBLE_PREVIEW.overlay, bottom: `${280 + textareaOffset}px`}
					: {...MODAL_STYLE_GAMBLE_PREVIEW.overlay, bottom: `${315}px`};
			}
			return textareaOffset > 34
				? {
						...MODAL_STYLE_GAMBLE_PREVIEW.overlay,
						bottom: `${15 + textareaOffset}px`,
				  }
				: MODAL_STYLE_GAMBLE_PREVIEW.overlay;
		}

		if (visibleStickersEmoji) {
			return textareaOffset > 34
				? {...MODAL_STYLE_BET_PREVIEW.overlay, bottom: `${280 + textareaOffset}px`}
				: {...MODAL_STYLE_BET_PREVIEW.overlay, bottom: `${315}px`};
		}

		return textareaOffset > 34
			? {
					...MODAL_STYLE_BET_PREVIEW.overlay,
					bottom: `${15 + textareaOffset}px`,
			  }
			: MODAL_STYLE_BET_PREVIEW.overlay;
	};

	const closeClickHandler = () => {
		toggleWidgetPreviewModalVisible(false);
		setCurrentBetForShare(null);
		if (appEnableGamble) setCurrentGambleForShare(null);
	};

	useEffect(() => {
		getOverlay(textareaHeight);
	}, [textareaHeight]);

	return (
		<Modal
			className='modal modal--widget-preview'
			isOpen={widgetPreviewModalVisible}
			ariaHideApp={false}
			style={{
				overlay: getOverlay(textareaHeight),
			}}>
			<Button className='modal__close' onClick={closeClickHandler}>
				{getAppIcon(IcoClose.pic)}
			</Button>

			<div className='modal__body'>
				<div className='modal__axis-y'>
					{appEnableGamble && currentGambleForShare ? (
						<GambleItem gamble={currentGambleForShare} />
					) : (
						currentBetForShare && <BetItem bet={currentBetForShare} />
					)}
				</div>
			</div>
		</Modal>
	);
};

export default observer(BetPreviewModal);
