/**
 * @based https://github.com/rajanlagah/react-internet-meter
 */

import {IMAGE_FOR_SPEEDTEST_YANDEX, IMAGE_FOR_SPEEDTEST_AWS} from 'constants/constants';
import AppInstance from 'models/enums/AppInstance.enum';

const internetSpeedMeter = () => {
	const resolveAfterImageOnload = () => {
		return new Promise<{bs: string; kbs: string; mbs: string}>(resolve => {
			const imageUrl =
				process.env.REACT_APP_INSTANCE === AppInstance.SS
					? IMAGE_FOR_SPEEDTEST_YANDEX
					: IMAGE_FOR_SPEEDTEST_AWS;
			const downloadSize = 1161707; // size byte - IMAGE_FOR_SPEEDTEST

			let endTime;

			const download = new Image();
			const startTime = new Date().getTime();
			const cacheBuster = `?nnn=${startTime}`;

			download.src = `${imageUrl}${cacheBuster}`;

			download.onload = () => {
				endTime = new Date().getTime();

				const duration = (endTime - startTime) / 1000;

				const bitsLoaded = downloadSize * 8;
				const speedBs = (bitsLoaded / duration).toFixed(2);
				const speedKbs = (+speedBs / 1024).toFixed(2);
				const speedMbs = (+speedKbs / 1024).toFixed(2);

				resolve({bs: `${speedBs} Bs`, kbs: `${speedKbs} Kbs`, mbs: `${speedMbs} Mbs`});
			};

			download.onerror = () => {
				resolve({bs: `download error`, kbs: `download error`, mbs: `download error`});
			};
		});
	};

	const startCalculatingSpeed = async () => {
		const result = await resolveAfterImageOnload();
		return result;
	};

	return {startCalculatingSpeed};
};

export default internetSpeedMeter;
