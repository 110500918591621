import useAppData from 'hooks/useAppData';
import appService from 'store/appService';
import {FunctionComponent, CSSProperties} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

export enum ToastIconsName {
	warning = 'warning',
	basket = 'basket',
	brick = 'brick',
	brickRed = 'brickRed',
	copy = 'copy',
	userWarning = 'userWarning',
	userBlocked = 'userBlocked',
	userAddedSpeakers = 'userAddedSpeakers',
	userRemovedSpeakers = 'userRemovedSpeakers',
	close = 'close',
	plusGreen = 'plusGreen',
	handPlain = 'handPlain',
	handPlainAccent = 'handPlainAccent',
	mic = 'mic',
	micAccent = 'micAccent',
	pin = 'pin',
	unPin = 'unPin',
	poll = 'poll',
	pollEndAccent = 'pollEndAccent',
	noInet = 'noInet',
	messageShown = 'messageShown',
	messageHidden = 'messageHidden',
	streamConnecting = 'streamConnecting',
}

interface IToastIconsProps {
	name: ToastIconsName;
	size?: number;
	style?: CSSProperties;
}

const ToastIcons: FunctionComponent<IToastIconsProps> = function Toast({name, size = 24, style}) {
	const props = {size, style};
	const {appIcons} = useLocalObservable(() => appService);
	const {getAppIcon} = useAppData();
	const {
		IcoWarn,
		IcoToastBasket,
		IcoToastBrickRed,
		IcoToastBrick,
		IcoToastCopy,
		IcoToastClose,
		IcoToastUserWarning,
		IcoToastUserBlocked,
		IcoToastUserAddedSpeakers,
		IcoToastUserRemovedSpeakers,
		IcoSubmenuShow,
		IcoHandPlain,
		IcoHandPlainAccent,
		IcoMic,
		IcoMicAccent,
		IcoToastPin,
		IcoToastUnpin,
		IcoToastPoll,
		IcoPollEndAccent,
		IcoNoinet,
		IcoToastMessageShown,
		IcoToastMessageHidden,
		IcoToastConnection,
	} = appIcons;

	switch (name) {
		case ToastIconsName.warning:
			return getAppIcon(IcoWarn.pic, {...props});
		case ToastIconsName.basket:
			return getAppIcon(IcoToastBasket.pic, {...props});
		case ToastIconsName.brick:
			return getAppIcon(IcoToastBrick.pic, {...props});
		case ToastIconsName.brickRed:
			return getAppIcon(IcoToastBrickRed.pic, {...props});
		case ToastIconsName.close:
			return getAppIcon(IcoToastClose.pic, {...props});
		case ToastIconsName.copy:
			return getAppIcon(IcoToastCopy.pic, {...props});
		case ToastIconsName.userWarning:
			return getAppIcon(IcoToastUserWarning.pic, {...props});
		case ToastIconsName.userBlocked:
			return getAppIcon(IcoToastUserBlocked.pic, {...props});
		case ToastIconsName.userAddedSpeakers:
			return getAppIcon(IcoToastUserAddedSpeakers.pic, {...props});
		case ToastIconsName.userRemovedSpeakers:
			return getAppIcon(IcoToastUserRemovedSpeakers.pic, {...props});
		case ToastIconsName.plusGreen:
			return getAppIcon(IcoSubmenuShow.pic, {...props});
		case ToastIconsName.handPlain:
			return getAppIcon(IcoHandPlain.pic, {...props});
		case ToastIconsName.handPlainAccent:
			return getAppIcon(IcoHandPlainAccent.pic, {...props});
		case ToastIconsName.mic:
			return getAppIcon(IcoMic.pic, {...props});
		case ToastIconsName.micAccent:
			return getAppIcon(IcoMicAccent.pic, {...props});
		case ToastIconsName.pin:
			return getAppIcon(IcoToastPin.pic, {...props});
		case ToastIconsName.unPin:
			return getAppIcon(IcoToastUnpin.pic, {...props});
		case ToastIconsName.poll:
			return getAppIcon(IcoToastPoll.pic, {...props});
		case ToastIconsName.pollEndAccent:
			return getAppIcon(IcoPollEndAccent.pic, {...props});
		case ToastIconsName.noInet:
			return getAppIcon(IcoNoinet.pic, {...props});
		case ToastIconsName.messageShown:
			return getAppIcon(IcoToastMessageShown.pic, {...props});
		case ToastIconsName.messageHidden:
			return getAppIcon(IcoToastMessageHidden.pic, {...props});
		case ToastIconsName.streamConnecting:
			return getAppIcon(IcoToastConnection.pic, {...props});

		default:
			return null;
	}
};

export default observer(ToastIcons);
