import {Message} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import modalService from 'store/modalService';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import MessageType from 'models/enums/MessageType.enum';
import threadService from 'store/threadService';

interface IButtonReportProps {
	message: Message;
}

const ButtonReport: FunctionComponent<IButtonReportProps> = function ButtonReport({message}) {
	const {id: messageId, talker, user} = message;
	const {appIcons} = useLocalObservable(() => appService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {setSubmenuMessage} = useLocalObservable(() =>
		currentThreadId ? threadService : roomService
	);
	const {setReportModal} = useLocalObservable(() => modalService);

	const {chatScreen} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();

	const {IcoReportMessage, IcoReportUser} = appIcons;

	const onReportHandler = (target: 'message' | 'user') => {
		sendAnalytics('message_complaint_pressed');
		setReportModal({
			report: {
				talker,
				user,
				target,
				messageId,
				blockUserByUserAvailable: true,
			},
		});
		setSubmenuMessage(null);
	};

	return (
		<>
			{message.type !== MessageType.STICKER && (
				<Button className='chat__submenu-btn' onClick={() => onReportHandler('message')}>
					{chatScreen.chatSubmenu.reportMessage}
					{getAppIcon(IcoReportMessage.pic, {className: 'chat__submenu-icon'})}
				</Button>
			)}

			<Button className='chat__submenu-btn' onClick={() => onReportHandler('user')}>
				{chatScreen.chatSubmenu.reportUser}
				{getAppIcon(IcoReportUser.pic, {className: 'chat__submenu-icon'})}
			</Button>
		</>
	);
};

export default observer(ButtonReport);
