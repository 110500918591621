import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface IShareGambleSkeleton {
	visible: boolean;
}

const ShareGambleSkeleton: FunctionComponent<IShareGambleSkeleton> = function ShareGambleSkeleton({
	visible,
}) {
	return (
		<CSSTransition in={visible} timeout={300} classNames='fade' unmountOnExit>
			<div className='share-gamble__skeleton'>
				<div className='share-gamble__skeleton'>
					<div className='share-gamble__skeleton-gambles'>
						{[0, 1, 2, 3, 4, 5].map((i: number) => (
							<div className='share-gamble__skeleton-gamble' key={i}>
								<LoadingSkeleton enableAnimation={false} />
							</div>
						))}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(ShareGambleSkeleton);
