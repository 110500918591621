import appService from 'store/appService';
import linkPreviewServices from 'store/linkPreviewService';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import Button from 'components/hoc/Button';

import './linkPreviewWriting.scss';

const LinkPreviewWriting: FunctionComponent = function LinkPreviewWriting() {
	const {appIcons} = useLocalObservable(() => appService);
	const {isVisibleLinkPreviewWriting, linkPreviewData, toggleIsVisibleLinkPreviewData} =
		useLocalObservable(() => linkPreviewServices);

	const {getAppIcon} = useAppData();

	const {IcoLink, IcoClose} = appIcons;

	if (!linkPreviewData || !linkPreviewData.title) {
		return null;
	}

	const {title, text} = linkPreviewData;

	const onCloseHandler = () => {
		toggleIsVisibleLinkPreviewData(false, true);
	};

	return (
		<CSSTransition in={isVisibleLinkPreviewWriting} timeout={500} classNames='fade' unmountOnExit>
			<div className='link-preview-writing'>
				<div className='link-preview-writing__icon'>{getAppIcon(IcoLink.pic)}</div>
				<div className='link-preview-writing__content'>
					<div className='link-preview-writing__title'>{title}</div>
					{text && <div className='link-preview-writing__text'>{text}</div>}
				</div>
				<Button className='link-preview-writing__close' onClick={onCloseHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
		</CSSTransition>
	);
};

export default observer(LinkPreviewWriting);
