import {FunctionComponent, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './chatStyle/chatSkeleton.scss';
import {observer, useLocalObservable} from 'mobx-react-lite';

interface IChatSkeleton {
	visible: boolean;
}

const ChatSkeleton: FunctionComponent<IChatSkeleton> = function Preloader({visible = false}) {
	return (
		<CSSTransition in={visible} timeout={300} classNames='fade' unmountOnExit>
			<div className='chat__skeleton'>
				<div className='chat__skeleton-messages'>
					{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
						<div className='chat__skeleton-message' key={i}>
							<div className='chat__skeleton-message-avatar'>
								<LoadingSkeleton circle enableAnimation={false} />
							</div>
							<div className='chat__skeleton-message-body'>
								<LoadingSkeleton enableAnimation={false} />
							</div>
						</div>
					))}
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(ChatSkeleton);
