import {MODAL_STYLE} from 'constants/constants';
import appService from 'store/appService';
import modalServices from 'store/modalService';
import useAppData from 'hooks/useAppData';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import useL10n from 'l10n/useL10n';
import ChatNameForm from 'components/chatNameForm/ChatNameForm';
import './changeNameModal.scss';

const ChangeNameModal: React.FC = function BlockedModal() {
	const {appIcons} = useLocalObservable(() => appService);
	const {changeNameModal} = useLocalObservable(() => modalServices);
	const translations = useL10n();
	const {getAppIcon} = useAppData();
	const {IcoWarn} = appIcons;

	if (!changeNameModal) {
		return null;
	}

	return (
		<Modal
			className='namechanging'
			isOpen={changeNameModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='namechanging__wrapper'>
				<div className='namechanging__header'>
					{getAppIcon(IcoWarn.pic)}
					<div className='namechanging__header-container'>
						<p className='namechanging__title'>{translations.modals.changeName.title}</p>
						<p className='namechanging__subtitle'>{translations.modals.changeName.subtitle}</p>
					</div>
				</div>
			</div>
			<div className='namechanging__body'>
				<ChatNameForm fromChangeNameModal />
			</div>
		</Modal>
	);
};

export default observer(ChangeNameModal);
