import axios from 'axios';
import {apiPath} from 'constants/api';
import {AppTheme} from 'models/app';
import {GreetingMessage} from 'models/room';
import {ChatBot, Settings, SettingsRules} from 'models/settings';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';

export default class SettingsService {
	static getSettings = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<Settings>(apiPath.settings(apiKey));

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSettingsThemes = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<AppTheme[]>(apiPath.settingsThemes(apiKey));

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings themes error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSettingsTheme = async (apiKey: string, themeId: number) => {
		try {
			const {data, status} = await axios.get<Settings>(apiPath.settingsTheme(apiKey, themeId));

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings theme error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRules = async (token: UUID, lang: string) => {
		try {
			const {data, status} = await axios.get<SettingsRules>(`${apiPath.getRules(lang)}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get rules error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getGreetingMessage = async (token: UUID, lang: string) => {
		try {
			const {data, status} = await axios.get<GreetingMessage>(apiPath.getGreetingMessage(lang), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get greeting message error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChatBot = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<ChatBot>(apiPath.getChatBot, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get ChatBot error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
