import {action, makeObservable, observable} from 'mobx';

enum RewindDirection {
	FORWARD = 'FORWARD',
	BACK = 'BACK',
}

class PlayerService {
	@observable
	public playPressed = false;

	@observable
	public pausePressed = false;

	@observable
	public rewind = {direction: RewindDirection.FORWARD, seconds: 0};

	@action
	public setPlayPressed = (value: boolean) => {
		this.playPressed = value;
	};

	@action
	public setPausePressed = (value: boolean) => {
		this.pausePressed = value;
	};

	@action
	public setRewind = (value: {direction: RewindDirection; seconds: number}) => {
		this.rewind = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new PlayerService();
