import {action, makeObservable, observable} from 'mobx';

class HintService {
	@observable
	public hintsViewed: string[] = [];

	@action
	public addHintsViewed = (typeHint: string) => {
		if (!this.hintsViewed.includes(typeHint)) {
			this.hintsViewed.push(typeHint);
		}
	};

	@action
	public clearHintsViewed = () => {
		this.hintsViewed = [];
	};

	constructor() {
		makeObservable(this);
	}
}

export default new HintService();
