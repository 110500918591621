import {action, makeObservable, observable} from 'mobx';
import {Badge} from 'models/badge';

class BadgeService {
	@observable
	public badgePopupArray: Badge[] | null = null;

	@action
	public setBadgePopupArray = (value: Badge[]) => {
		this.badgePopupArray = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new BadgeService();
