/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Acordo',
		subTitle: 'Acordo de licença e política de confidencialidade',
		textFirst: 'Aceito os termos',
		textSecond:
			'e condições e dou meu consentimento para o processamento de dados pessoais nos termos e condições definidos pelo',
		license: 'Contrato de Licença',
		policy: 'e Política de Privacidade.',
	},
	controlPanel: {
		streamEnded: 'Transmissão concluída',
		banAlert: 'Você não pode escrever neste chat',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Adesivos' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Perfil excluído',
		text: (date: string) => `Você pode restaurar seu perfil até ${date}`,
	},
	nameScreen: {
		title: 'Nome de usuário para o chat',
		inputPlaceholder: 'Digite um nome',
		nameError: 'Nome inválido',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Selecione outro:',
	},
	settingsScreen: {
		title: 'Configurações',
		personal: 'Configurações pessoais',
		room: 'Configurações da sala',
		profileBtn: 'Perfil',
		languageBtn: 'Idioma',
		blockedBtn: 'Bloqueados',
		rules: 'Regras do chat',
		deleteProfile: 'Excluir perfil',
		slowModeBtn: 'Modo lento',
		agora: {
			microphone: 'Microfone',
			playbackDevice: 'Fonte de áudio',
		},
	},
	languageScreen: {
		title: 'Idioma e interface',
		messagesTranslate: 'Tradução de mensagens',
		translateMessagesMode: 'Traduzir mensagens no chat',
		translateMessagesTo: 'Traduzir mensagens para:',
	},
	rulesScreen: {
		title: 'Regras do chat',
	},
	profileScreen: {
		title: 'Perfil',
		selectAnotherPic: 'Escolher outro avatar',
		chatNameLabel: 'Nome de usuário para o chat',
		changeName: 'editar',
	},
	blockedScreen: {
		title: 'Bloqueados',
		emptyListText: 'Não há usuários bloqueados',
	},
	chatScreen: {
		inputPlaceholder: 'Mensagem...',
		cantWrite: 'Você não pode escrever neste chat',
		chatSubmenu: {
			hideMessage: 'Ocultar mensagens',
			showMessage: 'Exibir mensagens',
			removeFromSpeakers: 'Remover dos oradores',
			setSpeaker: 'Atribuir como orador',
			hideOnlyMessage: 'Ocultar mensagem',
			showOnlyMessage: 'Exibir mensagem',
			reportMessage: 'Denunciar mensagem',
			reportUser: 'Denunciar usuário',
			muteSpeaker: 'Desativar o microfone',
			blockUser: 'Bloquear',
			unblockUser: 'Desbloquear',
			reply: 'Responder',
			copy: 'Copiar',
			edit: 'Editar',
			delete: 'Excluir',
			pinMessage: 'Fixar',
			unpinMessage: 'Desafixar',
			replyInThread: 'Responder no tópico',
		},
		actionMessage: {
			editing: 'Editar',
			reply: 'Responder',
		},
		status: {
			top: 'Top',
		},
		translating: 'Traduzindo...',
	},
	chatUsersScreen: {
		title: 'Participantes',
		edited: 'Alterado',
		hidden: 'oculto',
		unreadMessages: 'Mensagens não lidas',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Silenciar um participante',
				subtitle: 'Desativar o microfone',
			},
			setSpeaker: {
				title: 'Remover dos oradores',
				subtitle: 'Chat somente de texto',
			},
			removeFromSpeaker: {
				title: 'Atribuir como orador',
				subtitle: 'O usuário poderá falar por voz',
			},
			showMessages: {
				title: 'Exibir mensagens',
				subtitle: 'Visível apenas para o administrador',
			},
			hideMessages: {
				title: 'Ocultar mensagens',
				subtitle: 'Visível apenas para o administrador',
			},
			blockUser: {
				title: 'Bloquear',
				subtitle: 'Adicionar à lista de bloqueio',
			},
			unblockUser: {
				title: 'Desbloquear',
				subtitle: 'Desbloquear usuário',
			},
			blockUserByUser: {
				title: 'Bloquear',
				subtitle: 'Ocultar todas as mensagens do usuário',
			},
			unblockUserByUser: {
				title: 'Desbloquear',
				subtitle: 'Exibir todas as mensagens do usuário',
			},
			reportUser: {
				title: 'Denunciar usuário',
				subtitle: 'A denúncia será enviada anonimamente',
			},
		},
		days: {
			today: 'Hoje',
			yesterday: 'Ontem',
		},
		chatCreated: 'Chat criado,',
	},
	alerts: {
		btns: {
			unblock: 'Desbloquear',
			block: 'Bloquear',
			send: 'Enviar',
			sendAndBlock: 'Enviar e bloquear',
			show: 'Exibir',
			showAll: 'Exibir tudo',
			cancel: 'Cancelar',
			yes: 'Sim',
			hide: 'Ocultar selecionados',
			hideAll: 'Ocultar tudo',
			make: 'Fazer',
			remove: 'Remover',
			delete: 'Excluir',
			deleteAccount: 'Excluir perfil',
			close: 'Fechar',
			pin: 'Fixar',
			unpin: 'Desafixar',
			edit: 'Editar',
			end: 'Finalizar',
			endAndShare: 'Finalizar e compartilhar',
			reset: 'Redefinir',
			rules: 'Regras do chat',
			contactus: 'Escreva para nós',
		},
		pinMessage: {
			title: 'Fixar mensagem?',
		},
		unPinMessage: {
			title: 'Desafixar mensagem?',
		},
		accountRestored: {
			title: 'Perfil restaurado',
		},
		closeApp: {
			title: 'Deseja realmente sair?',
		},
		removeMessage: {
			title: 'Excluir a mensagem?',
		},
		report: {
			title: 'Enviar denúncia?',
			subtitle:
				'Você também pode bloquear o usuário, para que não vizualizem as mensagens um do outro.',
		},
		banUser: {
			title: (name: string) => `Bloquear ${name}?`,
			subtitle: (text: string) => `O usuário será bloqueado por $ text ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Desbloquear ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Exibir mensagens de ${name}?`,
			subTitle: 'Outros usuários poderão vizualizar as mensagens deste usuário.',
		},
		hideUserMessages: {
			title: (name: string) => `Ocultar mensagens de ${name}?`,
			subTitle: 'Outros usuários não poderão mais vizualizar as mensagens deste usuário.',
		},
		showUserMessage: {
			title: (name: string) => `Exibir mensagens de ${name}?`,
			subTitle: 'Outros usuários não poderão mais vizualizar as mensagens deste usuário.',
		},
		hideUserMessage: {
			title: (name: string) => `Ocultar mensagens de ${name}?`,
			subTitle: 'Outros usuários poderão vizualizar essa mensagem.',
		},
		blockUserInRoom: {
			title: (name: string) => `Bloquear ${name}?`,
			subTitle: 'O usuário não poderá participar desta sala.',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Desbloquear ${name}?`,
			subTitle: 'O usuário poderá participar desta sala.',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Bloquear ${name}?`,
			subTitle: 'Vocês não poderão vizualizar as mensagens um do outro.',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Atribuir ${name} como orador?`,
			unTitle: (name: string) => `Remover ${name} dos oradores?`,
		},
		banInApp: {
			title: 'O administrador o bloqueou',
			for: 'por',
			subtitle: (bannedUntil: string) => `Você está bloqueado até ${bannedUntil}`,
			permanent: 'Você está bloqueado permanentemente.',
		},
		banInAppBadNickname: {
			title: 'O administrador o bloqueou devido a nome inválido',
			subtitle: 'Vá até seu perfil, altere seu nome e entre novamente na sala de chat.',
		},
		deleteAccount: {
			title: 'Exclusão de perfil',
			subTitle: 'Todos os dados serão excluídos. Você poderá restaurar seu perfil em 30 dias.',
		},
		pollEdit: {
			title: 'Editar uma enquete?',
			subTitle: 'As respostas dos usuários serão redefinidas',
		},
		pollReset: {
			title: 'Redefinir?',
			subTitle: 'A enquete não será salva',
		},
		pollEnd: {
			title: 'Deseja realmente finalizar a enquete?',
		},
		pollDelete: {
			title: 'Excluir a enquete?',
		},
		agoraCheck: {
			updateIOSText: 'Atualize a versão do iOS para usar todas as ferramentas do aplicativo.',
			allowAccess: {
				title: 'Quer dizer alguma coisa?',
				text: 'Por favor, abra as configurações do aplicativo e permita o acesso ao microfone e ao alto-falante.',
			},
			microphoneAndSpeakerText:
				'O uso do microfone e do alto-falante está disponível desde a versão 14.3.',
			microphoneText: 'O uso do microfone e do alto-falante está disponível desde a versão 14.3.',
			btn: 'OK!',
		},
	},
	eventScreen: {
		title: 'Evento programado',
		titleSoon: 'A transmissão está prestes a começar',
		subtitle: 'O chat logo será aberto, aguarde.',
		days: 'dias',
		hours: 'horas',
		minutes: 'minutos',
		seconds: 'segundos',
		startTitle: 'Início',
	},
	toasts: {
		reciveComplaint: 'Recebemos sua denúncia',
		setSpeakerRoleForUser: 'O usuário foi atribuído como orador',
		unSpeakerRoleForUser: 'Usuário removido dos oradores',
		messagesShown: 'Mensagens exibidas',
		messagesHidden: 'Mensagens ocultas',
		messageShown: 'Mensagem exibida',
		messageHidden: 'Mensagem oculta',
		userBlocked: 'Usuário bloqueado',
		userUnBlocked: 'Usuário desbloqueado',
		youAreSpeaker: 'Você é orador! Diga algo',
		youAreNotSpeaker: 'O administrador o removeu dos oradores',
		userIsSpeaker: (name: string) => `${name} agora é orador`,
		userNotSpeaker: (name: string) => `${name} não é mais orador`,
		yourUserNameInChat: (name: string) => `Seu nome no chat é ${name}`,
		messageRemoved: 'Mensagem excluída',
		messageCopied: 'Texto copiado',
		obscenities: 'Tente reformular',
		requestSent: 'Solicitação enviada',
		requestDecline: 'Solicitação removida pelo administrador',
		youareblocked: (text: string) =>
			`Você foi bloqueado por ${text}. Você não pode escrever no chat.`,
		youareunblocked: 'Você foi desbloqueado. Você pode postar mensagens no chat.',
		youAreModerator: 'Você é moderador!',
		youAreNoModerator: 'Você não é mais moderador',
		micMuted: 'Seu microfone está mudo',
		noInet: 'Não há conexão com a Internet',
		messagePinned: 'Mensagem fixada.',
		messageUnpinned: 'Mensagem desafixada',
		pollPublished: 'Enquete publicada',
		pollPublishedError: 'Erro! Tente novamente mais tarde',
		pollAddPic: 'Adicionar foto',
		pollAddPicError: 'Selecionar outra imagem',
		pollDeleted: 'Enquete excluída',
		pollEnded: 'Enquete concluída',
		sendStickerWhenSlowModeIsOn: (time: string) => `Aguarde ${time} para enviar o sticker`,
		linkProhibited: 'Você não pode enviar links no chat.',
		phoneProhibited: 'Você não pode enviar números de telefone no chat.',
		profileHidden: 'O usuário ocultou o seu perfil',
		imageSizeWarning: 'Você pode enviar uma imagem de até 10 MB',
		imageBlocked: 'O moderador bloqueou o envio da imagem',
		streamKeyCopied: 'Chave de transmissão copiada',
	},
	btns: {
		acceptandgo: 'Aceitar e continuar',
		saveandgo: 'Salvar e continuar',
		save: 'Salvar',
		unblock: 'Desbloquear',
		block: 'Bloquear',
		restoreAccount: 'Restaurar perfil',
		close: 'Fechar',
	},
	coopyrights: 'Funciona por Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Denunciar mensagem' : 'Denunciar infração',
			subtitle: 'As denúncias são enviadas anonimamente',
			btns: {
				violence: 'Insultos, ameaças, palavrões',
				provocations: 'Provocações',
				personalDetails: 'Dados pessoais',
				flood: 'Lixo eletrônico',
				spam: 'Spam',
				fraudBegging: 'Fraude, pedir dinheiro',
				other: 'Outros',
			},
		},
		ban: {
			title: 'Bloquear usuário',
			subtitle: 'Especifique o motivo do bloqueio',
			btns: {
				insults: 'Insultos, ameaças, palavrões',
				personal: 'Divulgação de dados pessoais',
				flood: 'Dados pessoais',
				links: 'Links para serviços de terceiros',
				violence: 'Ameaças de violência',
				fraud: 'Tentativa de fraude e pedir dinheiro',
				nickname: 'Apelido não autorizado',
				politicial: 'Provocações políticas, sexualizadas e outras',
				repeated: 'Infração repetida',
				auto: 'Denúncias de usuários do chat',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Você não tem oradores em espera',
			handsUp: 'Mão levantada',
			waiting: 'Oradores em espera',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Dê a resposta correta',
				typeQuizMultiple: 'Assinale as opções corretas',
				typePoll: 'Compartilhe sua opinião',
				typePollMultiple: 'Pode-se marcar mais de uma opção',
				default: 'Enquete pública',
			},
			btns: {
				reply: 'Responder',
				vote: 'Votar',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Quiz',
				typePoll: 'Várias respostas ',
				default: 'Enquete pública',
			},
			btns: {
				edit: 'Editar enquete',
				end: 'Finalizar',
			},
		},
		pollCreated: {
			titles: {
				created: 'Criar enquete',
				typeQuiz: 'Quiz',
				typePoll: 'НVárias respostas ',
				default: 'Enquete pública',
			},
		},
		changeName: {
			title: 'Alterar nome no chat',
			subtitle: 'Você foi bloqueado por apelido inválido.',
		},
		temporaryAgreement: {
			title: 'Regras do chat',
			rules: {
				bePolite: {
					title: 'Seja gentil',
					text: 'Linguagem hostil e obscena não é permitida no chat.',
				},
				beAttentive: {
					title: 'Atenção',
					text: 'Não compartilhe informações pessoais nem solicite-as a outras pessoas.',
				},
				notAdvertise: {
					title: 'Não faça publicidade',
					text: 'ЛÉ melhor compartilhar opiniões ou emoções.',
				},
			},
			btns: {
				allRules: 'Todas as regras',
				acceptAndContinue: 'Aceitar e continuar',
			},
		},
		slowMode: {
			title: 'Modo lento',
			text: 'Os participantes não poderão enviar mensagens para o chat com frequência maior do que o intervalo de tempo selecionado.',
			enable: 'Ativar o modo lento',
			disable: 'Desativar o modo lento',
			sendTimeout: 'Tempo-limite para envio',
		},
		slowModeTimeout: {
			title: 'Tempo-limite para envio',
		},
		chatBot: {
			title: 'Utilização do chatbot',
			rules: [
				{
					title: 'Inteligência artificial.',
					text: 'O bot imita a comunicação humana e fornece informações em virtude de seu acúmulo de dados. No entanto, lembre-se de que suas respostas podem ser limitadas e não substituem um ser humano.',
				},
				{
					title: 'O bot pode cometer erros',
					text: 'O bot não pode garantir a precisão e a validade das informações.',
				},
			],
			btns: {
				close: 'Fechar',
			},
		},
		shareBet: {
			title: 'Compartilhar',
			noBetsTitle: 'Não foi possível encontrar suas apostas',
			noBetsText: 'Você pode compartilhar apostas feitas em até 14 dias',
			btns: {
				share: 'Compartilhar aposta',
				copy: 'Repetir aposta',
			},
			status: {
				null: 'Em jogo',
				win: 'Vitória',
				lose: 'Perda',
			},
			betTypeV1: {
				express: 'Múltiplo',
				single: 'Simples',
			},
			betTypeV2: {
				single: 'Simples',
				multi: 'Múltiplo',
				system: 'System',
			},
			totalFactor: 'Coef',
			bet: 'пари',
			showMore: 'Exibir mais',
		},
		shareGamble: {
			noGamblesText:
				'Você ainda não tem ganhos para compartilhar. Continue jogando, e sua próxima vitória aparecerá aqui para que você possa compartilhar com outros jogadores!',
		},
		record: {
			text: 'A gravação da transmissão está em processamento...',
		},
		translate: {
			title: 'Tradução de mensagens',
			chooseLanguage: 'Escolher idioma',
			descr:
				'As mensagens no chat serão traduzidas automaticamente para o idioma selecionado por você',
			btn: 'Traduzir',
		},
		streamSettings: {
			title: 'Configurações de transmissão',
			description:
				'Baixe e instale um codificador de vídeo. Insira a chave de transmissão nas configurações',
			shareScreen: {
				title: 'Compartilhar tela',
				description: 'Nenhuma configuração especial necessária',
			},
			rtmp: {
				title: 'Fonte externa de transmissão',
				description: 'Use um programa adicional para transmitir o fluxo',
			},
			streamKey: 'Chave de transmissão',
			showStreamKey: 'Mostrar',
			hideStreamKey: 'Ocultar',
			btns: {
				continue: 'Continuar',
				close: 'Fechar',
				ok: 'Pronto',
			},
		},
	},
	pinnedMessage: {
		title: 'Mensagem fixada',
	},
	errorPage: {
		error: 'Erro',
		types: [
			// errorNumber 0
			{
				title: 'Não estamos reconhecendo você',
				text: 'Tente entrar novamente no chat.',
			},
			// errorNumber 1
			{
				title: 'Não encontramos a sala de chat',
				text: 'Tente entrar novamente.',
			},
			// errorNumber 2
			{
				title: 'Não é possível carregar o chat',
				text: 'Tente recarregar.',
			},
			// errorNumber 3
			{
				title: 'Não estamos reconhecendo você',
				text: 'O modo anônimo para o chat não está disponível.',
			},
			// errorNumber 4
			{
				title: 'Apelido inaceitável',
				text: 'Vá para seu perfil, altere seu apelido e entre novamente na sala de chat.',
			},
		],
	},
	tooltips: {
		emotions: 'Segure para alterar',
		badInet: 'Conexão de rede ruim',
		blockedUsers: 'Pode-se ver a lista de usuários bloqueados aqui.',
		waitingSpeakers: 'Um dos participantes deseja falar.',
		wantSpeak: 'Clique aqui para solicitar direito à fala.',
		slowMode: {
			header: (time: string) =>
				`Modo lento ativado. Os usuários <br>podem enviar uma mensagem a <br>cada ${time}. Esta regra não se aplica <br>aos moderadores.`,
			btnSendMessage: (time: string) => `Você pode enviar mensagens <br>todos`,
		},
		shareBet: 'Compartilhar aposta no chat',
		translate: 'Traduzir mensagens para outro idioma',
	},
	poll: {
		toggleSubmenu: {
			create: 'Criar enquete',
			edit: 'Editar enquete',
			show: 'Exibir enquete',
			end: 'Finalizar enquete',
		},
		tooltip: {
			btn: 'Votar',
		},
		settings: {
			title: 'Configurações',
			titleAdditionally: 'Mais',
			switches: {
				quizMode: 'Modo quiz',
				multipleChoice: 'Escolher mais de uma resposta',
				withPhoto: 'Enquete com foto',
			},
		},
		question: {
			title: 'Pergunta',
			inputPlaceholder: 'Digite uma pergunta',
		},
		message: {
			title: 'Você votou',
		},
		answers: {
			title: 'Opções de resposta',
			warning: 'Você pode adicionar até 5 respostas',
		},
		answer: {
			tooltip: 'Selecione a resposta correta',
			inputPlaceholder: 'Resposta',
		},
		results: {
			text: 'Resultados da votação',
			votes: 'Votos',
			// words: ['voto', 'votos', 'votos'],
		},
		btns: {
			addQuestion: 'Adicionar respostas',
			addAnswers: 'Adicionar respostas',
			addAnswer: 'Adicionar resposta',
			publish: 'Publicar',
			vote: 'Vote',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Avaliar',
				continue: 'Continuar',
				checkConnection: 'Verificar conexão',
				close: 'Fechar',
			},
			quality: {
				title: 'Avalie a qualidade do som',
			},
			reason: {
				title: 'O que deu errado?',
			},
			connection: {
				title: 'Teste a qualidade da conexão',
				text: 'O teste não levará mais de um minuto e o ajudará a encontrar a causa do problema.',
			},
			statistics: {
				title: 'Coletando dados...',
			},
			done: {
				title: 'Obrigado!',
				text: 'Analisaremos o que saiu errado e iremos corrigir.',
			},
		},
	},
	chatBot: {
		message: 'Pergunte algo ao bot...',
		warnings: {
			disabled: 'Fui desconectado',
			overloaded: 'Estamos sobrecarregados',
		},
	},
	audioReason: {
		nothear: 'Nada foi ouvido',
		connection: 'O som interrompeu-se',
		microphone: 'Não foi possível utilizar o microfone',
	},
	screenShare: {
		title: 'Compartilhamento de tela',
		stopSharing: 'Parar compartilhamento de tela',
		modal: {
			title: 'O compartilhamento de tela está disponível apenas em um computador.',
			text: 'Abra o chat no seu computador e clique no botão ‘Compartilhamento de tela’ no canto superior direito.',
			btn: 'Copie o link do convite.',
		},
	},
	threads: {
		title: 'Tópico',
		back: 'Voltar',
		replies: ['Resposta', 'Respostas', 'Respostas'],
	},
	attach: {
		submenu: {
			sendImage: 'Enviar imagem',
			shareBet: 'Compartilhar aposta',
			shareGamble: 'Compartilhar ganhos',
		},
		images: ['imagem', 'imagens', 'imagens'],
		addCaption: 'Adicionar legenda',
	},
} as unknown as Dictionary;
