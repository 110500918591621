import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import OauthService from 'services/api/OauthService';
import appService from 'store/appService';
import userService from 'store/userService';
import useAnalytics from './useAnalytics';

const useUserProfile = () => {
	const {appEnableDecrypt} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userService);
	const {sendAnalytics} = useAnalytics();

	const getDecryptedUserId = async (userId: number) => {
		const decryptResponse = await OauthService.getDecryptedUserId(userId);
		if (decryptResponse.status === ResponseStatus.SUCCESS) {
			return decryptResponse.data;
		}
		return false;
	};

	const getDoubleEncryptedUserId = async (userId: number) => {
		const response = await OauthService.getDoubleEncryptedUserId(userId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const getProfileUrl = async (userId: number) => {
		const response = await OauthService.getProfileUrl(userId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const sendAnalyticsEvent = async (data: {userId: number; source: string}) => {
		if (appEnableDecrypt && data.userId) {
			const res = await getDecryptedUserId(data.userId);
			if (res) {
				const {result, decrypted} = res;
				sendAnalytics('profile_opened', {
					profile_user_id: decrypted,
					source: data.source,
				});
			}
			return;
		}

		data.userId &&
			sendAnalytics('profile_opened', {
				profile_user_id: data.userId,
				source: data.source,
			});
	};

	const profileUrlPostMessage = async (userId: number) => {
		const response = await getDoubleEncryptedUserId(userId);

		if (response) {
			const msg = {
				type: 'social',
				body: {
					action: 'open_profile',
					data: {
						user_id: response.encryptedExternalUserId || response.externalUserId || response.userId,
					},
				},
			};

			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify(msg));
				return;
			}
			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
				return;
			}

			window.parent.postMessage(msg, '*');
		}
	};

	return {sendAnalyticsEvent, getProfileUrl, profileUrlPostMessage};
};

export default useUserProfile;
