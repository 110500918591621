import appService from 'store/appService';
import {useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './language.scss';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoCheckmark} from 'assets/svg/ico-checkmark.svg';
import useAnalytics from 'hooks/useAnalytics';
import modalService from 'store/modalService';
import userService from 'store/userService';
import useUser from 'hooks/useUser';
import useTranslation from 'hooks/useTranslation';

const Language: React.FC = function Language() {
	const {translateLangs} = useLocalObservable(() => appService);
	const {translateMode, setTranslateMode, userData} = useLocalObservable(() => userService);
	const {settingsModalVisible} = useLocalObservable(() => modalService);
	const {sendAnalytics} = useAnalytics();
	const {userExtraDataPatchTranslateMode} = useUser();
	const {translateMessages} = useTranslation();

	const onLanguageHandler = (language: {languageCode: string; displayName: string}) => {
		setTranslateMode({...translateMode, lang: language});
		if (userData && settingsModalVisible) {
			userExtraDataPatchTranslateMode(userData, {...translateMode, lang: language});
			translateMessages(language.languageCode);
		}

		sendAnalytics('autotranslation_language', {
			language_selected: language.languageCode,
			source: settingsModalVisible ? 'settings' : 'modal',
		});
	};

	const renderLanguageItem = useCallback(
		(item: {languageCode: string; displayName: string}) => {
			return (
				<div className='language__item' key={item.languageCode}>
					<Button className='language__button' onClick={() => onLanguageHandler(item)}>
						{item.displayName}
						{item.languageCode === translateMode.lang.languageCode && (
							<span className='language__button-icon'>
								<IcoCheckmark />
							</span>
						)}
					</Button>
				</div>
			);
		},
		[translateMode.lang]
	);

	return (
		<div className='language'>
			<div className='language__items'>{translateLangs.map(renderLanguageItem)}</div>
		</div>
	);
};

export default observer(Language);
