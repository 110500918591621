import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import './screenShareWarnModal.scss';
import Modal from 'react-modal';
import modalService from 'store/modalService';
import {MODAL_STYLE_CENTER} from 'constants/constants';
import Button from 'components/hoc/Button';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import useCopy from 'hooks/useCopy';
import useL10n from 'l10n/useL10n';

const ScreenShareWarnModal: FunctionComponent = function ScreenShareWarnModal() {
	const {appIcons} = useLocalObservable(() => appService);
	const {IcoCopy} = appIcons;
	const {getAppIcon} = useAppData();
	const {btns, screenShare} = useL10n();
	const {screenShareWarnModalVisible, setScreenShareWarnModalVisible} = useLocalObservable(
		() => modalService
	);

	const getLink = () => {
		return window.location.href;
	};
	const {copied, copy} = useCopy(getLink());

	const onCloseClickHandler = () => {
		setScreenShareWarnModalVisible(false);
	};

	const onCopyHandler = async () => {
		await copy();
		setScreenShareWarnModalVisible(false);
	};

	return (
		<Modal
			className='modal modal--screenShareWarn'
			isOpen={screenShareWarnModalVisible}
			// onAfterOpen={onAfterOpenHandler}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE_CENTER.overlay,
			}}>
			<section className='screenShareWarn-modal'>
				<div className='screenShareWarn-modal__head'>
					<div className='screenShareWarn-modal__title'>{screenShare.modal.title}</div>
					<div className='screenShareWarn-modal__text'>{screenShare.modal.text}</div>
				</div>
				<div className='screenShareWarn-modal__body'>
					<button
						type='button'
						className='screenShareWarn-modal__copy-link-btn'
						onClick={onCopyHandler}>
						{getAppIcon(IcoCopy.pic, {className: 'chat__submenu-icon'})}
						{screenShare.modal.btn}
					</button>
				</div>
				<div className='screenShareWarn-modal__footer'>
					<Button className='btn' onClick={onCloseClickHandler}>
						{btns.close}
					</Button>
				</div>
			</section>
		</Modal>
	);
};

export default observer(ScreenShareWarnModal);
