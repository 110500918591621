import {FunctionComponent, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './preloader.scss';
import classNames from 'classnames';
import useSearchParams from 'hooks/useSearchParams';
import {useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';

interface IPreloaderProps {
	visible: boolean;
}

const Preloader: FunctionComponent<IPreloaderProps> = function Preloader({visible = false}) {
	const {appTheme} = useLocalObservable(() => appService);
	const {colorSchemeModeFromUrl} = useSearchParams();
	const [themeLoaded, setThemeLoaded] = useState(false);
	const preloaderClasses = classNames('preloader', {
		'preloader--light': colorSchemeModeFromUrl === 'light',
		'preloader--theme': themeLoaded,
	});

	useEffect(() => {
		if (appTheme) setThemeLoaded(true);
	}, [appTheme]);

	return (
		<CSSTransition in={visible} timeout={300} classNames='fade' unmountOnExit>
			<div className={preloaderClasses}>
				<div className='preloader__skeleton'>
					<div className='preloader__skeleton-header'>
						<div className='preloader__skeleton-exit'>
							<LoadingSkeleton circle enableAnimation={false} />
						</div>
						<div className='preloader__skeleton-right'>
							<div className='preloader__skeleton-counter'>
								<LoadingSkeleton enableAnimation={false} />
							</div>
							<div className='preloader__skeleton-settings'>
								<LoadingSkeleton enableAnimation={false} />
							</div>
						</div>
					</div>
					<div className='preloader__skeleton-chat'>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
							<div className='preloader__skeleton-message' key={i}>
								<div className='preloader__skeleton-message-avatar'>
									<LoadingSkeleton circle enableAnimation={false} />
								</div>
								<div className='preloader__skeleton-message-body'>
									<LoadingSkeleton enableAnimation={false} />
								</div>
							</div>
						))}
					</div>
					<div className='preloader__skeleton-bottom'>
						<div className='preloader__skeleton-msg'>
							<LoadingSkeleton enableAnimation={false} />
						</div>
						<div className='preloader__skeleton-btn'>
							<LoadingSkeleton circle enableAnimation={false} />
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default Preloader;
