import SVG from 'react-inlinesvg';

const useAppData = () => {
	const getAppIcon = (pic: string, props?: any) => {
		if (pic.includes('.svg')) {
			return <SVG src={pic} {...props} />;
		}
		return <img className='ico-item' src={pic} alt='' {...props} />;
	};

	return {getAppIcon};
};

export default useAppData;
