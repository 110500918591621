/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Die Vereinbarung',
		subTitle: 'Lizenzvereinbarung und Datenschutzrichtlinie',
		textFirst: 'Ich akzeptiere die Bedingungen',
		textSecond:
			'Und ich stimme der Verarbeitung meiner persönlichen Daten gemäß den festgelegten Bedingungen zu',
		license: 'Lizenzvereinbarung',
		policy: 'Personalangaben',
	},
	controlPanel: {
		streamEnded: 'Die Übertragung ist beendet',
		banAlert: 'Sie können in den Chat nicht schreiben',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stickers' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Das Profil ist gelöscht',
		text: (date: string) => `Sie können Ihr Profil bis wiederherstellen ${date}`,
	},
	nameScreen: {
		title: 'Name im Chat',
		inputPlaceholder: 'Geben Sie den Namen ein',
		nameError: 'Unzulässiger Name',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Anderen wählen:',
	},
	settingsScreen: {
		title: 'Einstellungen',
		personal: 'Persönliche Einstellungen',
		room: 'Einstellungen des Raums',
		profileBtn: 'Profil',
		languageBtn: 'Sprache',
		blockedBtn: 'Gesperrte',
		rules: 'Chat-Regeln',
		deleteProfile: 'Das Profil löschen',
		slowModeBtn: 'Langsamer Modus',
		agora: {
			microphone: 'Mikrophon',
			playbackDevice: 'Tonquelle',
		},
	},
	languageScreen: {
		title: 'Sprache und Schnittstelle',
		messagesTranslate: 'Nachrichten übersetzen',
		translateMessagesMode: 'Nachrichten im Chat übersetzen',
		translateMessagesTo: 'Nachrichten übersetzen nach:',
	},
	rulesScreen: {
		title: 'Chat-Regeln',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Anderen Avatar wählen',
		chatNameLabel: 'Name im Chat',
		changeName: 'Ändern',
	},
	blockedScreen: {
		title: 'Gesperrte',
		emptyListText: 'Keine gesperrten Nutzer',
	},
	chatScreen: {
		inputPlaceholder: 'Beitrag…',
		cantWrite: 'Sie können in den Chat nicht schreiben',
		chatSubmenu: {
			hideMessage: 'Beiträge ausblenden',
			showMessage: 'Beiträge zeigen',
			removeFromSpeakers: 'Aus der Sprecherliste entfernen',
			setSpeaker: 'Zum Sprecher machen',
			hideOnlyMessage: 'Beitrag ausblenden',
			showOnlyMessage: 'Beiträge zeigen',
			reportMessage: 'Sich über den Beitrag beschweren',
			reportUser: 'Sich über den Nutzer beschweren',
			muteSpeaker: 'Mikrophon einschalten',
			blockUser: 'Sperren',
			unblockUser: 'Entsperren',
			reply: 'Antworten',
			copy: 'Kopieren',
			edit: 'Ändern',
			delete: 'Löschen',
			pinMessage: 'Anheften',
			unpinMessage: 'Abheften',
			replyInThread: 'Im Thread antworten',
		},
		actionMessage: {
			editing: 'Editieren',
			reply: 'Antworten',
		},
		status: {
			top: 'Top',
		},
		translating: 'Übersetzen...',
	},
	chatUsersScreen: {
		title: 'Teilnehmer',
		edited: 'Geändert',
		hidden: 'versteckt',
		unreadMessages: 'Ungelesene Beiträge',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Den Teilnehmer stummschalten',
				subtitle: 'Mikrophon ausschalten',
			},
			setSpeaker: {
				title: 'Aus der Sprecherliste entfernen',
				subtitle: 'Nur-Text-Chat',
			},
			removeFromSpeaker: {
				title: 'Zum Sprecher machen',
				subtitle: 'Der Nutzer wird mit der Stimme sprechen können',
			},
			showMessages: {
				title: 'Beitrag zeigen',
				subtitle: 'Видно только админу',
			},
			hideMessages: {
				title: 'Скрыть сообщения',
				subtitle: 'Sieht nur der Administrator',
			},
			blockUser: {
				title: 'Sperren',
				subtitle: 'In die schwarze Liste eintragen',
			},
			unblockUser: {
				title: 'Entsperren',
				subtitle: 'Entsperren Sie den Nutzer',
			},
			blockUserByUser: {
				title: 'Sperren',
				subtitle: 'Alle Beiträge des Nutzers ausblenden',
			},
			unblockUserByUser: {
				title: 'Entsperren',
				subtitle: 'Alle Beiträge des Nutzers zeigen',
			},
			reportUser: {
				title: 'Sich über den Nutzer beschweren',
				subtitle: 'Die Beschwerde wird anonym gesendet',
			},
		},
		days: {
			today: 'Heute',
			yesterday: 'Gestern',
		},
		chatCreated: 'Der Chat ist erstellt,',
	},
	alerts: {
		btns: {
			unblock: 'Entsperren',
			block: 'Sperren',
			send: 'Absenden',
			sendAndBlock: 'Absenden und sperren',
			show: 'Zeigen',
			showAll: 'Alle zeigen',
			cancel: 'Abbrechen',
			yes: 'Ja',
			hide: 'Das Gewählte ausblenden',
			hideAll: 'Alle ausblenden',
			make: 'Machen',
			remove: 'Entfernen',
			delete: 'Löschen',
			deleteAccount: 'Profil löschen',
			close: 'Schliessen',
			pin: 'Anheften',
			unpin: 'Abheften',
			edit: 'Editieren',
			end: 'Beenden',
			endAndShare: 'Beenden und teilen',
			reset: 'Zurücksetzen',
			rules: 'Chat-Regeln',
			contactus: 'Uns anschreiben',
		},
		pinMessage: {
			title: 'Beitrag anheften?',
		},
		unPinMessage: {
			title: 'Beitrag abheften?',
		},
		accountRestored: {
			title: 'Das Profil ist wiederhergestellt',
		},
		closeApp: {
			title: 'Wollen Sie sich wirklich abmelden?',
		},
		removeMessage: {
			title: 'Beitrag löschen?',
		},
		report: {
			title: 'Beschwerde senden?',
			subtitle: 'Sie können auch den Nutzer sperren, um die Beiträge voneinander nicht zu sehen.',
		},
		banUser: {
			title: (name: string) => `${name} sperren?`,
			subtitle: (text: string) => `Der Nutzer wird für ${text} gesperrt`,
		},
		unBanUser: {
			title: (name: string) => `${name} entsperren?`,
		},
		showUserMessages: {
			title: (name: string) => `Beiträge von ${name} zeigen?`,
			subTitle: 'Andere Nutzer werden die Beiträge dieses Nutzers sehen könnenя',
		},
		hideUserMessages: {
			title: (name: string) => `Beiträge von ${name} ausblenden?`,
			subTitle: 'Andere Nutzer werden die Beiträge dieses Nutzers nicht mehr sehen können',
		},
		showUserMessage: {
			title: (name: string) => `Beitrag von ${name} zeigen?`,
			subTitle: 'Другие пользователи смогут видеть это сообщение',
		},
		hideUserMessage: {
			title: (name: string) => `Скрыть сообщениe от ${name}?`,
			subTitle: 'Andere Nutzer werden diesen Beitrag sehen können',
		},
		blockUserInRoom: {
			title: (name: string) => `Beitrag von ${name} ausblenden?`,
			subTitle: 'Andere Nutzer werden diesen Beitrag nicht mehr sehen können',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name} sperren?`,
			subTitle: 'Der Nutzer wird in diesem Raum nicht teilnehmen können',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name} sperren??`,
			subTitle: 'Sie werden die Beiträge voneinander nicht sehen',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name} zum Sprecher machen??`,
			unTitle: (name: string) => `${name} aus der Sprecherliste entfernen??`,
		},
		banInApp: {
			title: 'Administrator hat Sie gesperrt',
			for: 'für',
			subtitle: (bannedUntil: string) => `Sie sind bis ${bannedUntil} gesperrt`,
			permanent: 'Sie sind für immer gesperrt',
		},
		banInAppBadNickname: {
			title: 'Administrator hat Sie für unzulässigen Namen gesperrt',
			subtitle:
				'Betreten Sie Ihr Profil, ändern Sie den Namen und melden Sie sich im Chat erneut an',
		},
		deleteAccount: {
			title: 'Löschen des Profils',
			subTitle:
				'Alle Daten werden gelöscht. Sie werden innerhalb von 30 Tagen das Profil wiederherstellen können.',
		},
		pollEdit: {
			title: 'Umfrage editieren?',
			subTitle: 'Antworten der Nutzer werden zurückgesetzt',
		},
		pollReset: {
			title: 'Zurücksetzen?',
			subTitle: 'Die Umfrage wird nicht gespeichert',
		},
		pollEnd: {
			title: 'Wollen Sie wirklich die Umfrage beenden?',
		},
		pollDelete: {
			title: 'Umfrage löschen?',
		},
		agoraCheck: {
			updateIOSText:
				'Aktualisieren Sie die iOS-Version, um alle Funktionen der App nutzen zu können.',
			allowAccess: {
				title: 'Möchten Sie etwas sagen?',
				text: 'Bitte öffnen Sie die App-Einstellungen und erlauben Sie den Zugriff auf das Mikrofon und den Lautsprecher.',
			},
			microphoneAndSpeakerText:
				'Die Nutzung des Mikrofons und Lautsprechers ist seit der Version 14.3 verfügbar.',
			microphoneText:
				'Die Nutzung des Mikrofons und Lautsprechers ist seit der Version 14.3 verfügbar.',
			btn: 'Okay!',
		},
	},
	eventScreen: {
		title: 'Geplantes Event',
		titleSoon: 'Die Übertragung beginnt bald',
		subtitle: 'Der Chat wird bald verfügbar sein; bitte warten Sie.',
		days: 'Tagen',
		hours: 'Stunden',
		minutes: 'Minuten',
		seconds: 'Sekunden',
		startTitle: 'Anfang',
	},
	toasts: {
		reciveComplaint: 'Wir haben Ihre Beschwerde bekommen',
		setSpeakerRoleForUser: 'Der Nutzer wurde zum Sprecher ernannt',
		unSpeakerRoleForUser: 'Der Nutzer wurde aus der Sprecherliste entfernt',
		messagesShown: 'Die Beiträge wurden gezeigt',
		messagesHidden: 'Die Beiträge wurden ausgeblendet',
		messageShown: 'Der Beitrag wurde gezeigt',
		messageHidden: 'Der Beitrag wurde ausgeblendet',
		userBlocked: 'Der Nutzer wurde gesperrt',
		userUnBlocked: 'Der Nutzer wurde entsperrt',
		youAreSpeaker: 'Sie sind der Sprecher! Sagen Sie etwas',
		youAreNotSpeaker: 'Administrator hat Sie aus der Sprecherliste entfernt',
		userIsSpeaker: (name: string) => `${name} ist nun Sprecher`,
		userNotSpeaker: (name: string) => `${name} ist kein Sprecher mehr`,
		yourUserNameInChat: (name: string) => `Ihr Name im Chat ist ${name}`,
		messageRemoved: 'Der Beitrag wurde gelöscht',
		messageCopied: 'Der Text wurde kopiert',
		obscenities: 'Versuchen Sie umzuformulieren',
		requestSent: 'Die Anfrage wurde abgesendet',
		requestDecline: 'Die Anfrage wurde vom Administrator zurückgezogen',
		youareblocked: (text: string) =>
			`Sie wurden für ${text} gesperrt. Sie können in den Chat nicht schreiben.`,
		youareunblocked: 'Sie wurden entsperrt. Sie können Beiträge in den Chat schreiben',
		youAreModerator: 'Sie sind Moderator!',
		youAreNoModerator: 'Sie sind kein Moderator mehr.',
		micMuted: 'Ihr Mikrophon ist stummgeschaltet',
		noInet: 'Keine Internet-Verbindung',
		messagePinned: 'Der Beitrag ist angeheftet',
		messageUnpinned: 'Der Beitrag ist abgeheftet',
		pollPublished: 'Die Umfrage wurde veröffentlicht',
		pollPublishedError: 'Fehler! Versuchen Sie später',
		pollAddPic: 'Laden Sie ein Photo hoch',
		pollAddPicError: 'Wählen Sie anderes Bild',
		pollDeleted: 'Die Umfrage ist gelöscht',
		pollEnded: 'Die Umfrage ist beendet',
		sendStickerWhenSlowModeIsOn: (time: string) => `Warten Sie ${time} , um Sticker abzusenden`,
		linkProhibited: 'Sie können keine Links im Chat senden.',
		phoneProhibited: 'Sie können keine Telefonnummern im Chat senden.',
		profileHidden: 'Benutzer hat sein Profil versteckt',
		imageSizeWarning: 'Sie können ein Bild mit einer Größe von bis zu 10 MB hochladen',
		imageBlocked: 'Der Moderator hat den Bildupload blockiert',
		streamKeyCopied: 'Broadcast-Schlüssel kopiert',
	},
	btns: {
		acceptandgo: 'Akzeptieren und fortfahren',
		saveandgo: 'Speichern und fortfahren',
		save: 'Speichern',
		unblock: 'Entsperren',
		block: 'Sperren',
		restoreAccount: 'Das Profil wiederherstellen',
		close: 'Schliessen',
	},
	coopyrights: 'Funktioniert auf Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message'
					? 'Sich über den Beitrag beschweren'
					: 'Über eine Verletzung berichten',
			subtitle: 'Die Beschwerden werden anonym abgesendet',
			btns: {
				violence: 'Beleidigungen, Drohungen, Schimpferei',
				provocations: 'Provokationen',
				personalDetails: 'Personalangaben',
				flood: 'Flooding',
				spam: 'Spam',
				fraudBegging: 'Betrug, Bettelei',
				other: 'Anderes',
			},
		},
		ban: {
			title: 'Den Nutzer sperren',
			subtitle: 'Geben Sie den Grund für die Sperrung an',
			btns: {
				insults: 'Beleidigungen, Drohungen, Schimpferei',
				personal: 'Verbreitung von Personalangaben',
				flood: 'Flooding',
				links: 'Links zu externen Services',
				violence: 'Drohungen',
				fraud: 'Betrugsversuche und Bettelei',
				nickname: 'Unzulässiger Nickname',
				politicial: 'Politische, sexualisierte und andere Provokationen',
				repeated: 'Wiederholte Verletzung',
				auto: 'Beschwerden der Chat-Nutzer',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Sie haben keine wartenden Sprecher',
			handsUp: 'Hand gehoben',
			waiting: 'Wartende Sprecher',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Geben Sie richtige Antwort',
				typeQuizMultiple: 'Vermerken Sie richtige Varianten',
				typePoll: 'Teilen Sie Ihre Meinung',
				typePollMultiple: 'Man kann mehrere Varianten vermerken',
				default: 'Öffentliche Umfrage',
			},
			btns: {
				reply: 'Antworten',
				vote: 'Abstimmen',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Quiz',
				typePoll: 'Mehrere Antworten',
				default: 'Öffentliche Umfrage',
			},
			btns: {
				edit: 'Umfrage editieren',
				end: 'Beenden',
			},
		},
		pollCreated: {
			titles: {
				created: 'Umfrage erstellen',
				typeQuiz: 'Quiz',
				typePoll: 'Mehrere Antworten',
				default: 'Öffentliche Umfrage',
			},
		},
		changeName: {
			title: 'Ändern Sie den Namen im Chat',
			subtitle: 'Sie wurden für unzulässigen Nicknamen gesperrt',
		},
		temporaryAgreement: {
			title: 'Chat-Regeln',
			rules: {
				bePolite: {
					title: 'Seien Sie höflich',
					text: 'Im Chat sind gegnerische und obszöne Äuβerungen verboten.',
				},
				beAttentive: {
					title: 'Seien Sie aufmerksam',
					text: 'Teilen Sie keine Personalangaben und bitten Sie andere nicht darum.',
				},
				notAdvertise: {
					title: 'Machen Sie keine Werbung',
					text: 'Lieber teilen Sie Meinung oder Emotionen.',
				},
			},
			btns: {
				allRules: 'Alle Regeln',
				acceptAndContinue: 'Akzeptieren und fortfahren',
			},
		},
		slowMode: {
			title: 'Langsamer Modus',
			text: 'Die Teilnehmer werden keine Beiträge öfter als im gewählten ',
			enable: 'Langsamen Modus einschalten',
			disable: 'Langsamen Modus ausschalten',
			sendTimeout: 'Timeout der Absendung',
		},
		slowModeTimeout: {
			title: 'Timeout der Absendung',
		},
		chatBot: {
			title: 'Nutzung des Chatbots',
			rules: [
				{
					title: 'Künstliche Intelligenz',
					text: 'Der Bot imitiert den Austausch mit Menschen und gewährt Information aufgrund seiner Kenntnisse. Jedoch bedenken Sie, dass seine Antworten begrenzt sein können und einen Menschen nicht ersetzen werden.',
				},
				{
					title: 'Der Bot kann Fehler machen',
					text: 'Der Bot kann die Genauigkeit und Richtigkeit der Information nicht garantieren.',
				},
			],
			btns: {
				close: 'Schliessen',
			},
		},
		shareBet: {
			title: 'Einsatz ',
			noBetsTitle: 'Wir konnten Ihre Wette nicht finden',
			noBetsText: 'Man kann Wetten teilen, die innerhalb von 14 Tagen abgeschlossen wurden',
			btns: {
				share: 'Einsatz teilen',
				copy: 'Die Wette wiederholen',
			},
			status: {
				null: 'Im Spiel',
				win: 'Gewinn',
				lose: 'Verlust',
			},
			betTypeV1: {
				express: 'Mehrere',
				single: 'Einzel',
			},
			betTypeV2: {
				single: 'Einzel',
				multi: 'Mehrere',
				system: 'System',
			},
			totalFactor: 'Koeff',
			bet: 'Wette',
			showMore: 'Mehr zeigen',
		},
		shareGamble: {
			noGamblesText:
				'Sie haben noch keine Gewinne zum Teilen. Spielen Sie weiter, und Ihr nächster Gewinn wird hier erscheinen, damit Sie ihn mit anderen Spielern teilen können!',
		},
		record: {
			text: 'Aufnahme der Sendung wird bearbeitet…',
		},
		translate: {
			title: 'Nachrichten übersetzen',
			chooseLanguage: 'Sprache wählen',
			descr: 'Chat-Nachrichten werden automatisch in die von Ihnen gewählte Sprache übersetzt',
			btn: 'Traducir',
		},
		streamSettings: {
			title: 'Stream-Einstellungen',
			description:
				'Laden Sie einen Video-Encoder herunter und installieren Sie ihn. Geben Sie den Stream-Schlüssel in dessen Einstellungen ein',
			shareScreen: {
				title: 'Bildschirmfreigabe',
				description: 'Keine speziellen Einstellungen erforderlich',
			},
			rtmp: {
				title: 'Externe Stream-Quelle',
				description: 'Verwenden Sie ein zusätzliches Programm für das Streaming',
			},
			streamKey: 'Stream-Schlüssel',
			showStreamKey: 'Anzeigen',
			hideStreamKey: 'Verbergen',
			btns: {
				continue: 'Weiter',
				close: 'Schließen',
				ok: 'Fertig',
			},
		},
	},
	pinnedMessage: {
		title: 'Angehefteter Beitrag',
	},
	errorPage: {
		error: 'Fehler',
		types: [
			// errorNumber 0
			{
				title: 'Wir erkennen Sie nicht',
				text: 'Versuchen Sie sich im Chat erneut anzumelden',
			},
			// errorNumber 1
			{
				title: 'Wir können den Chat nicht finden',
				text: 'Versuchen Sie sich erneut anzumelden',
			},
			// errorNumber 2
			{
				title: 'Es ist unmöglich, den Chat zu laden',
				text: 'Versuchen Sie, erneut zu laden',
			},
			// errorNumber 3
			{
				title: 'Wir erkennen Sie nicht',
				text: 'Chat ist im Inkognitomodus unzugänglich',
			},
			// errorNumber 4
			{
				title: 'Unzulässiger Nickname',
				text: 'Betreten Sie Ihr Profil, ändern Sie den Nicknamen und melden Sie sich im Chat erneut an.',
			},
		],
	},
	tooltips: {
		emotions: 'Halten Sie, um zu ändern',
		badInet: 'Schlechte Netzverbindung',
		blockedUsers: 'Hier kann man die Liste der gesperrten <br> Nutzer sehen.',
		waitingSpeakers: 'Jemand der Teilnehmer will zum Wort kommen.',
		wantSpeak: 'Drücken Sie, um das Stimmrecht anzufragen.',
		slowMode: {
			header: (time: string) =>
				`Langsamer Modus ist eingeschaltet. Nutzer können jede <br>time einen Beitrag schicken. Diese Regel gilt nicht für die Moderatoren.`,
			btnSendMessage: (time: string) => `Sie können jede <br>${time} Beiträge schicken.`,
		},
		shareBet: 'Einsatz im Chat teilen',
		translate: 'Nachrichten in eine andere Sprache übersetzen',
	},
	poll: {
		toggleSubmenu: {
			create: 'Umfrage erstellen',
			edit: 'Umfrage editieren',
			show: 'Umfrage zeigen',
			end: 'Umfrage beenden',
		},
		tooltip: {
			btn: 'Stimmen',
		},
		settings: {
			title: 'Einstellungen',
			titleAdditionally: 'Zusätzlich',
			switches: {
				quizMode: 'Quiz-Modus',
				multipleChoice: 'Wahl mehrerer Antworten',
				withPhoto: 'Umfrage mit Photo',
			},
		},
		question: {
			title: 'Frage',
			inputPlaceholder: 'Geben Sie die Frage ein',
		},
		message: {
			title: 'Sie haben gestimmt',
		},
		answers: {
			title: 'Varianten der Antwort',
			warning: 'Sie können bis 5 Antworten hinzufügen',
		},
		answer: {
			tooltip: 'Wählen Sie die richtige Antwort',
			inputPlaceholder: 'Antwort',
		},
		results: {
			text: 'Ergebnisse der Abstimmung',
			votes: 'Stimme',
			// words: ['Stimme', 'Stimmen'],
		},
		btns: {
			addQuestion: 'Frage hinzufügen',
			addAnswers: 'Antworten hinzufügen',
			addAnswer: 'Antwort hinzufügen',
			publish: 'Veröffentlichen',
			vote: 'Stimmen Sie',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Einschätzen',
				continue: 'Fortfahren',
				checkConnection: 'Verbindung prüfen',
				close: 'Schliessen',
			},
			quality: {
				title: 'Bewerten Sie die Tonqualität',
			},
			reason: {
				title: 'Was ist schiefgelaufen?',
			},
			connection: {
				title: 'Testen Sie die Qualität der Verbindung',
				text: 'Die Prüfung wird nicht mehr als eine Minute dauern und die Ursache des Problems zu finden helfen',
			},
			statistics: {
				title: 'Wir sammeln Statistik…',
			},
			done: {
				title: 'Danke!',
				text: 'Wir werden untersuchen, was schiefgelaufen ist, und unbedingt korrigieren.',
			},
		},
	},
	chatBot: {
		message: 'Fragen Sie den Bot über etwas…',
		warnings: {
			disabled: 'Ich wurde abgeschaltet',
			overloaded: 'Wir sind überlastet',
		},
	},
	audioReason: {
		nothear: 'Man konnte nichts hören',
		connection: 'Der Ton war unterbrochen',
		microphone: 'Mikrophon konnte nicht benutzt werden',
	},
	screenShare: {
		title: 'Bildschirm teilen Die',
		stopSharing: 'Bildschirm teilen beenden',
		modal: {
			title: 'Bildschirmfreigabe ist nur von einem Computer aus möglich.',
			text: 'Öffnen Sie den Chat auf Ihrem Computer und klicken Sie auf die Schaltfläche "Bildschirm teilen" in der oberen rechten Ecke. ',
			btn: 'Kopieren Sie den Einladungslink.',
		},
	},
	threads: {
		title: 'Thread',
		back: 'Zurück',
		replies: ['Antwort', 'Antworten', 'Antworten'],
	},
	attach: {
		submenu: {
			sendImage: 'Bild senden',
			shareBet: 'Wette teilen',
			shareGamble: 'Gewinne teilen',
		},
		images: ['Bild', 'Bilder', 'Bilder'],
		addCaption: 'Beschriftung hinzufügen',
	},
} as unknown as Dictionary;
