/* eslint-disable no-param-reassign */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useRef} from 'react';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import modalService from 'store/modalService';
import attachService from 'store/attachService';
import toastService from 'store/toastService';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import controlPanelService from 'store/controlPanelService';
import classNames from 'classnames';
import MessageType from 'models/enums/MessageType.enum';

const SIZE = 10485760;
const FORMAT = 'image/png,image/jpg,image/jpeg,image/heic';
interface IControlPanelAttach {
	openShareModal: (type: MessageType) => void;
	appBettingVisible: boolean;
	appGambleVisible: boolean;
	inputTypeFileRef: React.RefObject<HTMLInputElement>;
}
const ControlPanelAttach: FunctionComponent<IControlPanelAttach> = function ControlPanelAttach({
	openShareModal,
	appBettingVisible,
	appGambleVisible,
	inputTypeFileRef,
}) {
	const {attach, toasts} = useL10n();
	const {appIcons, enableMessagePics} = useLocalObservable(() => appService);
	const {getAppIcon} = useAppData();
	const {IcoImage, IcoShare} = appIcons;

	const {toggleImagePreviewModalVisible} = useLocalObservable(() => modalService);
	const {files, setFiles} = useLocalObservable(() => attachService);
	const {addToast} = useLocalObservable(() => toastService);
	const {toggleAttachSubmenuVisible, attachSubmenuVisible} = useLocalObservable(
		() => controlPanelService
	);

	const controlPanelAttachClasses = classNames('control-panel__attach', {
		'control-panel__attach--visible': attachSubmenuVisible,
	});

	const btnAttachClickHandler = () => {
		inputTypeFileRef.current?.click();
	};

	const clear = () => {
		if (inputTypeFileRef.current) {
			inputTypeFileRef.current.type = '';
			inputTypeFileRef.current.type = 'file';
			inputTypeFileRef.current.value = '';
		}
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const arrFiles: {file: File; isChecked: boolean}[] = [];
			Array.from(e.target.files).forEach((file, index) => {
				(async () => {
					if (e.target.files) {
						if (file.size <= SIZE && FORMAT.includes(file.type)) {
							if (index < 10) {
								arrFiles.push({file: e.target.files[index], isChecked: true});
								setFiles([...arrFiles]);
							}
						} else {
							addToast({
								iconName: ToastIconsName.brickRed,
								liveTime: 3000,
								message: toasts.imageSizeWarning,
								cancellable: true,
							});
							toggleAttachSubmenuVisible(false);
						}
						if (index === Array.from(e.target.files).length - 1 && arrFiles.length) {
							toggleImagePreviewModalVisible(true);
							clear();
						}

						return;
					}

					clear();
					setFiles([]);
				})();
			});
		}
	};

	return (
		<div className={controlPanelAttachClasses}>
			<ul className='control-panel__attach-list'>
				{enableMessagePics && (
					<li className='control-panel__attach-item'>
						<button
							type='button'
							className='control-panel__attach-btn'
							onClick={btnAttachClickHandler}>
							<input
								type='file'
								multiple
								onChange={onChangeHandler}
								ref={inputTypeFileRef}
								accept={FORMAT}
							/>
							<span>{attach.submenu.sendImage}</span>
							{getAppIcon(IcoImage.pic, {className: 'control-panel__attach-icon'})}
						</button>
					</li>
				)}
				{appBettingVisible && (
					<li className='control-panel__attach-item'>
						<button
							type='button'
							className='control-panel__attach-btn'
							onClick={() => openShareModal(MessageType.BET)}>
							<span>{attach.submenu.shareBet}</span>
							{getAppIcon(IcoShare.pic, {className: 'control-panel__attach-icon'})}
						</button>
					</li>
				)}
				{appGambleVisible && (
					<li className='control-panel__attach-item'>
						<button
							type='button'
							className='control-panel__attach-btn'
							onClick={() => openShareModal(MessageType.GAMBLE)}>
							<span>{attach.submenu.shareGamble}</span>
							{getAppIcon(IcoShare.pic, {className: 'control-panel__attach-icon'})}
						</button>
					</li>
				)}
			</ul>
		</div>
	);
};

export default observer(ControlPanelAttach);
