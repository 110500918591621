interface IPostMessage {
	type: string;
	body: string;
}

const usePlayer = () => {
	const sendPostMessage = (msg: IPostMessage) => {
		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}

		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');
	};

	const audioPostMessage = (action: string) => {
		const msg = {
			type: 'audio',
			body: action,
		};

		sendPostMessage(msg);
	};

	const videoPostMessage = (action: string) => {
		const msg = {
			type: 'video',
			body: action,
		};

		sendPostMessage(msg);
	};

	const formatTime = (time: number) => {
		if (time) {
			const hours = Math.floor(time / 3600);
			const minutes = Math.floor((time % 3600) / 60);
			const seconds = Math.floor(time % 60);

			const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
			const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			return hours > 0
				? `${hours}:${formatMinutes}:${formatSeconds}`
				: `${minutes}:${formatSeconds}`;
		}
		return '0:00';
	};

	return {audioPostMessage, videoPostMessage, formatTime};
};

export default usePlayer;
