import {action, makeObservable, observable} from 'mobx';
import {Gamble} from 'models/gamble';

class GambleService {
	@observable
	public gambles: Gamble[] = [];

	@observable
	public gamblesError = '';

	@observable
	public currentGambleForShare: Gamble | null = null;

	@action
	public setGambles = (value: Gamble[], offset: number) => {
		if (this.gambles.length) {
			this.gambles.splice(offset, value.length, ...value);
			return;
		}
		this.gambles = value;
	};

	@action
	public clearGambles = () => {
		this.gambles = [];
	};

	@action
	public setGamblesError = (error: string) => {
		this.gamblesError = error;
	};

	@action
	public setCurrentGambleForShare = (value: Gamble | null) => {
		this.currentGambleForShare = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new GambleService();
