import {USER_UNNAMED} from 'constants/constants';
import UserRole from 'models/enums/UserRole.enum';
import {Talker} from 'models/room';
import appService from 'store/appService';
import roomService from 'store/roomService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useModeratorActions from 'hooks/useSubmenuActions';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import Button from 'components/hoc/Button';
import {Avatar} from 'components/avatar';
import {ReactComponent as IcoGuestIcon} from 'assets/svg/guest.svg';

import './waitingSpeakers.scss';
import {User} from 'models/user';

const WaitingSpeakers: FunctionComponent = function WaitingSpeakers() {
	const {appIcons} = useLocalObservable(() => appService);
	const {roomId, talkersWaitingSpeakers} = useLocalObservable(() => roomService);

	const {modals} = useL10n();
	const {changeUserRole} = useModeratorActions();
	const {getAppIcon} = useAppData();

	const {IcoAdd, IcoClose} = appIcons;

	const onRejectedHandler = (talkerId: number) => {
		roomId && SocketIoServices.emitRejectHand(roomId, talkerId);
	};

	const onSetRoleHandler = (user: User) => {
		if (roomId && user.id) {
			changeUserRole(user, roomId, UserRole.SPEAKER);
			// SocketIoServices.emitSetRole(user.id, roomId, UserRole.SPEAKER);
		}
	};

	const renderWaitingSpeaker = useCallback(
		({id, user, isModer}: Talker) => (
			<div className='waiting-speakers__item' key={id}>
				<div className={`waiting-speakers__item-avatar background-color-${user?.color || 0}`}>
					<Avatar
						src={user.pic !== '' ? user.pic : AnonimAva}
						alt={user.name && user.name !== '' ? user.name : USER_UNNAMED}
						className='waiting-speakers__item-avatar-img'
					/>
				</div>
				<div className='waiting-speakers__item-right'>
					<p className='waiting-speakers__item-name'>
						<span className='waiting-speakers__item-name-item'>{user.name}</span>

						{user.isInvitedGuest && <IcoGuestIcon className='waiting-speakers__item-invited' />}

						{isModer && !user.isInvitedGuest && (
							<span className='waiting-speakers__item-admin'>admin</span>
						)}

						{!isModer && !user.isInvitedGuest && user.isVip && user.vipStatus && (
							<span
								className={`waiting-speakers__item-status waiting-speakers__item-status--${user.vipStatus.toLowerCase()}`}>
								{user.vipStatus.toLowerCase()}
							</span>
						)}
					</p>
					<div className='waiting-speakers__item-btns'>
						<Button
							className='waiting-speakers__item-btn waiting-speakers__item-btn--close'
							onClick={() => onRejectedHandler(id)}>
							{getAppIcon(IcoClose.pic)}
						</Button>

						<Button className='waiting-speakers__item-btn' onClick={() => onSetRoleHandler(user)}>
							{getAppIcon(IcoAdd.pic)}
						</Button>
					</div>
				</div>
			</div>
		),
		[talkersWaitingSpeakers]
	);

	if (!talkersWaitingSpeakers?.length) {
		return (
			<div className='waiting-speakers waiting-speakers--empty'>
				<div className='waiting-speakers__text'>{modals.waitingSpeakers.text}</div>
			</div>
		);
	}

	return <div className='waiting-speakers'>{talkersWaitingSpeakers.map(renderWaitingSpeaker)}</div>;
};

export default observer(WaitingSpeakers);
