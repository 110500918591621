import {PollType, PollScreens} from 'models/enums/Poll.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {PollCreatedOption} from 'models/poll';
import appService from 'store/appService';
import roomService from 'store/roomService';
import userServices from 'store/userService';
import modalServices from 'store/modalService';
import pollService from 'store/pollService';
import toastService from 'store/toastService';
import RoomService from 'services/api/RoomService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import update from 'immutability-helper';
import classNames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import Button from 'components/hoc/Button';

import PollAnswerElement from './PollAnswerElement';
import './pollAnswers.scss';

const basePollCreatedOption = {
	text: '',
	pic: null,
	isRight: false,
};

const TEXT_MAX_LENGTH = 25;
const TEXT_MAX_LENGTH_WITH_IMG = 75;

const PollAnswers: FunctionComponent = function PollAnswers() {
	const {appIcons} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {togglePollCreateModalVisible} = useLocalObservable(() => modalServices);
	const {poll, pollCreated, pollWithPics, updatePollCreated, setScreen, setPollWithPics} =
		useLocalObservable(() => pollService);
	const {addToast} = useLocalObservable(() => toastService);
	const {toasts, poll: pollTranslate} = useL10n();
	const {getAppIcon} = useAppData();

	const [isNotValidFields, setisNotValidFields] = useState(false);
	const [isFieldsNotEmpty, setIsFieldsNotEmpty] = useState(false);
	const [isPollObjectsSame, setIsPollObjectsSame] = useState(false);
	const [noPicToastVisible, setNoPicToastVisible] = useState(false);
	const [disablePublishPollBtn, setDisablePublishPollBtn] = useState(false);
	const [elements, setElements] = useState(
		pollCreated?.options ? pollCreated.options : [{...basePollCreatedOption, id: uuidv4()}]
	);

	const {IcoRightArrow} = appIcons;

	const blockedPublishPoll = isNotValidFields || isFieldsNotEmpty || isPollObjectsSame;
	const isNotUploadedPic =
		pollWithPics.value &&
		elements.find((elem: PollCreatedOption) => elem.text.trim().length !== 0) &&
		elements.find((elem: PollCreatedOption) => elem.pic === null);
	const answerMaxLength = pollWithPics.value ? TEXT_MAX_LENGTH : TEXT_MAX_LENGTH_WITH_IMG;

	const pollAnswersListClasses = classNames('poll-answers__list', {
		'poll-answers__list--hide-button': elements.length <= 4 && isFieldsNotEmpty,
	});

	const btnPollClasses = classNames('btn btn--poll', {
		'btn--disabled': blockedPublishPoll,
	});

	useEffect(() => {
		setIsFieldsNotEmpty(
			elements.some((elem: PollCreatedOption) => {
				if (pollWithPics.value) {
					return elem.pic === null || elem.text.trim().length === 0;
				}
				return elem.text.trim().length === 0;
			})
		);

		if (elements.length <= 1) {
			setisNotValidFields(true);
			return;
		}

		if (pollCreated?.type === PollType.QUIZ) {
			setisNotValidFields(
				!elements.some((elem: PollCreatedOption) => {
					return elem.isRight;
				})
			);
			return;
		}

		setisNotValidFields(false);
	}, [elements]);

	const addNewFreshElement = () => {
		setElements([...elements, {...basePollCreatedOption, id: uuidv4()}]);
		setisNotValidFields(true);
	};

	useEffect(() => {
		if (pollCreated?.options) {
			setElements(pollCreated.options);
		}
	}, []);

	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setElements((prevCards: PollCreatedOption[]) => {
			return update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex] as PollCreatedOption],
				],
			});
		});
	}, []);

	const changeOptionData = (id: string, data: any, isRadio = false) => {
		setElements((prevCards: PollCreatedOption[]) => {
			return prevCards.map(el => {
				if (`${el.id}` === id) {
					return {...el, ...data};
				}
				if (isRadio) {
					return {...el, isRight: false};
				}
				return el;
			});
		});
	};

	const blurHandler = (e: React.FocusEvent<HTMLInputElement>, id: string) => {
		if (e.target.value === '' && elements.length > 1) {
			const newArray: PollCreatedOption[] = elements.filter(
				(el: PollCreatedOption) => `${el.id}` !== id
			);
			setElements(newArray);
		}
	};

	const renderElements = useCallback(
		(card: PollCreatedOption, index: number) => {
			return (
				<PollAnswerElement
					key={card.id}
					index={index}
					id={card.id}
					text={card.text}
					img={card.pic}
					isRight={card.isRight}
					moveCard={moveCard}
					changeData={changeOptionData}
					removeElementOnBlur={blurHandler}
				/>
			);
		},
		[elements]
	);

	const removePrevPoll = async () => {
		if (roomId && poll?.id) {
			const removePrevResponse = await RoomService.removePoll(roomId, poll.id, accessToken);
			return removePrevResponse.status === ResponseStatus.SUCCESS;
		}
		return false;
	};

	const setPoll = async () => {
		if (pollCreated && roomId) {
			const response = await RoomService.setPoll(
				{
					...pollCreated,
					options: elements,
					room: {
						externalRoomId: roomId,
					},
				},
				accessToken,
				pollWithPics
			);
			if (response.status === ResponseStatus.SUCCESS) {
				togglePollCreateModalVisible(false);
				setScreen(PollScreens.SETTINGS);
				setPollWithPics(false, false);
				addToast({
					iconName: ToastIconsName.poll,
					liveTime: 3000,
					message: toasts.pollPublished,
					cancellable: true,
				});
				return true;
			}
		}
		return false;
	};

	const publishPoll = async () => {
		setDisablePublishPollBtn(true);
		if (!blockedPublishPoll) {
			updatePollCreated({options: elements});
			if (poll) {
				const pollRemovedResponse = await removePrevPoll();
				if (pollRemovedResponse) {
					const setPollResponse = await setPoll();
					if (setPollResponse) {
						return;
					}
				}
			}
			if (!poll) {
				const setPollResponse = await setPoll();
				if (setPollResponse) {
					return;
				}
			}
			setDisablePublishPollBtn(false);
			addToast({
				iconName: ToastIconsName.warning,
				liveTime: 3000,
				message: toasts.pollPublishedError,
				cancellable: true,
			});
			return;
		}
		if (isNotUploadedPic && !noPicToastVisible) {
			setNoPicToastVisible(true);
			setDisablePublishPollBtn(false);
			addToast({
				iconName: ToastIconsName.brickRed,
				liveTime: 3000,
				message: toasts.pollAddPic,
				cancellable: true,
				deathCallback: () => {
					setNoPicToastVisible(false);
				},
			});
			return;
		}
		setDisablePublishPollBtn(false);
	};

	useEffect(() => {
		if (elements.length) {
			elements.forEach(item => {
				if (item.text.length > answerMaxLength) {
					changeOptionData(item.id.toString(), {text: item.text.slice(0, answerMaxLength)});
				}
			});
		}
	}, []);

	useEffect(() => {
		if (
			pollCreated?.isMultiple !== poll?.isMultiple ||
			pollCreated?.text !== poll?.text ||
			pollCreated?.type !== poll?.type ||
			pollWithPics.isChanged
		) {
			setIsPollObjectsSame(false);
			return;
		}

		if (elements.length !== poll?.options.length) {
			setIsPollObjectsSame(false);
			return;
		}

		setIsPollObjectsSame(
			!elements.some((elem: PollCreatedOption, index) => {
				return (
					elem.isRight !== poll?.options[index].isRight ||
					elem.pic !== poll?.options[index].pic ||
					elem.text !== poll?.options[index].text
				);
			})
		);
	}, [pollCreated, elements, poll, pollWithPics.isChanged]);

	return (
		<div className='poll-answers'>
			<p className='poll-answers__title'>{pollTranslate.answers.title}</p>
			<div className={pollAnswersListClasses}>
				<div className='poll-answers__list-dnd-container'>
					{elements.map((elem, i) => renderElements(elem, i))}
				</div>

				{elements.length <= 4 && !isFieldsNotEmpty && (
					<Button className='poll-answers__new-field' onClick={addNewFreshElement}>
						{pollTranslate.btns.addAnswer}
					</Button>
				)}
			</div>
			<p className='poll-answers__warning'>{pollTranslate.answers.warning}</p>
			<Button className={btnPollClasses} onClick={publishPoll} disabled={disablePublishPollBtn}>
				{pollTranslate.btns.publish}
				{getAppIcon(IcoRightArrow.pic, {className: 'btn__right-icon'})}
			</Button>
		</div>
	);
};

export default observer(PollAnswers);
