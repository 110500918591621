const events: {event: string; ligaEvent: string}[] = [
	{
		event: 'session_started',
		ligaEvent: 'Social_ButtonClick_ChatSessionStart',
	},
	{
		event: 'room_opened',
		ligaEvent: 'Social_ButtonClick_ChatWatchers',
	},
	{
		event: 'room_closed',
		ligaEvent: 'Social_ButtonClick_ChatCloseWatchers',
	},
	{
		event: 'name_set',
		ligaEvent: 'Social_ButtonClick_NameSet',
	},
	{
		event: 'avatar_set',
		ligaEvent: 'Social_ButtonClick_AvatarSet',
	},
	{
		event: 'settings_opened',
		ligaEvent: 'Social_ButtonClick_Settings',
	},
	{
		event: 'settings_closed',
		ligaEvent: 'Social_NavigationClick_SettingsClose',
	},
	{
		event: 'profile_opened',
		ligaEvent: 'Social_ButtonClick_Profile',
	},
	{
		event: 'change_avatar_pressed',
		ligaEvent: 'Social_ButtonClick_AvatarChange',
	},
	{
		event: 'avatar_changed',
		ligaEvent: 'Social_ButtonClick_AvatarChangesSaved',
	},
	{
		event: 'change_name_pressed',
		ligaEvent: 'Social_ButtonClick_NameChange',
	},
	{
		event: 'name_changed',
		ligaEvent: 'Social_ButtonClick_NameChangesSaved',
	},
	{
		event: 'banned_opened',
		ligaEvent: 'Social_ButtonClick_Banned',
	},
	{
		event: 'unblocking_pressed',
		ligaEvent: 'Social_ButtonClick_Unblock',
	},
	{
		event: 'unblocking_confirmed',
		ligaEvent: 'Social_ButtonClick_UnblockConfirmed',
	},
	{
		event: 'unblocking_cancelled',
		ligaEvent: 'Social_ButtonClick_UblockCanceled',
	},
	{
		event: 'delete_profile_pressed',
		ligaEvent: 'Social_ButtonClick_ProfileDelete',
	},
	{
		event: 'delete_profile_confirmed',
		ligaEvent: 'Social_ButtonClick_ProfileDeleteConfirmed',
	},
	{
		event: 'delete_profile_cancelled',
		ligaEvent: 'Social_ButtonClick_ProfileDeleteCancelled',
	},
	{
		event: 'account_restored',
		ligaEvent: 'Social_ButtonClick_AccountRestored',
	},
	{
		event: 'user-list_openned',
		ligaEvent: 'Social_ButtonClick_UserList',
	},
	{
		event: 'user-list_closed',
		ligaEvent: 'Social_NavigationClick_UserListClosed',
	},
	{
		event: 'message_sent',
		ligaEvent: 'Social_ButtonClick_MessageSend',
	},
	{
		event: 'message_changed',
		ligaEvent: 'Social_ButtonClick_MessageEdit',
	},
	{
		event: 'message_replied',
		ligaEvent: 'Social_ButtonClick_Reply',
	},
	{
		event: 'scrolled_to_unread_messages',
		ligaEvent: 'Social_ButtonClick_ScrollToUnread',
	},
	{
		event: 'message_text_copied',
		ligaEvent: 'Social_ButtonClick_MessageCopy',
	},
	{
		event: 'report_pressed',
		ligaEvent: 'Social_ButtonClick_Complain',
	},
	{
		event: 'report_sent',
		ligaEvent: 'Social_ButtonClick_ComplainConfirm',
	},
	{
		event: 'report_cancelled',
		ligaEvent: 'Social_ButtonClick_ComplainCancel',
	},
	{
		event: 'emo_panel_pressed',
		ligaEvent: 'Social_ButtonClick_EmojiPanel',
	},
	{
		event: 'emoji_sent',
		ligaEvent: 'Social_ButtonClick_EmojiSend',
	},
	{
		event: 'pq_opened',
		ligaEvent: 'Chat_Quize_Open',
	},
	{
		event: 'pq_viewed',
		ligaEvent: 'Chat_Quize_View',
	},
	{
		event: 'pq_answer_sent',
		ligaEvent: 'Chat_Quize_AnswerSent',
	},
	{
		event: 'pq_closed',
		ligaEvent: 'Chat_Quize_Close',
	},
	{
		event: 'hl_viewed',
		ligaEvent: 'Chat_Highlight_View',
	},
	{
		event: 'hl_button_pressed',
		ligaEvent: 'Chat_Highlight_Click',
	},
	{
		event: 'bad_connection',
		ligaEvent: 'Chat_Stream_BadConnection',
	},
	{
		event: 'ff_stream_evaluated',
		ligaEvent: 'Chat_Stream_Evaluated',
	},
	{
		event: 'ff_stream_type_of_problem_selected',
		ligaEvent: 'Chat_Stream_TypeOfProblem',
	},
	{
		event: 'ff_stream_connection_tested',
		ligaEvent: 'Chat_Stream_ConnectionTested',
	},
	{
		event: 'ff_stream_success',
		ligaEvent: 'Chat_Stream_Success',
	},
	{
		event: 'ff_stream_closed',
		ligaEvent: 'Chat_Stream_Closed',
	},
	{
		event: 'message_reaction_sent',
		ligaEvent: 'Chat_Reaction_Click',
	},
	{
		event: 'sticker_button_pressed',
		ligaEvent: 'Chat_Sticker_Click',
	},
	{
		event: 'sticker_sent',
		ligaEvent: 'Chat_Sticker_Sent',
	},
	{
		event: 'message_sending_blocked_slowmode',
		ligaEvent: 'Chat_SlowMode_ClickBlocked',
	},
	{
		event: 'bot_request',
		ligaEvent: 'Chat_Bot_RequestSent',
	},
	{
		event: 'betshare_list_opened',
		ligaEvent: 'Chat_SharingBet_Open',
	},
	{
		event: 'betshare_list_bet_shared',
		ligaEvent: 'Chat_SharingBet_Send',
	},
	{
		event: 'betshare_chat_repeated',
		ligaEvent: 'Chat_SharingBet_Repeat',
	},
];

export {events};
