/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Bet, BetInfo} from 'models/bet';
import {BetStakeStatus, BetOrderStatusV1, BetTypesV1, BetTypesV2} from 'models/enums/Bet.enum';
import {FunctionComponent, useEffect, useState} from 'react';

import useL10n from 'l10n/useL10n';

import './bet.scss';
import Button from 'components/hoc/Button';
import classNames from 'classnames';
import modalServices from 'store/modalService';
import SocketIoServices from 'services/SocketIoServices';
import roomServices from 'store/roomService';
import appService from 'store/appService';
import useAnalytics from 'hooks/useAnalytics';
import OauthService from 'services/api/OauthService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {getDateBet} from 'utils/message';
import useAppData from 'hooks/useAppData';
import {ReactComponent as IcoStatus} from 'assets/svg/ico-bet-pending.svg';
import {ReactComponent as IcoStatusReturn} from 'assets/svg/ico-bet-pending-return.svg';
import {ReactComponent as IcoBetBuilder} from 'assets/svg/ico-bet-builder.svg';

import useBetStatus from 'hooks/useBetStatus';
import threadService from 'store/threadService';
import MessageType from 'models/enums/MessageType.enum';
import {textEnding} from 'utils/helpers';
import copybettingService from 'store/copybettingService';

interface IBetItem {
	bet: Bet;
	isShareBetModal?: boolean;
	myMessage?: boolean | null;
	userId?: number;
}
const BetItem: FunctionComponent<IBetItem> = function BetItem({
	bet,
	isShareBetModal,
	myMessage,
	userId,
}) {
	const {modals} = useL10n();
	const {copybettingVersion, appIcons, isStand, appEnableDecrypt, language, appEnableWidgetText} =
		useLocalObservable(() => appService);
	const {roomId, roomName} = useLocalObservable(() => roomServices);
	const {toggleShareBetModal, toggleWidgetPreviewModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {threadMessageId, currentThreadId} = useLocalObservable(() => threadService);
	const {setCurrentBetForShare} = useLocalObservable(() => copybettingService);

	const [betPositions, setBetPositions] = useState<BetInfo[]>([]);
	const [isShowMoreBtnVisible, setIsShowMoreBtnVisible] = useState(false);
	const [isOpened, setIsOpened] = useState(false);

	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {betOrderStatuses, betStakeStatuses} = useBetStatus();

	const {IcoChevron} = appIcons;

	const isVisibleShareBetBtnCopy = () => {
		return copybettingVersion === 1
			? !isShareBetModal &&
					!myMessage &&
					bet.status !== BetOrderStatusV1.WIN &&
					bet.status !== BetOrderStatusV1.LOSE
			: !isShareBetModal &&
					!myMessage &&
					betOrderStatuses.find(el => el.id === bet.status)?.isCopybetBtn &&
					!bet.positions.map(el => el.betBuilderStakes).some(element => element?.length);
	};

	const betTimesRepeated = bet.timesRepeated;

	const betStatusText = [
		{
			text: modals.shareBet.status.null,
			type: null,
		},
		{
			text: modals.shareBet.status.null,
			type: 'null',
		},
		{
			text: modals.shareBet.status.win,
			type: BetOrderStatusV1.WIN,
		},
		{
			text: modals.shareBet.status.lose,
			type: BetOrderStatusV1.LOSE,
		},
	];

	const betOrderClassesV1 = classNames('bet', {
		'share-bet__item': isShareBetModal,
		'bet--multi': bet.betType === BetTypesV1.EXPRESS,
		'bet--single': bet.betType === BetTypesV1.SINGLE,
		'bet--win': bet.status === BetOrderStatusV1.WIN,
		'bet--lose': bet.status === BetOrderStatusV1.LOSE,
		'bet--null': bet.status === null || bet.status === 'null',
	});

	const betOrderClassesV2 = classNames(`bet bet--v2`, {
		'share-bet__item': isShareBetModal,
	});

	const betOrderStatusClassesV2 = classNames(
		`bet__status bet__status--${betOrderStatuses.find(el => el.id === bet.status)?.color}`
	);

	const betStakeStatusClassesV2 = (eventStatus?: number) => {
		return classNames('bet__event', {
			'bet__event--lost': eventStatus === BetStakeStatus.Lost,
			'bet__event--lost-return': eventStatus === BetStakeStatus['Lost | Return'],
			'bet__event--won': eventStatus === BetStakeStatus.Won,
			'bet__event--won-return':
				eventStatus === BetStakeStatus['Won | Return'] ||
				eventStatus === BetStakeStatus['Won | Initial Return'],
			'bet__event--pending': eventStatus === BetStakeStatus.Pending,
			'bet__event--pending-return': eventStatus === BetStakeStatus['Pending | Initial Return'],
			'bet__event--canceled': eventStatus === BetStakeStatus.Canceled,
			'bet__event--return':
				eventStatus === BetStakeStatus.Return || eventStatus === BetStakeStatus['Initial Return'],
		});
	};

	const isReturnStatus = (item: BetInfo) => {
		return (
			item.status === BetStakeStatus['Pending | Initial Return'] ||
			item.status === BetStakeStatus['Lost | Return'] ||
			item.status === BetStakeStatus['Won | Initial Return'] ||
			item.status === BetStakeStatus['Won | Return']
		);
	};

	const shareClickHandler = () => {
		sendAnalytics('betshare_list_bet_shared', {
			bet,
			event_id: roomId,
			chat_event_name: roomName || '',
			bets_id: bet.positions.map(el => el.eventId).toString(),
		});
		if (appEnableWidgetText) {
			setCurrentBetForShare(bet);
			toggleWidgetPreviewModalVisible(true);
			toggleShareBetModal(false);
			return;
		}

		if (threadMessageId && currentThreadId && currentThreadId === 'new') {
			SocketIoServices.createThread({threadMessageId, messageType: MessageType.BET, bet});
			toggleShareBetModal(false);
			return;
		}

		if (roomId) {
			SocketIoServices.emitShareBet({
				externalRoomId: currentThreadId || roomId,
				bet,
			});
			toggleShareBetModal(false);
		}
	};

	const showMoreClickHandler = () => {
		bet.positions && setBetPositions(bet.positions);
		setIsShowMoreBtnVisible(false);
		setIsOpened(true);
	};

	const getDecryptedUserId = async () => {
		if (userId) {
			const decryptResponse = await OauthService.getDecryptedUserId(userId);
			if (decryptResponse.status === ResponseStatus.SUCCESS) {
				const {result, decrypted} = decryptResponse.data;
				sendAnalytics('betshare_chat_repeated', {
					profile_user_id: decrypted,
					bet,
					event_id: roomId,
					chat_event_name: roomName || '',
					bets_id: bet.positions.map(el => el.eventId).toString(),
				});
			}
		}
	};

	const copyBetClickHandler = () => {
		const betParams = bet.positions.map(el => {
			const {eventId, eventTitle, outcomeId} = el;
			return {eventId, eventTitle, outcomeId};
		});
		const {barcode} = bet;
		const msg = {
			type: 'betRepeat',
			body: {
				action: 'share',
				data: !isStand ? {betParams, barcode} : {bet},
			},
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
		} else if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
		} else window.parent.postMessage(msg, '*');

		if (appEnableDecrypt) {
			getDecryptedUserId();
			return;
		}
		sendAnalytics('betshare_chat_repeated', {
			profile_user_id: userId,
			bet,
			event_id: roomId,
			chat_event_name: roomName || '',
			bets_id: bet.positions.map(el => el.eventId).toString(),
		});
	};

	const renderBetDate = (date: string) => {
		return <div className='bet__date'>{getDateBet(date)}</div>;
	};

	useEffect(() => {
		if (bet.positions) {
			if (bet.positions.length > 3 && !isOpened) {
				setBetPositions(bet.positions.filter((el, index) => index < 3));
				!isShowMoreBtnVisible && setIsShowMoreBtnVisible(true);
				return;
			}
			setBetPositions(bet.positions);
		}
	}, [bet]);

	const renderBetInfo = (item: BetInfo, index: number) => (
		<div key={`${index}${item.eventId}`}>
			{/* copybetting v1 */}
			{copybettingVersion === 1 && (
				<div className='bet__event'>
					<div className='bet__event-outcome'>
						<p className='bet__event-outcome-title'>
							{item.outcomeGroupTitle} {item.outcomeTitle} {item.additionalValue} ({item.blockTitle}
							)
						</p>
						<div className='bet__event-outcome-factor'>{item.factorValue}</div>
					</div>
					<p className='bet__event-title'>{item.eventTitle} </p>
				</div>
			)}

			{/* copybetting v2 */}
			{copybettingVersion === 2 && (
				<div className={betStakeStatusClassesV2(item.status)}>
					<div className='bet__event-line'>
						<div className='bet__event-outcome'>
							<p className='bet__event-outcome-title'>{item.eventTitle}</p>
						</div>

						<div className='bet__event-info'>
							{item.betBuilderStakes?.length && item.betBuilderStakes?.length > 0 ? (
								<div className='bet__event-bet-builder'>
									<div className='bet__event-bet-builder-head'>
										<IcoBetBuilder className='bet__event-bet-builder-ico' />
										<p>{`Bet Builder (${item.betBuilderStakes.length})`}</p>
									</div>
									<div className='bet__event-bet-builder-list'>
										{item.betBuilderStakes.map((el, ind) => {
											return (
												<div key={ind} className='bet__event-bet-builder-item'>
													<span className='bet__event-bet-builder-period'>{el.periodName} </span>
													<span className='bet__event-bet-builder-group'>
														{el.outcomeGroupTitle}
													</span>
													{el.outcomeTitle && (
														<span className='bet__event-bet-builder-title'>
															{' '}
															{el.outcomeTitle}{' '}
														</span>
													)}
													{el.argument && (
														<span className='bet__event-bet-builder-argument'>({el.argument})</span>
													)}
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<p className='bet__event-title'>
									{item.outcomeGroupTitle} {item.outcomeTitle} {item.additionalValue}
									{item.blockTitle && <span>({item.blockTitle})</span>}
								</p>
							)}

							<div className='bet__event-outcome-factor'>{item.factorValue}</div>
						</div>

						{/* bet event statuses */}
						{isReturnStatus(item) ? (
							<IcoStatusReturn className='bet__event-status-ico' />
						) : (
							<IcoStatus className='bet__event-status-ico' />
						)}

						<div className='bet__event-status'>
							{item.status &&
								typeof item.status === 'number' &&
								betStakeStatuses.find(el => el.id === item.status)?.stakeStatus}
						</div>

						{!item.status && (
							<div className='bet__event-info'>
								<p className='bet__event-title'>
									{item.outcomeGroupTitle} {item.outcomeTitle} {item.additionalValue} (
									{item.blockTitle})
								</p>
								<div className='bet__event-outcome-factor'>{item.factorValue}</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);

	return (
		<div className={copybettingVersion === 1 ? betOrderClassesV1 : betOrderClassesV2}>
			{/* copybetting v1 */}
			{copybettingVersion === 1 && (
				<div className='bet__heading'>
					<div className='bet__heading-info'>
						<p className='bet__status'>{betStatusText.find(el => el.type === bet.status)?.text}</p>
						<div className='bet__title'>
							{bet.betType?.toUpperCase() === BetTypesV1.EXPRESS &&
								modals.shareBet.betTypeV1.express}
							{bet.betType?.toUpperCase() === BetTypesV1.SINGLE && modals.shareBet.betTypeV1.single}
							{bet.betType?.toUpperCase() === BetTypesV1.EXPRESS && (
								<span>
									. {bet.positions?.length} {modals.shareBet.bet}
								</span>
							)}
						</div>
					</div>
					<div className='bet__heading-right'>
						{renderBetDate(bet.orderDate)}
						{bet.betType === BetTypesV1.EXPRESS && (
							<div className='bet__total-factor'>
								{modals.shareBet.totalFactor}: <b>{bet.totalFactor}</b>
							</div>
						)}
					</div>
				</div>
			)}

			{/* copybetting v2 */}
			{copybettingVersion === 2 && (
				<div className='bet__heading'>
					<div className='bet__heading-left'>
						<div className='bet__heading-info'>
							<div className='bet__title'>
								{bet.betType === BetTypesV2.MULTI &&
									`${modals.shareBet.betTypeV2.multi} (${bet.positions.length})`}
								{bet.betType === BetTypesV2.SINGLE && modals.shareBet.betTypeV2.single}
								{bet.betType === BetTypesV2.SYSTEM && modals.shareBet.betTypeV2.system}
							</div>
							{bet.status && typeof bet.status === 'number' && (
								<p className={betOrderStatusClassesV2}>
									{betOrderStatuses.find(el => el.id === bet.status)?.orderStatus}
								</p>
							)}
						</div>
						{renderBetDate(bet.orderDate)}
					</div>
					<div className='bet__heading-right'>
						<div className='bet__total-factor'>
							<b>{bet.totalFactor}</b>
						</div>
					</div>
				</div>
			)}

			<div className='bet__content'>
				<div className='bet__events'>
					{betPositions.map(renderBetInfo)}
					{isShowMoreBtnVisible && !isOpened && (
						<Button className='bet__events-btn' onClick={showMoreClickHandler}>
							{modals.shareBet.showMore} {bet.positions.length - 3}
							{copybettingVersion === 2 && (
								<span className='bet__events-btn-ico'>{getAppIcon(IcoChevron.pic)}</span>
							)}
						</Button>
					)}
				</div>

				{(isShareBetModal || isVisibleShareBetBtnCopy()) && (
					<div className='bet__bottom'>
						{isShareBetModal && (
							<Button className='btn btn--sm' onClick={shareClickHandler}>
								{modals.shareBet.btns.share}
							</Button>
						)}
						{isVisibleShareBetBtnCopy() && (
							<Button className='btn btn--sm' onClick={copyBetClickHandler}>
								{modals.shareBet.btns.copy}
							</Button>
						)}
					</div>
				)}

				{betTimesRepeated && betTimesRepeated > 0 ? (
					<div className='bet__time-repeated'>
						<span>{modals.shareBet.betRepeated}: </span>
						{betTimesRepeated}{' '}
						{language === 'ru' ? textEnding(betTimesRepeated, modals.shareBet.times) : ''}
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default observer(BetItem);
