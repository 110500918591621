/* eslint-disable react/no-danger */
/* eslint-disable max-lines */
import {times} from 'data/slowModeData';
import {
	MAX_TEXTAREA_LENGTH,
	MAX_TEXTAREA_LENGTH_FOR_MODERATOR,
	MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
	MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR,
	MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT,
	START_NUMBER_OF_MESSAGES,
} from 'constants/constants';
import checkRealTextLength from 'utils/checkRealTextLength';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import {ActionType} from 'models/enums/ActionType.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import ToasNames from 'models/enums/ToasNames.enum';
import appService from 'store/appService';
import roomService from 'store/roomService';
import userService from 'store/userService';
import chatService from 'store/chatService';
import toastService from 'store/toastService';
import controlPanelService from 'store/controlPanelService';
import actionMessageService from 'store/actionMessageService';
import linkPreviewService from 'store/linkPreviewService';
import stickersEmojiService from 'store/stickersEmojiService';
import slowModeService from 'store/slowModeService';
import suggestService from 'store/suggestService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useLinkPreview from 'hooks/useLinkPreview';
import useAntimat from 'hooks/useAntimat';
import useChatActions from 'hooks/useChatActions';
import useAppData from 'hooks/useAppData';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isDesktop, isMobile} from 'react-device-detect';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import Tooltip from 'components/tooltip/Tooltip';
import {Field} from 'components/hoc';
import Button from 'components/hoc/Button';
import useUser from 'hooks/useUser';
import {ToastMessageColor} from 'components/toasts/Toast';
import {isValidUrl} from 'utils/checkValidLink';
import {isPhoneNumber} from 'utils/checkPhoneNumber';
import threadService from 'store/threadService';
import modalService from 'store/modalService';
import {qs} from 'utils/helpers';
import useMessage from 'hooks/useMessage';
import attachService from 'store/attachService';
import MessageType from 'models/enums/MessageType.enum';
import containsOnlyOneEmojiSymbol from 'utils/checkIfOnlyOneEmoji';
import useStickers from 'hooks/useStickers';
import copybettingService from 'store/copybettingService';
import gambleService from 'store/gambleService';

const LINE_HEIGHT = 22; // px
const PADDING_VERTICAL = 12; // px
const BORDER_WIDTH = 2; // px
const TIME_FOR_TIMER_SLOWMODE = 1000; // ms
const DELAY_FOR_SEND_MESSAGE = 500; // ms

interface IChatTextareaProps {
	visibleButtons: boolean;
}

const ChatTextarea: React.FC<IChatTextareaProps> = function ChatTextarea(props) {
	const textInputRef = useRef<HTMLTextAreaElement>(null);
	const blockingSendMessageEnterKeyRef = useRef(false);
	const maxLengthWarningTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const slowModeTimeLeftTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const sendMessageKeyPressTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const referenceSlowMode = useRef<HTMLDivElement>(null);

	const {appReadOnly, language, isInternernetActive, appIcons, enableLinksBlock} =
		useLocalObservable(() => appService);
	const {currentThreadId, threadMessageId} = useLocalObservable(() => threadService);
	const {
		roomId,
		myTalker,
		clearChatTextarea,
		toggleClearChatTextarea,
		previousMessagesMode,
		togglePreviousMessagesMode,
		removeUnrecordedMessages,
	} = useLocalObservable(() => (currentThreadId ? threadService : roomService));
	const {isUserExternalIdCorrect, userData} = useLocalObservable(() => userService);
	const {
		textareaValue,
		visibleTextareaSendBtn,
		chatBot,
		setTextareaValue,
		setVisibleTextareaSendBtn,
		blockedBtnSend,
		setBlockedBtnSend,
	} = useLocalObservable(() => chatService);
	const {addToast, toasts, deleteToastByMessage} = useLocalObservable(() => toastService);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelService);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage, actionData} = useLocalObservable(
		() => actionMessageService
	);
	const {linkPreviewData, setLinkPreviewData, toggleIsVisibleLinkPreviewData} = useLocalObservable(
		() => linkPreviewService
	);
	const {visibleStickersEmoji, togglleVisibleStickersEmoji} = useLocalObservable(
		() => stickersEmojiService
	);
	const {files, setIsTyping} = useLocalObservable(() => attachService);

	const {
		slowMode,
		slowModeTimeLeft,
		slowModeTimeLeftOffset,
		slowModeCountdownEnable,
		setSlowModeTimeLeft,
		setslowModeTimeLeftOffset,
		toggleSlowModeCountdownEnable,
	} = useLocalObservable(() => slowModeService);
	const {visibleSuggest, toggleVisibleSuggest} = useLocalObservable(() => suggestService);
	const {
		visibleThreadModal,
		imagePreviewModalVisible,
		toggleImagePreviewModalVisible,
		toggleWidgetPreviewModalVisible,
		setTextareaHeight,
	} = useLocalObservable(() => modalService);
	const {setSuggestStickers, toggleVisibleSuggestSticker} = useLocalObservable(
		() => suggestService
	);
	const {currentBetForShare, setCurrentBetForShare} = useLocalObservable(() => copybettingService);
	const {currentGambleForShare, setCurrentGambleForShare} = useLocalObservable(() => gambleService);

	const [hasLinkPreview, setHasLinkPreview] = useState(false);
	const [maxLengthWarning, setMaxLengthWarning] = useState(false);
	const [maxLengthData, setMaxLengthData] = useState({
		characters: MAX_TEXTAREA_LENGTH,
		visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
	});
	const [slowModeTooltip, setSlowModeTooltip] = useState(false);
	const [sendAnalyticsSlowModeEnable, setSendAnalyticsSlowModeEnable] = useState(true);

	const {chatScreen, toasts: toastsTranslate, tooltips, attach} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getMetadata, abortGetMetadata} = useLinkPreview();
	const {containsMat} = useAntimat();
	const {getMessagesWithServices} = useChatActions();
	const {getAppIcon} = useAppData();
	const {userExtraDataCheckChatBotRules} = useUser();
	const {sendPicMessage} = useMessage();
	const {findStickerByEmoji} = useStickers();

	const {IcoSendMessage, IcoStickers, IcoKeyboard} = appIcons;

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const textTrim = textareaValue?.trim() || '';
	const realTextLength = checkRealTextLength(textareaValue || '');

	const isBanned =
		(currentThreadId &&
			(!!threadService.myTalker?.bans?.length ||
				userData?.bans?.length ||
				!!roomService.myTalker?.bans?.length)) ||
		(!currentThreadId && (!!roomService.myTalker?.bans?.length || userData?.bans?.length));

	const clearMaxLengthWarningTimeout = () => {
		if (maxLengthWarningTimeoutRef.current) {
			clearTimeout(maxLengthWarningTimeoutRef.current);
		}
	};

	const abortAndClearLinkPreview = () => {
		abortGetMetadata();
		toggleIsVisibleLinkPreviewData(false, true);
	};

	const findLinks = async (incomingText: string) => {
		const arrayFoundLinks = incomingText.match(/\bhttp(s?):\/\/\S+/gi);
		if (arrayFoundLinks?.length && arrayFoundLinks.length > 0) {
			const response = await getMetadata(arrayFoundLinks[0]);
			if (response) {
				if (linkPreviewData?.title !== response.url) {
					setLinkPreviewData({
						title: response.url || response.siteName,
						text: response.description,
					});
					toggleIsVisibleLinkPreviewData(true);
				}
				return;
			}
		}
		abortAndClearLinkPreview();
	};

	const slowModeCountdown = () => {
		let timeLeft = slowModeTimeLeft;
		if (slowModeTimeLeft - slowModeTimeLeftOffset - TIME_FOR_TIMER_SLOWMODE > 0) {
			timeLeft = slowModeTimeLeft - slowModeTimeLeftOffset;
		}

		if (slowModeTimeLeftTimeoutRef.current) {
			clearInterval(slowModeTimeLeftTimeoutRef.current);
		}

		slowModeTimeLeftTimeoutRef.current = setInterval(() => {
			const calcTimeLeft = timeLeft - TIME_FOR_TIMER_SLOWMODE;
			if (calcTimeLeft >= TIME_FOR_TIMER_SLOWMODE) {
				timeLeft = calcTimeLeft;
				setSlowModeTimeLeft(calcTimeLeft);
				return;
			}

			setSlowModeTimeLeft(0);
			setslowModeTimeLeftOffset(0);
			toggleSlowModeCountdownEnable(false);
			setSendAnalyticsSlowModeEnable(true);

			if (slowModeTimeLeftTimeoutRef.current) {
				clearInterval(slowModeTimeLeftTimeoutRef.current);
			}
		}, TIME_FOR_TIMER_SLOWMODE);
	};

	const slowModeConvertTime = (time: number) => {
		const minutes = Math.floor(time / 60000);
		const seconds = ((time % 60000) / 1000).toFixed(0);
		// const minutesCorrect = `00${minutes}`.substring(minutes.toString().length);
		const secondsCorrect = `00${seconds}`.substring(seconds.toString().length);
		return minutes > 0 ? `${minutes}:${secondsCorrect}` : secondsCorrect;
	};

	const suggestManagement = (incomingValue: string) => {
		if (chatBot && chatBot.isActive) {
			if (incomingValue.length === 1 && /^@/.test(incomingValue) && !visibleSuggest) {
				toggleVisibleSuggest(true);
			}
			if (
				!incomingValue.length ||
				(incomingValue.length && !/^@/.test(incomingValue)) ||
				(`@${chatBot.name}` === incomingValue && visibleSuggest) ||
				(/^@/.test(incomingValue) && !`@${chatBot.name}`.includes(incomingValue))
			) {
				toggleVisibleSuggest(false);
			}

			return;
		}

		toggleVisibleSuggest(false);
	};

	const checkContactingChatbot = (textInputValue: string) => {
		const textInputValueTrim = textInputValue.trim();
		const textInputValueSplit = textInputValueTrim.split(' ');
		return (
			(/^@/.test(textInputValueTrim) &&
				textInputValueSplit.length === 1 &&
				chatBot &&
				`@${chatBot.name}` === textInputValueTrim) ||
			(/^@/.test(textInputValueTrim) &&
				textInputValueSplit.length > 1 &&
				chatBot &&
				!chatBot.isActive &&
				textInputValueSplit[0] === `@${chatBot.name}`)
		);
	};

	const textForSlowModeTooltip = () => {
		let findtTime = times.find(item => item.time === slowMode.local.time);
		if (slowMode.global.enable && !slowMode.local.enable) {
			findtTime = times.find(item => item.time === slowMode.global.time);
		}
		if (findtTime) {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				findtTime.title,
				language,
				{default: findtTime.title.ru.default}
			);
			return tooltips.slowMode.btnSendMessage(findTitle.default);
		}
		return '';
	};

	const checkMessageSentStatus = useCallback(
		debounce(
			async (
				value: string,
				isVisibleObsenitiesToastWarning,
				isVisibleLinksToastWarning,
				isVisiblePhoneToastWarning
			) => {
				if (value.trim()) {
					if (!myTalker?.isModer && !userData?.isModer) {
						if (isPhoneNumber(value)) {
							setBlockedBtnSend(true);
							if (!isVisiblePhoneToastWarning) {
								setBlockedBtnSend(true);
								setVisibleTextareaSendBtn(true);
								addToast({
									name: ToasNames.PHONEPROHIBITED,
									iconName: ToastIconsName.brickRed,
									liveTime: 3000,
									message: toastsTranslate.phoneProhibited,
									messageColor: ToastMessageColor.ERROR,
									cancellable: true,
								});
							}
							return;
						}
					}

					if (enableLinksBlock) {
						if (!myTalker?.isModer && !userData?.isModer) {
							if (isValidUrl(value)) {
								setBlockedBtnSend(true);
								setVisibleTextareaSendBtn(true);

								if (!isVisibleLinksToastWarning) {
									setBlockedBtnSend(true);
									addToast({
										name: ToasNames.LINKPROHIBITED,
										iconName: ToastIconsName.brickRed,
										liveTime: 3000,
										message: toastsTranslate.linkProhibited,
										messageColor: ToastMessageColor.ERROR,
										cancellable: true,
									});
								}
								return;
							}
						}
					}

					const {status, message, isVailable} = await containsMat(value.trim(), language, () =>
						setBlockedBtnSend(true)
					);
					if (
						status === ResponseStatus.SUCCESS &&
						textInputRef.current &&
						textInputRef.current.value
					) {
						if (!checkContactingChatbot(value)) {
							if (message) {
								setVisibleTextareaSendBtn(true);
								if (myTalker?.isModer || userData?.isModer) {
									findLinks(message);
								}
							} else if (!files.filter(el => el.isChecked).length) {
								setVisibleTextareaSendBtn(false);
								if (linkPreviewData) {
									abortAndClearLinkPreview();
								}
							}

							if (!isVailable) {
								if (!isVisibleObsenitiesToastWarning) {
									setBlockedBtnSend(true);
									addToast({
										name: ToasNames.OBSCENITIES,
										iconName: ToastIconsName.brickRed,
										liveTime: 3000,
										message: toastsTranslate.obscenities,
										messageColor: ToastMessageColor.ERROR,
										cancellable: true,
									});
								}

								return;
							}

							if (isVailable) {
								setBlockedBtnSend(false);
								deleteToastByMessage(toastsTranslate.obscenities);
							}

							return;
						}
					}
				}

				if (
					!files.filter(el => el.isChecked).length &&
					!currentBetForShare &&
					!currentGambleForShare
				) {
					setVisibleTextareaSendBtn(false);
					setBlockedBtnSend(false);
				} else setBlockedBtnSend(false);
			},
			100,
			{leading: false}
		),
		[myTalker, linkPreviewData, language, chatBot, files, currentBetForShare, currentGambleForShare]
	);

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {value} = e.target;

		const unified = value.codePointAt(0)?.toString(16);
		if (!containsOnlyOneEmojiSymbol(value)) {
			setSuggestStickers([]);
			toggleVisibleSuggestSticker(false);
		} else if (unified) {
			const foundStickers = findStickerByEmoji(unified);
			if (foundStickers.length && !currentBetForShare && !currentGambleForShare) {
				setSuggestStickers([...foundStickers]);
				toggleVisibleSuggestSticker(true);
			}
		}
		setTextareaValue(value);
		suggestManagement(value);

		checkMessageSentStatus(
			value,
			!!toasts.find(t => t.name && t.name === ToasNames.OBSCENITIES),
			!!toasts.find(t => t.name && t.name === ToasNames.LINKPROHIBITED),
			!!toasts.find(t => t.name && t.name === ToasNames.PHONEPROHIBITED)
		);
	};

	const onBlurHandler = () => {
		setIsTyping(false);
		if (!textTrim && !isBanned) toggleVisibleButtons(true);
	};

	const onFocusHandler = () => {
		setIsTyping(true);
		let checkScrollEnd = false;
		const messagesScroll = document.querySelector('.chat__axis-y');

		if (messagesScroll) {
			checkScrollEnd =
				messagesScroll.scrollTop + messagesScroll.clientHeight === messagesScroll.scrollHeight;
		}

		toggleVisibleButtons(false);

		if (!isVisibleActionMessage) {
			setTimeout(() => {
				if (messagesScroll && checkScrollEnd) {
					messagesScroll.scroll({top: messagesScroll.scrollHeight});
				}
			}, 200);
		}

		if (isMobile && visibleStickersEmoji) {
			togglleVisibleStickersEmoji(false);
		}
	};

	const onInputHandler = () => {
		if (textInputRef.current) {
			textInputRef.current.style.height = 'auto';
			textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
		}
	};

	const resetPreviousState = () => {
		togglePreviousMessagesMode(false);
		removeUnrecordedMessages();
		getMessagesWithServices(START_NUMBER_OF_MESSAGES);
	};

	const createThread = () => {
		if (threadMessageId) {
			if (files.filter(el => el.isChecked).length) {
				SocketIoServices.createThread({
					threadMessageId,
					text: textTrim,
					pics: files
						.filter(el => el.isChecked)
						.map(el => el.file)
						.map(file => {
							return {name: file?.name, mimtype: file?.type, buffer: file};
						}),
					messageType: MessageType.PIC,
				});
			} else
				SocketIoServices.createThread({
					threadMessageId,
					text: textTrim,
				});
		}
		toggleImagePreviewModalVisible(false);
		setTextareaValue('');
		abortAndClearLinkPreview();
		toggleVisibleSuggest(false);
		setVisibleTextareaSendBtn(false);
		setBlockedBtnSend(false);
		if (textInputRef.current) {
			textInputRef.current.style.height = `${LINE_HEIGHT + PADDING_VERTICAL + BORDER_WIDTH}px`;
		}
		if (qs('.chat__textarea-item'))
			qs('.chat__textarea-item').style.height = `${
				LINE_HEIGHT + PADDING_VERTICAL + BORDER_WIDTH
			}px`;
	};

	const sendPicMessageOnly = () => {
		if (actionData) {
			const {type, message} = actionData;

			if (type === ActionType.REPLAY) {
				sendPicMessage({
					text: textTrim,
					externalRoomId: currentThreadId || roomId,
					hasPreview: hasLinkPreview,
					mentionMessageId: message.id,
				});
			}

			toggleIsVisibleActionMessage(false, true);
		} else {
			sendPicMessage({
				text: textTrim,
				externalRoomId: currentThreadId || roomId,
				hasPreview: hasLinkPreview,
			});
		}
	};

	const sendMessageWithText = () => {
		if (roomId)
			if (actionData) {
				const {type, message} = actionData;

				if (type === ActionType.REPLAY) {
					if (imagePreviewModalVisible && files.filter(el => el.isChecked).length) {
						sendPicMessage({
							text: textTrim,
							externalRoomId: currentThreadId || roomId,
							hasPreview: hasLinkPreview,
							mentionMessageId: message.id,
						});
					} else
						SocketIoServices.emitMessage({
							text: textTrim,
							externalRoomId: currentThreadId || roomId,
							mentionMessageId: message.id,
							hasPreview: hasLinkPreview,
						});
				}

				if (type === ActionType.EDIT) {
					sendAnalytics('message_changed');
					SocketIoServices.emitEditMessage({
						messageId: message.id,
						text: textTrim,
						externalRoomId: currentThreadId || roomId,
						hasPreview: hasLinkPreview,
					});
				}

				toggleIsVisibleActionMessage(false, true);
			} else {
				if (
					userData &&
					chatBot &&
					chatBot.isActive &&
					/^@/.test(textTrim) &&
					textTrim.split(' ')[0] === `@${chatBot.name}`
				) {
					userExtraDataCheckChatBotRules(userData);
				}

				if (currentBetForShare) {
					SocketIoServices.emitShareBet({
						externalRoomId: currentThreadId || roomId,
						bet: currentBetForShare,
						text: textTrim,
						hasPreview: hasLinkPreview,
					});
					toggleWidgetPreviewModalVisible(false);
					setCurrentBetForShare(null);
					return;
				}
				if (currentGambleForShare) {
					SocketIoServices.emitShareGamble({
						externalRoomId: currentThreadId || roomId,
						gamble: currentGambleForShare,
						text: textTrim,
						hasPreview: hasLinkPreview,
					});
					toggleWidgetPreviewModalVisible(false);
					setCurrentGambleForShare(null);
					return;
				}
				if (imagePreviewModalVisible && files.filter(el => el.isChecked).length) {
					sendPicMessage({
						text: textTrim,
						externalRoomId: currentThreadId || roomId,
						hasPreview: hasLinkPreview,
					});
					return;
				}
				SocketIoServices.emitMessage({
					text: textTrim,
					externalRoomId: currentThreadId || roomId,
					hasPreview: hasLinkPreview,
				});
			}
	};

	const refreshState = () => {
		setTextareaValue('');
		abortAndClearLinkPreview();
		toggleVisibleSuggest(false);
		toggleVisibleSuggestSticker(false);
		setVisibleTextareaSendBtn(false);
		setBlockedBtnSend(false);

		if (imagePreviewModalVisible) {
			toggleImagePreviewModalVisible(false);
		}

		if (!isMyTalkerModer && (slowMode.global.enable || slowMode.local.enable)) {
			setSlowModeTimeLeft(slowMode.local.enable ? slowMode.local.time : slowMode.global.time);
			toggleSlowModeCountdownEnable(true);
		}

		if (textInputRef.current) {
			textInputRef.current.style.height = `${LINE_HEIGHT + PADDING_VERTICAL + BORDER_WIDTH}px`;
			if (qs('.chat__textarea-item'))
				qs('.chat__textarea-item').style.height = `${
					LINE_HEIGHT + PADDING_VERTICAL + BORDER_WIDTH
				}px`;
			toggleVisibleButtons(true);
		}
	};

	const sendMessageToSocket = async () => {
		if (!appReadOnly && isUserExternalIdCorrect && isInternernetActive) {
			setBlockedBtnSend(true);
			const {isVailable}: any = await containsMat(textTrim, language);

			if ((textTrim && isVailable) || files.filter(el => el.file).length) {
				if (threadMessageId && visibleThreadModal && currentThreadId === 'new') {
					createThread();
					return;
				}
			}

			const picMessageOnly = imagePreviewModalVisible && files.filter(el => el.isChecked).length;

			if (roomId && !textTrim && picMessageOnly) {
				if (previousMessagesMode) resetPreviousState();
				sendPicMessageOnly();
				refreshState();
			}

			if (roomId && !textTrim && currentBetForShare) {
				if (previousMessagesMode) resetPreviousState();
				SocketIoServices.emitShareBet({
					externalRoomId: currentThreadId || roomId,
					bet: currentBetForShare,
					hasPreview: hasLinkPreview,
				});
				toggleWidgetPreviewModalVisible(false);
				setCurrentBetForShare(null);
				refreshState();
			}

			if (roomId && !textTrim && currentGambleForShare) {
				if (previousMessagesMode) resetPreviousState();
				SocketIoServices.emitShareGamble({
					externalRoomId: currentThreadId || roomId,
					gamble: currentGambleForShare,
					hasPreview: hasLinkPreview,
				});
				toggleWidgetPreviewModalVisible(false);
				setCurrentBetForShare(null);
				refreshState();
			}

			if (roomId && textTrim && isVailable) {
				if (previousMessagesMode) resetPreviousState();
				sendMessageWithText();
				refreshState();
			}
		}
	};

	const debounceSendMessageToSocket = debounce(sendMessageToSocket, DELAY_FOR_SEND_MESSAGE, {
		leading: true,
		trailing: false,
	});

	const onCheckKeyUp = () => {
		if (realTextLength >= maxLengthData.characters && !props.visibleButtons && !maxLengthWarning) {
			setMaxLengthWarning(true);
			clearMaxLengthWarningTimeout();
			maxLengthWarningTimeoutRef.current = setTimeout(() => {
				setMaxLengthWarning(false);
			}, MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT);
		}
	};

	const onCheckKeyPress = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey && isDesktop) {
			e.preventDefault();
			if (
				!appReadOnly &&
				isUserExternalIdCorrect &&
				!blockedBtnSend &&
				!blockingSendMessageEnterKeyRef.current &&
				!checkContactingChatbot(e.currentTarget.value) &&
				((!slowMode.global.enable && !slowMode.local.enable) ||
					((slowMode.global.enable || slowMode.local.enable) && !slowModeCountdownEnable))
			) {
				blockingSendMessageEnterKeyRef.current = true;
				await debounceSendMessageToSocket();
				sendMessageKeyPressTimeoutRef.current = setTimeout(() => {
					blockingSendMessageEnterKeyRef.current = false;
				}, DELAY_FOR_SEND_MESSAGE);
			}
		}
	};

	const onTogglleVisibleStickersEmojiHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			if (visibleStickersEmoji) {
				textInputRef.current?.focus();
			} else {
				sendAnalytics('sticker_button_pressed');
			}
			togglleVisibleStickersEmoji(!visibleStickersEmoji);
		}
	};

	useEffect(() => {
		if (
			!files.filter(el => el.isChecked).length &&
			!textTrim &&
			!currentBetForShare &&
			!currentGambleForShare
		) {
			setBlockedBtnSend(true);
			setVisibleTextareaSendBtn(false);
		} else {
			setBlockedBtnSend(false);
			setVisibleTextareaSendBtn(true);
		}
	}, [files, currentBetForShare, currentGambleForShare]);

	useEffect(() => {
		return () => {
			clearMaxLengthWarningTimeout();
			if (slowModeTimeLeftTimeoutRef.current) {
				setSlowModeTimeLeft(0);
				setslowModeTimeLeftOffset(0);
				toggleSlowModeCountdownEnable(false);
				clearInterval(slowModeTimeLeftTimeoutRef.current);
			}
			if (sendMessageKeyPressTimeoutRef.current) {
				clearTimeout(sendMessageKeyPressTimeoutRef.current);
			}
			if (maxLengthWarningTimeoutRef.current) {
				clearTimeout(maxLengthWarningTimeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (imagePreviewModalVisible) setVisibleTextareaSendBtn(true);
	}, [imagePreviewModalVisible]);

	useEffect(() => {
		const textInputRefCurrent = textInputRef.current;
		if (textInputRefCurrent && textInputRefCurrent.value.length > 0) {
			if (!props.visibleButtons) {
				textInputRefCurrent.removeAttribute('style');
				textInputRefCurrent.setAttribute('style', `height:${textInputRefCurrent.scrollHeight}px;`);
				return;
			}
			textInputRefCurrent.removeAttribute('style');
		}
	}, [textTrim, props.visibleButtons]);

	useEffect(() => {
		if (actionData) {
			const {type, message} = actionData;

			if (type === ActionType.EDIT) {
				setTextareaValue(message.text);
			}

			toggleVisibleButtons(false);
		}
	}, [actionData]);

	useEffect(() => {
		if (linkPreviewData) {
			setHasLinkPreview(true);
		} else {
			setHasLinkPreview(false);
			if (textTrim && textInputRef.current) {
				textInputRef.current.focus();
			}
		}
	}, [linkPreviewData]);

	useEffect(() => {
		if (clearChatTextarea) {
			if (!myTalker?.bans?.length) {
				setTextareaValue('');
			}
			toggleClearChatTextarea(false);
			toggleVisibleSuggest(false);
			abortAndClearLinkPreview();
			if (!files.filter(el => el.isChecked).length) setVisibleTextareaSendBtn(false);
			textInputRef.current?.removeAttribute('style');
		}
	}, [clearChatTextarea, files]);

	useEffect(() => {
		if (textareaValue.length > 0 && !(textareaValue.length === 1 && /^@/.test(textareaValue))) {
			setVisibleTextareaSendBtn(true);
		}
	}, [myTalker?.bans?.length]);

	useEffect(() => {
		if (myTalker) {
			if (myTalker.isModer || userData?.isModer) {
				setMaxLengthData({
					characters: MAX_TEXTAREA_LENGTH_FOR_MODERATOR,
					visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR,
				});
				return;
			}
			if (textareaValue.length > MAX_TEXTAREA_LENGTH) {
				setTextareaValue(textareaValue.slice(0, MAX_TEXTAREA_LENGTH));
			}
			setMaxLengthData({
				characters: MAX_TEXTAREA_LENGTH,
				visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
			});
		}
	}, [myTalker]);

	useEffect(() => {
		if (
			!isMyTalkerModer &&
			(slowMode.global.enable || slowMode.local.enable) &&
			slowModeCountdownEnable
		) {
			slowModeCountdown();
		}
	}, [myTalker, slowMode, slowModeCountdownEnable]);

	useEffect(() => {
		if (!slowMode.global.enable && !slowMode.local.enable && slowModeTimeLeftTimeoutRef.current) {
			setSlowModeTimeLeft(0);
			setslowModeTimeLeftOffset(0);
			toggleSlowModeCountdownEnable(false);
			clearInterval(slowModeTimeLeftTimeoutRef.current);
		}
	}, [slowMode, slowModeTimeLeftTimeoutRef.current]);

	useEffect(() => {
		const textarea = textInputRef.current;
		if (!textarea) return;

		const resizeObserver = new ResizeObserver(() => {
			const height = textarea.clientHeight;

			if (setTextareaHeight) {
				setTextareaHeight(height);
			}
		});

		resizeObserver.observe(textarea);

		// eslint-disable-next-line consistent-return
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const chatTextareaWrap = classNames('chat__textarea-wrap', {
		'chat__textarea-wrap--warning': maxLengthWarning,
	});

	const chatTextareaStickersEmojiBtnClasses = classNames('chat__textarea-stickers-emoji-btn', {
		'chat__textarea-stickers-emoji-btn--upper':
			realTextLength > maxLengthData.visivbeCounter && !props.visibleButtons,
	});

	const textareaClass = classNames('chat__textarea-item', {
		'chat__textarea-item--wtext': realTextLength > maxLengthData.visivbeCounter,
	});

	const renderTextLength = useCallback(() => {
		if (realTextLength > maxLengthData.visivbeCounter && !props.visibleButtons) {
			return (
				<span className='chat__textarea-length'>
					{realTextLength}/{maxLengthData.characters}
				</span>
			);
		}
		return null;
	}, [textareaValue, props.visibleButtons, maxLengthData]);

	const renderTextarea = () => {
		return (
			<div className={chatTextareaWrap}>
				<div className='chat__textarea-container'>
					<Field
						fieldType='textarea'
						placeholder={imagePreviewModalVisible ? attach.addCaption : chatScreen.inputPlaceholder}
						className={textareaClass}
						innerRef={textInputRef}
						autoComplete='off'
						autoCorrect='off'
						spellCheck={false}
						value={textareaValue}
						rows={1}
						maxLength={maxLengthData.characters}
						onChange={onChangeHandler}
						onFocus={onFocusHandler}
						onKeyDown={onCheckKeyPress}
						onKeyUp={onCheckKeyUp}
						onInput={onInputHandler}
						onBlur={onBlurHandler}
					/>

					{renderTextLength()}

					<Button
						className={chatTextareaStickersEmojiBtnClasses}
						onClick={onTogglleVisibleStickersEmojiHandler}>
						{visibleStickersEmoji ? getAppIcon(IcoKeyboard.pic) : getAppIcon(IcoStickers.pic)}
					</Button>
				</div>

				{visibleTextareaSendBtn && (
					<div className='chat__textarea-wrap-btn' ref={referenceSlowMode}>
						{!isMyTalkerModer &&
						(slowMode.global.enable || slowMode.local.enable) &&
						slowModeTimeLeft ? (
							<>
								<Button
									className='chat__textarea-timer-btn'
									onClick={() => {
										setSlowModeTooltip(!slowModeTooltip);
										if (sendAnalyticsSlowModeEnable) {
											setSendAnalyticsSlowModeEnable(false);
											sendAnalytics('message_sending_blocked_slowmode');
										}
									}}>
									<span>{slowModeConvertTime(slowModeTimeLeft)}</span>
								</Button>

								{slowModeTooltip && textForSlowModeTooltip() && (
									<Tooltip
										referenceElement={referenceSlowMode.current}
										position={TooltipPositoinType.TOP}
										text={textForSlowModeTooltip()}
										delay={3000}
										callbackOnHide={() => setSlowModeTooltip(false)}
									/>
								)}
							</>
						) : (
							<Button
								className='chat__textarea-send-btn'
								disabled={
									blockedBtnSend || !isInternernetActive || !isUserExternalIdCorrect || appReadOnly
								}
								onClick={debounceSendMessageToSocket}>
								{getAppIcon(IcoSendMessage.pic)}
							</Button>
						)}
					</div>
				)}
			</div>
		);
	};

	const renderBanText = () => {
		return (
			<span
				className='control-panel__ban-alert'
				dangerouslySetInnerHTML={{__html: chatScreen.cantWrite}}
			/>
		);
	};

	return isBanned ? renderBanText() : renderTextarea();
};

export default observer(ChatTextarea);
