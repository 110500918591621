/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Müqavilə',
		subTitle: 'Lisenziya Müqaviləsi və Məxfilik Siyasəti',
		textFirst: 'Şərtləri qəbul edirəm və',
		textSecond: 'müəyyən edilmiş şərtlər əsasında şəxsi məlumatlarımın emalına razılığımı verirəm',
		license: 'Lisenziya Müqaviləsi',
		policy: 'və Məxfilik Siyasətində ',
	},
	controlPanel: {
		streamEnded: 'Yayım bitdi',
		banAlert: 'Çata yaza bilməzsiniz',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stikerlər' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Profil silinib',
		text: (date: string) => `Siz profilinizi -dək bərpa edə bilərsiniz ${date}`,
	},
	nameScreen: {
		title: 'Çatda adı',
		inputPlaceholder: 'Adınızı daxil edin',
		nameError: 'Yanlış ad',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Digərini seçin:',
	},
	settingsScreen: {
		title: 'Parametrlər',
		personal: 'Şəxsi Parametrlər',
		room: 'Otaq Parametrləri',
		profileBtn: 'Profil',
		languageBtn: 'Dil və interfeys',
		blockedBtn: 'Blok edilmiş',
		rules: 'Çat qaydaları',
		deleteProfile: 'Profili silin',
		slowModeBtn: 'Yavaş rejim',
		agora: {
			microphone: 'Mikrofon',
			playbackDevice: 'Səsin mənbəyi',
		},
	},
	languageScreen: {
		title: 'Dil və interfeys',
		messagesTranslate: 'Mesajların tərcüməsi',
		translateMessagesMode: 'Çatdakı mesajları tərcümə edin',
		translateMessagesTo: 'Mesajları -a tərcümə edin',
	},
	rulesScreen: {
		title: 'Çat qaydaları',
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Başqa avatarı seçin',
		chatNameLabel: 'Çatda adı',
		changeName: 'Dəyişdirin',
	},
	blockedScreen: {
		title: 'Blok edilmiş',
		emptyListText: 'Bloklanmış istifadəçilər yoxdur',
	},
	chatScreen: {
		inputPlaceholder: 'Mesaj...',
		cantWrite: 'Çata yaza bilməzsiniz',
		chatSubmenu: {
			hideMessage: 'Mesajları gizlədin',
			showMessage: 'Mesajları göstərin',
			removeFromSpeakers: 'Spikerlərdən çıxarın',
			setSpeaker: 'Spiker edin',
			hideOnlyMessage: 'Mesajı gizlədin',
			showOnlyMessage: 'Mesajı göstərin',
			reportMessage: 'Mesajı şikayət edin',
			reportUser: 'İstifadəçini şikayət edin',
			muteSpeaker: 'Mikrofonu söndürün',
			blockUser: 'Bloklayın',
			unblockUser: 'Blokdan çıxarın',
			reply: 'Cavab verin',
			copy: 'Kopyalayın',
			edit: 'Dəyişin',
			delete: 'Silin',
			pinMessage: 'Bərkidin',
			unpinMessage: 'Sancaqdan çıxarın',
			goToProfile: 'Profilə girin',
			replyInThread: 'Treddə cavablayın',
		},
		actionMessage: {
			editing: 'Redaktə',
			reply: 'Cavab verin',
		},
		status: {
			top: 'Ən yaxşı',
		},
		translating: 'Tərcümə edilir...',
	},
	chatUsersScreen: {
		title: 'İştirakçılar',
		edited: 'Dəyişdi',
		hidden: 'gizlədildi',
		unreadMessages: 'Oxunmamış mesajlar',
		usersSubmenu: {
			muteSpeaker: {
				title: 'İştirakçının səsini söndürün',
				subtitle: 'Mikrofonu söndürün',
			},
			setSpeaker: {
				title: 'Spikerlərdən çıxarın',
				subtitle: 'Yalnız mətn çatı',
			},
			removeFromSpeaker: {
				title: 'Spiker edin',
				subtitle: 'İstifadəçi səsli şəkildə danışa bilər',
			},
			showMessages: {
				title: 'Mesajları göstərin',
				subtitle: 'Yalnız admin görür',
			},
			hideMessages: {
				title: 'Mesajları gizlədin',
				subtitle: 'Yalnız admin görür',
			},
			blockUser: {
				title: 'Bloklayın',
				subtitle: 'Stop-siyahıya əlavə edin',
			},
			unblockUser: {
				title: 'Blokdan çıxarın',
				subtitle: 'İstifadəçini blokdan çıxarın',
			},
			blockUserByUser: {
				title: 'Bloklayın',
				subtitle: 'Bütün istifadəçi mesajlarını gizlədin',
			},
			unblockUserByUser: {
				title: 'Blokdan çıxarın',
				subtitle: 'Bütün istifadəçi mesajlarını göstərin',
			},
			reportUser: {
				title: 'İstifadəçini şikayət edin',
				subtitle: 'Şikayət anonim şəkildə göndərilir',
			},
		},
		days: {
			today: 'Bu gün',
			yesterday: 'Dünən',
		},
		chatCreated: 'Çat yaradıldı,',
	},
	alerts: {
		btns: {
			unblock: 'Blokdan çıxarın',
			block: 'Bloklayın',
			send: 'Göndərin',
			sendAndBlock: 'Göndərin və bloklayın',
			show: 'Göstərin',
			showAll: 'Hamısını göstərin',
			cancel: 'Ləğv edin',
			yes: 'Bəli',
			hide: 'Seçilmişləri gizlədin',
			hideAll: 'Hamısını gizlədin',
			make: 'Edin',
			remove: 'Çıxarın',
			delete: 'Silin',
			deleteAccount: 'Profili silin',
			close: 'Bağlayın',
			pin: 'Bərkidin',
			unpin: 'Sancaqdan çıxarın',
			edit: 'Redaktə edin',
			end: 'Tamamlayın',
			endAndShare: 'Tamamlayın və paylaşın',
			reset: 'Sıfırlayın',
			rules: 'Çat qaydaları',
			contactus: 'Bizə yazın',
		},
		pinMessage: {
			title: 'Mesaj bərkidilsin?',
		},
		unPinMessage: {
			title: 'Mesaj sancaqdan çıxarılsın?',
		},
		accountRestored: {
			title: 'Profil bərpa edildi',
		},
		closeApp: {
			title: 'Həqiqətən çıxmaq istəyirsiniz?',
		},
		removeMessage: {
			title: 'Mesaj silinsin?',
		},
		report: {
			title: 'Şikayət göndərilsin?',
			subtitle:
				'Həmçinin bir-birinizin mesajlarını görməmək üçün istifadəçini blok edə bilərsiniz.',
		},
		banUser: {
			title: (name: string) => `Bloklayın ${name}?`,
			subtitle: (text: string) => `İstifadəçi ${text}-ə görə bloklanır`,
		},
		unBanUser: {
			title: (name: string) => `Blokdan çıxarın ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `${name}-dən gələn mesajlar göstərilsin?`,
			subTitle: 'Digər istifadəçilər bu istifadəçinin mesajlarını görə bilər',
		},
		hideUserMessages: {
			title: (name: string) => `${name}-dən gələn mesajlar gizlədilsin?`,
			subTitle: 'Digər istifadəçilər artıq bu istifadəçinin mesajlarını görə bilməz',
		},
		showUserMessage: {
			title: (name: string) => `${name}-dən gələn mesaj göstərilsin?`,
			subTitle: 'Digər istifadəçilər bu mesajı görə bilər',
		},
		hideUserMessage: {
			title: (name: string) => `${name}-dən gələn mesaj gizlədilsin?`,
			subTitle: 'Digər istifadəçilər artıq bu mesajı görə bilməz',
		},
		blockUserInRoom: {
			title: (name: string) => `Bloklayın ${name}?`,
			subTitle: 'İstifadəçi bu otaqda iştirak edə bilməz',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Blokdan çıxarın ${name}?`,
			subTitle: 'İstifadəçi bu otaqda iştirak edə bilər',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Bloklayın ${name}?`,
			subTitle: 'Bir-birinizin mesajlarını görə bilməzsiniz',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `${name} spiker edilsin?`,
			unTitle: (name: string) => `${name} spikerlərdən çıxarılsın?`,
		},
		banInApp: {
			title: 'Administrator yanlış ada görə sizi bloklayıb.',
			for: '',
			subtitle: (bannedUntil: string) => `Siz ${bannedUntil}-ə qədər bloklanmısınız`,
			permanent: 'Siz həmişəlik bloklanmısınız',
		},
		banInAppBadNickname: {
			title: 'Administrator yanlış ada görə sizi bloklayıb.',
			subtitle: 'Profilinizə girin, adınızı dəyişin və yenidən çata daxil olun',
		},
		deleteAccount: {
			title: 'Profilin silinməsi',
			subTitle: 'Bütün məlumatlar silinəcək. 30 gün ərzində profilinizi bərpa edə bilərsiniz.',
		},
		pollEdit: {
			title: 'Sorğunuz redaktə edilsin?',
			subTitle: 'İstifadəçi cavabları sıfırlanır',
		},
		pollReset: {
			title: 'Sıfırlansın?',
			subTitle: 'Sorğu yadda saxlanmayacaq',
		},
		pollEnd: {
			title: 'Sorğunu tamamlamaq istədiyinizə əminsiniz?',
		},
		pollDelete: {
			title: 'Sorğu silinsin?',
		},
		agoraCheck: {
			updateIOSText: 'Tətbiqin bütün imkanlarından istifadə etmək üçün iOS versiyasını yeniləyin.',
			allowAccess: {
				title: 'Uca və aydın',
				text: 'Mikrofon və dinamikdən istifadə etmək üçün tətbiqin parametrlərinə daxil olun və girişi təsdiqləyin.',
			},
			microphoneAndSpeakerText: 'Mikrofon və dinamik yalnız 14.3 versiyasında işləyir',
			microphoneText: 'Mikrofon 14.3 versiyasında işləyir',
			btn: 'Aydındır',
		},
	},
	eventScreen: {
		title: 'Planlaşdırılmış tədbir',
		titleSoon: 'Yayım tezliklə başlayır',
		subtitle: 'Çat tezliklə açılır, lütfən gözləyin.',
		days: 'günlər',
		hours: 'saatlar',
		minutes: 'dəqiqələr',
		seconds: 'saniyələr',
		startTitle: 'Başlanğıc',
	},
	toasts: {
		reciveComplaint: 'Şikayətinizi aldıq',
		setSpeakerRoleForUser: 'İstifadəçi spiker təyin edildi',
		unSpeakerRoleForUser: 'İstifadəçi spikerlər siyahısından silindi',
		messagesShown: 'Mesajlar göstərildi',
		messagesHidden: 'Mesajlar gizlədildi',
		messageShown: 'Mesaj göstərildi',
		messageHidden: 'Mesaj gizlədildi',
		userBlocked: 'İstifadəçi bloklandı',
		userUnBlocked: 'İstifadəçi blokdan çıxarıldı',
		youAreSpeaker: 'Siz spikersiniz! Danışın',
		youAreNotSpeaker: 'Administrator sizi spikerlər siyahısından sildi',
		userIsSpeaker: (name: string) => `${name} indi spikerdir`,
		userNotSpeaker: (name: string) => `${name} artıq spiker deyil`,
		yourUserNameInChat: (name: string) => `Çatdakı adınız ${name}-dir`,
		messageRemoved: 'Mesaj silindi',
		messageCopied: 'Mətn kopyalandı',
		obscenities: 'Parafraz etməyə çalışın',
		requestSent: 'Sorğu göndərildi',
		requestDecline: 'Mikrofonla bağlı sorğunuz qəbul edilmədi',
		youareblocked: (text: string) => `${text} -ə görə bloklanmısınız. Çatda yaza bilməzsiniz`,
		youareunblocked: 'Blokdan çıxarıldınız. Çatda mesaj yaza bilərsiniz',
		youAreModerator: 'Moderatorsunuz!',
		youAreNoModerator: 'Artıq moderator deyilsiniz',
		micMuted: 'Mikrofonunuz səssizdir',
		noInet: 'İnternet bağlantısı yoxdur',
		messagePinned: 'Mesaj bərkidildi',
		messageUnpinned: 'Mesaj sancaqdan çıxarıldı',
		pollPublished: 'Sorğu dərc edildi',
		pollPublishedError: 'Xəta! Bir az sonra təkrar cəhd edin',
		pollAddPic: 'Şəkil əlavə edin',
		pollAddPicError: 'Başqa şəkil seçin',
		pollDeleted: 'Sorğu silindi',
		pollEnded: 'Sorğu tamamlandı',
		sendStickerWhenSlowModeIsOn: (time: string) => `Stikerin göndərilməsi üçün ${time} gözləyin`,
		streamConnecting: 'Yayıma qoşuluruq...',
		linkProhibited: 'Çatda linklər göndərmək olmaz.',
		phoneProhibited: 'Çatda telefon nömrələri göndərmək olmaz.',
		profileHidden: 'İstifadəçi öz profilini gizli saxlayır.',
		imageSizeWarning: '10 mb-dan çox olmayan şəkil yükləyin',
		imageBlocked: 'Moderator şəklin göndərilməsini blokladı',
		streamKeyCopied: 'Yayım açarı kopyalandı',
	},
	btns: {
		acceptandgo: 'Qəbul və davam edin',
		saveandgo: 'Yadda saxlayın və davam edin',
		save: 'Yadda saxlayın',
		unblock: 'Blokdan çıxarın',
		block: 'Bloklayın',
		restoreAccount: 'Profili bərpa edin',
		close: 'Bağlayın',
	},
	coopyrights: 'Watchers.io bazasında işləyir',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Mesajı şikayət edin' : 'Pozuntu barədə bildirin',
			subtitle: 'Şikayətlər anonim şəkildə göndərilir',
			btns: {
				violence: 'Təhqirlər, təhdidlər, söyüşlər',
				provocations: 'Təxribatlar',
				personalDetails: 'Şəxsi məlumatlar',
				flood: 'Flud',
				spam: 'Spam',
				fraudBegging: 'Fırıldaqçılıq, dilənçilik',
				other: 'Digər',
			},
		},
		ban: {
			title: 'İstifadəçini bloklayın',
			subtitle: 'Bloklamanın səbəbini göstərin',
			btns: {
				insults: 'Təhqirlər, təhdidlər, söyüşlər',
				personal: 'Şəxsi məlumatların yayılması',
				flood: 'Flud',
				links: 'Kənar xidmətlərə keçid linkləri',
				violence: 'Zorakılıq ilə bağlı təhdidlər',
				fraud: 'Fırıldaqçılıq və dilənçilik cəhdləri',
				nickname: 'Yanlış ləqəb',
				politicial: 'Siyasi, seksual və digər təxribatlar',
				repeated: 'Təkrar pozuntu',
				auto: 'Çat istifadəçilərinin şikayətləri',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Gözləyən spikerlər yoxdur',
			handsUp: 'Əliniz qalxıb',
			waiting: 'Gözləyən spikerlər',
		},
		agreements: {
			license: 'Lisenziya Müqaviləsi',
			policy: 'Məxfilik Siyasəti',
			licenseText: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est fermentum fusce eu
			rutrum nullam ut blandit. Ut quam sapien faucibus blandit at non quis adipiscing. Erat
			rutrum amet dictum purus habitant blandit.

			Amet lorem ornare enim non tincidunt pellentesque facilisis cras diam. Adipiscing nullam
			volutpat, sed convallis fermentum. Elementum volutpat, ultrices ipsum morbi amet. Aliquet
			pretium ipsum dictum tristique vulputate semper laoreet at habitant. Tellus quam ut
			aliquam volutpat vitae.

			Et adipiscing porta porttitor mus ullamcorper et porttitor curabitur. Amet diam bibendum
			odio tincidunt faucibus amet integer. Sagittis, velit ut sit sodales at nec, eleifend
			bibendum. Nulla in semper a, sit mattis lectus eget nunc pellentesque. Volutpat egestas in
			aenean sed. Nullam ultricies commodo, ligula facilisis placerat viverra.

			Pellentesque lectus lorem eget ut rhoncus faucibus amet, etiam. Nulla arcu rhoncus,
			volutpat sit lectus. Condimentum semper eleifend tincidunt tempor metus.

			Tempor facilisis id nec eget at et non. Est id tellus quis elementum, vel risus. Et quis
			in aliquam viverra rhoncus et. Suspendisse pellentesque duis non vel id vulputate dolor eu
			aliquam. In sapien volutpat elementum risus. Fringilla aliquet bibendum nullam volutpat
			sem odio sed.
		`,
			policyText: `
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est fermentum fusce eu
		rutrum nullam ut blandit. Ut quam sapien faucibus blandit at non quis adipiscing. Erat
		rutrum amet dictum purus habitant blandit.

		Amet lorem ornare enim non tincidunt pellentesque facilisis cras diam. Adipiscing nullam
		volutpat, sed convallis fermentum. Elementum volutpat, ultrices ipsum morbi amet. Aliquet
		pretium ipsum dictum tristique vulputate semper laoreet at habitant. Tellus quam ut
		aliquam volutpat vitae.

		Et adipiscing porta porttitor mus ullamcorper et porttitor curabitur. Amet diam bibendum
		odio tincidunt faucibus amet integer. Sagittis, velit ut sit sodales at nec, eleifend
		bibendum. Nulla in semper a, sit mattis lectus eget nunc pellentesque. Volutpat egestas in
		aenean sed. Nullam ultricies commodo, ligula facilisis placerat viverra.

		Pellentesque lectus lorem eget ut rhoncus faucibus amet, etiam. Nulla arcu rhoncus,
		volutpat sit lectus. Condimentum semper eleifend tincidunt tempor metus.

		Tempor facilisis id nec eget at et non. Est id tellus quis elementum, vel risus. Et quis
		in aliquam viverra rhoncus et. Suspendisse pellentesque duis non vel id vulputate dolor eu
		aliquam. In sapien volutpat elementum risus. Fringilla aliquet bibendum nullam volutpat
		sem odio sed.
	`,
		},
		poll: {
			supTitles: {
				typeQuiz: 'Düzgün cavabı verin',
				typeQuizMultiple: 'Düzgün cavab variantlarını qeyd edin',
				typePoll: 'Fikrinizi paylaşın',
				typePollMultiple: 'Bir neçə variantı qeyd etmək olar',
				default: 'İctimai sorğu',
			},
			btns: {
				reply: 'Cavab verin',
				vote: 'Səs verin',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Viktorina',
				typePoll: 'Bir neçə cavab',
				default: 'İctimai sorğu',
			},
			btns: {
				edit: 'Sorğu anketini redaktə edin',
				end: 'Tamamlayın',
			},
		},
		pollCreated: {
			titles: {
				created: 'Sorğu anketini yaradın',
				typeQuiz: 'Viktorina',
				typePoll: 'Bir neçə cavab',
				default: 'İctimai sorğu',
			},
		},
		changeName: {
			title: 'Çatdakı adı dəyişin.',
			subtitle: 'Yanlış ləqəbə görə bloklanmısınız',
		},
		temporaryAgreement: {
			title: 'Çat qaydaları',
			rules: {
				bePolite: {
					title: 'Nəzakətli olun',
					text: 'Çatda təhqiramiz və ədəbsiz ifadələrin işlənməsi qadağandır.',
				},
				beAttentive: {
					title: 'Diqqətli olun',
					text: 'Şəxsi məlumatlarınızı paylaşmayın və ya başqalarından istəməyin.',
				},
				notAdvertise: {
					title: 'Reklam etməyin',
					text: 'Ən yaxşısı fikirlərinizi və ya emosiyalarınızı paylaşmaqdır.',
				},
			},
			btns: {
				allRules: 'Bütün qaydalar',
				acceptAndContinue: 'Qəbul və davam edin',
			},
		},
		slowMode: {
			title: 'Yavaş rejim',
			text: 'İştirakçılar seçilmiş müddət çərçivəsindən artıq çata mesajlar göndərə bilməz',
			enable: 'Yavaş rejimini yandırın',
			disable: 'Yavaş rejimini söndürün',
			sendTimeout: 'Göndərişin taym-aut vaxtı',
		},
		slowModeTimeout: {
			title: 'Göndərişin taym-aut vaxtı',
		},
		chatBot: {
			title: 'Çatbotdan istifadə',
			rules: [
				{
					title: 'Süni intellekt',
					text: 'Bot insanlarla ünsiyyəti təqlid edir və öz biliyi çərçivəsində məlumatları təqdim edir. Yalnız unutmayın ki, onun cavabları məhduddur və insanı əvəz edə bilməz.',
				},
				{
					title: 'Bot səhv edə bilər',
					text: 'Bot məlumatların düzgünlüyünə və etibarlılığına zəmanət verə bilməz.',
				},
			],
			btns: {
				close: 'Bağlayın',
			},
		},
		shareBet: {
			title: 'Paylaşın',
			noBetsTitle: 'Mərclərinizi tapmaq mümkün olmadı',
			noBetsText: '14 gün ərzində edilən mərcləri paylaşa bilərsiniz',
			btns: {
				share: 'Mərci paylaşın ',
				copy: 'Mərci təkrarlayın:',
			},
			status: {
				null: 'Oyundasınız',
				win: 'Qalibiyyət',
				lose: 'Məğlubiyyət',
			},
			betTypeV1: {
				express: 'Ekspres',
				single: 'Tək',
			},
			betTypeV2: {
				single: 'Tək',
				multi: 'çoxl.',
				system: 'System',
			},
			totalFactor: 'Əmsal',
			bet: 'mərc',
			showMore: 'Daha çoxunu göstərin',
			betRepeated: 'mərci təkrarladı',
		},
		shareGamble: {
			noGamblesText:
				'Hələ ki, paylaşacaq qazancınız yoxdur. Oynamağa davam edin və növbəti qazancınız burada görünəcək ki, onu digər oyunçularla paylaşa biləsiniz!',
		},
		record: {
			text: 'Yayımın qeyd yazısı emal edilir...',
		},
		agoraDevices: {
			title: 'Audio parametrləri',
			text: 'Düzgün mikrofonun seçildiyinə əmin olun',
		},
		translate: {
			title: 'Mesajların tərcüməsi',
			chooseLanguage: 'Dili seçin',
			descr: 'Çatdakı bütün mesajlar avtomatik olaraq seçilmiş dilə tərcümə olunacaq.',
			btn: 'Tərcüməni aktiv edin',
		},
		streamSettings: {
			title: 'Yayım Parametrləri',
			description: 'Video kodlayıcı yükləyin və quraşdırın. Parametrlərdə yayım açarını daxil edin',
			shareScreen: {
				title: 'Ekranı Paylaş',
				description: 'Xüsusi parametrlər tələb olunmur',
			},
			rtmp: {
				title: 'Xarici Yayım Mənbəyi',
				description: 'Yayımı həyata keçirmək üçün əlavə proqram istifadə edin',
			},
			streamKey: 'Yayım Açarı',
			showStreamKey: 'Göstər',
			hideStreamKey: 'Gizlət',
			btns: {
				continue: 'Davam et',
				close: 'Bağla',
				ok: 'Hazırdır',
			},
		},
	},
	pinnedMessage: {
		title: 'Bərkidilmiş mesaj',
	},
	errorPage: {
		error: 'Xəta',
		types: [
			// errorNumber 0
			{
				title: 'Sizi tanımırıq',
				text: 'Çata yenidən daxil olmağa çalışın',
			},
			// errorNumber 1
			{
				title: 'Çatı tapa bilmirik',
				text: 'Yenidən daxil olmağa cəhd edin',
			},
			// errorNumber 2
			{
				title: 'Çatı yükləmək mümkün deyil',
				text: 'Yenidən yükləməyə çalışın',
			},
			// errorNumber 3
			{
				title: 'Sizi tanımırıq',
				text: 'İnkoqnito rejimində çat əlçatan deyil',
			},
			// errorNumber 4
			{
				title: 'Yanlış ləqəb',
				text: 'Profilinizə keçin, ləqəbinizi dəyişin və yenidən çata daxil olun.',
			},
		],
	},
	tooltips: {
		emotions: 'Dəyişmək üçün basıb saxlayın',
		badInet: 'Zəif şəbəkə bağlantısı',
		blockedUsers: 'Bloklanmış istifadəçilərin siyahısına buradan baxa bilərsiniz.',
		waitingSpeakers: 'İştirakçılardan bəziləri danışmaq istəyir.',
		wantSpeak: 'Səs hüquqlarını almaq üçün basın.',
		slowMode: {
			header: (time: string) =>
				`Yavaş rejim aktivdir. İstifadəçilər <br>hər$ {time} bir mesaj göndərə bilər. Bu qayda moderatorlara şamil edilmir`,
			btnSendMessage: (time: string) => `Siz hər <br> mesaj göndərə bilərsiniz`,
		},
		shareBet: 'Çatda mərci paylaşın',
	},
	poll: {
		toggleSubmenu: {
			create: 'Sorğu anketini yaradın',
			edit: 'Sorğu anketini redaktə edin',
			show: 'Sorğu anketini göstərin',
			end: 'Sorğu anketini tamamlayın',
		},
		tooltip: {
			btn: 'Səs verin',
		},
		settings: {
			title: 'Parametrlər',
			titleAdditionally: 'Əlavə',
			switches: {
				quizMode: 'Viktorina rejimi',
				multipleChoice: 'Bir neçə cavab seçimi',
				withPhoto: 'Şəkilli sorğu',
			},
		},
		question: {
			title: 'Sual',
			inputPlaceholder: 'Sualı daxil edin',
		},
		message: {
			title: 'Siz səs verdiniz',
		},
		answers: {
			title: 'Cavab variantları',
			warning: 'Maksimum 5 cavab əlavə edə bilərsiniz',
		},
		answer: {
			tooltip: 'Düzgün cavabı seçin',
			inputPlaceholder: 'Cavab',
		},
		results: {
			text: 'Səsvermənin nəticələri',
			votes: 'səs',
			// words: ['səs', 'səs', 'səslər'],
		},
		btns: {
			addQuestion: 'Sualı əlavə edin',
			addAnswers: 'Cavabları əlavə edin',
			addAnswer: 'Cavabı əlavə edin',
			publish: 'Dərc edin',
			vote: 'Səs verin',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Qiymətləndirin',
				continue: 'Davam edin',
				checkConnection: 'Bağlantını yoxlayın',
				close: 'Bağlayın',
			},
			quality: {
				title: 'Səsin keyfiyyətini qiymətləndirin',
			},
			reason: {
				title: 'Xəta baş verdi?',
			},
			connection: {
				title: 'Bağlantınızın keyfiyyətini yoxlayın',
				text: 'Yoxlama bir dəqiqədən az vaxt aparır və problemin səbəbini tapmağa kömək edir.',
			},
			statistics: {
				title: 'Statistikanı toplayırıq...',
			},
			done: {
				title: 'Çox sağ olun!',
				text: 'Xətanı tapıb mütləq düzəldəcəyik.',
			},
		},
	},
	chatBot: {
		message: 'Botdan istədiyinizi soruşun...',
		warnings: {
			disabled: 'Mənim əlaqəm kəsildi',
			overloaded: 'Həddən artıq yüklənmişik',
		},
	},
	audioReason: {
		nothear: 'Heç nə eşidilmir',
		connection: 'Səs kəsildi',
		microphone: 'Mikrofondan istifadə etmək mümkün deyil',
	},

	copybetting: {
		orderStatus: {
			won: 'Qalibiyyət',
			lost: 'Məğlubiyyət',
			new: 'New',
			pending: 'Gözləyən',
			cashedOut: 'Geri çəkilmə',
			boreDraw: 'qolsuz heç-heçə',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Operator tərəfindən ləğv',
			megaBetCanceledByPlayer: 'MegaBet Oyunçu ləğv',
			megaBetCanceledBySystem: 'MegaBet Sistem tərəfindən ləğv',
			betGamesPending: 'BetGames Gözləyən',
			betGamesWon: 'BetGames Qalibiyyət',
			betGamesLost: 'BetGames Məğlubiyyət',
			paid: 'Ödənişli',
			initialWin: 'Gedən uduşlar',
			autoBetCanceledBySystem: 'AutoBet Sistem tərəfindən ləğv',
			autoBetCanceledByPlayer: 'AutoBet Oyunçu ləğv',
			autobetPending: 'AutoBet Gözləyən',
			goalAhead: 'Bir məqsəd olacaq',
			canceled: 'Ləğv edin',
			return: 'Qayıtmaq',
		},
		stakeStatus: {
			pending: 'Gözləyən',
			won: 'Qalibiyyət',
			lost: 'Məğlubiyyət',
			return: 'Qayıtmaq',
			lostReturn: 'Məğlubiyyət | Qayıtmaq',
			wonReturn: 'Qalibiyyət | Qayıtmaq',
			canceled: 'Ləğv edin',
			initialReturn: 'Çıxan geri qaytarılma',
			pendingInitialReturn: 'Gözləyən | Çıxan geri qaytarılma',
			wonInitialReturn: 'Qalibiyyət | Çıxan geri qaytarılma',
		},
	},
	screenShare: {
		title: 'Ekranın nümayişi',
		stopSharing: 'Yayımı dayandırın',
		modal: {
			title: 'Ekranınızı yalnız kompüterinizdən paylaşa bilərsiniz',
			text: 'Kompüterinizdə çatı açın və yuxarı sağ küncdəki “Ekranı Paylaşın” düyməsini basın.',
			btn: 'Giriş linkini kopyalayın',
		},
	},
	threads: {
		title: 'Tred',
		back: 'Əvvəlki səhifə',
		replies: ['Cavab', 'cavablar', 'cavablar'],
	},

	attach: {
		submenu: {
			sendImage: 'Şəkli göndərin',
			shareBet: 'Mərci paylaşın',
			shareGamble: 'Uduşu paylaşın',
		},
		images: ['şəkil', 'şəkillər', 'şəkillərin'],
		addCaption: 'İmza əlavə edin',
	},
} as unknown as Dictionary;
