/* eslint-disable max-lines */
import {USER_UNNAMED} from 'constants/constants';
import UserRole from 'models/enums/UserRole.enum';
import {Talker} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import chatServices from 'store/chatService';
import modalServices from 'store/modalService';
import SocketIoServices from 'services/SocketIoServices';
import RoomService from 'services/api/RoomService';
import useSubmenuActions from 'hooks/useSubmenuActions';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import useL10n from 'l10n/useL10n';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import Button from 'components/hoc/Button';
import {Avatar} from 'components/avatar';
import {ReactComponent as GuestIcon} from 'assets/svg/guest.svg';

interface IUserItemProps {
	talker: Talker;
}

const UserItem: FunctionComponent<IUserItemProps> = function UserItem({talker}) {
	const {role: talkerRole, user} = talker;
	const {isVip, vipStatus} = user;
	const {appReadOnly, appVoice, blockedUsers, appIcons} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect, accessToken} = useLocalObservable(() => userServices);
	const {roomId, messages, myTalker} = useLocalObservable(() => roomServices);
	const {hideVisibleModalUsers, setReportModal} = useLocalObservable(() => modalServices);
	const {chatBot} = useLocalObservable(() => chatServices);

	const [showMore, setShowMore] = useState(false);

	const {blockUser, unBlockUser, messagesVisible, changeUserRole, blockUserByUser} =
		useSubmenuActions();
	const {chatUsersScreen} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();

	const {
		IcoSubmenuHide,
		IcoSubmenuShow,
		IcoSubmenuBlock,
		IcoSubmenuUnblock,
		IcoSubmenuRemoveSpeaker,
		IcoSpeakerAdd,
		IcoMute,
		IcoChevron,
	} = appIcons;

	const foundMessages = !!messages.find(m => m.user && m.user?.id === user.id);
	const foundVisibleMessage = !!messages.find(m => m.user && m.user?.id === user.id && m.isVisible);

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const isTalkerSpeaker = talkerRole === UserRole.SPEAKER;
	const isTalkerBanned = !!talker.bans?.length;
	const isTalkerChatBot = user && chatBot && user.name === chatBot.name;
	const isTalkerModer = talker?.isModer || user?.isModer;

	const commonCheckForVisibleControls = userData && user && myTalker && userData.id !== user.id;
	const isVisibleControlsForModer =
		commonCheckForVisibleControls && (myTalker.isModer || userData.isModer);
	const isVisibleControlsForNoModer =
		commonCheckForVisibleControls && !myTalker.isModer && !userData.isModer && !isTalkerModer;

	const userControlsClass = classNames('users-modal__user-controls', {
		'users-modal__user-controls--active': showMore,
	});

	const iconClass = classNames('users-modal__user-icon', {
		'users-modal__user-icon--active': showMore,
	});

	const messagesVisibleHandler = () => {
		if (roomId && user) {
			messagesVisible(user, roomId, foundVisibleMessage);
		}
	};

	const blockUserHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user) {
			isTalkerBanned ? unBlockUser(user, roomId) : blockUser(user);
		}
	};

	const setRoleHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user) {
			changeUserRole(user, roomId, isTalkerSpeaker ? UserRole.GUEST : UserRole.SPEAKER);
		}
	};

	const muteUserHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user?.id) {
			SocketIoServices.emitMute(roomId, true, user.id);
		}
	};

	const onBlockUserByUserHandler = async () => {
		const blockedUserFind = blockedUsers.find(item => item.id === user.id);
		if (blockedUserFind && blockedUserFind.id && accessToken) {
			sendAnalytics('unblocking_pressed');
			await RoomService.deleteBlockedUser(blockedUserFind.id, accessToken);
			return;
		}
		sendAnalytics('ban_pressed');
		blockUserByUser(user);
	};

	return (
		<div className='users-modal__user'>
			<div className='users-modal__user-container'>
				<div className={`users-modal__user-avatar background-color-${user?.color || 0}`}>
					<Avatar
						src={user?.pic ? user.pic : AnonimAva}
						alt={user.name && user.name !== '' ? user.name : USER_UNNAMED}
						className='users-modal__user-avatar-img'
					/>
				</div>
				<div className='users-modal__user-wrapper'>
					<p className='users-modal__user-name'>
						<span className='users-modal__user-name-item'>
							{user.name && user.name !== '' ? user.name : USER_UNNAMED}
						</span>
						{user.isInvitedGuest && <GuestIcon className='users-modal__user-invited' />}

						{isTalkerModer && <span className='users-modal__user-admin'>admin</span>}

						{!isTalkerModer && !user.isInvitedGuest && isVip && vipStatus && (
							<span
								className={`users-modal__user-status users-modal__user-status--${vipStatus.toLowerCase()}`}>
								{vipStatus.toLowerCase()}
							</span>
						)}
					</p>
					{!appReadOnly &&
						isUserExternalIdCorrect &&
						(isVisibleControlsForModer || isVisibleControlsForNoModer) && (
							<>
								<span className={iconClass}>{getAppIcon(IcoChevron.pic)}</span>
								<Button className='users-modal__user-btn' onClick={() => setShowMore(!showMore)}>
									show more
								</Button>
							</>
						)}
				</div>
			</div>

			{!appReadOnly &&
				isUserExternalIdCorrect &&
				isVisibleControlsForModer &&
				!isVisibleControlsForNoModer &&
				showMore && (
					<div className={userControlsClass}>
						{appVoice && !talker.isMuted && talker.role === UserRole.SPEAKER && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{chatUsersScreen.usersSubmenu.muteSpeaker.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{chatUsersScreen.usersSubmenu.muteSpeaker.subtitle}
									</p>
								</div>

								{getAppIcon(IcoMute.pic, {className: 'users-modal__user-controls-icon'})}

								<Button className='users-modal__user-controls-action' onClick={muteUserHandler} />
							</div>
						)}

						{appVoice && isMyTalkerModer && !isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{isTalkerSpeaker
											? chatUsersScreen.usersSubmenu.setSpeaker.title
											: chatUsersScreen.usersSubmenu.removeFromSpeaker.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{isTalkerSpeaker
											? chatUsersScreen.usersSubmenu.setSpeaker.subtitle
											: chatUsersScreen.usersSubmenu.removeFromSpeaker.subtitle}
									</p>
								</div>

								{isTalkerSpeaker
									? getAppIcon(IcoSubmenuRemoveSpeaker.pic, {
											className: 'users-modal__user-controls-icon',
									  })
									: getAppIcon(IcoSpeakerAdd.pic, {
											className: 'users-modal__user-controls-icon',
									  })}

								<Button className='users-modal__user-controls-action' onClick={setRoleHandler}>
									action
								</Button>
							</div>
						)}

						{foundMessages && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{foundVisibleMessage
											? chatUsersScreen.usersSubmenu.hideMessages.title
											: chatUsersScreen.usersSubmenu.showMessages.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{chatUsersScreen.usersSubmenu.showMessages.subtitle}
									</p>
								</div>

								{foundVisibleMessage
									? getAppIcon(IcoSubmenuHide.pic, {
											className: 'users-modal__user-controls-icon',
									  })
									: getAppIcon(IcoSubmenuShow.pic, {
											className: 'users-modal__user-controls-icon',
									  })}

								<Button
									className='users-modal__user-controls-action'
									onClick={messagesVisibleHandler}>
									action
								</Button>
							</div>
						)}

						{!isTalkerModer && !isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									{isTalkerBanned ? (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.unblockUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.unblockUser.subtitle}
											</p>
										</>
									) : (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.blockUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.blockUser.subtitle}
											</p>
										</>
									)}
								</div>

								{isTalkerBanned
									? getAppIcon(IcoSubmenuUnblock.pic, {
											className: 'users-modal__user-controls-icon',
									  })
									: getAppIcon(IcoSubmenuBlock.pic, {
											className: 'users-modal__user-controls-icon',
									  })}

								<Button className='users-modal__user-controls-action' onClick={blockUserHandler} />
							</div>
						)}
					</div>
				)}

			{!appReadOnly &&
				isUserExternalIdCorrect &&
				!isVisibleControlsForModer &&
				isVisibleControlsForNoModer &&
				showMore && (
					<div className={userControlsClass}>
						{!isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									{blockedUsers.find(u => u.id === user.id) ? (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.unblockUserByUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.unblockUserByUser.subtitle}
											</p>
										</>
									) : (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.blockUserByUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.blockUserByUser.subtitle}
											</p>
										</>
									)}
								</div>

								{blockedUsers.find(u => u.id === user.id)
									? getAppIcon(IcoSubmenuUnblock.pic, {
											className: 'users-modal__user-controls-icon',
									  })
									: getAppIcon(IcoSubmenuBlock.pic, {
											className: 'users-modal__user-controls-icon',
									  })}

								<Button
									className='users-modal__user-controls-action'
									onClick={onBlockUserByUserHandler}
								/>
							</div>
						)}
					</div>
				)}
		</div>
	);
};

export default observer(UserItem);
