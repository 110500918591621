/* eslint-disable jsx-a11y/label-has-associated-control */
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import './switcher.scss';
import useAppData from 'hooks/useAppData';
import classNames from 'classnames';

interface ISwitcherProps {
	checked: boolean;
	onChange: () => void;
	ico?: string;
}

const Switcher: FunctionComponent<ISwitcherProps> = function Switcher(props) {
	const id = uuidv4();
	const {getAppIcon} = useAppData();

	const switcherClasses = classNames('ico', {
		'switcher--ico': props.ico,
	});
	return (
		<div className={switcherClasses}>
			<input id={id} type='checkbox' className='switcher__checkbox' {...props} />
			<label className='switcher__label' htmlFor={id}>
				{props.ico && <span className='switcher__ico'>{getAppIcon(props.ico)}</span>}
			</label>
		</div>
	);
};

export default observer(Switcher);
