/* eslint-disable max-lines */
import {MAXIMUM_NUMBER_OF_MESSAGES, START_NUMBER_OF_MESSAGES} from 'constants/constants';
import {getParent, qs} from 'utils/helpers';
import MessageType from 'models/enums/MessageType.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message} from 'models/room';
import {PollMessageData} from 'models/poll';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import controlPanelServices from 'store/controlPanelService';
import slowModeServices from 'store/slowModeService';
import agoraService from 'store/agoraService';
import threadService from 'store/threadService';
import RoomService from 'services/api/RoomService';
import {useRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import {PollMessage} from 'components/poll/pollMessage';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import useChatActions from 'hooks/useChatActions';
import useMessage from 'hooks/useMessage';
import useSearchParams from 'hooks/useSearchParams';
import useTranslation from 'hooks/useTranslation';
import Button from 'components/hoc/Button';
import {isDesktop} from 'react-device-detect';

import {ReactComponent as IcoFabIcon} from 'assets/svg/ico-fab.svg';
import ChatMessagesPreloader from './ChatMessagesPreloader';
import ChatIntro from './ChatIntro';
import ChatMessage from './chatMessage/ChatMessage';
import {PinnedMessage} from '../pinnedMessage';
import './chatStyle/chat.scss';
import ChatSkeleton from './ChatSkeleton';
import ChatVideoTrack from './ChatVideoTrack';

interface IChat {
	isThread?: boolean;
}

const Chat: FunctionComponent<IChat> = function Chat({isThread}) {
	const roomScrollRef = useRef<HTMLDivElement>(null);
	const threadScrollRef = useRef<HTMLDivElement>(null);
	const chatScrollRef = isThread ? threadScrollRef : roomScrollRef;
	const chatMessagesRef = useRef<HTMLDivElement>(null);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [disableScroll, setDisableScroll] = useState(false);
	const [lastViewedMessage, setLastViewedMessage] = useState<number | null>(null);
	const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
	const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
	const [checkUserLastMessageForSlowMode, setCheckUserLastMessageForSlowMode] =
		useState<boolean>(false);
	const [visibleSkeleton, setVisibleSkeleton] = useState(true);

	const {appReadOnly, isInternernetActive, appLowerUsersModule, appEnableFullscreenOption} =
		useLocalObservable(() => appService);
	const {userData, accessToken, translateMode} = useLocalObservable(() => userServices);
	const {
		roomId,
		myTalker,
		messages,
		addMessages,
		unrecordedMessages,
		pinnedMessages,
		mentionMessageArray,
		removeMentionMessage,
		updateMentionMessageArray,
		submenuMessage,
		blockedUsersForFilteredMessages,
		chatScrollPositionBottom,
		previousMessagesMode,
		removeUnrecordedMessage,
		removeUnrecordedMessages,
		togglePreviousMessagesMode,
		toggleChatScrollPositionBottom,
		restApiDataLoaded,
		visibleDateForFirstMessage,
		toggleVisibleDateForFirstMessage,
		isFilterBySubscription,
		subscriptions,
		localMessages,
		setLocalMessages,
		setActivePinnedMessage,
		setPinnedMessages,
		clearMessages,
	} = useLocalObservable(() => (isThread ? threadService : roomServices));
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {visibleButtons, toggleVisibleButtons} = useLocalObservable(() => controlPanelServices);
	const {slowMode, setSlowModeTimeLeft, setslowModeTimeLeftOffset, toggleSlowModeCountdownEnable} =
		useLocalObservable(() => slowModeServices);
	const {isScreenSharing, isMyTalkerShareScreen, isFullScreenVideo} = useLocalObservable(
		() => agoraService
	);
	const {sendAnalytics} = useAnalytics();
	const {
		getMessagesWithServices,
		getMessagesAround,
		scrollToMessage,
		checkScrollTop,
		checkMessages,
	} = useChatActions();
	const {visibleMessageElements} = useMessage();
	const {userIdFromUrl} = useSearchParams();
	const {translateMessages} = useTranslation();

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const talkerIsBanned = !!myTalker?.bans?.length;
	const userIsBanned = !!myTalker?.user?.bans?.length;
	const isPinnedMessagesFromBlockedUsers = blockedUsersForFilteredMessages.some(item =>
		pinnedMessages.map(el => el.user?.id).includes(item)
	);
	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
		'--panel-top-transparent'
	);

	const chatMessagesClasses = classNames('chat__messages', {
		'chat__messages--wpinned': pinnedMessages !== null,
	});

	const chatAxisClasses = classNames('chat__axis-y', {
		'chat__axis-y--screen-share': isScreenSharing && !isThread && !isFullScreenVideo,
		'chat__axis-y--pinned-screensharing': isScreenSharing && pinnedMessages?.length,
		'chat__axis-y--thread': isThread,
		'chat__axis-y--fullscreen-mode': isFullScreenMode,
	});

	const chatClasses = classNames('chat', {
		'chat--header-transparent': cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent),
		'chat--unauthorized': !userIdFromUrl || +userIdFromUrl === 0,
		'chat--lower': appLowerUsersModule,
		'chat--thread': isThread,
		'chat--fullscreen-mode': isFullScreenMode,
	});

	const checkMessage = (message: Message) => {
		return (
			isMyTalkerModer ||
			(!isMyTalkerModer && message.user?.id === userData?.id && !message.isVisible) ||
			(!isMyTalkerModer && message.isVisible) ||
			message.type === MessageType.BET ||
			message.type === MessageType.GAMBLE
		);
	};

	const visibleSubmenu = (messageId: number) => {
		if (submenuMessage && submenuMessage.messageId === messageId && !talkerIsBanned) {
			return true;
		}
		return false;
	};

	const onShowButtonsHandler = () => {
		if (!visibleButtons && !talkerIsBanned && !userIsBanned) {
			toggleVisibleButtons(true);
		}
	};

	const resetStateAfterScrollToBottom = () => {
		if (!chatScrollPositionBottom) {
			toggleChatScrollPositionBottom(true);
		}

		if (mentionMessageArray.length) {
			updateMentionMessageArray(null);
		}

		setLastViewedMessage(null);
		setNewMessagesCount(0);
		setScrollToBottom(false);
	};

	const onScrollToUnreadMessagesHandler = () => {
		sendAnalytics('scrolled_to_unread_messages');

		if (previousMessagesMode) {
			togglePreviousMessagesMode(false);
			removeUnrecordedMessages();
			getMessagesWithServices(START_NUMBER_OF_MESSAGES, +isFilterBySubscription);
			resetStateAfterScrollToBottom();
			toggleVisibleDateForFirstMessage(false);
			setTimeout(() => {
				setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
			}, 100);

			return;
		}

		if (newMessagesCount !== 0 && !scrollToBottom) {
			const messageToScroll = document.querySelector(
				`.chat__message[data-id="${lastViewedMessage}"]`
			);
			const nextMessageIdToScroll = (messageToScroll?.nextSibling as HTMLElement | null)?.dataset
				.id;
			if (
				lastViewedMessage &&
				nextMessageIdToScroll &&
				messageToScroll &&
				messageToScroll instanceof HTMLElement
			) {
				scrollToMessage(
					parseInt(nextMessageIdToScroll, 10),
					chatMessagesRef.current,
					chatScrollRef.current
				);
			}
			setScrollToBottom(true);
		}

		if (newMessagesCount === 0 || scrollToBottom) {
			chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
			resetStateAfterScrollToBottom();
			setTimeout(() => {
				setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
			}, 100);
		}
	};

	const onClickMessageReplayHandler = async (event: any) => {
		const eventTarget = event.target;
		if (eventTarget && getParent(eventTarget, 'chat__message-replay')) {
			event.preventDefault();
			const messageReplayId = parseInt(
				getParent(eventTarget, 'chat__message-replay').dataset.replayId,
				10
			);
			if (messageReplayId) {
				if (messageReplayId && qs(`.chat__message[data-id="${messageReplayId}"]`)) {
					scrollToMessage(
						messageReplayId,
						chatMessagesRef.current,
						chatScrollRef.current,
						true,
						true
					);
					return;
				}
				const response = await getMessagesAround(
					chatScrollRef.current,
					setDisableScroll,
					setVisiblePreloader,
					messageReplayId,
					MAXIMUM_NUMBER_OF_MESSAGES,
					+isFilterBySubscription
				);

				if (response.status === ResponseStatus.SUCCESS) {
					if (!previousMessagesMode) {
						togglePreviousMessagesMode(true);
					}

					setTimeout(() => {
						scrollToMessage(
							messageReplayId,
							chatMessagesRef.current,
							chatScrollRef.current,
							true,
							true
						);
					}, 500);
				}

				toggleChatScrollPositionBottom(false);
			}
		}
	};

	const documentClickHandler = (event: any) => {
		onClickMessageReplayHandler(event);

		if (appReadOnly) {
			const eventTarget = event.target;
			if (
				eventTarget &&
				(getParent(eventTarget, 'welcome-modal__footer') ||
					getParent(eventTarget, 'modal__footer') ||
					getParent(eventTarget, 'chat-rules') ||
					getParent(eventTarget, 'modal__body') ||
					getParent(eventTarget, 'modal__head'))
			) {
				return;
			}

			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify({type: 'watchersAttemptClick'}));
				return;
			}
			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
					JSON.stringify({type: 'watchersAttemptClick'})
				);
				return;
			}
			window.parent.postMessage({type: 'watchersAttemptClick'}, '*');
		}
	};

	const onClickOnPinnedMessageHandler = async (messageId: number) => {
		if (pinnedMessages?.length && !isPinnedMessagesFromBlockedUsers) {
			if (localMessages.find(lm => lm.id === messageId)) {
				scrollToMessage(messageId, chatMessagesRef.current, chatScrollRef.current, true, true);
				return;
			}

			const response = await getMessagesAround(
				chatScrollRef.current,
				setDisableScroll,
				setVisiblePreloader,
				messageId,
				MAXIMUM_NUMBER_OF_MESSAGES,
				+isFilterBySubscription
			);

			if (response.status === ResponseStatus.SUCCESS) {
				if (!previousMessagesMode) {
					togglePreviousMessagesMode(true);
				}

				setTimeout(() => {
					scrollToMessage(messageId, chatMessagesRef.current, chatScrollRef.current, true, true);
				}, 500);
			}
		}
	};

	const onScrollToMentionMessageHandler = async () => {
		const {0: firstMentionMessage} = mentionMessageArray;
		if (localMessages.find(lm => lm.id === firstMentionMessage.id)) {
			scrollToMessage(firstMentionMessage.id, chatMessagesRef.current, chatScrollRef.current, true);
			return;
		}

		if (previousMessagesMode) {
			const response = await getMessagesAround(
				chatScrollRef.current,
				setDisableScroll,
				setVisiblePreloader,
				firstMentionMessage.id,
				MAXIMUM_NUMBER_OF_MESSAGES,
				+isFilterBySubscription
			);

			if (response.status === ResponseStatus.SUCCESS) {
				setTimeout(() => {
					scrollToMessage(
						firstMentionMessage.id,
						chatMessagesRef.current,
						chatScrollRef.current,
						true,
						false
					);
					removeUnrecordedMessage(firstMentionMessage.id);
					removeMentionMessage(firstMentionMessage.id);
				}, 500);
			}
		}
	};

	const setChatScrollTop = (value: number) => {
		if (chatScrollRef.current && chatScrollRef.current instanceof HTMLElement) {
			chatScrollRef.current.scrollTop = value;
		}
	};

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollTop(
					setDisableScroll,
					setVisiblePreloader,
					setNewMessagesCount,
					setScrollToBottom,
					resetStateAfterScrollToBottom,
					newMessagesCount,
					visiblePreloader,
					scrollToBottom,
					chatScrollRef.current,
					setChatScrollTop,
					scrollEvent
				),
			100
		),
		[messages, newMessagesCount, visiblePreloader, chatScrollPositionBottom, previousMessagesMode]
	);

	const renderMessageItem = useCallback(
		(item: Message, index: number) => {
			const {
				id,
				type,
				pollText,
				mentionMessage,
				mentionMessageData,
				advertisement,
				reactions,
				sticker,
				talker,
				bet,
				gamble,
				stickerPic,
				picData,
			} = item;
			if (mentionMessage) mentionMessage.mentionMessageData = mentionMessageData;
			const pollMessageData: PollMessageData = pollText ? JSON.parse(pollText) : null;

			switch (type) {
				case MessageType.USER:
				case MessageType.ADVERTISEMENT:
				case MessageType.POLLRESULTS:
				case MessageType.STICKER:
				case MessageType.BET:
				case MessageType.GAMBLE:
				case MessageType.PIC: {
					return (
						<ChatMessage
							key={id}
							message={item}
							mentionMessage={mentionMessage && !mentionMessage?.deletedAt ? mentionMessage : null}
							highlightMessage={advertisement}
							pollResultsMessage={pollMessageData}
							stickerMessage={sticker}
							stickerPic={stickerPic}
							betMessage={bet?.data ? {...bet, ...JSON.parse(bet.data)} : null}
							gambleMessage={gamble || null}
							visibleMessageSubmenu={visibleSubmenu(id)}
							isFirstMessage={!!(id === localMessages[0]?.id)}
							isLastMessage={id === lastViewedMessage}
							reactions={reactions}
							{...visibleMessageElements(index, item, localMessages, visibleDateForFirstMessage)}
							isThread={isThread}
							pics={picData}
						/>
					);
				}
				case MessageType.VOTE: {
					if (talker.id === myTalker?.id && pollMessageData) {
						return (
							<div className='chat__message chat__message--poll' key={id} data-id={id}>
								<PollMessage pollMessageData={pollMessageData} />
							</div>
						);
					}
					return null;
				}
				default: {
					return null;
				}
			}
		},
		[
			localMessages,
			lastViewedMessage,
			submenuMessage,
			visibleDateForFirstMessage,
			isThread,
			subscriptions,
		]
	);

	useEffect(() => {
		setTimeout(() => {
			chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
		}, 1000);
	}, []);

	useEffect(() => {
		if (isScreenSharing) chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
	}, [isScreenSharing]);

	useEffect(() => {
		if (roomId && accessToken) {
			document.addEventListener('click', documentClickHandler);
		}
		return () => {
			document.removeEventListener('click', documentClickHandler);
		};
	}, [roomId, accessToken]);

	useEffect(() => {
		const {messagesLoaded, pinnedMessageLoaded} = restApiDataLoaded;

		if (messagesLoaded && pinnedMessageLoaded) {
			setVisibleSkeleton(false);
		}
	}, [restApiDataLoaded]);

	useEffect(() => {
		if (messages.length > 0) {
			const filteredMessages = messages.filter((message: Message) => checkMessage(message));
			setLocalMessages(filteredMessages);
			return;
		}
		setLocalMessages([]);
	}, [messages, isMyTalkerModer]);

	useEffect(() => {
		if (!checkMessages(messages).length)
			getMessagesWithServices(START_NUMBER_OF_MESSAGES, +isFilterBySubscription);
	}, [messages.filter(el => el.isVisible).length]);

	useEffect(() => {
		if (translateMode.enable && translateMode.lang.languageCode && messages.length) {
			translateMessages(translateMode.lang.languageCode);
		}
	}, [messages.length]);

	useEffect(() => {
		const lastMention = messages[messages.length - 1];
		const alwaysMentioned =
			lastMention && mentionMessageArray.find(item => item.id === lastMention.id);

		if (
			lastMention?.mentionMessage !== null &&
			lastMention?.user?.id !== userData?.id &&
			alwaysMentioned === undefined
		) {
			const mentionedMessage = messages.find(
				message => message.id === lastMention.mentionMessage?.id && lastMention.isNotReaded
			);

			if (
				mentionedMessage &&
				mentionedMessage.user &&
				mentionedMessage.user.id === userData?.id &&
				chatScrollRef.current &&
				!chatScrollPositionBottom
			) {
				updateMentionMessageArray(lastMention);
			}
		}

		if (
			messages.length &&
			messages[messages.length - 1].isNotReaded &&
			lastMention?.type !== MessageType.VOTE &&
			lastMention?.user?.id !== userData?.id &&
			chatScrollRef.current &&
			lastViewedMessage === null &&
			!chatScrollPositionBottom
		) {
			setLastViewedMessage(messages[messages.length - 2].id);
			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify({type: 'haveUnreadedMessages'}));
				return;
			}
			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
					JSON.stringify({type: 'haveUnreadedMessages'})
				);
				return;
			}

			window.parent.postMessage({type: 'haveUnreadedMessages'}, '*');
		}

		if (!messages[messages.length - 1]?.isNotReaded) {
			setLastViewedMessage(null);
		}
	}, [messages.length]);

	useEffect(() => {
		if (previousMessagesMode) {
			setNewMessagesCount(unrecordedMessages.length);

			if (unrecordedMessages.length > 0) {
				unrecordedMessages.forEach(unrecordedMessage => {
					if (
						unrecordedMessage.mentionMessage &&
						unrecordedMessage.mentionMessage.user &&
						unrecordedMessage.mentionMessage.user.id === userData?.id &&
						!mentionMessageArray.find(m => m.id === unrecordedMessage.id)
					) {
						updateMentionMessageArray(unrecordedMessage);
					}
				});
			}

			return;
		}

		if (messages.length && lastViewedMessage !== null) {
			setNewMessagesCount(
				messages.filter(
					item =>
						item.isVisible &&
						item.isNotReaded &&
						item.type !== MessageType.VOTE &&
						userData?.id !== item.user.id
				).length
			);
		}
	}, [messages, unrecordedMessages, lastViewedMessage]);

	useEffect(() => {
		if (localMessages.length) {
			const checkLastMessageUser =
				localMessages.length &&
				localMessages[localMessages.length - 1].isNotReaded &&
				localMessages[localMessages.length - 1].user &&
				localMessages[localMessages.length - 1].user?.id === userData?.id;

			if (
				!disableScroll &&
				!previousMessagesMode &&
				(checkLastMessageUser || chatScrollPositionBottom)
			) {
				setTimeout(() => {
					chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
					// setVisiblePreloader(false);
				});
			}

			setDisableScroll(false);
		}
	}, [localMessages]);

	useEffect(() => {
		if (messages.length && !chatScrollRef.current?.classList.contains('visible')) {
			chatScrollRef.current?.classList.add('visible');
		}
	}, [localMessages, chatScrollRef.current, isScreenSharing, isFullScreenVideo]);

	useEffect(() => {
		(async () => {
			const slowModeTime = slowMode.local.enable ? slowMode.local.time : slowMode.global.time;
			if (
				!isMyTalkerModer &&
				localMessages.length &&
				(slowMode.global.enable || slowMode.local.enable) &&
				!checkUserLastMessageForSlowMode
			) {
				setCheckUserLastMessageForSlowMode(true);

				if (
					!localMessages.find(m => m.user.id === userData?.id) &&
					new Date().getTime() - new Date(localMessages[0].createdAt).getTime() <= slowModeTime
				) {
					if (roomId && userData?.id) {
						const response = await RoomService.getUserLastMessage(
							accessToken,
							roomId,
							userData?.id
						);
						if (response.status === ResponseStatus.SUCCESS) {
							if (response.data) {
								const timeDifference =
									new Date().getTime() - new Date(response.data.createdAt).getTime();
								if (timeDifference <= slowModeTime) {
									setSlowModeTimeLeft(slowModeTime);
									setslowModeTimeLeftOffset(timeDifference);
									toggleSlowModeCountdownEnable(true);
								}
							}
						}
					}
					return;
				}

				if (localMessages.find(m => m.user.id === userData?.id)) {
					const myMessages = localMessages.filter(m => m.user.id === userData?.id);
					const timeDifference =
						new Date().getTime() - new Date(myMessages[myMessages.length - 1].createdAt).getTime();
					if (timeDifference <= slowModeTime) {
						setSlowModeTimeLeft(slowModeTime);
						setslowModeTimeLeftOffset(timeDifference);
						toggleSlowModeCountdownEnable(true);
					}
				}
			}
		})();
	}, [isMyTalkerModer, localMessages, slowMode, checkUserLastMessageForSlowMode]);

	const chatScrollToBottom = () => {
		chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
	};

	useEffect(() => {
		setTimeout(() => {
			chatScrollToBottom();
		}, 0);
	}, [isFullScreenVideo]);

	const getFilteredMessages = async () => {
		await getMessagesWithServices(
			START_NUMBER_OF_MESSAGES,
			+isFilterBySubscription,
			chatScrollToBottom
		);
		toggleChatScrollPositionBottom(true);
	};
	useEffect(() => {
		if (isFilterBySubscription) {
			setLocalMessages([]);
			clearMessages();
		}
		setVisibleSkeleton(true);
		setDisableScroll(true);
		setPinnedMessages([]);
		setActivePinnedMessage(null);
		getFilteredMessages();
		setDisableScroll(false);
	}, [isFilterBySubscription]);

	useEffect(() => {
		if (isInternernetActive && !chatScrollPositionBottom) {
			setTimeout(() => {
				chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
			});
		}
	}, [isInternernetActive]);

	if (!localMessages.length && !visibleSkeleton && !isScreenSharing && !isMyTalkerShareScreen) {
		return (
			<div
				className='chat'
				onClick={onShowButtonsHandler}
				onKeyDown={onShowButtonsHandler}
				role='button'
				tabIndex={0}>
				<ChatIntro />
			</div>
		);
	}

	return (
		<div
			className={chatClasses}
			onClick={onShowButtonsHandler}
			onKeyDown={onShowButtonsHandler}
			role='button'
			tabIndex={0}>
			{!visibleSkeleton &&
				pinnedMessages &&
				!!pinnedMessages?.length &&
				!isThread &&
				!currentThreadId && <PinnedMessage handleClick={onClickOnPinnedMessageHandler} />}
			<ChatSkeleton visible={visibleSkeleton} />
			{(isScreenSharing || isMyTalkerShareScreen) && !isThread && <ChatVideoTrack />}
			{!localMessages.length && !visibleSkeleton && (isScreenSharing || isMyTalkerShareScreen) && (
				<ChatIntro />
			)}
			<div className={chatAxisClasses} onScroll={onScrollHandler} ref={chatScrollRef}>
				<div className={chatMessagesClasses} ref={chatMessagesRef}>
					{!visibleDateForFirstMessage && <ChatMessagesPreloader visible={visiblePreloader} />}
					{localMessages.map(renderMessageItem)}
				</div>
			</div>

			<CSSTransition
				in={!!mentionMessageArray.length || !chatScrollPositionBottom}
				timeout={200}
				classNames='scale'
				unmountOnExit>
				<div className='chat__btns'>
					{!!mentionMessageArray.length && (
						<Button onClick={onScrollToMentionMessageHandler} className='chat__mention-btn' />
					)}
					<CSSTransition
						in={!chatScrollPositionBottom}
						timeout={200}
						classNames='scale'
						unmountOnExit>
						<div className='chat__unreaded-messages'>
							{!!newMessagesCount && (
								<span className='chat__unreaded-messages-text'>{newMessagesCount}</span>
							)}
							<Button
								onClick={onScrollToUnreadMessagesHandler}
								className='chat__unreaded-messages-btn'
								aria-label='scroll-to'>
								<IcoFabIcon />
							</Button>
						</div>
					</CSSTransition>
				</div>
			</CSSTransition>
		</div>
	);
};

export default observer(Chat);
