import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {useEffect, useRef} from 'react';
import GambleService from 'services/api/GambleService';
import appService from 'store/appService';
import gambleService from 'store/gambleService';
import modalService from 'store/modalService';
import userService from 'store/userService';

const useGamble = () => {
	const {accessToken, userData} = useLocalObservable(() => userService);
	const {shareGambleModalVisible} = useLocalObservable(() => modalService);
	const {project, isStand} = useLocalObservable(() => appService);
	const {setGambles, setGamblesError} = useLocalObservable(() => gambleService);

	const currentOffset = useRef<number>(0);
	const isLoading = useRef<boolean>(false);

	useEffect(() => {
		if (!shareGambleModalVisible) currentOffset.current = 0;
	}, [shareGambleModalVisible]);

	const getGamblesWithServices = async (offset = 0, limit = 15) => {
		if (accessToken && userData?.id) {
			const response = await GambleService.getGamble(accessToken, userData.id, offset, limit);
			if (response.status === ResponseStatus.SUCCESS) {
				if (!response.data.length && currentOffset.current > 0) {
					currentOffset.current -= limit;
					return;
				}
				if (response.data.length === limit) currentOffset.current += limit;
				setGambles(response.data, offset);
				return;
			}

			if (response.status === ResponseStatus.ERROR) {
				setGamblesError(response.data);
			}
		}
	};

	const checkScrollBottom = async (
		event: React.BaseSyntheticEvent,
		userGamblesScrollRef: HTMLDivElement | null,
		setVisiblePreloaderBottom: (val: boolean) => void
	) => {
		const {scrollTop, scrollHeight} = event.target;

		if (
			!isStand &&
			userGamblesScrollRef &&
			(Math.round(userGamblesScrollRef.offsetHeight + scrollTop) === Math.abs(scrollHeight) ||
				Math.ceil(userGamblesScrollRef.offsetHeight + scrollTop) === Math.abs(scrollHeight))
		) {
			if (accessToken && !isLoading.current) {
				isLoading.current = true;
				setVisiblePreloaderBottom(true);
				userGamblesScrollRef?.scroll({top: userGamblesScrollRef?.scrollHeight});

				await getGamblesWithServices(currentOffset.current);
				setVisiblePreloaderBottom(false);

				setTimeout(() => {
					isLoading.current = false;
				}, 1000);
			}
		}
	};

	return {getGamblesWithServices, checkScrollBottom};
};

export default useGamble;
