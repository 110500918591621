import {PollEditActionType} from 'models/enums/Poll.enum';
import appService from 'store/appService';
import pollService from 'store/pollService';
import modalService from 'store/modalService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import usePollAnalytics from 'hooks/usePollAnalytics';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';

interface IPollToggleSubmenuProps {
	callBackFunctionAfterClick: (value: boolean) => void;
}

const PollToggleSubmenu: FunctionComponent<IPollToggleSubmenuProps> = function PollToggleSubmenu({
	callBackFunctionAfterClick,
}) {
	const {appIcons} = useLocalObservable(() => appService);
	const {poll, pollAlreadyVoted} = useLocalObservable(() => pollService);
	const {togglePollCreateModalVisible, togglePollEditModalVisible, togglePollModalVisible} =
		useLocalObservable(() => modalService);

	const {poll: pollTranslate} = useL10n();
	const {sendPollAnalytics} = usePollAnalytics();
	const {getAppIcon} = useAppData();

	const {IcoPoll, IcoEdit, IcoPollEnd} = appIcons;

	const onPollSubmenuCreateHandler = () => {
		togglePollCreateModalVisible(true);
		callBackFunctionAfterClick(false);
	};

	const onPollSubmenuEditHandler = () => {
		togglePollEditModalVisible(true);
		callBackFunctionAfterClick(false);
	};

	const onPollSubmenuOpenHandler = () => {
		togglePollModalVisible(true);
		callBackFunctionAfterClick(false);
		pollAlreadyVoted ? sendPollAnalytics('pq_viewed') : sendPollAnalytics('pq_opened');
	};

	const onPollSubmenuEndHandler = async () => {
		togglePollEditModalVisible(true, PollEditActionType.END);
		callBackFunctionAfterClick(false);
	};

	const renderSubmenuItems = useCallback(() => {
		if (poll) {
			return (
				<ul className='poll-toggle__submenu-items'>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuEditHandler}>
							{pollTranslate.toggleSubmenu.edit}
							{getAppIcon(IcoEdit.pic)}
						</Button>
					</li>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuOpenHandler}>
							{pollTranslate.toggleSubmenu.show}
							{getAppIcon(IcoPoll.pic)}
						</Button>
					</li>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuEndHandler}>
							{pollTranslate.toggleSubmenu.end}
							{getAppIcon(IcoPollEnd.pic)}
						</Button>
					</li>
				</ul>
			);
		}
		return (
			<ul className='poll-toggle__submenu-items'>
				<li className='poll-toggle__submenu-item'>
					<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuCreateHandler}>
						{pollTranslate.toggleSubmenu.create}
						{getAppIcon(IcoEdit.pic)}
					</Button>
				</li>
			</ul>
		);
	}, [poll]);

	return <div className='poll-toggle__submenu'>{renderSubmenuItems()}</div>;
};

export default observer(PollToggleSubmenu);
