import {hints} from 'data/hints';
import {qs, containsClass} from 'utils/helpers';
import appServices from 'store/appService';
import hintService from 'store/hintService';
import {useEffect} from 'react';
import {useLocalObservable} from 'mobx-react-lite';

const useHints = () => {
	const {appIcons, tutorialMode, toggleTutorialMode, toggleTutorialModeCompleted} =
		useLocalObservable(() => appServices);
	const {addHintsViewed} = useLocalObservable(() => hintService);

	const {IcoClose} = appIcons;

	const hintHTML = (text: string) => {
		return `
			<div class="hint__container">
				<button type="button" class="hint__button-close"><img src="${IcoClose.pic}" class="ico-item" alt="close" /></button>
				<div class="hint__content">
					<p class="hint__text">
						${text}
					</p>
				</div>
			</div>
		`;
	};

	const removeHintFromElement = (removeChatOverlay: boolean) => {
		if (removeChatOverlay) {
			document.querySelector('.chat')?.classList.remove('chat--overflow');
		}
		qs('.hint').remove();
		toggleTutorialMode(false);
	};

	const onHintHandler = (e: any) => {
		if (containsClass(e.target, 'hint__button-close') && qs('.hint')) {
			e.preventDefault();
			e.stopPropagation();
			removeHintFromElement(true);
		}
	};

	const checkViewPortY = (element: any) => {
		let viewPortHeight = document.documentElement.clientHeight;
		const rect = element.getBoundingClientRect();
		const hintNode = qs('.hint');
		const mainBottom = qs('.main-bottom');
		let posYPointer = 'top';
		let posY = rect.y;
		if (mainBottom) {
			viewPortHeight -= mainBottom.clientHeight;
		}
		if (hintNode) {
			if (posY + element.clientHeight + hintNode.clientHeight >= viewPortHeight) {
				posY = -hintNode.clientHeight - 10;
				posYPointer = 'bottom';
			} else {
				posY = element.clientHeight + 10;
			}
		}
		return {
			posY: `${posY}px`,
			posYPointer,
		};
	};

	const checkViewPortX = (element: any) => {
		const viewPortWidth = document.documentElement.clientWidth;
		const rect = element.getBoundingClientRect();
		const hintNode = qs('.hint');
		let posXPointer = 'left';
		let posX = rect.x;

		if (qs('.poll-message__container', element)) {
			posX = qs('.poll-message__container', element).getBoundingClientRect().x;
		}

		if (hintNode) {
			if (posX + hintNode.clientWidth > viewPortWidth) {
				posX = -(hintNode.clientWidth - element.clientWidth);
				posXPointer = 'right';
			}
		}
		return {
			posX: `${posX}px`,
			posXPointer,
		};
	};

	const setPositionHint = (element: any) => {
		const hintNode: any = qs('.hint');
		let style: {top: string; left?: string; right?: string} = {
			left: '0px',
			top: '0px',
		};
		let positionPointer = ['hint--top', 'hint--left'];
		if (element) {
			const {posX, posXPointer} = checkViewPortX(element);
			const {posY, posYPointer} = checkViewPortY(element);
			style = {
				top: posY,
				left: posX,
			};
			positionPointer = [`hint--${posYPointer}`, `hint--${posXPointer}`];
		}
		if (hintNode) {
			Object.assign(hintNode.style, style);
			hintNode.classList.add(...positionPointer);
		}
	};

	const addHintToElement = (element: any, hintType: string) => {
		const hintNode: any = qs('.hint');
		const findHint = hints.find(h => h.type === hintType);
		if (element && findHint) {
			if (hintNode) {
				hintNode.remove();
			}

			const newHint = document.createElement('div');
			newHint.classList.add('hint', `hint--${hintType}`);
			newHint.innerHTML = hintHTML(findHint.text);
			element.prepend(newHint);
			setPositionHint(element);
			addHintsViewed(hintType);
			document.querySelector('.chat')?.classList.add('chat--overflow');

			if (!tutorialMode) {
				toggleTutorialMode(true);
				toggleTutorialModeCompleted(true);
			}
		}
	};

	useEffect(() => {
		document.addEventListener('click', onHintHandler);
		return () => {
			document.removeEventListener('click', onHintHandler);
		};
	}, []);

	return {addHintToElement, removeHintFromElement};
};

export default useHints;
