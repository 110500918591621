export const hints = [
	{
		id: 0,
		type: 'message',
		text: 'Any user can answer, react to, or report other user’s messages. User has to select the reason to report, and moderators will check it. Also, users can block each other—in this case, they won’t see each other’s messages.',
	},
	{
		id: 1,
		type: 'poll',
		text: 'Admins can publish polls through the admin panel or chat interface. Also, scheduling or sending multiple polls to several rooms simultaneously is possible.',
	},
	{
		id: 2,
		type: 'highlight',
		text: 'A marketing message is sent by moderators through the admin panel. This type of message (or coupon) can contain a clickable button, description, and custom picture. It can be sent at once or scheduled for a certain time (with any time of duration). Regular users cannot answer or report it. ',
	},
	{
		id: 3,
		type: 'chatbot',
		text: 'The bot is set through the admin panel (it’s possible to find more appropriate tone of voice, change its name, and upload an avatar) and can also be educated with specific data from the platform’s side.',
	},
	{
		id: 4,
		type: 'bet',
		text: 'If suitable for the platform, copy-betting and copy-trading widgets are available. In this case, any user can share their bets or deals in a chat; other users can copy them.',
	},
	{
		id: 5,
		type: 'pin',
		text: 'Only admins and moderators can pin messages.',
	},
	{
		id: 6,
		type: 'polltoggle',
		text: 'Admins can publish polls through the admin panel or chat interface. Also, scheduling or sending multiple polls to several rooms simultaneously is possible.',
	},
];
