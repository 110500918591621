import {USER_UNNAMED} from 'constants/constants';
import {Talker} from 'models/room';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import Button from 'components/hoc/Button';

import './Users.scss';

const Users: FunctionComponent = function Users() {
	const {appIcons, appReadOnly, enableRoomSpeak} = useLocalObservable(() => appService);
	const {roomData, speakers, talkersCount} = useLocalObservable(() => roomServices);
	const {showVisibleModalUsers} = useLocalObservable(() => modalServices);
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();

	const {IcoUsers} = appIcons;

	const usersClickHandler = () => {
		if (!appReadOnly) {
			sendAnalytics('user-list_openned');
			showVisibleModalUsers();
		}
	};

	const userVOD = (talker: Talker) => {
		return classNames('users__list-item', {
			'users__list-item--active': talker.voiceActive,
		});
	};

	const usersClasess = classNames('users', {
		'users--have-speakers': (roomData?.isSpeak || enableRoomSpeak) && speakers.length > 0,
	});

	const renderNowSpeakers = useCallback(
		(talker: Talker) => (
			<div key={talker.id} className={userVOD(talker)}>
				<img
					className={`users__list-item-img background-color-${talker?.user?.color || 0}`}
					src={talker.user.pic !== '' ? talker.user.pic : AnonimAva}
					alt={talker.user.name !== '' ? talker.user.name : USER_UNNAMED}
				/>
			</div>
		),
		[speakers]
	);

	return (
		<div className={usersClasess}>
			{(roomData?.isSpeak || enableRoomSpeak) && speakers.length > 0 && (
				<div className='users__list'>{speakers.slice(0, 2).map(renderNowSpeakers)}</div>
			)}
			<div className='users__counter'>
				{getAppIcon(IcoUsers.pic)}
				<span className='users__counter-number'>{talkersCount}</span>
			</div>
			<Button className='users__click-area' onClick={usersClickHandler} />
		</div>
	);
};

export default observer(Users);
