import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userService';
import RoomService from 'services/api/RoomService';
import {useLocalObservable} from 'mobx-react-lite';
import roomService from 'store/roomService';
import {useEffect, useRef} from 'react';
import modalService from 'store/modalService';

const LIMIT = 25;

const useTalkers = () => {
	const isLoading = useRef<boolean>(false);
	const getNextTalkersOffset = useRef<number>(LIMIT);

	const {accessToken} = useLocalObservable(() => userServices);
	const {
		roomId,
		addTalkers,
		talkers,
		setSpeakers,
		setBannedTalkers,
		setTalkersCount,
		setWaitingSpeakers,
		addSubscriptions,
	} = useLocalObservable(() => roomService);
	const {visibleModalUsers} = useLocalObservable(() => modalService);

	useEffect(() => {
		if (!visibleModalUsers) getNextTalkersOffset.current = 0;
	}, [visibleModalUsers]);

	const getTalkers = async (value?: {limit?: number; offset?: number}) => {
		if (accessToken && roomId) {
			const response = await RoomService.getTalkers(
				roomId,
				accessToken,
				value?.limit || LIMIT,
				value?.offset || 0
			);
			if (response.status === ResponseStatus.SUCCESS) {
				addTalkers(response.data);
			}
			return response.data;
		}
		return false;
	};

	const getSubscriptions = async (value?: {limit?: number; offset?: number}) => {
		if (accessToken && roomId) {
			const response = await RoomService.getTalkers(
				roomId,
				accessToken,
				value?.limit || LIMIT,
				value?.offset || 0,
				1
			);
			if (response.status === ResponseStatus.SUCCESS) {
				addSubscriptions(response.data);
			}
			return response.data;
		}
		return false;
	};

	const getSpeakers = async () => {
		if (accessToken && roomId) {
			const response = await RoomService.getSpeakers(roomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setSpeakers(response.data);
			}
		}
	};

	const getBannedTalkers = async () => {
		if (accessToken && roomId) {
			const response = await RoomService.getBannedTalkers(roomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setBannedTalkers(response.data);
			}
		}
	};

	const getWaitingSpeakers = async () => {
		if (accessToken && roomId) {
			const response = await RoomService.getWaitingSpeakers(roomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setWaitingSpeakers(response.data);
			}
		}
	};

	const getTalkersCount = async () => {
		if (accessToken && roomId) {
			const response = await RoomService.getTalkersCount(roomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setTalkersCount(response.data.talkersLength);
			}
		}
	};

	const getNextTalkers = async (
		event: React.BaseSyntheticEvent,
		userTalkersScrollRef: HTMLDivElement | null,
		setVisiblePreloaderBottom: (val: boolean) => void
	) => {
		const {scrollTop, scrollHeight} = event.target;

		if (
			userTalkersScrollRef &&
			userTalkersScrollRef.offsetHeight + scrollTop === Math.abs(scrollHeight)
		) {
			if (accessToken && !isLoading.current) {
				isLoading.current = true;
				setVisiblePreloaderBottom(true);
				userTalkersScrollRef?.scroll({top: userTalkersScrollRef?.scrollHeight});

				const responseData = await getTalkers({
					limit: LIMIT,
					offset: getNextTalkersOffset.current,
				});
				if (responseData.length === LIMIT) getNextTalkersOffset.current += LIMIT;
				else setVisiblePreloaderBottom(false);

				setTimeout(() => {
					isLoading.current = false;
				}, 1000);
			}
		}
	};

	return {
		getTalkers,
		getSubscriptions,
		getSpeakers,
		getBannedTalkers,
		getWaitingSpeakers,
		getTalkersCount,
		getNextTalkers,
	};
};

export default useTalkers;
