import {action, makeObservable, observable} from 'mobx';

class ScreenSharingService {
	@observable
	public isScreenSharing = false;

	@observable
	public isLocalAudioTrack = false;

	@observable
	public isMyTalkerShareScreen = false;

	@observable
	public isFullScreenVideo = false;

	@action
	public setIsScreenSharing = (value: boolean) => {
		this.isScreenSharing = value;
	};

	@action
	public setIsLocalAudioTrack = (value: boolean) => {
		this.isLocalAudioTrack = value;
	};

	@action
	public setIsMyTalkerShareScreen = (value: boolean) => {
		this.isMyTalkerShareScreen = value;
	};

	@action
	public setIsFullScreenVideo = (value: boolean) => {
		this.isFullScreenVideo = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ScreenSharingService();
