import {FunctionComponent} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';

const ShareGamblePreloader: FunctionComponent = function ShareGamblePreloader() {
	const {appLoaderIcon} = useLocalObservable(() => appService);
	const {getAppIcon} = useAppData();

	return <div className='share-gamble__preloader'>{getAppIcon(appLoaderIcon)}</div>;
};

export default ShareGamblePreloader;
