import {stickersEmojiBtnsData} from 'data/stickersEmojiBtnsData';
import {MAX_TEXTAREA_LENGTH, MAX_TEXTAREA_LENGTH_FOR_MODERATOR} from 'constants/constants';
import AppThemes from 'models/enums/AppThemes.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Sticker, StickersEmojiControlsBtn, Talker} from 'models/room';
import appService from 'store/appService';
import roomServices from 'store/roomService';
import chatServices from 'store/chatService';
import stickersEmojiServices from 'store/stickersEmojiService';
import modalServices from 'store/modalService';
import suggestService from 'store/suggestService';
import copybettingService from 'store/copybettingService';
import gambleService from 'store/gambleService';

import checkRealTextLength from 'utils/checkRealTextLength';
import debounce from 'lodash/debounce';

import useAppData from 'hooks/useAppData';
import useStickers from 'hooks/useStickers';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Button from 'components/hoc/Button';
import useL10n from 'l10n/useL10n';
import './stickersEmojiPicker.scss';
import {isValidUrl} from 'utils/checkValidLink';
import ToasNames from 'models/enums/ToasNames.enum';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import {ToastMessageColor} from 'components/toasts/Toast';
import toastService from 'store/toastService';
import userService from 'store/userService';
import {StickersPicker} from './stickersPicker';

const StickersEmojiViewer: FunctionComponent = function StickersEmojiViewer() {
	const myTalkerRef = useRef<Talker | null>(null);
	const [activeBtn, setActiveBtn] = useState(StickersEmojiType.STICKERS);
	const isVisibleLinksToastWarning = useRef(false);
	const {language, appTheme, appIcons, showDefaultStickers, enableLinksBlock} = useLocalObservable(
		() => appService
	);
	const {myTalker} = useLocalObservable(() => roomServices);
	const {setTextareaValue, setVisibleTextareaSendBtn, setBlockedBtnSend} = useLocalObservable(
		() => chatServices
	);
	const {visibleStickersEmoji, stickerPacks, togglleVisibleStickersEmoji} = useLocalObservable(
		() => stickersEmojiServices
	);
	const {isModalOpen, imagePreviewModalVisible} = useLocalObservable(() => modalServices);
	const {setSuggestStickers, toggleVisibleSuggestSticker} = useLocalObservable(
		() => suggestService
	);
	const {currentBetForShare} = useLocalObservable(() => copybettingService);
	const {currentGambleForShare} = useLocalObservable(() => gambleService);
	const {addToast, toasts} = useLocalObservable(() => toastService);
	const {userData} = useLocalObservable(() => userService);

	const {getAppIcon} = useAppData();
	const {findStickerByEmoji} = useStickers();

	const {IcoToastClose, IcoStickers, IcoEmoji} = appIcons;

	const {controlPanel, toasts: toastsTranslate} = useL10n();

	const hideStickers = imagePreviewModalVisible || currentBetForShare || currentGambleForShare;

	const maxTextareaLength = () =>
		myTalkerRef.current?.isModer ? MAX_TEXTAREA_LENGTH_FOR_MODERATOR : MAX_TEXTAREA_LENGTH;

	const onClickStickersEmojiViewerHandler = () => {
		togglleVisibleStickersEmoji(false);
	};

	useEffect(() => {
		isVisibleLinksToastWarning.current = !!toasts.find(
			t => t.name && t.name === ToasNames.LINKPROHIBITED
		);
	}, [toasts]);

	const onEmojiSelectHandler = (emoji: any) => {
		const {unified} = emoji;
		const textarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');

		if (unified && !textarea?.value) {
			const foundStickers = findStickerByEmoji(unified);

			if (foundStickers.length && !hideStickers) {
				setSuggestStickers([...foundStickers]);
				toggleVisibleSuggestSticker(true);
			}
		} else {
			setSuggestStickers([]);
			toggleVisibleSuggestSticker(false);
		}

		if (
			textarea &&
			checkRealTextLength(textarea.value) + emoji.native.length < maxTextareaLength()
		) {
			if (textarea.selectionStart !== textarea.value.length) {
				const {selectionStart} = textarea;
				setTextareaValue(
					`${textarea.value.slice(0, selectionStart)}${emoji.native}${textarea.value.slice(
						textarea.selectionStart,
						textarea.value.length
					)}`
				);
				textarea.setSelectionRange(
					selectionStart + emoji.native.length,
					selectionStart + emoji.native.length
				);
			} else {
				setTextareaValue(`${textarea.value}${emoji.native}`);
				textarea.setSelectionRange(textarea.value.length, textarea.value.length);
			}
			if (enableLinksBlock) {
				if (!myTalker?.isModer && !userData?.isModer) {
					if (textarea && isValidUrl(textarea.value)) {
						setBlockedBtnSend(true);
						setVisibleTextareaSendBtn(true);

						if (!isVisibleLinksToastWarning.current) {
							setBlockedBtnSend(true);
							addToast({
								name: ToasNames.LINKPROHIBITED,
								iconName: ToastIconsName.brickRed,
								liveTime: 3000,
								message: toastsTranslate.linkProhibited,
								messageColor: ToastMessageColor.ERROR,
								cancellable: true,
							});
						}
						textarea.blur();
						textarea.focus();
						return;
					}
				}
			}

			textarea.blur();
			textarea.focus();
			setBlockedBtnSend(false);
			setVisibleTextareaSendBtn(true);
		}
	};

	const getFilteredBtnsData = () => {
		if (showDefaultStickers) {
			return imagePreviewModalVisible || hideStickers
				? stickersEmojiBtnsData.filter(el => el.tabName !== StickersEmojiType.STICKERS)
				: stickersEmojiBtnsData;
		}
		return stickersEmojiBtnsData.filter(el => el.tabName !== StickersEmojiType.STICKERS);
	};

	const renderControlsItem = useCallback(
		(btn: StickersEmojiControlsBtn) => {
			if (
				(stickerPacks.length && btn.tabName === StickersEmojiType.STICKERS) ||
				btn.tabName === StickersEmojiType.EMOJI
			) {
				return (
					<div className='stickers-emoji-viewer__controls-item' key={btn.id}>
						<Button
							className={`stickers-emoji-viewer__controls-button stickers-emoji-viewer__controls-button--${
								btn.tabName
							} ${btn.tabName === activeBtn ? 'active' : ''}`}
							onClick={() => setActiveBtn(btn.tabName)}>
							{getAppIcon(btn.icon === 'IcoStickers' ? IcoStickers.pic : IcoEmoji.pic)}
							{controlPanel.tabName(btn.tabName)}
						</Button>
					</div>
				);
			}
			return null;
		},
		[activeBtn, stickerPacks]
	);

	const renderStickersRmojiItems = useCallback(() => {
		switch (activeBtn) {
			case StickersEmojiType.STICKERS: {
				if (stickerPacks.length) {
					return (
						<div className='stickers-emoji-viewer__item stickers-emoji-viewer__item--stickers'>
							<div className='stickers-emoji-viewer__item-axis-y'>
								<StickersPicker />
							</div>
						</div>
					);
				}
				return null;
			}
			case StickersEmojiType.EMOJI: {
				return (
					<div className='stickers-emoji-viewer__item stickers-emoji-viewer__item--emoji'>
						<Picker
							data={data}
							className='emoji-picker'
							theme={appTheme && appTheme.name === AppThemes.DARK ? 'dark' : 'light'}
							locale={language === LanguageTag.ru ? LanguageTag.ru : LanguageTag.en}
							navPosition='bottom'
							searchPosition='none'
							previewPosition='none'
							dynamicWidth
							onEmojiSelect={onEmojiSelectHandler}
						/>
					</div>
				);
			}
			default: {
				return null;
			}
		}
	}, [activeBtn, stickerPacks]);

	useEffect(() => {
		if (myTalker) {
			myTalkerRef.current = myTalker;
		}
	}, [myTalker]);

	useEffect(() => {
		if (stickerPacks.length && showDefaultStickers && !hideStickers) {
			setActiveBtn(StickersEmojiType.STICKERS);
			return;
		}
		setActiveBtn(StickersEmojiType.EMOJI);
	}, [
		stickerPacks.length,
		showDefaultStickers,
		imagePreviewModalVisible,
		currentBetForShare,
		currentGambleForShare,
	]);

	useEffect(() => {
		if (isModalOpen) {
			togglleVisibleStickersEmoji(false);
		}
	}, [isModalOpen]);

	if (!visibleStickersEmoji) {
		return null;
	}

	return (
		<div className='stickers-emoji-viewer'>
			<Button className='stickers-emoji-viewer__close' onClick={onClickStickersEmojiViewerHandler}>
				{getAppIcon(IcoToastClose.pic)}
			</Button>
			<div className='stickers-emoji-viewer__container'>
				<div className='stickers-emoji-viewer__controls'>
					{getFilteredBtnsData().map(renderControlsItem)}
				</div>
				<div className='stickers-emoji-viewer__items'>{renderStickersRmojiItems()}</div>
			</div>
		</div>
	);
};

export default observer(StickersEmojiViewer);
