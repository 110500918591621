/* eslint-disable max-lines */
import AppAuthStatus from 'models/enums/AppAuthStatus.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import UserVipStatus from 'models/enums/UserVipStatus.enum';
import {RegisterRequest} from 'models/requests';
import {RegisterResponseData} from 'models/responses';
import {Offer} from 'models/offer';
import appService from 'store/appService';
import userService from 'store/userService';
import modalService from 'store/modalService';
import badgeService from 'store/badgeService';
import tooltipsService from 'store/tooltipsService';

import OauthService from 'services/api/OauthService';
import UserService from 'services/api/UserService';
import useSearchParams from 'hooks/useSearchParams';
import useAntimat from 'hooks/useAntimat';
import useSettings from 'hooks/useSettings';
import {encryptionUserName} from 'utils/encryptionUserName';
import {useLocalObservable} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import {User} from 'models/user';
import {useEffect, useRef} from 'react';
import welcomeService from 'store/welcomeService';
import offerService from 'store/offerService';
import roomService from 'store/roomService';

import {WelcomeShowFrequency} from 'models/enums/WelcomeShowFrequency.enum';
import OpenProfileType from 'models/enums/OpenProfileType.enum';

import useAnalytics from './useAnalytics';

const useUser = () => {
	const history = useHistory();

	const {
		language,
		appEnableAgreementAndRules,
		changeDeleteState,
		setAppAuthStatus,
		isAppSettings,
		appEnableUserStatus,
		appEnableDecrypt,
		appEnableRegistrationName,
		appEnableRegistrationPicUrl,
		appEnableRegistrationPicId,
		appEnableNameVerification,
		setIsAppSettings,
		appEnableWelcome,
		isWelcomePopupShown,
		setIsWelcomePopupShown,
		enableOpenProfile,
		openProfileType,
		appEnableMessageTranslation,
		appEnableOauth,
		welcomeShowOnce,
		welcomeShowFrequency,
		welcomeIsDelayed,
		welcomeDelay,
		appReadOnly,
	} = useLocalObservable(() => appService);
	const {
		accessToken,
		updateUserData,
		setAccessToken,
		setUserData,
		setUserId,
		setDecryptedUserId,
		setTranslateMode,
		userData,
	} = useLocalObservable(() => userService);
	const {
		agreementAndChatRulesModal,
		welcomeModalVisible,
		toggleAgreementAndChatRulesModal,
		toggleChatBotRulesModal,
		toggleWelcomeModalVisible,
		toggleOfferModalVisible,
		toggleBadgeModalVisible,
	} = useLocalObservable(() => modalService);
	const {welcomeMessage} = useLocalObservable(() => welcomeService);
	const {offers, setOffer} = useLocalObservable(() => offerService);
	const {roomId} = useLocalObservable(() => roomService);
	const {setBadgePopupArray} = useLocalObservable(() => badgeService);
	const {setTranslateTooltipVisible} = useLocalObservable(() => tooltipsService);
	const {
		search,
		apikeyFromUrl,
		userIdFromUrl,
		nicknameFromUrl,
		picFromUrl,
		picIdFromUrl,
		langFromUrl,
		statusNameFromUrl,
		readonlyFromUrl,
		profileUrlFromUrl,
		authCodeFromUrl,
	} = useSearchParams();
	const {getSettingsPartnerWithServices} = useSettings();
	const {checkName} = useAntimat();
	const {sendAnalytics} = useAnalytics();
	const location = useLocation();

	const userIdLocal =
		!userIdFromUrl && readonlyFromUrl && readonlyFromUrl === 'true' ? '0' : userIdFromUrl;
	const isDisabledMode = location.pathname === '/disabled';
	const userOffersRef = useRef<any>(null);

	const userExtraDataCheckAgreementAndChatRules = (user: User) => {
		const userExtraData = user?.extraData;
		const userExtraDataFindRules = userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'rules')
			: false;
		if (
			appEnableAgreementAndRules &&
			(!userExtraData ||
				(userExtraData &&
					(!userExtraDataFindRules ||
						(userExtraDataFindRules && !JSON.parse(userExtraData).rules.agreementAndChat))))
		) {
			toggleAgreementAndChatRulesModal(true);
		}
	};

	const userPatchExtraData = async (extraDataForPatchUser: any) => {
		const response = await UserService.patchUserData(
			{extraData: JSON.stringify(extraDataForPatchUser)},
			accessToken
		);
		if (response.status === ResponseStatus.SUCCESS) {
			updateUserData(response.data);
		}
	};

	const getUserExtraDataParse = (user: User) => {
		return user?.extraData ? JSON.parse(user.extraData) : {};
	};

	const showWelcomeModal = () => {
		toggleWelcomeModalVisible(true);
		setIsWelcomePopupShown(true);
		sendAnalytics('ip_viewed', {
			header: welcomeMessage?.title || 'none',
			show: welcomeShowOnce ? 'once' : 'multiple_times',
			show_when:
				welcomeShowFrequency === WelcomeShowFrequency.ON_ENTRY ? 'on_each_entry' : 'once_daily',
		});
	};

	const userExtraDataCheckWelcome = (user: User, isChatRulesShown = true) => {
		const userExtraData = user?.extraData;
		const userExtraDataFindWelcome = userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'welcome')
			: false;
		let isWelcomeShown = false;
		const showWelcome = () => {
			if (welcomeIsDelayed) {
				setTimeout(() => {
					showWelcomeModal();
				}, welcomeDelay);
				return isWelcomeShown;
			}
			showWelcomeModal();
			return isWelcomeShown;
		};

		const checkWelcomeLastShownTime = () => {
			const welcomeLastShownTime = localStorage.getItem('welcomeLastShownTime');
			if (welcomeLastShownTime) {
				const day = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
				if (new Date(welcomeLastShownTime).getTime() <= day) {
					showWelcome();
					isWelcomeShown = true;
					localStorage.setItem('welcomeLastShownTime', new Date().toISOString());
				}
				return isWelcomeShown;
			}
			showWelcome();
			isWelcomeShown = true;
			localStorage.setItem('welcomeLastShownTime', new Date().toISOString());
			return isWelcomeShown;
		};

		if (
			(!agreementAndChatRulesModal || !isChatRulesShown) &&
			appEnableWelcome &&
			!isWelcomePopupShown
		) {
			if (!welcomeShowOnce) {
				switch (welcomeShowFrequency) {
					case WelcomeShowFrequency.ON_ENTRY: {
						showWelcome();
						isWelcomeShown = true;
						break;
					}
					case WelcomeShowFrequency.DAILY: {
						checkWelcomeLastShownTime();
						break;
					}
					default: {
						showWelcomeModal();
						isWelcomeShown = true;
					}
				}

				return isWelcomeShown;
			}

			if (!userExtraData || (userExtraData && !userExtraDataFindWelcome)) {
				showWelcomeModal();
				isWelcomeShown = true;
				userPatchExtraData({
					...getUserExtraDataParse(user),
					welcome: true,
				});
			}
			return isWelcomeShown;
		}
		return isWelcomeShown;
	};

	const showOfferModal = (offer: Offer) => {
		toggleOfferModalVisible(true);
		setOffer(offer);
		sendAnalytics('hl_viewed', {hl_content: offer.title || offer.text, type: 'pop-up'});
	};

	const findUserExtraDataOffer = (userExtraData: string | undefined): string | false => {
		return userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'offers') || false
			: false;
	};

	const findUserExtraDataBadges = (userExtraData: string | undefined): string | false => {
		return userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'badges') || false
			: false;
	};

	const getUserOffers = (userExtraData: string | undefined) => {
		const userExtraDataFindOffer: string | false = findUserExtraDataOffer(userExtraData);
		const viewedOffers =
			userExtraData && userExtraDataFindOffer
				? JSON.parse(userExtraData)[userExtraDataFindOffer]
				: [];
		return viewedOffers;
	};

	const getUserViewedBadges = (userExtraData: string | undefined) => {
		const userExtraDataFindBadges: string | false = findUserExtraDataBadges(userExtraData);
		const viewedOffers =
			userExtraData && userExtraDataFindBadges
				? JSON.parse(userExtraData)[userExtraDataFindBadges]
				: [];
		return viewedOffers;
	};

	const getUserRoomOffers = (userOffers: {roomId: string; items: number[]}[]) => {
		return userOffers.find(item => item.roomId === roomId)?.items || [];
	};

	const findCurrentOffer = (userOffers: number[]) => {
		const notViewedOffers = userOffersRef.current?.filter(
			(offer: Offer) => !userOffers.includes(offer.id)
		);
		return notViewedOffers?.length ? notViewedOffers[0] : null;
	};

	const hasOffers = () => {
		return offers !== null && offers.length > 0;
	};

	const shouldShowOfferModal = (isSomePopupVisible: boolean, user: User) => {
		return (
			(!agreementAndChatRulesModal && !welcomeModalVisible && user.isOnboard) ||
			(!isSomePopupVisible && user.isOnboard)
		);
	};

	const shouldShowBadgeModal = (isSomePopupVisible: boolean) => {
		return (!agreementAndChatRulesModal && !welcomeModalVisible) || !isSomePopupVisible;
	};

	const handleOfferDisplay = (currentOffer: Offer): void => {
		setTimeout(() => {
			showOfferModal(currentOffer);
		}, currentOffer.showDelayMS || 10000);
	};

	const checkOfferLastShownTime = (offer: Offer) => {
		const offerLastShownTime = localStorage.getItem('offerLastShownTime');
		if (offerLastShownTime) {
			const day = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
			if (new Date(offerLastShownTime).getTime() <= day) {
				handleOfferDisplay(offer);
				localStorage.setItem('offerLastShownTime', new Date().toISOString());
			}
			return;
		}
		handleOfferDisplay(offer);
		localStorage.setItem('offerLastShownTime', new Date().toISOString());
	};

	const updateUserOffers = (
		user: User,
		userOffers: {roomId: string; items: number[]}[],
		currentOfferId: number
	): void => {
		let updatedOffers = [...userOffers];
		if (userOffers.length) {
			if (userOffers.some(el => el.roomId === roomId))
				updatedOffers = userOffers.map(el => {
					if (el.roomId === roomId) {
						return {...el, items: [...el.items, currentOfferId]};
					}
					return el;
				});
			else if (roomId) updatedOffers.push({roomId, items: [currentOfferId]});
		} else if (roomId) {
			updatedOffers.push({roomId, items: [currentOfferId]});
		}

		userPatchExtraData({
			...getUserExtraDataParse(user),
			offers: [...updatedOffers],
		});
	};

	const updateUserViewedBadges = (user: User, userBadges: number[], newElems: number[]): void => {
		const updatedBadges = [...userBadges];

		if (newElems) {
			newElems.forEach(el => {
				updatedBadges.push(el);
			});
		}

		userPatchExtraData({
			...getUserExtraDataParse(user),
			badges: [...updatedBadges],
		});
	};
	const userExtraDataCheckBadge = (user: User, isSomePopupVisible = true): boolean => {
		const userExtraData: string | undefined = user?.extraData;
		const userViewedBadges: any = getUserViewedBadges(userExtraData);

		const findNewElems = user.badges?.filter(element => !userViewedBadges.includes(element.id));
		const findNewElemsIds = findNewElems?.map(elem => elem.id);

		if (findNewElems?.length && findNewElemsIds?.length) {
			if (shouldShowBadgeModal(isSomePopupVisible)) {
				updateUserViewedBadges(user, userViewedBadges, findNewElemsIds);
				setBadgePopupArray(
					findNewElems.filter(
						el => el.communicationPic || el.communicationText || el.communicationTitle
					)
				);
				toggleBadgeModalVisible(true);
			}
			return true;
		}
		return false;
	};

	const userExtraDataCheckOffer = (user: User, isSomePopupVisible = true): void => {
		const userExtraData: string | undefined = user?.extraData;
		const userOffers: any = getUserOffers(userExtraData);
		const userRoomOffers = getUserRoomOffers(userOffers);

		let currentOffer: Offer | null = findCurrentOffer(userRoomOffers);
		if (currentOffer) setOffer(currentOffer);

		if (
			userRoomOffers.length &&
			currentOffer === null &&
			hasOffers() &&
			offers !== null &&
			offers.some(offer => offer.showMultipleTimes)
		) {
			const multipleTimesOffers = offers.filter(offer => offer.showMultipleTimes);
			currentOffer = multipleTimesOffers[Math.floor(Math.random() * multipleTimesOffers.length)];
			if (currentOffer) {
				setOffer(currentOffer);
			}
		} else if (!userRoomOffers.length && hasOffers()) {
			// eslint-disable-next-line prefer-destructuring
			if (offers) currentOffer = offers[0];
			setOffer(currentOffer);
		}

		if (currentOffer && shouldShowOfferModal(isSomePopupVisible, user)) {
			if (currentOffer.showMultipleTimes) {
				switch (currentOffer.showFrerquency) {
					case WelcomeShowFrequency.ON_ENTRY: {
						handleOfferDisplay(currentOffer);
						break;
					}
					case WelcomeShowFrequency.DAILY: {
						checkOfferLastShownTime(currentOffer);
						break;
					}
					default: {
						handleOfferDisplay(currentOffer);
					}
				}

				if (!userRoomOffers.includes(currentOffer.id))
					updateUserOffers(user, userOffers, currentOffer.id);
				return;
			}

			handleOfferDisplay(currentOffer);
			updateUserOffers(user, userOffers, currentOffer.id);
		} else if (appEnableMessageTranslation) setTranslateTooltipVisible(true);
	};

	const userExtraDataCheckTooltip = (user: User, tooltip: string) => {
		const userExtraDataParse = user?.extraData ? JSON.parse(user.extraData) : {};

		const userExtraDataFindTooltips = Object.keys(userExtraDataParse).find(
			item => item === 'tooltips'
		);

		const currentTooltip = userExtraDataFindTooltips
			? Object.keys(userExtraDataParse[userExtraDataFindTooltips]).find(el => el === tooltip)
			: null;

		if (!currentTooltip) {
			return false;
		}
		return true;
	};

	const userExtraDataPatchTooltip = async (user: User, tooltip: string) => {
		userPatchExtraData({
			...getUserExtraDataParse(user),
			tooltips: {...getUserExtraDataParse(user).tooltips, [tooltip]: true},
		});
	};

	const userExtraDataCheckTranslateMode = (user: User) => {
		const userExtraData = user?.extraData;
		const translateMode = userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'translateMode')
			: false;

		if (appEnableMessageTranslation && userExtraData && translateMode) {
			setTranslateMode(JSON.parse(userExtraData)[translateMode]);
			return true;
		}
		return false;
	};

	const userExtraDataPatchTranslateMode = async (
		user: User,
		translateMode: {
			enable: boolean;
			lang: {
				languageCode: string;
				displayName: string;
			};
		}
	) => {
		userPatchExtraData({
			...getUserExtraDataParse(user),
			translateMode,
		});
	};

	const userExtraDataCheckChatBotRules = async (user: User) => {
		const userExtraDataParse = user?.extraData ? JSON.parse(user.extraData) : {};
		const userExtraDataFindRules = Object.keys(userExtraDataParse).find(item => item === 'rules');
		let extraDataForPatchUser = {};

		if (userExtraDataParse) {
			if (userExtraDataFindRules) {
				const currentTooltip = userExtraDataFindRules
					? Object.keys(userExtraDataParse[userExtraDataFindRules]).find(el => el === 'chatBot')
					: null;

				if (currentTooltip) {
					toggleChatBotRulesModal(false);
					return;
				}

				extraDataForPatchUser = {
					...userExtraDataParse,
					rules: {...userExtraDataParse.rules, chatBot: true},
				};
			} else {
				extraDataForPatchUser = {
					...userExtraDataParse,
					rules: {chatBot: true},
				};
			}
		} else {
			extraDataForPatchUser = {rules: {chatBot: true}};
		}

		userPatchExtraData(extraDataForPatchUser);

		toggleChatBotRulesModal(true);
	};

	const checkAndUpdateUserData = async (data: RegisterResponseData, externalId: string) => {
		let patchUserDataObj: {name?: string; picId?: string; pic?: string} = {};
		if (nicknameFromUrl && data.user.name !== encryptionUserName(nicknameFromUrl)) {
			patchUserDataObj = {
				name: encryptionUserName(nicknameFromUrl),
			};
		}

		if (picIdFromUrl && data.user.pic?.search(picIdFromUrl) === -1) {
			patchUserDataObj = {
				...patchUserDataObj,
				picId: picIdFromUrl,
			};
		}

		if (picFromUrl && data.user.pic !== picFromUrl) {
			patchUserDataObj = {
				...patchUserDataObj,
				pic: picFromUrl,
			};
		}

		if (patchUserDataObj.name || patchUserDataObj.picId || patchUserDataObj.pic) {
			const response = await UserService.patchUserData(patchUserDataObj, data.access_token);
			if (response.status === ResponseStatus.SUCCESS) {
				updateUserData(response.data);
			} else {
				updateUserData(patchUserDataObj);
			}
		}
	};

	const setRegParams = (params: RegisterRequest) => {
		let regParams = {...params};
		if (
			appEnableUserStatus &&
			statusNameFromUrl &&
			Object.keys(UserVipStatus).includes(statusNameFromUrl.toUpperCase())
		) {
			regParams = {
				...regParams,
				isVip: true,
				vipStatus: statusNameFromUrl.toUpperCase() as UserVipStatus,
			};
		}

		if (appEnableRegistrationName && nicknameFromUrl) {
			regParams = {
				...regParams,
				name: nicknameFromUrl,
			};
		}

		if (appEnableRegistrationPicId && picIdFromUrl) {
			regParams = {
				...regParams,
				picId: picIdFromUrl,
			};
		}

		if (appEnableRegistrationPicUrl && picFromUrl) {
			regParams = {
				...regParams,
				pic: picFromUrl,
			};
		}

		if (enableOpenProfile && openProfileType === OpenProfileType.GET && profileUrlFromUrl) {
			regParams = {
				...regParams,
				profileUrl: profileUrlFromUrl,
			};
		}

		return regParams;
	};

	const checkNicknameContainsMat = async () => {
		if (nicknameFromUrl && appEnableNameVerification) {
			const {isVailable} = await checkName(
				nicknameFromUrl,
				langFromUrl ? langFromUrl.toLowerCase() : language
			);
			if (!isVailable) {
				history.push({
					pathname: '/error',
					search: `${search}&errorNumber=4`,
				});
				setAppAuthStatus(AppAuthStatus.ERROR);
				return true;
			}
			return false;
		}
		return false;
	};

	const getDecryptedUserId = async (externalId: string) => {
		if (+externalId !== 0 && apikeyFromUrl) {
			const decrypResponce = await OauthService.decryptUserId(externalId, apikeyFromUrl);
			if (decrypResponce.status === ResponseStatus.SUCCESS) {
				const {result, decrypted} = decrypResponce.data;
				amplitude.setUserId(decrypted);
				setDecryptedUserId(decrypted);
			}
		}
	};

	const checkNickname = () => {
		if (appEnableRegistrationName && !nicknameFromUrl && !readonlyFromUrl) {
			history.push({
				pathname: '/error',
				search,
			});
			setAppAuthStatus(AppAuthStatus.ERROR);
			return false;
		}
		return true;
	};

	const userOauth = async () => {
		setIsAppSettings(false);

		if (apikeyFromUrl && appEnableOauth && authCodeFromUrl) {
			if (!checkNickname()) return;
			if (await checkNicknameContainsMat()) return;

			let regParams: RegisterRequest = {
				apiKey: apikeyFromUrl,
				authCode: authCodeFromUrl,
			};

			regParams = setRegParams(regParams);

			const token = localStorage.getItem('accessToken');

			const response = await OauthService.registerUser(regParams, token);
			if (response.status === ResponseStatus.SUCCESS) {
				const {externalId} = response.data.user;
				setAccessToken(response.data.access_token);
				localStorage.setItem('accessToken', response.data.access_token);
				setUserData({
					...response.data.user,
					externalId,
					ban: {...response.data.ban},
				});

				if (externalId) {
					setUserId(externalId);
					if (appEnableDecrypt) {
						await getDecryptedUserId(externalId);
					} else amplitude.setUserId(externalId);
				}

				changeDeleteState(false);
				if (response.data.isDeleted)
					history.push({
						pathname: '/restore',
						search,
					});

				if (
					response.data.user.externalId &&
					(appEnableRegistrationName || appEnableRegistrationPicId || appEnableRegistrationPicUrl)
				) {
					await checkAndUpdateUserData(response.data, response.data.user.externalId);
				}
				return;
			}

			setAppAuthStatus(AppAuthStatus.ERROR);
		}
		setAppAuthStatus(AppAuthStatus.ERROR);
	};

	const userAuth = async () => {
		setIsAppSettings(false);

		if (apikeyFromUrl && userIdLocal) {
			if (!checkNickname()) return;

			let regParams: RegisterRequest = {
				apiKey: apikeyFromUrl,
				externalId: userIdLocal,
			};
			regParams = setRegParams(regParams);

			setUserId(userIdLocal);

			if (appEnableDecrypt) {
				await getDecryptedUserId(userIdLocal);
			} else amplitude.setUserId(userIdLocal);

			if (await checkNicknameContainsMat()) return;

			const response = await OauthService.registerUser(regParams);
			if (response.status === ResponseStatus.SUCCESS) {
				setUserData({
					...response.data.user,
					externalId: userIdLocal,
					ban: {...response.data.ban},
				});
				setAccessToken(response.data.access_token);
				changeDeleteState(false);
				if (response.data.isDeleted)
					history.push({
						pathname: '/restore',
						search,
					});

				if (
					appEnableRegistrationName ||
					appEnableRegistrationPicId ||
					appEnableRegistrationPicUrl
				) {
					await checkAndUpdateUserData(response.data, userIdLocal);
				}
				return;
			}

			setAppAuthStatus(AppAuthStatus.ERROR);
		}
		setAppAuthStatus(AppAuthStatus.ERROR);
	};

	useEffect(() => {
		if (!appEnableOauth && !authCodeFromUrl && isAppSettings && userIdLocal && !isDisabledMode) {
			userAuth();
			return;
		}
		if (appEnableOauth && authCodeFromUrl && isAppSettings && !isDisabledMode) {
			userOauth();
		}
	}, [isAppSettings, appEnableOauth]);

	useEffect(() => {
		if (offers) userOffersRef.current = offers;
	}, [offers]);

	const getAppSettings = async () => {
		if (apikeyFromUrl) {
			await getSettingsPartnerWithServices(apikeyFromUrl);
		}
	};

	return {
		getAppSettings,
		userExtraDataCheckAgreementAndChatRules,
		userExtraDataCheckChatBotRules,
		userPatchExtraData,
		getUserExtraDataParse,
		userExtraDataCheckTooltip,
		userExtraDataPatchTranslateMode,
		userExtraDataCheckTranslateMode,
		userExtraDataPatchTooltip,
		userExtraDataCheckWelcome,
		userExtraDataCheckBadge,
		userExtraDataCheckOffer,
		userOauth,
	};
};

export default useUser;
