/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'सहमति',
		subTitle: 'लाइसेंस अनुबंध और गोपनीयता नीति',
		textFirst: 'मैं शर्तों को स्वीकार करता हूं और परिभाषित शर्तों ',
		textSecond: 'के तहत व्यक्तिगत डेटा के प्रसंस्करण के लिए अपनी सहमति देता हूं',
		license: 'लाइसेंस समझौता और',
		policy: 'गोपनीयता नीति।',
	},
	controlPanel: {
		streamEnded: 'प्रसारण समाप्त हुआ',
		banAlert: 'आप चैट में नहीं लिख सकते',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'स्टिकर' : 'इमोजी',
	},
	accountDeletedScreen: {
		title: 'प्रोफ़ाइल मिटा दी गई है',
		text: (date: string) => `आप अपनी प्रोफ़ाइल को पुनर्स्थापित कर सकते हैं ${date}`,
	},
	nameScreen: {
		title: 'चैट नाम',
		inputPlaceholder: 'अपना नाम दर्ज करें',
		nameError: 'अमान्य नाम',
	},
	avatarScreen: {
		title: 'अवतार',
		label: 'दूसरा चुनें:',
	},
	settingsScreen: {
		title: 'सेटिंग्स',
		personal: 'व्यक्तिगत सेटिंग',
		room: 'कक्ष सेटिंग्स',
		profileBtn: 'प्रोफ़ाइल',
		languageBtn: 'भाषा',
		blockedBtn: 'अवरुद्ध',
		rules: 'चैट नियम',
		deleteProfile: 'प्रोफ़ाइल हटाएं',
		slowModeBtn: 'धीमा मोड',
		agora: {
			microphone: 'माइक्रोफ़ोन',
			playbackDevice: 'ध्वनि स्रोत',
		},
	},
	languageScreen: {
		title: 'भाषा और अन्तराफलक',
		messagesTranslate: 'संदेश अनुवाद',
		translateMessagesMode: 'चैट संदेश अनुवाद करें',
		translateMessagesTo: 'संदेश अनुवाद करें:',
	},
	rulesScreen: {
		title: 'चैट नियम',
	},
	profileScreen: {
		title: 'प्रोफ़ाइल',
		selectAnotherPic: 'कोई भिन्न अवतार चुनें',
		chatNameLabel: 'चैट नाम',
		changeName: 'परिवर्तन',
	},
	blockedScreen: {
		title: 'अवरुद्ध',
		emptyListText: 'कोई अवरुद्ध उपयोगकर्ता नहीं',
	},
	chatScreen: {
		inputPlaceholder: 'संदेश...',
		cantWrite: 'आप चैट में नहीं लिख सकते',
		chatSubmenu: {
			hideMessage: 'संदेश छिपाएँ',
			showMessage: 'संदेश दिखाएँ',
			removeFromSpeakers: 'स्पीकर से हटाएँ',
			setSpeaker: 'एक वक्ता बनाओ',
			hideOnlyMessage: 'संदेश छिपाएँ',
			showOnlyMessage: 'संदेश दिखाएँ',
			reportMessage: 'संदेश की रिपोर्ट करें',
			reportUser: 'उपयोगकर्ता की रिपोर्ट करें',
			muteSpeaker: 'माइक्रोफ़ोन म्यूट करें',
			blockUser: 'ब्लॉक',
			unblockUser: 'अनब्लॉक',
			reply: 'उत्तर',
			copy: 'कॉपी',
			edit: 'परिवर्तन',
			delete: 'हटाएं',
			pinMessage: 'पिन',
			unpinMessage: 'अनपिन',
			replyInThread: 'थ्रेड में उत्तर दें',
		},
		actionMessage: {
			editing: 'संपादन',
			reply: 'उत्तर',
		},
		status: {
			top: 'शीर्ष',
		},
		translating: 'अनुवाद...',
	},
	chatUsersScreen: {
		title: 'प्रतिभागी',
		edited: 'बदल दिया गया',
		hidden: 'छुपा',
		unreadMessages: 'अपठित संदेश',
		usersSubmenu: {
			muteSpeaker: {
				title: 'किसी प्रतिभागी को म्यूट करें',
				subtitle: 'माइक्रोफ़ोन म्यूट करें',
			},
			setSpeaker: {
				title: 'स्पीकर से हटाएँ',
				subtitle: 'केवल टेक्स्ट चैट करें',
			},
			removeFromSpeaker: {
				title: 'एक वक्ता बनाओ',
				subtitle: 'प्रतिभागी आवाज से बात कर सकेंगे',
			},
			showMessages: {
				title: 'संदेश दिखाएँ',
				subtitle: 'केवल एडमिन ही देख सकता है',
			},
			hideMessages: {
				title: 'संदेश छिपाएँ',
				subtitle: 'केवल एडमिन ही देख सकता है',
			},
			blockUser: {
				title: 'अवरोध पैदा करना',
				subtitle: 'स्टॉप सूची में जोड़ें',
			},
			unblockUser: {
				title: 'अनब्लॉक',
				subtitle: 'उपयोगकर्ता को अनब्लॉक करें',
			},
			blockUserByUser: {
				title: 'अवरोध पैदा करना',
				subtitle: 'सभी उपयोगकर्ता संदेशों को छिपाएँ',
			},
			unblockUserByUser: {
				title: 'अनब्लॉक',
				subtitle: 'उपयोगकर्ता द्वारा सभी पोस्ट दिखाएं',
			},
			reportUser: {
				title: 'किसी उपयोगकर्ता की रिपोर्ट करें',
				subtitle: 'शिकायत गुमनाम रूप से भेजी जाएगी',
			},
		},
		days: {
			today: 'आज',
			yesterday: 'कल',
		},
		chatCreated: 'चैट बनाई गई',
	},
	alerts: {
		btns: {
			unblock: 'अनब्लॉक',
			block: 'ब्लॉक',
			send: 'भेजना',
			sendAndBlock: 'भेजें और ब्लॉक करें',
			show: 'दिखाओ',
			showAll: 'सब दिखाएं',
			cancel: 'रद्द करें',
			yes: 'हाँ',
			hide: 'चयनित छिपाएँ',
			hideAll: 'सभी को छिपाएं',
			make: 'करना',
			remove: 'दूर रखो',
			delete: 'हटाएं',
			deleteAccount: 'प्रोफ़ाइल हटाएं',
			close: 'बंद करना',
			pin: 'पिन',
			unpin: 'अनपिन',
			edit: 'संपादन करना',
			end: 'पूरा',
			endAndShare: 'पूरा करें और साझा करें',
			reset: 'पुनः स्थापित करें',
			rules: 'चैट नियम',
			contactus: 'हमें लिखें',
		},
		pinMessage: {
			title: 'संदेश पिन करें?',
		},
		unPinMessage: {
			title: 'संदेश अनपिन करें?',
		},
		accountRestored: {
			title: 'प्रोफ़ाइल पुनर्स्थापित की गई',
		},
		closeApp: {
			title: 'क्या आप सचमुच छोड़ना चाहते हैं?',
		},
		removeMessage: {
			title: 'संदेश को हटाएं?',
		},
		report: {
			title: 'शिकायत भेजें?',
			subtitle:
				'आप किसी उपयोगकर्ता को एक-दूसरे की पोस्ट देखने से रोकने के लिए उसे ब्लॉक भी कर सकते हैं।',
		},
		banUser: {
			title: (name: string) => `${name} ब्लॉक करें?`,
			subtitle: (text: string) => `उपयोगकर्ता को ${text} के लिए ब्लॉक कर दिया जाएगा`,
		},
		unBanUser: {
			title: (name: string) => `${name} अनब्लॉक करें??`,
		},
		showUserMessages: {
			title: (name: string) => `${name} से संदेश दिखाएँ??`,
			subTitle: 'अन्य उपयोगकर्ता इस उपयोगकर्ता की पोस्ट देख सकेंगे',
		},
		hideUserMessages: {
			title: (name: string) => `${name} से संदेश छिपाएँ?`,
			subTitle: 'अन्य उपयोगकर्ता अब इस उपयोगकर्ता की पोस्ट नहीं देख पाएंगे',
		},
		showUserMessage: {
			title: (name: string) => `${name} से संदेश दिखाएँ?`,
			subTitle: 'अन्य उपयोगकर्ता इस संदेश को देख सकेंगे',
		},
		hideUserMessage: {
			title: (name: string) => `${name} से संदेश छिपाएँ?`,
			subTitle: 'अन्य उपयोगकर्ता अब इस संदेश को नहीं देख पाएंगे',
		},
		blockUserInRoom: {
			title: (name: string) => `${name} को ब्लॉक करें?`,
			subTitle: 'उपयोगकर्ता इस कक्ष में भाग नहीं ले पाएगा',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name} को अनब्लॉक करें?`,
			subTitle: 'उपयोगकर्ता इस कक्ष में भाग ले सकेगा',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name} को ब्लॉक करें?`,
			subTitle: 'आप एक-दूसरे के संदेश नहीं देख पाएंगे',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `स्पीकर का ${name} बताएं?`,
			unTitle: (name: string) => `स्पीकर से ${name} हटाएँ?`,
		},
		banInApp: {
			title: 'व्यवस्थापक ने आपको पीछे से ब्लॉक कर दिया है',
			for: '',
			subtitle: (bannedUntil: string) => `आपको तब तक ब्लॉक किया गया है ${bannedUntil}`,
			permanent: 'आपको हमेशा के लिए ब्लॉक कर दिया गया है',
		},
		banInAppBadNickname: {
			title: 'व्यवस्थापक ने आपको अमान्य नाम के कारण ब्लॉक कर दिया है.',
			subtitle: 'अपनी प्रोफ़ाइल पर जाएं, अपना नाम बदलें और चैट दोबारा दर्ज करें',
		},
		deleteAccount: {
			title: 'प्रोफ़ाइल हटाना',
			subTitle:
				'सारा डेटा हटा दिया जाएगा. आप 30 दिनों के भीतर अपनी प्रोफ़ाइल पुनर्स्थापित कर सकेंगे.',
		},
		pollEdit: {
			title: 'जनमत संपादित करें?',
			subTitle: 'उपयोगकर्ता प्रतिक्रियाएँ रीसेट कर दी जाएंगी',
		},
		pollReset: {
			title: 'रीसेट?',
			subTitle: 'सर्वेक्षण सहेजा नहीं जाएगा',
		},
		pollEnd: {
			title: 'क्या आप वाकई सर्वेक्षण पूरा करना चाहते हैं?',
		},
		pollDelete: {
			title: 'सर्वेक्षण हटाएं?',
		},
		agoraCheck: {
			updateIOSText: 'सभी ऐप के उपकरणों का उपयोग करने के लिए iOS संस्करण को अपडेट करें।',
			allowAccess: {
				title: 'क्या आप कुछ कहना चाहते हैं?',
				text: 'कृपया एप्लिकेशन सेटिंग्स खोलें और माइक्रोफोन और स्पीकर तक पहुंच की अनुमति दें।',
			},
			microphoneAndSpeakerText:
				'14.3 संस्करण से स्वीकार्य माइक्रोफोन और स्पीकर का उपयोग उपलब्ध है।',
			microphoneText: '14.3 संस्करण से स्वीकार्य माइक्रोफोन और स्पीकर का उपयोग उपलब्ध है।',
			btn: 'ठीक है!',
		},
	},
	eventScreen: {
		title: 'निर्धारित कार्यक्रम',
		titleSoon: 'प्रसारण जल्द ही शुरू होगा',
		subtitle: 'चैट जल्दी ही खुलेगा, कृपया प्रतीक्षा करें।',
		days: 'दिन',
		hours: 'समय',
		minutes: 'मिनट',
		seconds: 'सेकंड',
		startTitle: 'शुरू',
	},
	toasts: {
		reciveComplaint: 'हमें आपकी शिकायत मिल गई है',
		setSpeakerRoleForUser: 'उपयोगकर्ता को स्पीकर के रूप में नियुक्त किया गया',
		unSpeakerRoleForUser: 'उपयोगकर्ता को स्पीकर से हटा दिया गया',
		messagesShown: 'संदेश दिखाए गए',
		messagesHidden: 'संदेश छिपाये गये',
		messageShown: 'संदेश दिखाया गया',
		messageHidden: 'संदेश छुपाया गया',
		userBlocked: 'उपयोगकर्ता को ब्लॉक कर दिया गया है',
		userUnBlocked: 'उपयोगकर्ता को अनब्लॉक कर दिया गया है',
		youAreSpeaker: 'आप स्पीकर हैं ! कुछ कहो',
		youAreNotSpeaker: 'एडमिन ने आपको स्पीकर से हटा दिया है',
		userIsSpeaker: (name: string) => `${name} अब स्पीकर है`,
		userNotSpeaker: (name: string) => `${name} अब स्पीकर  नहीं है`,
		yourUserNameInChat: (name: string) => `चैट में आपका नाम ${name}`,
		messageRemoved: 'संदेश हटाये',
		messageCopied: 'संदेश कॉपी किया गया',
		obscenities: 'व्याख्या करने का प्रयास करें',
		requestSent: 'अनुरोध भेज दिया गया है',
		requestDecline: 'एडमिन द्वारा अनुरोध वापस ले लिया गया',
		youareblocked: (text: string) =>
			`आपको ${text} के लिए ब्लॉक कर दिया गया था. आप चैट में नहीं लिख सकते`,
		youareunblocked: 'आपको अनब्लॉक कर दिया गया है. आप चैट में संदेश लिख सकते हैं',
		youAreModerator: 'आप संचालक हैं!',
		youAreNoModerator: 'अब आप संचालक नहीं हैं',
		micMuted: 'आपका माइक्रोफ़ोन म्यूट है',
		noInet: 'कोई इंटरनेट कनेक्शन नहीं',
		messagePinned: 'पोस्ट पिन किया गया',
		messageUnpinned: 'पोस्ट अनपिन किया गया',
		pollPublished: 'सर्वेक्षण प्रकाशित',
		pollPublishedError: 'गलती! बाद में प्रयास करें',
		pollAddPic: 'चित्र को अपलोड करें',
		pollAddPicError: 'कोई अन्य छवि चुनें',
		pollDeleted: 'पोल हटा दिया गया',
		pollEnded: 'सर्वेक्षण पूरा हुआ',
		sendStickerWhenSlowModeIsOn: (time: string) => `स्टीकर भेजे जाने के लिए ${time} प्रतीक्षा करें`,
		linkProhibited: 'आप चैट में लिंक नहीं भेज सकते।',
		phoneProhibited: 'आप चैट में फ़ोन नंबर नहीं भेज सकते हैं।',
		profileHidden: 'उपयोगकर्ता ने अपनी प्रोफ़ाइल छिपा दी है',
		imageSizeWarning: 'आप 10 एमबी तक की छवि अपलोड कर सकते हैं',
		imageBlocked: `मोडरेटर ने छवि अपलोड को ब्लॉक कर दिया है`,
		streamKeyCopied: 'प्रसारण कुंजी की प्रतिलिपि बनाई गई',
	},
	btns: {
		acceptandgo: 'स्वीकार करें और जारी रखें',
		saveandgo: 'सेव करें और जारी रखें',
		save: 'सेव',
		unblock: 'अनब्लॉक',
		block: 'ब्लॉक',
		restoreAccount: 'प्रोफ़ाइल पुनर्स्थापित करें',
		close: 'बंद करना',
	},
	coopyrights: 'Watchers.io द्वारा संचालित',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'किसी संदेश की रिपोर्ट करें' : 'उल्लंघन की रिपोर्ट करें',
			subtitle: 'शिकायतें गुमनाम रूप से भेजी जाती हैं',
			btns: {
				violence: 'अपमान, धमकियाँ, अपशब्द',
				provocations: 'उकसावा',
				personalDetails: 'व्यक्तिगत जानकारी',
				flood: 'बाढ़',
				spam: 'स्पैम',
				fraudBegging: 'धोखाधड़ी, भीख माँगना',
				other: 'अन्य',
			},
		},
		ban: {
			title: 'ब्लॉक उपयोगकर्ता',
			subtitle: 'ब्लॉक करने का कारण बताएं',
			btns: {
				insults: 'अपमान, धमकियाँ, अपशब्द',
				personal: 'व्यक्तिगत डेटा का प्रसार',
				flood: 'बाढ़',
				links: 'तीसरे पक्ष की सेवाओं के लिए लिंक',
				violence: 'हिंसा की धमकियाँ',
				fraud: 'धोखाधड़ी के प्रयास औरभीख मांगना',
				nickname: 'अमान्य उपनाम',
				politicial: 'राजनीतिक, कामुक और अन्य उकसावे',
				repeated: 'बार-बार उल्लंघन',
				auto: 'चैट उपयोगकर्ता की शिकायतें',
				spam: 'स्पैम',
			},
		},
		waitingSpeakers: {
			text: 'आपके पास प्रतीक्षा करने वाला कोई स्पीकर नहीं है',
			handsUp: 'हाथ उठाया',
			waiting: 'प्रतीक्षारत स्पीकर',
		},
		poll: {
			supTitles: {
				typeQuiz: 'सही उत्तर दीजिये',
				typeQuizMultiple: 'सही विकल्पों की जाँच करें',
				typePoll: 'अपनी राय साझा करें',
				typePollMultiple: 'कई विकल्प नोट किए जा सकते हैं',
				default: 'सार्वजनिक सर्वेक्षण',
			},
			btns: {
				reply: 'उत्तर',
				vote: 'वोट',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'प्रश्नोत्तरी',
				typePoll: 'अनेक उत्तर',
				default: 'सार्वजनिक सर्वेक्षण',
			},
			btns: {
				edit: 'जनमत संपादित करें',
				end: 'पूरा',
			},
		},
		pollCreated: {
			titles: {
				created: 'एक सर्वेक्षण बनाएं',
				typeQuiz: 'प्रश्नोत्तरी',
				typePoll: 'अनेक उत्तर',
				default: 'सार्वजनिक सर्वेक्षण',
			},
		},
		changeName: {
			title: 'अपना चैट नाम बदलें',
			subtitle: 'आपको एक अमान्य उपनाम के कारण ब्लॉक कर दिया गया है',
		},
		temporaryAgreement: {
			title: 'चैट नियम',
			rules: {
				bePolite: {
					title: 'विनम्र रहें',
					text: 'चैट में शत्रुतापूर्ण और अश्लील भाषा निषिद्ध है।',
				},
				beAttentive: {
					title: 'ध्यान से',
					text: 'व्यक्तिगत जानकारी साझा न करें या दूसरों से न पूछें।',
				},
				notAdvertise: {
					title: 'विज्ञापन न करें',
					text: 'अपनी राय या भावनाएँ साझा करना बेहतर है। सभी नियम',
				},
			},
			btns: {
				allRules: 'सभी नियम',
				acceptAndContinue: 'स्वीकार करें और जारी रखें',
			},
		},
		slowMode: {
			title: 'धीमा मोड',
			text: 'प्रतिभागी चयनित समयावधि के दौरान अधिक बार चैट संदेश नहीं भेज पाएंगे',
			enable: 'धीमा मोड सक्षम करें',
			disable: 'धीमा मोड बंद करें',
			sendTimeout: 'समय समाप्ति भेजें',
		},
		slowModeTimeout: {
			title: 'समय समाप्ति भेजें',
		},
		chatBot: {
			title: 'चैटबॉट का उपयोग करना',
			rules: [
				{
					title: 'आर्टिफिशियल इंटेलिजेंस',
					text: 'बॉट लोगों के साथ संचार का अनुकरण करता है और अपने ज्ञान के आधार पर जानकारी प्रदान करता है। हालाँकि, याद रखें कि उसके उत्तर सीमित हो सकते हैं और किसी व्यक्ति का स्थान नहीं लेंगे।',
				},
				{
					title: 'बॉट गलतियाँ कर सकता है',
					text: 'बॉट सूचना की सटीकता और विश्वसनीयता की गारंटी नहीं दे सकता।समाप्त',
				},
			],
			btns: {
				close: 'बंद करना',
			},
		},
		shareBet: {
			title: 'शेयर करना',
			noBetsTitle: 'आपके दांव नहीं मिल सके',
			noBetsText: 'आप गेम में 14 दिनों के भीतर लगाए गए दांवों को साझा कर सकते हैं',
			btns: {
				share: 'शेयर शर्त',
				copy: 'दांव दोहराएँ',
			},
			status: {
				null: 'खेल में',
				win: 'जीत',
				lose: 'हार',
			},
			betTypeV1: {
				express: 'बहु',
				single: 'एकल',
			},
			betTypeV2: {
				single: 'एकल',
				multi: 'बहु',
				system: 'System',
			},
			totalFactor: 'कोएफ़',
			bet: 'बेट',
			showMore: 'और दिखाओ',
		},
		shareGamble: {
			noGamblesText:
				'आपके पास साझा करने के लिए अभी तक कोई जीत नहीं है। खेलते रहें, और आपकी अगली जीत यहाँ दिखाई देगी ताकि आप इसे अन्य खिलाड़ियों के साथ साझा कर सकें!',
		},
		record: {
			text: 'प्रसारण रिकॉर्डिंग पर कार्रवाई की जा रही है',
		},
		translate: {
			title: 'संदेश अनुवाद',
			chooseLanguage: 'भाषा चुनें',
			descr: 'चैट संदेश स्वचालित रूप से आपके द्वारा चयनित भाषा में अनुवादित होंगे',
			btn: 'अनुवाद',
		},
		streamSettings: {
			title: 'स्ट्रीम सेटिंग्स',
			description:
				'वीडियो एन्कोडर डाउनलोड करें और इंस्टॉल करें। उसकी सेटिंग्स में स्ट्रीम कुंजी डालें',
			shareScreen: {
				title: 'स्क्रीन साझा करें',
				description: 'कोई विशेष सेटिंग्स की आवश्यकता नहीं है',
			},
			rtmp: {
				title: 'बाहरी स्ट्रीम स्रोत',
				description: 'स्ट्रीमिंग के लिए एक अतिरिक्त प्रोग्राम का उपयोग करें',
			},
			streamKey: 'स्ट्रीम कुंजी',
			showStreamKey: 'दिखाएं',
			hideStreamKey: 'छिपाएं',
			btns: {
				continue: 'जारी रखें',
				close: 'बंद करें',
				ok: 'समाप्त',
			},
		},
	},
	pinnedMessage: {
		title: 'पिन किया गया संदेश',
	},
	errorPage: {
		error: 'गलती',
		types: [
			// errorNumber 0
			{
				title: 'हम आपको नहीं पहचानते',
				text: 'चैट में पुनः प्रवेश करने का प्रयास करें',
			},
			// errorNumber 1
			{
				title: 'हमें चैट नहीं मिल रही है',
				text: 'दोबारा लॉगिन करने का प्रयास करें',
			},
			// errorNumber 2
			{
				title: 'चैट लोड नहीं हो सकी',
				text: 'रीबूट का प्रयास करें',
			},
			// errorNumber 3
			{
				title: 'हम आपको नहीं पहचानते',
				text: 'गुप्त मोड में चैट उपलब्ध नहीं है',
			},
			// errorNumber 4
			{
				title: 'अमान्य उपनाम',
				text: 'अपनी प्रोफ़ाइल पर जाएं, अपना उपनाम बदलें और फिर से चैट में प्रवेश करें।',
			},
		],
	},
	tooltips: {
		emotions: 'बदलने के लिए रुकें',
		badInet: 'ख़राब नेटवर्क कनेक्शन',
		blockedUsers: 'आप यहां ब्लॉक किए गए <br> उपयोगकर्ताओं की सूची देख सकते हैं।',
		waitingSpeakers: 'कुछ प्रतिभागी बोलना चाहते हैं।',
		wantSpeak: 'अपने वोट का अनुरोध करने के लिए क्लिक करें।',
		slowMode: {
			header: (time: string) =>
				`धीमा मोड सक्षम है. उपयोगकर्ता प्रत्येक ${time} बार एक संदेश भेज सकते हैं। यह नियम संचालक पर लागू नहीं होता`,
			btnSendMessage: (time: string) => `आप संदेश भेज सकते हैं <br>
प्रत्येक${time}.`,
		},
		shareBet: 'चैट में शर्त साझा करें',
		translate: 'संदेशों का दूसरी भाषा में अनुवाद करें',
	},
	poll: {
		toggleSubmenu: {
			create: 'एक सर्वेक्षण बनाएं',
			edit: 'पोल संपादित करें',
			show: 'पोल दिखाएँ',
			end: 'सम्पूर्ण सर्वेक्षण',
		},
		tooltip: {
			btn: 'वोट',
		},
		settings: {
			title: 'सेटिंग्स',
			titleAdditionally: 'इसके अतिरिक्त',
			switches: {
				quizMode: 'क्विज़ मोड',
				multipleChoice: 'बहुविकल्पी',
				withPhoto: 'फोटो सहित पोल',
			},
		},
		question: {
			title: 'सवाल',
			inputPlaceholder: 'कोई प्रश्न लिखें',
		},
		message: {
			title: 'तुमने वोट दिया',
		},
		answers: {
			title: 'उत्तर विकल्प',
			warning: 'आप अधिकतम 5 उत्तर जोड़ सकते हैं',
		},
		answer: {
			tooltip: 'सही उत्तर का चयन करें',
			inputPlaceholder: 'उत्तर',
		},
		results: {
			text: 'मतदान के नतीजे',
			votes: 'वोट',
			// words: ['वोट', 'वोट', 'वोट'],
		},
		btns: {
			addQuestion: 'एक प्रश्न जोड़ें',
			addAnswers: 'उत्तर जोड़ें',
			addAnswer: 'एक उत्तर जोड़ें',
			publish: 'प्रकाशित करना',
			vote: 'वोट',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'अनुमान लगाना',
				continue: 'जारी रखना',
				checkConnection: 'कनेक्शन की जाँच करें',
				close: 'समाप्त',
			},
			quality: {
				title: 'ध्वनि की गुणवत्ता को रेटिंग दें',
			},
			reason: {
				title: 'कुछ गलत हो गया?',
			},
			connection: {
				title: 'अपने कनेक्शन की गुणवत्ता का परीक्षण करें',
				text: 'जांच में एक मिनट से अधिक समय नहीं लगेगा और आपको समस्या का कारण ढूंढने में मदद मिलेगी।',
			},
			statistics: {
				title: 'आंकड़े एकत्रित किए जा रहे हैं...',
			},
			done: {
				title: 'धन्यवाद!',
				text: 'हम अध्ययन करेंगे कि क्या गलत हुआ और इसे ठीक करना सुनिश्चित करेंगे।',
			},
		},
	},
	chatBot: {
		message: 'बॉट से कुछ भी पूछें',
		warnings: {
			disabled: 'मुझे डिसकनेक्ट कर दिया गया है',
			overloaded: 'हम ओवरलोड हैं',
		},
	},
	audioReason: {
		nothear: 'कुछ भी नहीं सुना गया',
		connection: 'आवाज बाधित हो गयी',
		microphone: 'Нमाइक्रोफ़ोन का उपयोग नहीं किया जा सका',
	},
	screenShare: {
		title: 'स्क्रीन शेयरिंग',
		stopSharing: 'स्क्रीन शेयरिंग बंद करें',
		modal: {
			title: 'स्क्रीन शेयरिंग केवल कंप्यूटर से ही उपलब्ध है।',
			text: `अपने कंप्यूटर पर चैट खोलें और ऊपरी दाएं कोने में 'स्क्रीन शेयरिंग' बटन पर क्लिक करें।`,
			btn: 'आमंत्रण लिंक कॉपी करें।',
		},
	},
	threads: {
		title: 'थ्रेड',
		back: 'वापस',
		replies: ['उत्तर', 'उत्तर', 'उत्तर'],
	},
	attach: {
		submenu: {
			sendImage: `छवि भेजें`,
			shareBet: 'सट्टा साझा करें',
			shareGamble: 'जीत साझा करें',
		},
		images: ['छवि', 'छवियां', 'छवियों'],
		addCaption: 'कैप्शन जोड़ें',
	},
} as unknown as Dictionary;
