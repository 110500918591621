/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Accord',
		subTitle: 'Contrat de licence et politique de confidentialité',
		textFirst: 'J’accepte les conditions ',
		textSecond: 'et donne mon accord au traitement des données personnelles dans les conditions',
		license: 'prévues par le contrat de licence',
		policy: 'et la politique de confidentialité',
	},
	controlPanel: {
		streamEnded: 'La diffusion est terminée',
		banAlert: 'Vous ne pouvez pas écrire dans le chat',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stickers' : 'Emojis',
	},
	accountDeletedScreen: {
		title: 'Profil supprimé',
		text: (date: string) => `Récupérez votre profil avant le ${date}`,
	},
	nameScreen: {
		title: 'Prénom dans le tchat',
		inputPlaceholder: 'Entrez votre prénom',
		nameError: 'Prénom non autorisé',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Choisir un autre:',
	},
	settingsScreen: {
		title: 'Paramètres',
		personal: 'Paramètres personnalisés',
		room: `Configuration de l'espace de discussion`,
		profileBtn: 'Profil',
		languageBtn: 'Langue et interface',
		blockedBtn: 'Bloqués',
		rules: `Règles d'utilisation du tchat`,
		deleteProfile: 'Supprimer le profil',
		slowModeBtn: 'Mode lent',
		agora: {
			microphone: 'Micro',
			playbackDevice: 'Source du son',
		},
	},
	languageScreen: {
		title: 'Langue et interface',
		messagesTranslate: 'Traduction des messages',
		translateMessagesMode: 'Traduire les messages',
		translateMessagesTo: 'Traduire les messages en',
	},
	rulesScreen: {
		title: `Règles d'utilisation du tchat`,
	},
	profileScreen: {
		title: 'Profil',
		selectAnotherPic: 'Choisir un autre avatar',
		chatNameLabel: 'Prénom dans le tchat',
		changeName: 'modifier',
	},
	blockedScreen: {
		title: 'Bloqués',
		emptyListText: 'Pas d’utilisateurs bloqués',
	},
	chatScreen: {
		inputPlaceholder: 'Message…',
		cantWrite: 'Vous ne pouvez pas écrire dans le tchat',
		chatSubmenu: {
			hideMessage: 'Masquer les messages',
			showMessage: 'Afficher les messages',
			removeFromSpeakers: 'Supprimer des speakers',
			setSpeaker: 'Ajouter comme speaker',
			hideOnlyMessage: 'Masquer le message',
			showOnlyMessage: 'Afficher le message',
			reportMessage: 'Signaler un message',
			reportUser: 'Signaler un utilisateur',
			muteSpeaker: 'Désactiver le micro',
			blockUser: 'Bloquer',
			unblockUser: 'Débloquer',
			reply: 'Répondre',
			copy: 'Copier',
			edit: 'Modifier',
			delete: 'Supprimer',
			pinMessage: 'Epingler',
			unpinMessage: 'Retirer',
			goToProfile: 'Accéder au profil',
			replyInThread: 'Répondre dans le fil',
		},
		actionMessage: {
			editing: 'Rédaction',
			reply: 'Répondre',
		},
		status: {
			top: 'Top',
		},
		translating: 'Traduction en cours…',
	},
	chatUsersScreen: {
		title: 'Participants',
		edited: 'Modifié',
		hidden: 'Masqué',
		unreadMessages: 'Messages non lus',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Désactiver le micro du participant',
				subtitle: 'Désactiver le micro',
			},
			setSpeaker: {
				title: 'Retirer des speakers',
				subtitle: 'Tchat par texte uniquement',
			},
			removeFromSpeaker: {
				title: 'Donner la parole',
				subtitle: 'L’utilisateur pourra parler à voix haute',
			},
			showMessages: {
				title: 'Afficher les messages',
				subtitle: 'Visible par l’administrateur uniquement',
			},
			hideMessages: {
				title: 'Masquer les messages',
				subtitle: 'Visible par l’administrateur uniquement',
			},
			blockUser: {
				title: 'Bloquer',
				subtitle: 'Ajouter à la liste de blocage',
			},
			unblockUser: {
				title: `Débloquer?`,
				subtitle: 'Débloquer l’utilisateur',
			},
			blockUserByUser: {
				title: 'Bloquer',
				subtitle: 'Masquer tous les messages de l’utilisateur',
			},
			unblockUserByUser: {
				title: 'Débloquer',
				subtitle: 'Afficher tous les messages de l’utilisateur',
			},
			reportUser: {
				title: 'Signaler un utilisateur',
				subtitle: 'Envoyer un signalement anonyme',
			},
		},
		days: {
			today: 'Aujourd’hui',
			yesterday: 'Hier',
		},
		chatCreated: 'Le tchat est créé,',
	},
	alerts: {
		btns: {
			unblock: 'Débloquer',
			block: 'Bloquer',
			send: 'Envoyer',
			sendAndBlock: 'Envoyer et bloquer',
			show: 'Afficher',
			showAll: 'Tout afficher',
			cancel: 'Annulation',
			yes: 'Oui',
			hide: 'Masquer la sélection',
			hideAll: 'Tout masquer',
			make: 'Faire',
			remove: 'Enlever',
			delete: 'Supprimer',
			deleteAccount: 'Supprimer le profil',
			close: 'Fermer',
			pin: 'Epingler',
			unpin: 'Retirer',
			edit: 'Rédiger',
			end: 'Terminer',
			endAndShare: 'Terminer et partager',
			reset: 'Réinitialiser',
			rules: `Règles d'utilisation du tchat`,
			contactus: 'Nous écrire',
		},
		pinMessage: {
			title: 'Epingler le message ?',
		},
		unPinMessage: {
			title: 'Retirer le message ?',
		},
		accountRestored: {
			title: 'Profil récupéré',
		},
		closeApp: {
			title: 'Vous êtes sûr de vouloir partir ?',
		},
		removeMessage: {
			title: 'Supprimer le message?',
		},
		report: {
			title: 'Envoyer un signalement ?',
			subtitle: 'Vous pouvez bloquer l’utilisateur pour ne plus voir vos messages respectifs.',
		},
		banUser: {
			title: (name: string) => `Bloquer ${name} ?`,
			subtitle: (text: string) => `L’utilisateur sera bloqué à cause de ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Débloquer ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Afficher les messages de ${name}?`,
			subTitle: 'Les autres utilisateurs pourront voir les messages de cet utilisateur',
		},
		hideUserMessages: {
			title: (name: string) => `Masquer les messages de ${name}?`,
			subTitle: 'Les autres utilisateurs ne pourront plus voir les messages de cet utilisateur',
		},
		showUserMessage: {
			title: (name: string) => `Afficher le message de ${name}?`,
			subTitle: 'Les autres utilisateurs pourront voir ce message',
		},
		hideUserMessage: {
			title: (name: string) => `Masquer le message de ${name}?`,
			subTitle: 'Les autres utilisateurs ne pourront plus voir ce message',
		},
		blockUserInRoom: {
			title: (name: string) => `Bloquer ${name}?`,
			subTitle: 'L’utilisateur sera bloqué à cause de $ text.',
		},
		unBlockUserInRoom: {
			title: (name: string) => `Débloquer ${name}?`,
			subTitle: 'L’utilisateur pourra participer à cet espace de discussion.',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Bloquer ${name}?`,
			subTitle: 'Vous ne verrez pas vos messages respectifs',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Donner la parole à ${name}?`,
			unTitle: (name: string) => `Retirer la parole à ${name}?`,
		},
		banInApp: {
			title: 'L’administrateur vous a bloqué ',
			for: 'à cause de',
			subtitle: (bannedUntil: string) => `Vous êtes bloqués jusqu’à ${bannedUntil}`,
			permanent: 'Vous êtes bloqués de façon permanente',
		},
		banInAppBadNickname: {
			title: 'L’administrateur vous a bloqué pour un prénom non autorisé',
			subtitle: 'Modifier le prénom dans votre profil et accédez à nouveau au tchat',
		},
		deleteAccount: {
			title: 'Suppression du profil',
			subTitle:
				'Toutes les données seront supprimées. Vous pourrez récupérer votre profil dans un délai de 30 jours.',
		},
		pollEdit: {
			title: 'Rédiger un sondage?',
			subTitle: 'Les réponses des utilisateurs seront réinitialisées',
		},
		pollReset: {
			title: 'Réinitialiser?',
			subTitle: 'Le sondage ne saura pas sauvegardé',
		},
		pollEnd: {
			title: 'Vous êtes sûr de vouloir terminer le sondage?',
		},
		pollDelete: {
			title: 'Supprimer le sondage?',
		},
		agoraCheck: {
			updateIOSText: `Mettez à jour votre version d'iOS afin de profiter de toutes les fonctionnalités de l'application.`,
			allowAccess: {
				title: 'Fort et distinctement',
				text: 'Pour utiliser le micro et le haut-parleur, allez dans les paramètres de l’application et confirmez l’accès.',
			},
			microphoneAndSpeakerText:
				'Le micro et le haut-parleur sont accessibles uniquement à partir de la version 14.3',
			microphoneText: 'Le micro est disponible à partir de la version 14.3',
			btn: `D'accord`,
		},
	},
	eventScreen: {
		title: 'Evènements prévus',
		titleSoon: 'La diffusion va bientôt commencer',
		subtitle: 'Le tchat va bientôt ouvrir, un instant.',
		days: 'jours',
		hours: 'heures',
		minutes: 'minutes',
		seconds: 'secondes',
		startTitle: 'Début',
	},
	toasts: {
		reciveComplaint: 'Nous avons bien reçu votre signalement',
		setSpeakerRoleForUser: 'L’utilisateur est désormais speaker',
		unSpeakerRoleForUser: 'L’utilisateur est supprimé des speakers',
		messagesShown: 'Les messages sont affichés',
		messagesHidden: 'Les messages sont masqués',
		messageShown: 'Le message est affiché',
		messageHidden: 'Le message est masqué',
		userBlocked: 'L’utilisateur est bloqué',
		userUnBlocked: 'L’utilisateur est débloqué',
		youAreSpeaker: 'Vous êtes speaker ! Dites quelque chose',
		youAreNotSpeaker: 'L’administrateur vous a supprimé des speakers',
		userIsSpeaker: (name: string) => `${name} est désormais speaker`,
		userNotSpeaker: (name: string) => `${name} n’est plus speaker`,
		yourUserNameInChat: (name: string) => `Votre prénom dans le tchat — ${name}`,
		messageRemoved: 'Le message est supprimé',
		messageCopied: 'Le texte est copié',
		obscenities: 'Essayez de le dire autrement',
		requestSent: 'La demande est envoyée',
		requestDecline: 'Votre demande de micro est refusée',
		youareblocked: (text: string) =>
			`Vous êtes bloqués à cause de ${text}. Vous ne pouvez pas écrire dans le tchat.`,
		youareunblocked: 'Vous êtes débloqués. Vous pouvez écrire dans le tchat.',
		youAreModerator: 'Vous êtes modérateur!',
		youAreNoModerator: 'Vous n’êtes plus modérateur',
		micMuted: 'Votre micro est désactivé',
		noInet: 'Il n’y a a pas de connexion internet',
		messagePinned: 'Le message est épinglé',
		messageUnpinned: 'Le message est retiré',
		pollPublished: 'Le sondage est publié',
		pollPublishedError: 'Erreur ! Veuillez réessayer plus tard',
		pollAddPic: 'Ajouter une image',
		pollAddPicError: 'Veuillez choisir une autre image',
		pollDeleted: 'Le sondage est supprimé',
		pollEnded: 'Le sondage est terminé',
		sendStickerWhenSlowModeIsOn: (time: string) =>
			`Veuillez attendre ${time} pour l’envoi du sticker`,
		streamConnecting: 'Connexion à la diffusion',
		linkProhibited: 'Vous ne pouvez pas envoyer de lien via le tchat.',
		phoneProhibited: 'Vous ne pouvez pas envoyer de numéro de téléphone via le tchat.',
		profileHidden: `L'utilisateur a masqué son profil.`,
		imageSizeWarning: 'Vous pouvez télécharger une image de 10 Mo maximum',
		imageBlocked: `Le modérateur a bloqué l'envoi de l'image`,
		streamKeyCopied: 'Clé de diffusion copiée',
	},
	btns: {
		acceptandgo: 'Accepter et continuer',
		saveandgo: 'Sauvegarder et continuer',
		save: 'Sauvegarder',
		unblock: 'Débloquer',
		block: 'Bloquer',
		restoreAccount: 'Récupérer le profil',
		close: 'Fermer',
	},
	coopyrights: 'Fonctionne sur la base de Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Signaler un message' : 'Informer d’une infraction',
			subtitle: 'Les signalements sont anonymes',
			btns: {
				violence: 'Les insultes, menaces, grossièretés',
				provocations: 'Provocations',
				personalDetails: 'Données personnelles',
				flood: 'Flood',
				spam: 'Spam',
				fraudBegging: 'Fraude, mendicité',
				other: 'Autre',
			},
		},
		ban: {
			title: 'Bloquer l’utilisateur',
			subtitle: 'Veuillez indiquer la raison du blocage',
			btns: {
				insults: 'Les insultes, menaces, grossièretés',
				personal: 'Des données personnelles',
				flood: 'Flood',
				links: 'Liens vers des services externes',
				violence: 'Menaces de violences',
				fraud: 'Tentatives de fraudes et mendicité',
				nickname: 'Nom de profil non autorisé',
				politicial: 'Provocations politiques, sexuelles et autres',
				repeated: 'Nouvelle infraction',
				auto: 'Signalement des utilisateurs du tchat',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Vous n’avez pas de speakers en attente',
			handsUp: 'Une main est levée',
			waiting: 'Speakers en attente',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Veuillez donner la bonne réponse',
				typeQuizMultiple: 'Sélectionnez les bonnes réponses',
				typePoll: 'Partagez votre opinion',
				typePollMultiple: 'Plusieurs réponses sont possibles',
				default: 'Sondage publique',
			},
			btns: {
				reply: 'Répondre',
				vote: 'Voter',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Jeu concours',
				typePoll: 'Plusieurs réponses possibles',
				default: 'Sondage publique',
			},
			btns: {
				edit: 'Rédiger un sondage',
				end: 'Terminer',
			},
		},
		pollCreated: {
			titles: {
				created: 'Créer un sondage',
				typeQuiz: 'Jeu concours',
				typePoll: 'Plusieurs réponses possibles',
				default: 'Sondage publique',
			},
		},
		changeName: {
			title: 'Modifiez votre prénom dans le tchat.',
			subtitle: `Vous êtes bloqués car votre nom d'utilisateur n'est pas autorisé`,
		},
		temporaryAgreement: {
			title: `Règles d'utilisation du tchat`,
			rules: {
				bePolite: {
					title: 'Soyez poli',
					text: 'Les propos hostiles et obscènes sont interdits dans le tchat.',
				},
				beAttentive: {
					title: 'Soyez vigilant',
					text: 'Ne partagez pas de données personnelles et n’en demandez pas aux autres.',
				},
				notAdvertise: {
					title: 'Ne faites pas de publicité',
					text: 'Partagez plutôt votre opinion ou sentiments.',
				},
			},
			btns: {
				allRules: 'Toutes les règles',
				acceptAndContinue: 'Accepter et continuer',
			},
		},
		slowMode: {
			title: 'Mode lent',
			text: 'Les participants ne peuvent pas envoyer de messages dans le tchat plus souvent que le délai imparti',
			enable: 'Activer le mode lent',
			disable: 'Désactiver le mode lent',
			sendTimeout: `Délai d'envoi`,
		},
		slowModeTimeout: {
			title: 'Temps de réponse dépassé',
		},
		chatBot: {
			title: 'Utilisation d’un chatbot',
			rules: [
				{
					title: 'Intelligence artificielle',
					text: 'Un bot imite les échanges entre des personnes et fournit des informations dans la limite de ses connaissances. Rappelez-vous que ses réponses peuvent être limitées et ne remplacent pas un humain.',
				},
				{
					title: 'Un bot peut se tromper',
					text: 'Un bot ne peut garantir la précision et fiabilité d’une information.',
				},
			],
			btns: {
				close: 'Fermer',
			},
		},
		shareBet: {
			title: 'Afficher',
			noBetsTitle: 'Impossible de trouver vos paris',
			noBetsText: `Il est possible d'afficher ses paris dans un délai de 14 jours`,
			btns: {
				share: 'Afficher sa mise',
				copy: 'Répéter le pari',
			},
			status: {
				null: 'Dans le jeu',
				win: 'Gagnant',
				lose: 'Perdant',
			},
			betTypeV1: {
				express: 'Express',
				single: 'Unique',
			},
			betTypeV2: {
				single: 'Unique',
				multi: 'Plus.',
				system: 'System',
			},
			totalFactor: 'Coeff.',
			bet: 'pari',
			showMore: 'Afficher encore',
			betRepeated: 'Le pari a été repris par',
		},
		shareGamble: {
			noGamblesText: `Vous n'avez pas encore de gains à partager. Continuez à jouer, et votre prochain gain apparaîtra ici pour que vous puissiez le partager avec d'autres joueurs !`,
		},
		record: {
			text: `L'enregistrement de la diffusion est en cours…`,
		},
		agoraDevices: {
			title: 'Paramètres audio',
			text: 'Vérifiez que vous avez sélectionné le bon micro',
		},
		translate: {
			title: 'Traduction des messages',
			chooseLanguage: 'Sélectionnez la langue',
			descr: 'Tous les messages du tchat seront automatiquement traduits dans la langue choisie.',
			btn: 'Activer la traduction',
		},
		streamSettings: {
			title: 'Paramètres du flux',
			description:
				'Téléchargez et installez un encodeur vidéo. Entrez la clé du flux dans ses paramètres',
			shareScreen: {
				title: "Partage d'écran",
				description: 'Aucun réglage spécial requis',
			},
			rtmp: {
				title: 'Source de flux externe',
				description: 'Utiliser un programme supplémentaire pour diffuser le flux',
			},
			streamKey: 'Clé de flux',
			showStreamKey: 'Afficher',
			hideStreamKey: 'Masquer',
			btns: {
				continue: 'Continuer',
				close: 'Fermer',
				ok: 'Terminé',
			},
		},
	},
	pinnedMessage: {
		title: 'Message épinglé',
	},
	errorPage: {
		error: 'Erreur',
		types: [
			// errorNumber 0
			{
				title: 'Profil non reconnu',
				text: 'Essayez de vous reconnecter au tchat',
			},
			// errorNumber 1
			{
				title: 'Impossible de trouver le tchat',
				text: 'Essayez de vous reconnecter',
			},
			// errorNumber 2
			{
				title: 'Impossible de télécharger le tchat',
				text: 'Essayez de redémarrer',
			},
			// errorNumber 3
			{
				title: 'Profil non reconnu',
				text: 'Le tchat en mode incognito n’est pas disponible',
			},
			// errorNumber 4
			{
				title: `Nom d'utilisateur non autorisé`,
				text: `Changer le nom d'utilisateur dans votre profil et reconnectez-vous au tchat`,
			},
		],
	},
	tooltips: {
		emotions: 'Maintenez la touche appuyée pour modifier',
		badInet: 'Mauvaise connexion au réseau internet',
		blockedUsers: 'Voir la liste des utilisateurs bloqués',
		waitingSpeakers: 'Un des participants veut parler.',
		wantSpeak: 'Cliquez pour demander le droit à la parole.',
		slowMode: {
			header: (time: string) =>
				`Mode lent activé. Les utilisateurs peuvent envoyer un message <br>chaque ${time}. Cette restriction ne concernent pas les modérateurs.`,
			btnSendMessage: (time: string) => `Vous pouvez envoyer des messages <br> toutes les ${time}.`,
		},
		shareBet: 'Afficher sa mise',
		translate: 'Traduire les messages dans une autre langue',
	},
	poll: {
		toggleSubmenu: {
			create: 'Créer un sondage',
			edit: 'Rédiger un sondage',
			show: 'Afficher un sondage',
			end: 'Terminer le sondage',
		},
		tooltip: {
			btn: 'Voter',
		},
		settings: {
			title: 'Paramètres',
			titleAdditionally: 'En complément',
			switches: {
				quizMode: 'Mode jeu concours',
				multipleChoice: 'Sélection de plusieurs réponses',
				withPhoto: 'Sondage avec les images',
			},
		},
		question: {
			title: 'Question',
			inputPlaceholder: 'Entrez la question',
		},
		message: {
			title: 'Vous avez voté',
		},
		answers: {
			title: 'Possibilités de réponse',
			warning: 'Vous pouvez ajouter jusqu’à 5 réponses',
		},
		answer: {
			tooltip: 'Choisissez la bonne réponse',
			inputPlaceholder: 'Réponse',
		},
		results: {
			text: 'Résultats du sondage',
			votes: 'Votes',
			// words: ['голос', 'голоса', 'голосов'],
		},
		btns: {
			addQuestion: 'Ajouter une question',
			addAnswers: 'Ajouter des réponses',
			addAnswer: 'Ajouter une réponse',
			publish: 'Publier',
			vote: 'Votez',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Evaluer',
				continue: 'Continuer',
				checkConnection: 'Vérifier la connexion',
				close: 'Fermer',
			},
			quality: {
				title: 'Vérifier la qualité du son',
			},
			reason: {
				title: `Que s'est t'il passé?`,
			},
			connection: {
				title: 'Vérifiez la qualité de la connexion',
				text: `La vérification ne prendra que quelques minutes et permettra d'identifier la cause du problème`,
			},
			statistics: {
				title: 'Collecte des statistiques…',
			},
			done: {
				title: 'Merci!',
				text: `Nous allons analyser l'incident et corriger le problème.`,
			},
		},
	},
	chatBot: {
		message: 'Formulez une demande au bot',
		warnings: {
			disabled: 'On m’a désactivé',
			overloaded: 'Nous sommes surchargés',
		},
	},
	audioReason: {
		nothear: 'Le son ne fonctionnait pas',
		connection: 'Le son était interrompu',
		microphone: 'Il était impossible d’utiliser le micro',
	},

	copybetting: {
		orderStatus: {
			won: 'Won',
			lost: 'Lost',
			new: 'New',
			pending: 'Pending',
			cashedOut: 'Cashed out',
			boreDraw: 'Bore draw',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Canceled By Operator',
			megaBetCanceledByPlayer: 'MegaBet Canceled By Player',
			megaBetCanceledBySystem: 'MegaBet Canceled By System',
			betGamesPending: 'BetGames Pending',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'Paid',
			initialWin: 'Initial Win',
			autoBetCanceledBySystem: 'AutoBet Canceled By System',
			autoBetCanceledByPlayer: 'AutoBet Canceled By Player',
			autobetPending: 'Autobet Pending',
			goalAhead: 'Goal Ahead',
			canceled: 'Canceled',
			return: 'Return',
		},
		stakeStatus: {
			pending: 'Pending',
			won: 'Won',
			lost: 'Lost',
			return: 'Return',
			lostReturn: 'Lost | Return',
			wonReturn: 'Won | Return',
			canceled: 'Canceled',
			initialReturn: 'Initial Return',
			pendingInitialReturn: 'Pending | Initial Return',
			wonInitialReturn: 'Won | Initial Return',
		},
	},

	screenShare: {
		title: 'Partager l’écran',
		stopSharing: 'Stopper la diffusion',
		modal: {
			title: 'Il est possible de partager l’écran uniquement depuis un ordinateur',
			text: 'Ouvrez le tchat depuis un ordinateur et cliquez sur le bouton « Partage d’écran » en haut à droite.',
			btn: 'Copier le lien pour l’exécution',
		},
	},
	threads: {
		title: 'Fil',
		back: 'Retour',
		replies: ['Réponse', 'Vastaukset', 'Réponses'],
	},
	attach: {
		submenu: {
			sendImage: `Envoyer l'image`,
			shareBet: 'Partager le pari',
			shareGamble: 'Partager les gains',
		},
		images: ['image', 'images', 'images'],
		addCaption: 'Ajouter une légende',
	},
} as unknown as Dictionary;
