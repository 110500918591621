import {AlertBtnType} from 'models/enums/Alert.enum';
import appService from 'store/appService';
import userService from 'store/userService';
import roomServices from 'store/roomService';
import alertService from 'store/alertService';
import toastService from 'store/toastService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useMessage from 'hooks/useMessage';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import './pinnedMessage.scss';
import classNames from 'classnames';
import {getNextElementInArray, getPreviousElementInArray} from 'utils/helpers';
import agoraService from 'store/agoraService';
import {isDesktop} from 'react-device-detect';

interface IPinnedMessageProps {
	handleClick: (messageId: number) => void;
}

const PinnedMessage: FunctionComponent<IPinnedMessageProps> = function PinnedMessage(props) {
	const {handleClick} = props;
	const {appReadOnly, appIcons, appEnableFullscreenOption} = useLocalObservable(() => appService);
	const {isUserExternalIdCorrect, userData} = useLocalObservable(() => userService);
	const {
		roomId,
		myTalker,
		pinnedMessages,
		activePinnedMessage,
		setActivePinnedMessage,
		localMessages,
	} = useLocalObservable(() => roomServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const {isFullScreenVideo} = useLocalObservable(() => agoraService);

	const {alerts, toasts, pinnedMessage: pinnedMessageTranslate} = useL10n();
	const {getAppIcon} = useAppData();
	const {getMessageText} = useMessage();

	const [slicedPinnedMessages, setSlicedPinnedMessages] = useState<any[]>([]);

	const messagesRefs = useRef<Set<HTMLElement | null>>(new Set([]));
	const activePinnedMessageRef = useRef<any>(null);

	const {IcoPin} = appIcons;

	const talkerIsModer = !!myTalker?.isModer || userData?.isModer;
	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
		'--panel-top-transparent'
	);
	const pinnedMessageClasses = classNames('pinned-message', {
		'pinned-message--top': cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent),
		'pinned-message--fullscreen-mode': isFullScreenMode,
	});

	const pinnedMessageTextClasses = (pinnedMessageId: number) =>
		classNames('pinned-message__text', {
			'pinned-message__text--active': pinnedMessageId === activePinnedMessage?.id,
		});
	const pinnedMessagePaginationItemClasses = (pinnedMessageId: number, index: number) => {
		const pinnedMessageIndex = pinnedMessages.findIndex(
			element => element.id === activePinnedMessage?.id
		);
		return classNames('pinned-message__pagination-item', {
			'pinned-message__pagination-item--active':
				(pinnedMessages.length < 6 && activePinnedMessage?.id === pinnedMessageId) ||
				(pinnedMessages.length > 5 && index === 0 && pinnedMessageIndex === 0) ||
				(pinnedMessages.length > 5 && index === 1 && pinnedMessageIndex === 1) ||
				(pinnedMessages.length > 5 &&
					index === 2 &&
					pinnedMessageIndex <= pinnedMessages.length - 3 &&
					pinnedMessageIndex > 1) ||
				(pinnedMessages.length > 5 &&
					index === 3 &&
					pinnedMessageIndex === pinnedMessages.length - 2) ||
				(pinnedMessages.length > 5 &&
					index === 4 &&
					pinnedMessageIndex === pinnedMessages.length - 1),
		});
	};

	const unpinMessage = () => {
		if (!appReadOnly && isUserExternalIdCorrect && roomId && activePinnedMessage) {
			showAlert({
				title: alerts.unPinMessage.title,
				buttons: [
					{
						text: alerts.btns.unpin,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							SocketIoServices.emitUnpinMessage({
								externalRoomId: roomId,
								messageId: activePinnedMessage.id,
							});
							addToast({
								iconName: ToastIconsName.unPin,
								liveTime: 3000,
								message: toasts.messageUnpinned,
								cancellable: true,
							});
							hideAlert();
						},
					},
					{
						text: alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const clickOnPinnedMessageHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && activePinnedMessage !== null) {
			handleClick(activePinnedMessage.id);
			const prevPinnedMessage = getPreviousElementInArray(pinnedMessages, activePinnedMessage.id);
			if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
			else setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
		}
	};

	useEffect(() => {
		if (pinnedMessages.length) {
			if (pinnedMessages.length > 5) {
				setSlicedPinnedMessages(pinnedMessages.slice(0, 5));
			} else {
				setSlicedPinnedMessages([...pinnedMessages]);
			}
		}
	}, [pinnedMessages.length]);

	const _observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				const {top, bottom} = entry.boundingClientRect;
				const prevPinnedMessage = getPreviousElementInArray(
					pinnedMessages,
					activePinnedMessageRef.current?.id
				);
				const nextPinnedMessage = getNextElementInArray(
					pinnedMessages,
					activePinnedMessageRef.current?.id
				);

				const currentElement = entry.target as HTMLDivElement;
				const currentElementId = Number(currentElement.getAttribute('data-id'));

				if (entry.isIntersecting) {
					const intersectingElement = entry.target as HTMLDivElement;
					const messageId = Number(intersectingElement.getAttribute('data-id'));

					if (
						messageId !== activePinnedMessageRef.current?.id &&
						messageId > nextPinnedMessage?.id
					) {
						if (bottom > 100) {
							if (nextPinnedMessage) setActivePinnedMessage(nextPinnedMessage);
						}
					}
				} else if (currentElementId <= activePinnedMessageRef.current?.id) {
					if (top > 100) {
						if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
					}
				}
			});
		},
		{rootMargin: '100px 0px 100px 0px', threshold: [0, 0.25, 0.5, 0.75, 1]}
	);

	useEffect(() => {
		messagesRefs.current.forEach(messageElement => {
			if (messageElement) _observer.observe(messageElement);
		});

		return () => {
			messagesRefs.current.forEach(messageElement => {
				if (messageElement) _observer.unobserve(messageElement);
			});
		};
	}, [messagesRefs.current.size, pinnedMessages.length, activePinnedMessageRef.current?.id]);

	useEffect(() => {
		if (activePinnedMessage) {
			setTimeout(() => {
				activePinnedMessageRef.current = activePinnedMessage;
				localMessages.forEach(message => {
					const element = document.querySelector(
						`.chat__message[data-id="${message?.id}"]`
					) as HTMLElement;

					if (element) {
						messagesRefs.current.add(element);
					}
				});
			}, 1000);
		}
	}, [activePinnedMessage, localMessages.length]);

	return (
		<div className={pinnedMessageClasses} data-hint='pin'>
			<div className='pinned-message__container'>
				<Button onClick={clickOnPinnedMessageHandler} className='pinned-message__scrollbtn' />
				{pinnedMessages && pinnedMessages?.length > 1 && (
					<div className='pinned-message__pagination'>
						{slicedPinnedMessages.map((el: any, index: number) => {
							return (
								<span
									key={el.id}
									className={pinnedMessagePaginationItemClasses(el.id, index)}
									style={{
										height: `${
											32 / slicedPinnedMessages.length - 3 / (slicedPinnedMessages.length - 1)
										}px`,
									}}
								/>
							);
						})}
					</div>
				)}
				{getAppIcon(IcoPin.pic, {className: 'pinned-message__icon'})}

				<div className='pinned-message__body'>
					<p className='pinned-message__title'>{pinnedMessageTranslate.title}</p>
					<div className='pinned-message__wrap'>
						<div
							className='pinned-message__elems'
							style={{
								transform: `translateY(-${
									14 * pinnedMessages.findIndex(element => element.id === activePinnedMessage?.id)
								}px)`,
							}}>
							{pinnedMessages.map(el => {
								return (
									<p className={pinnedMessageTextClasses(el.id)} key={el.id}>
										{(el && getMessageText(el)) ||
											el.advertisement?.title ||
											el.advertisement?.text}
									</p>
								);
							})}
						</div>
					</div>
				</div>
			</div>

			{talkerIsModer && <Button className='pinned-message__unpin' onClick={unpinMessage} />}
		</div>
	);
};

export default observer(PinnedMessage);
