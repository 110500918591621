import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {PollOptions} from 'components/poll/pollOptions';
import appService from 'store/appService';
import pollService from 'store/pollService';
import roomServices from 'store/roomService';
import {PollEditActionType, PollType} from 'models/enums/Poll.enum';
import RoomService from 'services/api/RoomService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userService';
import alertService from 'store/alertService';
import toastService from 'store/toastService';
import {AlertBtnType} from 'models/enums/Alert.enum';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import './pollEdit.scss';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import Button from 'components/hoc/Button';

const PollEditModal: FunctionComponent = function PollEditModal() {
	const {modals, alerts, toasts} = useL10n();
	const {pollEditModalVisible, togglePollEditModalVisible, togglePollCreateModalVisible} =
		useLocalObservable(() => modalServices);
	const {appIcons} = useLocalObservable(() => appService);
	const {poll} = useLocalObservable(() => pollService);
	const {roomId} = useLocalObservable(() => roomServices);
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);

	const {getAppIcon} = useAppData();

	const {IcoClose, IcoSubmenuBasket} = appIcons;

	const onModalClosehandler = () => {
		togglePollEditModalVisible(false);
	};

	const onRemoveBtnClickHandler = async () => {
		if (roomId && poll) {
			showAlert({
				title: alerts.pollDelete.title,
				buttons: [
					{
						text: alerts.btns.delete,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: async () => {
							hideAlert();
							togglePollEditModalVisible(false);
							const response = await RoomService.removePoll(roomId, poll.id, accessToken);
							if (response.status === ResponseStatus.SUCCESS) {
								addToast({
									iconName: ToastIconsName.poll,
									liveTime: 3000,
									message: toasts.pollDeleted,
									cancellable: true,
								});
							}
						},
					},
					{
						text: alerts.btns.cancel,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							hideAlert();
						},
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const onEditBtnClickHandler = () => {
		showAlert({
			title: alerts.pollEdit.title,
			subtitle: alerts.pollEdit.subTitle,
			buttons: [
				{
					text: alerts.btns.edit,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: () => {
						togglePollEditModalVisible(false);
						togglePollCreateModalVisible(true);
						hideAlert();
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const onEndBtnClickHandler = () => {
		let alertButtons = [
			{
				text: alerts.btns.end,
				type: AlertBtnType.NORMAL,
				onPress: async () => {
					hideAlert();
					if (roomId && accessToken) {
						const response = await RoomService.endPoll(roomId, accessToken);
						if (response.status === ResponseStatus.SUCCESS) {
							addToast({
								iconName: ToastIconsName.pollEndAccent,
								liveTime: 3000,
								message: toasts.pollEnded,
								cancellable: true,
							});
						}
					}
				},
			},
			{
				text: alerts.btns.cancel,
				type: AlertBtnType.DESTRUCTIVE,
				onPress: () => {
					hideAlert();
				},
			},
		];

		if (poll?.type === PollType.POLL) {
			alertButtons = [
				{
					text: alerts.btns.endAndShare,
					type: AlertBtnType.NORMAL,
					onPress: async () => {
						hideAlert();
						if (roomId && accessToken) {
							const response = await RoomService.endPoll(roomId, accessToken, true);
							if (response.status === ResponseStatus.SUCCESS) {
								addToast({
									iconName: ToastIconsName.pollEndAccent,
									liveTime: 3000,
									message: toasts.pollEnded,
									cancellable: true,
								});
							}
						}
					},
				},
				...alertButtons,
			];
		}

		showAlert({
			title: alerts.pollEnd.title,
			buttons: alertButtons,
			closeOnBackdropPress: true,
		});
	};

	const getSupTitle = () => {
		if (poll?.type === PollType.QUIZ) {
			return modals.pollEdit.supTitles.typeQuiz;
		}
		if (poll?.type === PollType.POLL && poll.isMultiple) {
			return modals.pollEdit.supTitles.typePoll;
		}
		return modals.pollEdit.supTitles.default;
	};

	if (!pollEditModalVisible.visible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--poll-edit'
			isOpen={pollEditModalVisible.visible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<Button
					className='modal__remove-btn'
					onClick={onRemoveBtnClickHandler}
					aria-label='remove-poll'>
					{getAppIcon(IcoSubmenuBasket.pic)}
				</Button>
				<div className='modal__title'>{getSupTitle()}</div>
				<Button className='modal__close' aria-label='modal-close' onClick={onModalClosehandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='poll-edit'>
						<div className='poll-edit__head'>
							<p className='poll-edit__title'>{poll?.text}</p>
						</div>
						<div className='poll-edit__content'>
							<PollOptions viewType='edit' viewVertical={!!(poll && poll.options.length < 4)} />
						</div>
					</div>
				</div>
				{pollEditModalVisible.typeAction === PollEditActionType.EDIT ? (
					<Button className='btn btn--poll btn--poll' onClick={onEditBtnClickHandler}>
						{modals.pollEdit.btns.edit}
					</Button>
				) : (
					<Button className='btn btn--poll btn--poll' onClick={onEndBtnClickHandler}>
						{modals.pollEdit.btns.end}
					</Button>
				)}
			</div>
		</Modal>
	);
};

export default observer(PollEditModal);
